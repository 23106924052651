import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { GroupResponseSheetComponent } from './components/group-response-sheet/group-response-sheet.component';
import { GroupResponseModalComponent } from './components/group-response-modal/group-response-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from 'src/app/_services/process-httpmsg.service';
import { ProjectService } from 'src/app/_services/project.service';
import { ResponseService } from 'src/app/_services/response.service';

@Component({
  selector: 'app-participant-responses',
  templateUrl: './participant-responses.component.html',
  styleUrls: ['./participant-responses.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ParticipantResponsesComponent implements OnInit, OnDestroy {
  project_id = this.route.parent.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');

  project: Observable<any>;
  ngDestroy$ = new Subject();
  colour_primary: string = '#880e4f'; //Default to costello purple
  colour_secondary: string = '#bdbdbd'; //Default to accent grey

  dataSource: TableDataSource;
  displayedColumns = ['question', 'response', 'note', 'action'];
  tableData = [];
  expandedElement: string | null;
  errMsg: String;
  loadingError$ = new Subject<boolean>();
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'question';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageIndex = 0;
  pageSize = 10;

  constructor(
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private responseService: ResponseService,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.responseService,
      this.processHTTPMsgService
    );
    this.dataSource.loadResponses(this.project_id, this.round_id).then(
      (res) => {
        this.tableData = res;

        this.showTable = true;
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );

    this.project = this.projectService.getProject(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading project:', error);
        this.loadingError$.next(true);
        return of();
      })
    );
    this.project.pipe(first()).subscribe(
      (result) => {
        if (result.data.client_colour_primary) {
          this.colour_primary = result.data.client_colour_primary;
        }
        if (result.data.client_colour_secondary) {
          this.colour_secondary = result.data.client_colour_secondary;
        }
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (screenWidth <= 599) {
      this.displayedColumns = ['question', 'response', 'action'];
    } else {
      this.displayedColumns = ['question', 'response', 'note', 'action'];
    }
  }

  expand_contract(row) {
    if (this.expandedElement === row) {
      this.expandedElement = null;
    } else {
      this.expandedElement = row;
    }
  }

  groupResponses(question) {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(GroupResponseSheetComponent, {
        data: {
          project_id: this.project_id,
          question: question,
          barcolour: this.colour_primary,
        },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((helpMessage) => {
          console.log(helpMessage);
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(GroupResponseModalComponent, {
        data: {
          project_id: this.project_id,
          question: question,
          barcolour: this.colour_primary,
        },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((helpMessage) => {
          console.log(helpMessage);
        });
    }
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private responseService: ResponseService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadResponses(project_id, round_id): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.responseService
        .getParticipantResponses(project_id, round_id)
        .subscribe(
          (responses) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(responses.data);
            resolve(responses.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
