import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from '../../../_services/user.service';
import { ProjectService } from '../../../_services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, map, startWith, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AddParticipantModalComponent,
  AddParticipantSheetComponent,
} from './components/project-team-add-participant/project-team-add-participant.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { RoundService } from '../../../_services/round.service';
import {
  ConfirmDeleteModalComponent,
  ConfirmDeleteSheetComponent,
} from '../../../components/confirm-delete/confirm-delete.component';
import {
  AddClientModalComponent,
  AddClientSheetComponent,
} from './components/project-team-add-client/project-team-add-client.component';
import {
  AddCostelloModalComponent,
  AddCostelloSheetComponent,
} from './components/project-team-add-costello/project-team-add-costello.component';
import { UserEditModalComponent } from 'src/app/components/user-edit/user-edit.component';
import { ParticipantUploadComponent } from './components/participant-upload/participant-upload.component';

@Component({
  selector: 'app-project-team',
  templateUrl: './project-team.component.html',
  styleUrls: ['./project-team.component.scss'],
})
export class ProjectTeamComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');

  allClients: Observable<any>;
  allCostello: Observable<any>;
  team: Observable<any>;
  costelloTeam = [];
  clientTeam = [];
  participants = [];
  clientOptions = [];
  costelloOptions = [];
  filteredClientOptions: Observable<any[]>;
  filteredCostelloOptions: Observable<any[]>;
  primary_contact: Number;

  newCostelloMember = new UntypedFormControl();
  newClientMember = new UntypedFormControl();
  newParticipant = new UntypedFormControl();
  mailer = new UntypedFormControl();

  siteKey = '6LcgJ1kaAAAAANz8ev8f7_4PKlAoRgVtaqBQ0ywx';

  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private projectService: ProjectService,
    private roundService: RoundService,
    private userService: UserService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.team = this.projectService.getProjectUsers(this.project_id).pipe(
      first(),
      catchError((error) => {
        this.errMsg = error;
        console.error('Error loading team:', error);
        this.loadingError$.next(true);
        return of();
      })
    );

    this.team.pipe(first()).subscribe(
      (projectUsers) => {
        this.costelloTeam = projectUsers.data.filter((user) => {
          return !user.client_account && !user.participant_account;
        });
        const costelloExclusion = JSON.stringify(
          this.costelloTeam.map((user) => user.id.toString())
        )
          .replace('[', '{')
          .replace(']', '}');
        this.allCostello = this.userService
          .getUsers('false', costelloExclusion, 'false')
          .pipe(
            catchError((error) => {
              this.errMsg = error;
              console.error('Error loading Costello users:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
        this.allCostello.pipe(first()).subscribe(
          (allCostello) => {
            this.costelloOptions = allCostello.data;
          },
          (error) => {
            this.errMsg = error;
          }
        );
        this.filteredCostelloOptions = this.newCostelloMember.valueChanges.pipe(
          startWith(''),
          map((value) => (typeof value === 'string' ? value : value.name)),
          map((name) =>
            name ? this._costellofilter(name) : this.costelloOptions.slice()
          )
        );

        this.clientTeam = projectUsers.data.filter((user) => {
          return user.client_account;
        });
        const clientExclusion = JSON.stringify(
          this.clientTeam.map((user) => user.id.toString())
        )
          .replace('[', '{')
          .replace(']', '}');
        this.allClients = this.userService
          .getUsers('true', clientExclusion)
          .pipe(
            catchError((error) => {
              this.errMsg = error;
              console.error('Error loading clients:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
        this.allClients.pipe(first()).subscribe(
          (allClients) => {
            this.clientOptions = allClients.data;
          },
          (error) => {
            this.errMsg = error;
          }
        );
        this.filteredClientOptions = this.newClientMember.valueChanges.pipe(
          startWith(''),
          map((value) => (typeof value === 'string' ? value : value.name)),
          map((name) =>
            name ? this._clientfilter(name) : this.clientOptions.slice()
          )
        );

        this.participants = projectUsers.data.filter((user) => {
          return user.participant_account;
        });
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );

    // Retrieve primary contact
    this.projectService
      .getProject(this.project_id)
      .pipe(first())
      .subscribe(
        (project) => {
          this.primary_contact = project.data.primary_contact_id;
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id]);
  }

  displayFn(user): string {
    return user && user.email ? user.email : '';
  }

  private _clientfilter(email: string): any[] {
    const filterValue = email.toLowerCase();

    return this.clientOptions.filter(
      (option) => option.email.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _costellofilter(email: string): any[] {
    const filterValue = email.toLowerCase();

    return this.costelloOptions.filter(
      (option) => option.email.toLowerCase().indexOf(filterValue) === 0
    );
  }

  addMember(newMember) {
    if (newMember.id) {
      // Add an existing user
      this.projectService
        .addProjectUser(this.project_id, newMember.id)
        .pipe(first())
        .subscribe(
          () => {
            this.costelloTeam.push(newMember);
            this.newCostelloMember.setValue('');
            this.snackBar.open('User added!', 'Close', {
              duration: 1500,
              verticalPosition: 'top',
            });
          },
          (error) => {
            this.errMsg = error;
          }
        );
    } else {
      // Add new user
      if (window.innerWidth <= 599) {
        // Small screen version:
        const Ref = this._bottomSheet.open(AddCostelloSheetComponent, {
          data: { email: this.newCostelloMember.value },
        });
        Ref.afterDismissed()
          .pipe(first())
          .subscribe((newUser) => {
            if (newUser) {
              this.projectService
                .addCostelloMember(
                  this.project_id,
                  newUser.first_name,
                  newUser.last_name,
                  newUser.email,
                  newUser.title
                )
                .pipe(first())
                .subscribe(
                  (createdUser) => {
                    this.costelloTeam.push(createdUser.data);
                    this.newCostelloMember.setValue('');
                    this.snackBar.open('User created!', 'Close', {
                      duration: 1500,
                      verticalPosition: 'top',
                    });
                  },
                  (error) => {
                    this.errMsg = error;
                  }
                );
            }
          });
      } else {
        // Large screen version:
        const Ref = this.dialog.open(AddCostelloModalComponent, {
          data: { email: this.newCostelloMember.value },
          width: '600px',
        });
        Ref.afterClosed()
          .pipe(first())
          .subscribe((newUser) => {
            if (newUser) {
              this.projectService
                .addCostelloMember(
                  this.project_id,
                  newUser.first_name,
                  newUser.last_name,
                  newUser.email,
                  newUser.title
                )
                .pipe(first())
                .subscribe(
                  (createdUser) => {
                    this.costelloTeam.push(createdUser.data);
                    this.newCostelloMember.setValue('');
                    this.snackBar.open('User created!', 'Close', {
                      duration: 1500,
                      verticalPosition: 'top',
                    });
                  },
                  (error) => {
                    this.errMsg = error;
                  }
                );
            }
          });
      }
    }
  }

  editMember(user) {
    const Ref = this.dialog.open(UserEditModalComponent, {
      data: {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        title: user.title,
      },
      width: '600px',
    });

    Ref.afterClosed()
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          this.userService
            .updateUser(
              user.id,
              data.first_name,
              data.last_name,
              data.email,
              data.title
            )
            .pipe(first())
            .subscribe(
              () => {
                window.location.reload();
              },
              (error) => {
                console.error(error);
                this.snackBar.open(error, 'Close', {
                  duration: 5000,
                });
              }
            );
        }
      });
  }

  deleteInternal(member_id) {
    this.projectService
      .deleteProjectUser(this.project_id, member_id)
      .pipe(first())
      .subscribe(
        () => {
          this.costelloTeam = this.costelloTeam.filter((user) => {
            return user.id !== member_id;
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  deleteExternal(member_id) {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ConfirmDeleteSheetComponent, {
        data: {
          message: "Removing the user will also delete the user's app account.",
        },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.userService
              .deleteExternalUser(this.project_id, member_id)
              .pipe(first())
              .subscribe(
                () => {
                  this.clientTeam = this.clientTeam.filter((user) => {
                    return user.id !== member_id;
                  });
                  this.participants = this.participants.filter((user) => {
                    return user.id !== member_id;
                  });
                  this.snackBar.open('User deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
        data: {
          message: "Removing the user will also delete the user's app account.",
        },
        width: '400px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.userService
              .deleteExternalUser(this.project_id, member_id)
              .pipe(first())
              .subscribe(
                () => {
                  this.clientTeam = this.clientTeam.filter((user) => {
                    return user.id !== member_id;
                  });
                  this.participants = this.participants.filter((user) => {
                    return user.id !== member_id;
                  });
                  this.snackBar.open('User deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  setContact(member_id) {
    this.projectService
      .setProjectContact(this.project_id, member_id)
      .pipe(first())
      .subscribe(
        (newData) => {
          this.primary_contact = newData.data.primary_contact;
          this.snackBar.open('Primary contact changed!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  addClient() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(AddClientSheetComponent, {
        data: { email: this.newClientMember.value },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((newClient) => {
          if (newClient) {
            this.projectService
              .addClient(
                this.project_id,
                newClient.first_name,
                newClient.last_name,
                newClient.email,
                newClient.title
              )
              .pipe(first())
              .subscribe(
                (createdClient) => {
                  this.clientTeam.push(createdClient.data);
                  this.newClientMember.setValue('');
                  this.snackBar.open('User created!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(AddClientModalComponent, {
        data: { email: this.newClientMember.value },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((newClient) => {
          if (newClient) {
            this.projectService
              .addClient(
                this.project_id,
                newClient.first_name,
                newClient.last_name,
                newClient.email,
                newClient.title
              )
              .pipe(first())
              .subscribe(
                (createdClient) => {
                  this.clientTeam.push(createdClient.data);
                  this.newClientMember.setValue('');
                  this.snackBar.open('User created!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  addParticipant() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(AddParticipantSheetComponent, {
        data: { email: this.newParticipant.value },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((newParticipant) => {
          if (newParticipant) {
            this.projectService
              .addParticipant(
                this.project_id,
                newParticipant.first_name,
                newParticipant.last_name,
                newParticipant.email,
                newParticipant.title
              )
              .pipe(first())
              .subscribe(
                (createdUser) => {
                  this.participants.push(createdUser.data);
                  this.newParticipant.setValue('');
                  this.snackBar.open('User created!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(AddParticipantModalComponent, {
        data: { email: this.newParticipant.value },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((newParticipant) => {
          if (newParticipant) {
            this.projectService
              .addParticipant(
                this.project_id,
                newParticipant.first_name,
                newParticipant.last_name,
                newParticipant.email,
                newParticipant.title
              )
              .pipe(first())
              .subscribe(
                (createdUser) => {
                  this.participants.push(createdUser.data);
                  this.newParticipant.setValue('');
                  this.snackBar.open('User created!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  uploadParticipants() {
    const dialogRef = this.dialog.open(ParticipantUploadComponent, {
      data: { project_id: this.project_id },
      height: '50%',
      width: '60%',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }

  sendActivationEmails() {
    this.projectService
      .activateParticipantAccounts(this.project_id, this.participants)
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open('Activation emails sent!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  sendActivationEmail(member) {
    this.projectService
      .activateParticipantAccounts(this.project_id, [member])
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open('Activation email sent!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}
