import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { RoundService } from '../../../../../_services/round.service';

@Component({
  selector: 'app-round-open-modal',
  templateUrl: './round-open-modal.component.html',
  styleUrls: ['./round-open-modal.component.scss'],
})
export class RoundOpenModalComponent implements OnInit {
  roundChecks: Observable<any>;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<RoundOpenModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roundService: RoundService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  ngOnInit(): void {
    this.roundChecks = this.roundService
      .roundPrep(this.data.project_id, this.data.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading round:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
  }

  close(confirmed) {
    this.dialogRef.close(confirmed);
  }
}
