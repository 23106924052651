<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
<div class="table-container">
<table mat-table [dataSource]="tableData" *ngIf="showTable" matSort matSortActive={{sortField}} matSortDirection={{direction}} multiTemplateDataRows>

    <ng-container matColumnDef="participant">
        <th mat-header-cell *matHeaderCellDef>Participant</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
          <a>{{item.alias}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef>Response</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <div *ngIf="!item.opt_out1 && !item.opt_out2">
                <a *ngIf="item.question_type==='Free Text'">{{item.text_value}}</a>
                <a *ngIf="item.question_type==='Likert'">{{item.likert_value}}</a>
                <a *ngIf="item.question_type==='Multiple Choice'">{{item.options}}
                    <a *ngIf="item.text_value">[{{item.text_value}}]</a>
                </a>
                <a *ngIf="item.question_type==='Numeric'">{{item.numeric_value}} {{item.unit_label}}</a>
                <a *ngIf="item.question_type==='Ranking'"><b>{{item.top_label}} to {{item.bottom_label}}: </b>{{item.options}}</a>
                <a *ngIf="item.question_type==='Single Choice'">{{item.options}}
                    <a *ngIf="item.text_value">[{{item.text_value}}]</a>
                </a>
            </div>
            <div *ngIf="project | async as project else processAsync">
                <a *ngIf="item.opt_out1"><i>"{{project.data.opt_out_label1}}"</i></a>
                <a *ngIf="item.opt_out2"><i>"{{project.data.opt_out_label2}}"</i></a>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <button mat-button *ngIf="item.note" (click)="expand_contract(item)">
                Click to expand note
            </button>
          <a *ngIf="!item.note">-</a>
        </td>
    </ng-container>

    <!-- Expanded note row -->
    <ng-container matColumnDef="expandedElement">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <a class="element-description"><b>Note: </b>{{element.note}}</a>
            </div>  
        </td>
    </ng-container>

    <ng-container matColumnDef="analysis_inclusion">
        <th mat-header-cell *matHeaderCellDef>
            <a *ngIf="!is_client">Include in Group Analysis?</a>
            <a *ngIf="is_client">Included in Group Analysis</a>
        </th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <div *ngIf="!item.opt_out1 && !item.opt_out2 && item.question_type!=='Free Text'">
                <mat-checkbox *ngIf="!is_client" color="primary"
                [formControl]="inclusion.controls[n]"></mat-checkbox>
                <mat-icon *ngIf="is_client && inclusion.controls[n].value" class="included">check</mat-icon>
                <mat-icon *ngIf="is_client && !inclusion.controls[n].value" class="excluded">close</mat-icon>
            </div>
            <a *ngIf="item.opt_out1 || item.opt_out2 || item.question_type==='Free Text'">
                N/A
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="warning">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item; let n = dataIndex">
            <mat-icon color="warn" *ngIf="item.profiling_failed===true"
                matTooltip="This participant is not eligible for the round based on their responses to the profiling questions"
            >warning</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="save">
        <th mat-header-cell *matHeaderCellDef>
            <button *ngIf="!is_client && tableData[0].question_type!=='Free Text'" mat-icon-button (click)="saveChanges()"
                matTooltip="Save changes to response inclusions">
                <mat-icon>save</mat-icon>
            </button>
        </th>
        <td mat-cell *matCellDef="let item; let n = dataIndex"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
        class="element-row"
        [class.expanded-row]="expandedElement === row"
    ></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: ['expandedElement']" class="detail-row"></tr>
</table>
</div>

<div fxLayout="row" fxLayoutAlign="end center">

    <!-- <button mat-flat-button color="primary" [disabled]="inclusion.pristine"
        (click)="saveChanges()"
    >Save Changes</button> -->
    <!-- <mat-paginator 
    [length]="numRecords"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 20]" 
    showFirstLastButtons
    ></mat-paginator> -->
</div>

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>