import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getQuestions(project_id, round_id, exclusion_list='{""}', question_type='%', section='%'): Observable<any> {
    let params = new HttpParams()
    // .set('question_type', question_type)
    // .set('section', section)
    .set('exclusion_list', exclusion_list);

    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getPriorQuestions(project_id, question_types='{"%"}'): Observable<any> {
    let params = new HttpParams()
    .set('question_types', question_types);

    return this.http.get(baseURL + '/projects/' + project_id + '/prior_questions', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getNextQuestion(project_id, round_id, current_question_number): Observable<any> {
    return this.http.get(baseURL + '/projects/' +  project_id + '/rounds/' + round_id + '/questions/' + current_question_number + '/next').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addQuestion(project_id, round_id, question, question_type): Observable<any> {
    const content = {
      question: question,
      question_type: question_type
    };

    return this.http.post(baseURL + '/projects/' +  project_id + '/rounds/' + round_id + '/questions', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getQuestion(project_id, round_id, question_number): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_number).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    ); 
  }

  getParticipantQuestion(project_id, round_id, question_number): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/participant_questions/' + question_number).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    ); 
  }

  updateQuestion(project_id, round_id, question_number, question, question_type, standard_question,
    required, calculate_consensus, filter_use, note_use, instructions?, section?, top_label?, bottom_label?, unit_label?,
    min_value?, max_value?, num_dps?, linked_question?
  ): Observable<any> {
    const content = {
      question: question,
      question_type: question_type,
      standard_question: standard_question,
      required: required,
      calculate_consensus: calculate_consensus,
      filter_use: filter_use,
      note_use: note_use,
      instructions: instructions, 
      section: section,
      top_label: top_label,
      bottom_label: bottom_label,
      unit_label: unit_label,
      min_value: min_value,
      max_value: max_value,
      num_dps: num_dps,
      linked_question: linked_question
    };
    return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_number, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteQuestion(project_id, round_id, question_number): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_number).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  importQuestions(questions: any[], project_id, round_id): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/import_questions/', questions).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getSections(project_id, round_id, name?): Observable<any> {
    let params = new HttpParams()
    .set('name', name);

    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/sections', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProfilingSections(project_id, name?): Observable<any> {
    let params = new HttpParams()
    .set('name', name);

    return this.http.get(baseURL + '/projects/' + project_id + '/profiling/sections', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getSection(project_id, section_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/sections/' + section_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    ); 
  }

  addProfilingSection(project_id, question_id, name, instructions?, details?): Observable<any> {
    const content = {
      name: name,
      instructions: instructions,
      details: details
    }
    return this.http.post(baseURL + '/projects/' + project_id + '/sections/profiling_questions/' + question_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addQuestionSection(project_id, question_id, name, instructions?, details?): Observable<any> {
    const content = {
      name: name,
      instructions: instructions,
      details: details
    }
    return this.http.post(baseURL + '/projects/' + project_id + '/sections/questions/' + question_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateSection(project_id, section_id, name, instructions?, details?): Observable<any> {
    const content = {
      name: name,
      instructions: instructions,
      details: details
    }
    return this.http.put(baseURL + '/projects/' + project_id + '/sections/' + section_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteSection(project_id, section_id): Observable<any> {

    return this.http.delete(baseURL + '/projects/' + project_id + '/sections/' + section_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getBranchingLogic(project_id, round_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/branching_logic').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addBranchingLogic(project_id, round_id, question_id, content): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/branching_logic', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  // BACKEND: Add question should check if first question, if not update default for previous question to be the new question as next
}
