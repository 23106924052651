import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-round-revert-modal',
  templateUrl: './round-revert-modal.component.html',
  styleUrls: ['./round-revert-modal.component.scss'],
})
export class RoundRevertModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<RoundRevertModalComponent>) {}

  ngOnInit(): void {}

  close(confirmed) {
    this.dialogRef.close(confirmed);
  }
}
