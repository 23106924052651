import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../_services/process-httpmsg.service';
import { ProjectService } from '../../_services/project.service';
import { QuestionService } from '../../_services/question.service';

@Component({
  selector: 'app-display-freetext',
  templateUrl: './display-freetext.component.html',
  styleUrls: ['./display-freetext.component.scss'],
})
export class DisplayFreetextComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: string;
  @Input() required: boolean;
  @Input() show_header: boolean;
  @Input() response: any;
  @Input() section_id: number;
  @Input() question_instructions: string;
  @Input() useSkip1: boolean;
  @Input() useSkip2: boolean;
  @Input() skipLabel1: string;
  @Input() skipLabel2: string;
  @Input() client_colour_primary: string;
  @Input() client_colour_secondary: string;

  @Output() formCopy = new EventEmitter<UntypedFormGroup>();

  form: UntypedFormGroup;

  project_id = this.route.snapshot.paramMap.get('project_id');

  section: Observable<any>;
  ngDestroy$ = new Subject();
  colour_primary: string = '#880e4f'; //Default to costello purple
  colour_secondary: string = '#bdbdbd'; //Default to accent grey

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionService: QuestionService
  ) {
    this.form = fb.group({
      response: [],
      skip1: [false],
      skip2: [false],
    });
  }

  ngOnInit(): void {
    //Handle access to project_id from participant view
    if (!this.project_id) {
      this.project_id = this.route.parent.snapshot.paramMap.get('project_id');
    }
    if (this.section_id) {
      this.section = this.questionService
        .getSection(this.project_id, this.section_id)
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading section:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    }

    if (this.client_colour_primary) {
      this.colour_primary = this.client_colour_primary;
    }
    if (this.client_colour_secondary) {
      this.colour_secondary = this.client_colour_secondary;
    }
    this.setClientTheme(this.colour_primary, this.colour_secondary);

    // Set required validator if needed
    if (this.required) {
      this.form.controls.response.setValidators(Validators.required);
      this.form.controls.response.updateValueAndValidity();
    }

    // Send current form to participant-question component
    this.form.valueChanges.pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.formCopy.emit(this.form);
    });

    //Handle skip selection
    this.form.controls.response.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((responseValue) => {
        if (responseValue) {
          this.form.controls.skip1.setValue(false, { emitEvent: false });
          this.form.controls.skip2.setValue(false, { emitEvent: false });
          if (!this.form.controls.response.validator && this.required) {
            this.form.controls.response.setValidators(Validators.required);
            this.form.controls.response.updateValueAndValidity();
          }
        }
      });
    this.form.controls.skip1.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((skip1Value) => {
        if (skip1Value) {
          this.form.controls.skip2.setValue(false, { emitEvent: false });
          this.form.controls.response.setValue(null, { emitEvent: false });
          this.form.controls.response.clearValidators();
          this.form.controls.response.updateValueAndValidity();
        } else if (!this.form.value.skip2 && this.required) {
          this.form.controls.response.setValidators(Validators.required);
          this.form.controls.response.updateValueAndValidity();
        }
      });
    this.form.controls.skip2.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((skip2Value) => {
        if (skip2Value) {
          this.form.controls.skip1.setValue(false, { emitEvent: false });
          this.form.controls.response.setValue(null, { emitEvent: false });
          this.form.controls.response.clearValidators();
          this.form.controls.response.updateValueAndValidity();
        } else if (!this.form.value.skip1 && this.required) {
          this.form.controls.response.setValidators(Validators.required);
          this.form.controls.response.updateValueAndValidity();
        }
      });

    // Add current response if exists
    if (this.response) {
      this.form.controls.response.setValue(this.response.value, {
        emitEvent: false,
      });
      this.form.controls.skip1.setValue(this.response.opt_out1, {
        emitEvent: false,
      });
      this.form.controls.skip2.setValue(this.response.opt_out2, {
        emitEvent: false,
      });
    }
  }

  ngOnChanges(changes) {
    //handle changes to required validator from parent component (question config)
    if (changes.required) {
      if (this.required && !this.form.value.skip1 && !this.form.value.skip2) {
        this.form.controls.response.setValidators(Validators.required);
        this.form.controls.response.updateValueAndValidity();
      } else {
        this.form.controls.response.clearValidators();
        this.form.controls.response.updateValueAndValidity();
      }
    }
    //handle changes in associated section from parent component (question config)
    if (changes.section_id) {
      if (this.section_id) {
        this.section = this.questionService
          .getSection(this.project_id, this.section_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading section:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      }
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  setClientTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-colour', primary);
    document.documentElement.style.setProperty('--secondary-colour', secondary);
  }
}
