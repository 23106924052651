<div *ngIf="user | async as user else processAsync" fxLayout="column" fxLayoutAlign="center center" >
    
    <mat-stepper [orientation]="(stepperOrientation | async)!" class="stepper"
        labelPosition="bottom" #stepper [linear]="user.admin_account !== true">
        <!-- Make 'edit' icon a check mark -->
        <ng-template matStepperIcon="edit">
            <mat-icon>check</mat-icon>
        </ng-template>

        <div *ngIf="questionData | async as questionData else processAsync">
        <mat-step *ngFor="let question of questionData.data; index as i"
            [stepControl]="profilingForm.controls[i]" errorMessage="Please complete the question">
            <ng-template matStepLabel>{{question.question}}</ng-template>
            <div *ngIf="project | async as project else projectAsync" fxLayout="column" fxLayoutAlign="center center">
                <div class="full-container">
                    <app-display-freetext *ngIf="question.question_type === 'Free Text'"
                        [useSkip1]="project.data.opt_out1_use"
                        [useSkip2]="project.data.opt_out2_use"
                        [skipLabel1]="project.data.opt_out_label1"
                        [skipLabel2]="project.data.opt_out_label2"
                        [client_colour_primary]="project.data.client_colour_primary"
                        [client_colour_secondary]="project.data.client_colour_secondary"
                        [question]="question.question"
                        [show_header]="false"
                        [required]="question.required"
                        [response]="question.existing_response"
                        [section_id]="question.section"
                        [question_instructions]="question.instructions"
                        (formCopy)="onResponse($event, i)"
                    ></app-display-freetext>
                    <app-display-numeric *ngIf="question.question_type === 'Numeric'" 
                        [useSkip1]="project.data.opt_out1_use"
                        [useSkip2]="project.data.opt_out2_use"
                        [skipLabel1]="project.data.opt_out_label1"
                        [skipLabel2]="project.data.opt_out_label2"
                        [client_colour_primary]="project.data.client_colour_primary"
                        [client_colour_secondary]="project.data.client_colour_secondary"
                        [question]="question.question"
                        [show_header]="false"
                        [minimum]="question.min_value"
                        [maximum]="question.max_value"
                        [decimalPlaces]="question.num_dps"
                        [units]="question.unit_label"
                        [useNote]="question.note_use"
                        [required]="question.required"
                        [response]="question.existing_response"
                        [section_id]="question.section"
                        [question_instructions]="question.instructions"
                        (formCopy)="onResponse($event, i)"
                    ></app-display-numeric>
                    <app-display-mcq *ngIf="question.question_type === 'Multiple Choice'" 
                        [useSkip1]="project.data.opt_out1_use"
                        [useSkip2]="project.data.opt_out2_use"
                        [skipLabel1]="project.data.opt_out_label1"
                        [skipLabel2]="project.data.opt_out_label2"
                        [client_colour_primary]="project.data.client_colour_primary"
                        [client_colour_secondary]="project.data.client_colour_secondary"
                        [options]="question.question_options"
                        [show_header]="false"
                        [question]="question.question"
                        [useNote]="question.note_use"
                        [required]="question.required"
                        [response]="question.existing_response"
                        [section_id]="question.section"
                        [question_instructions]="question.instructions"
                        (formCopy)="onResponse($event, i)"
                    ></app-display-mcq>
                    <app-display-ranking *ngIf="question.question_type === 'Ranking'" 
                        [useSkip1]="project.data.opt_out1_use"
                        [useSkip2]="project.data.opt_out2_use"
                        [skipLabel1]="project.data.opt_out_label1"
                        [skipLabel2]="project.data.opt_out_label2"
                        [client_colour_primary]="project.data.client_colour_primary"
                        [client_colour_secondary]="project.data.client_colour_secondary"
                        [options]="question.question_options"
                        [show_header]="false"
                        [top_label]="question.top_label" 
                        [bottom_label]="question.bottom_label"
                        [question]="question.question"
                        [useNote]="question.note_use"
                        [required]="question.required"
                        [response]="question.existing_response"
                        [section_id]="question.section"
                        [question_instructions]="question.instructions"
                        (formCopy)="onResponse($event, i)"
                    ></app-display-ranking>
                    <app-display-scq *ngIf="question.question_type === 'Single Choice'" 
                        [useSkip1]="project.data.opt_out1_use"
                        [useSkip2]="project.data.opt_out2_use"
                        [skipLabel1]="project.data.opt_out_label1"
                        [skipLabel2]="project.data.opt_out_label2"
                        [client_colour_primary]="project.data.client_colour_primary"
                        [client_colour_secondary]="project.data.client_colour_secondary"
                        [options]="question.question_options"
                        [show_header]="false"
                        [question]="question.question"
                        [useNote]="question.note_use"
                        [required]="question.required"
                        [response]="question.existing_response"
                        [section_id]="question.section"
                        [question_instructions]="question.instructions"
                        (formCopy)="onResponse($event, i)"
                    ></app-display-scq>
         
                    
                </div>
                <div fxShow fxShow.xs="false" fxLayout="row" fxLayoutAlign="center" class="floating-section">
                    <button *ngIf="i > 0  && !terminate" mat-flat-button matStepperPrevious
                        color="accent" class="button">Back</button>
                    
                    <button *ngIf="!terminate && !profilingForm.pristine" mat-flat-button (click)="save()"
                        class="button button-save" [disabled]="profilingForm.pristine">Save Answers</button>
                    
                    <button id="next_{{i}}" *ngIf="i < questionData.data.length-1 && !terminate" mat-flat-button 
                        class="button button-fwd" matStepperNext [disabled]="profilingForm.controls[i]?.invalid && user.admin_account !== true"
                            (click)="refreshToken()"
                        >Next</button>
                    
                    <button  *ngIf="i===questionData.data.length-1 && !terminate" mat-flat-button 
                        class="button button-fwd" [disabled]="profilingForm.controls[i]?.invalid || profilingForm.invalid" (click)="completeSection()" >
                        Complete Section</button>

                    <button *ngIf="terminate" mat-flat-button
                        class="button button-fwd" (click)="endQuestionnaire()">
                        End Questionnaire</button>
                </div>
                <div fxHide fxShow.xs="true" fxLayout="row" fxLayoutAlign="center" class="floating-section">
                    <button *ngIf="i > 0  && !terminate" mat-icon-button matStepperPrevious
                        class="button icon-fwd" matTooltip="Back">
                        <mat-icon>arrow_back_ios_new</mat-icon>
                    </button>
                    
                    <button *ngIf="!terminate && !profilingForm.pristine" mat-icon-button (click)="save()"
                        class="button button-save" [disabled]="profilingForm.pristine" matTooltip="Save Answers">
                        <mat-icon>save</mat-icon>
                    </button>
                    
                    <button id="next_{{i}}" *ngIf="i < questionData.data.length-1 && !terminate" mat-icon-button 
                        class="button icon-fwd" matStepperNext [disabled]="profilingForm.controls[i]?.invalid && user.admin_account !== true"
                            (click)="refreshToken()" matTooltip="Next">
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </button>
                    
                    <button  *ngIf="i===questionData.data.length-1 && !terminate" mat-icon-button 
                        class="button button-fwd" [disabled]="profilingForm.controls[i]?.invalid || profilingForm.invalid" 
                        (click)="completeSection()" matTooltip="Complete Section">
                        <mat-icon>done</mat-icon>
                    </button>

                    <button *ngIf="terminate" mat-icon-button matTooltip="End Questionnaire"
                        class="button button-fwd" (click)="endQuestionnaire()">
                        <mat-icon>logout</mat-icon>
                    </button>
                </div>
            </div>
        
        </mat-step>
        </div>
    </mat-stepper>
    
</div>

<!-- LOADING -->
<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>

<ng-template #projectAsync>
    <div class="full-container">
    </div>

</ng-template>