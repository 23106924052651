<div fxLayout="column">
    
    <!-- NAVBAR -->
    <div *ngIf="profilingQuestions | async as profilingQuestions else processAsync">
        <nav mat-tab-nav-bar>
            <a *ngIf="profilingQuestions.data.length > 0"
                mat-tab-link [routerLink]="'/panel/' + project_id + '/round/' + round_id + '/background_questions'"
                routerLinkActive #rla="routerLinkActive"
                [active]="rla.isActive">Background Questions</a>
            <a mat-tab-link *ngFor="let question_num of retrievedQuestions; let i = index"
                [routerLink]="'/panel/' + project_id +'/round/' +round_id+ '/respond/' + question_num.num"
                routerLinkActive #rla="routerLinkActive"
                [active]="rla.isActive"
                [disabled]="invalid"
                > {{i + 1}}
            </a>
        </nav>
    </div>

    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

    <div *ngIf="responseHistory | async as responseHistory else processAsync">
        <!-- PROGRESS BAR -->
        <mat-progress-bar *ngIf="route.snapshot.url[2].path!=='background_questions'"
            mode="determinate" [value]="progress"></mat-progress-bar>

        <!-- QUESTION PANEL -->
        <div class="main-content" *ngIf="likertOptions | async as likertOptions else processAsync">
            <div *ngIf="existingResponse | async as existingResponse else processAsync">
                <app-participant-question *ngIf="question | async as question else processAsync"
                    [question]="question.data"
                    [existingResponse]="existingResponse.data"
                    [likertOptions]="likertOptions.data"
                    [num_questions]="responseHistory.data.num_questions"
                    (response)="onResponse($event)"
                    (invalid)="onFormChange($event)"
                ></app-participant-question> 
            </div>
        </div>


        <div class="main-content">
            <app-participant-profiling-question 
                [terminate]="responseHistory.data.profiling_failed"
                [profiling_passed]="responseHistory.data.profiling_passed"
                [completed]="responseHistory.data.completed"
                *ngIf="route.snapshot.url[2].path==='background_questions'">
            </app-participant-profiling-question>
        </div>

    </div>

</div>


<!-- LOADING -->
<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>