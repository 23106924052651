import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getProjects(name='%', client_id='{"%"}', status='%', skip=0, limit=50): Observable<any> {
    let params = new HttpParams()
    .set('name', name)
    .set('client_company', client_id)
    .set('projectStatus', status)
    // .set('skip', skip.toString())
    // .set('limit', limit.toString())

    return this.http.get(baseURL + '/projects', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addProject(name, client_company, primary_contact, project_code?, disease_area?, hide_incomplete_results?, open_delphi?,
    opt_out_label1?, opt_out_label2?, opt_out1_use?, opt_out2_use?, note?): Observable<any> {
    const content = {
      name: name,
      client_company: client_company,
      primary_contact: primary_contact,
      project_code: project_code,
      disease_area: disease_area,
      hide_incomplete_results: hide_incomplete_results,
      open_delphi: open_delphi,
      opt_out_label1: opt_out_label1,
      opt_out_label2: opt_out_label2,
      opt_out1_use: opt_out1_use,
      opt_out2_use: opt_out2_use,
      note: note
    };
    return this.http.post(baseURL + '/projects', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProject(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateProject(project_id, name, client_company, primary_contact, status, project_code?, disease_area?, 
    hide_incomplete_results?, open_delphi?,
    opt_out_label1?, opt_out_label2?, opt_out1_use?, opt_out2_use?, note?): Observable<any> {
      const content = {
        name: name,
        client_company: client_company,
        primary_contact: primary_contact,
        project_code: project_code,
        disease_area: disease_area,
        hide_incomplete_results: hide_incomplete_results,
        open_delphi: open_delphi,
        opt_out_label1: opt_out_label1,
        opt_out_label2: opt_out_label2,
        opt_out1_use: opt_out1_use,
        opt_out2_use: opt_out2_use,
        note: note,
        projectStatus: status
      };
      return this.http.put(baseURL + '/projects/' + project_id, content).pipe(
        catchError(error => this.processHTTPMsgService.handleError(error))
      );
    }

  deleteProject(project_id): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  closeProject(project_id): Observable<any> {
    return this.http.put(baseURL + '/projects/' + project_id + '/close', {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProjectProgress(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/progress').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProjectResults(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/results').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProjectUsers(project_id, participant_account="%"): Observable<any> {
    let params = new HttpParams()
    .set('participant_account', participant_account)

    return this.http.get(baseURL + '/projects/' + project_id + '/users', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addProjectUser(project_id, user_id): Observable<any> {
    return this.http.put(baseURL + '/projects/' + project_id + '/users/' + user_id, {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteProjectUser(project_id, user_id): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/users/' + user_id, {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  setProjectContact(project_id, user_id): Observable<any> {
    return this.http.put(baseURL + '/projects/' + project_id + '/primary_contact/' + user_id, {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProjectParticipants(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/participant_permissions').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateParticipantPermission(project_id, confirmed): Observable<any> {
    const content = {
      confirmed: confirmed
    };

    return this.http.put(baseURL + '/projects/' + project_id + '/participant_permissions', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getDashContent(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/dash_content').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addDashContent(project_id, content) {
    return this.http.post(baseURL + '/projects/' + project_id + '/dash_content', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getCopyApprovalDetails(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/copyapproval').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getHelp(project_id, message): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/get_help', {message: message}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addCostelloMember(project_id, first_name, last_name, email, title): Observable<any> {
    const content = {
      email: email.toLowerCase(),
      title: title,
      first_name: first_name,
      last_name: last_name
    }
    return this.http.post(baseURL + '/auth/new_costello/' + project_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addClient(project_id, first_name, last_name, email, title): Observable<any> {
    const content = {
      email: email.toLowerCase(),
      title: title,
      first_name: first_name,
      last_name: last_name
    }
    return this.http.post(baseURL + '/auth/new_client/' + project_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addParticipant(project_id, first_name, last_name, email, title): Observable<any> {
    const content = {
      email: email.toLowerCase(),
      title: title,
      first_name: first_name,
      last_name: last_name
    }
    return this.http.post(baseURL + '/auth/new_participant/' + project_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  activateParticipantAccounts(project_id, accounts): Observable<any> {
    return this.http.put(baseURL + '/auth/new_participant/' + project_id + '/activate', accounts).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

}
