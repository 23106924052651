<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
  ><b>Error:&nbsp;</b> {{ errMsg }}</a
>

<div fxLayout="column" *ngIf="round | async as round; else processAsync">
  <!-- PAGE HEADER -->
  <div class="toolbar accent-back" fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <h3 fxHide.gt-xs="true" fxLayout="row" fxLayoutAlign="center center">
      {{ round.data.name }}
    </h3>
    <h3 fxHide.xs="true" fxLayout="row" fxLayoutAlign="center center">
      {{ round.data.name }} - {{ round.data.status }}
      <mat-icon
        *ngIf="round.data.status === 'Draft'"
        class="icon-spacer"
        matTooltip="The round will not be visible to participants while in draft stage"
        >help</mat-icon
      >
      <mat-icon
        *ngIf="round.data.status === 'Open'"
        class="icon-spacer"
        matTooltip="The round is open for participants to submit responses"
        >help</mat-icon
      >
    </h3>
    <span class="flex-spacer"></span>
    <button
      fxHide
      fxShow.sm="true"
      mat-flat-button
      color="warn"
      (click)="openRound()"
      *ngIf="round.data.status === 'Draft' && !is_client && !downloading"
    >
      <mat-icon>lock_open</mat-icon>Open
    </button>
    <button
      fxHide.lt-md="true"
      mat-flat-button
      color="warn"
      (click)="openRound()"
      *ngIf="round.data.status === 'Draft' && !is_client && !downloading"
    >
      <mat-icon>lock_open</mat-icon>Open Round for Responses
    </button>
    <button
      fxHide
      fxShow.sm="true"
      mat-flat-button
      color="warn"
      (click)="closeRound()"
      *ngIf="round.data.status === 'Open' && !is_client && !downloading"
    >
      <mat-icon>lock</mat-icon>Close
    </button>
    <button
      fxHide.lt-md="true"
      mat-flat-button
      color="warn"
      (click)="revertRound()"
      *ngIf="round.data.status !== 'Draft' && !is_client && !downloading"
      style="margin-right: 10px"
    >
      <mat-icon>undo</mat-icon>Revert to Draft
    </button>
    <button
      fxHide.lt-md="true"
      mat-flat-button
      color="warn"
      (click)="closeRound()"
      *ngIf="round.data.status === 'Open' && !is_client && !downloading"
    >
      <mat-icon>lock</mat-icon>Close Round
    </button>
    <button
      fxHide.xs="true"
      mat-button
      *ngIf="!is_client && round.data.status === 'Draft'"
      [matMenuTriggerFor]="importMenu"
    >
      <mat-icon>publish</mat-icon>Import
    </button>
    <button
      fxHide.gt-xs="true"
      mat-icon-button
      *ngIf="!is_client && round.data.status === 'Draft'"
      [matMenuTriggerFor]="importMenu"
    >
      <mat-icon>publish</mat-icon>
    </button>
    <mat-menu #importMenu="matMenu">
      <button mat-menu-item (click)="importQuestions()">Questions</button>
      <button mat-menu-item (click)="importQuestionOptions()">
        Question Options
      </button>
    </mat-menu>
    <button
      fxShow
      fxHide.xs="true"
      mat-button
      [matMenuTriggerFor]="exportMenu"
      *ngIf="
        round.data.status === 'Closed' ||
        (round.data.status === 'Open' &&
          round.data.hide_incomplete_results === false) ||
        !is_client
      "
    >
      <mat-icon>get_app</mat-icon>Export
    </button>
    <button
      fxHide
      fxShow.xs="true"
      mat-icon-button
      [matMenuTriggerFor]="exportMenu"
      *ngIf="
        round.data.status === 'Closed' ||
        (round.data.status === 'Open' &&
          round.data.hide_incomplete_results === false) ||
        !is_client
      "
    >
      <mat-icon>get_app</mat-icon>
    </button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="exportCSV">CSV format</button>
      <button
        mat-menu-item
        [matMenuTriggerFor]="exportPDF"
        *ngIf="
          round.data.status === 'Closed' ||
          (round.data.status === 'Open' &&
            round.data.hide_incomplete_results === false) ||
          !is_client
        "
      >
        PDF format
      </button>
    </mat-menu>
    <mat-menu #exportCSV="matMenu">
      <button mat-menu-item (click)="exportQuestions()">Questions</button>
      <button mat-menu-item (click)="exportQuestionOptions()">
        Question Options
      </button>
      <button
        mat-menu-item
        (click)="exportResponses()"
        *ngIf="
          round.data.status === 'Closed' ||
          (round.data.status === 'Open' &&
            round.data.hide_incomplete_results === false) ||
          !is_client
        "
      >
        Question Responses
      </button>
      <button
        mat-menu-item
        (click)="exportIndividualResponses()"
        *ngIf="
          round.data.status === 'Closed' ||
          (round.data.status === 'Open' &&
            round.data.hide_incomplete_results === false) ||
          !is_client
        "
      >
        Individual Participant Responses
      </button>
    </mat-menu>
    <mat-menu #exportPDF="matMenu">
      <button mat-menu-item (click)="exportCopyApproval()">
        Copy Approval
      </button>
    </mat-menu>

    <button
      mat-icon-button
      *ngIf="!is_client && !downloading"
      (click)="deleteRound()"
      matTooltip="Delete round"
    >
      <mat-icon>delete_outline</mat-icon>
    </button>

    <button
      mat-icon-button
      [matMenuTriggerFor]="optionsMenu"
      *ngIf="!is_client && !downloading"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #optionsMenu="matMenu">
      <button
        mat-menu-item
        routerLink="/main/project/{{ project_id }}/round/{{ round_id }}/options"
        routerLinkActive="active"
      >
        Options
      </button>
      <button
        mat-menu-item
        routerLink="/main/project/{{ project_id }}/round/{{
          round_id
        }}/participants"
        routerLinkActive="active"
      >
        Participants
      </button>
    </mat-menu>

    <!-- Download Status -->
    <div
      *ngIf="download$ | async as download"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <a *ngIf="download.state == 'PENDING'">Building file...</a>
      <a *ngIf="download.state == 'IN_PROGRESS'">Downloading...</a>
      <a *ngIf="download.state == 'DONE'">Downloaded</a>
      <mat-progress-spinner
        *ngIf="download.state !== 'DONE'"
        [mode]="download.state == 'PENDING' ? 'indeterminate' : 'determinate'"
        [value]="download.progress"
        diameter="25"
      ></mat-progress-spinner>
      <mat-icon *ngIf="download.state == 'DONE'">done</mat-icon>
    </div>
  </div>

  <!-- QUESTIONS TABLE -->
  <div
    class="footer-pad"
    fxFlex="100%"
    *ngIf="questions | async as questions; else processAsync"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
      <h3>QUESTIONS</h3>
      <button
        mat-mini-fab
        fxHide.gt-xs="true"
        *ngIf="!is_client"
        color="primary"
        aria-label="Add question"
        (click)="
          addSmallScreen(
            round.data.use_freetext,
            round.data.use_likert,
            round.data.use_mcq,
            round.data.use_numeric,
            round.data.use_ranking,
            round.data.use_scq
          )
        "
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <span
      class="empty-warning"
      fxLayoutAlign="center center"
      *ngIf="questions.data.length === 0"
      >No questions have been added</span
    >
    <app-round-dash-table
      *ngIf="questions.data.length > 0"
      [round_status]="round.data.status"
      [hide_incomplete_results]="round.data.hide_incomplete_results"
    ></app-round-dash-table>
  </div>

  <!-- LOWER PANEL -->
  <div fxLayout="row" fxLayoutAlign="center">
    <!-- Summary figure -->
    <div
      class="footer-pad"
      fxFlex="75%"
      fxFlex.sm="70%"
      fxFlex.xs="100%"
      *ngIf="questions | async as questions; else processAsync"
    >
      <h3 class="subheader" *ngIf="round.data.status !== 'Closed'">
        CURRENT PROGRESS
      </h3>
      <h3 class="subheader" *ngIf="round.data.status === 'Closed'">
        FINAL RESULTS
      </h3>
      <mat-divider></mat-divider>
      <span
        class="empty-warning"
        fxLayoutAlign="center center"
        *ngIf="questions.data.length === 0"
        >No questions have been added</span
      >
      <app-round-progress-figure
        *ngIf="questions.data.length > 0"
      ></app-round-progress-figure>
      <a
        fxLayoutAlign="center"
        *ngIf="round.data.status !== 'Closed' && questions.data.length > 0"
      >
        Note that results in this figure are subject to change until all
        participants have completed the round</a
      >
    </div>

    <!-- Add question -->
    <div
      class="new-form"
      *ngIf="!is_client && round.data.status === 'Draft'"
      fxFlex="25%"
      fxFlex.sm="30%"
      fxHide.lt-sm="true"
      fxLayout="column"
      fxLayoutAlign="start space-between"
    >
      <h3 fxLayout="row" fxLayoutAlign="start" class="subheader">
        NEW QUESTION
      </h3>
      <mat-divider></mat-divider>
      <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
        <mat-form-field class="form-control">
          <textarea
            matInput
            type="text"
            formControlName="name"
            placeholder="Question"
            required
          ></textarea>
          <mat-hint>
            <span
              *ngIf="
                form.controls.name.errors?.required &&
                form.controls.name.touched
              "
              class="warn-fore"
              >Please enter the question</span
            >
            <span
              class="warn-fore"
              [hidden]="!form.controls.name.errors?.pattern"
              >This field contains unsupported characters!</span
            >
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control">
          <mat-label>Question Type</mat-label>
          <mat-select formControlName="questionType" required>
            <mat-option *ngFor="let type of questionTypes" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
          <mat-hint>
            <span
              *ngIf="
                form.controls.questionType.errors?.required &&
                form.controls.questionType.touched
              "
              class="warn-fore"
              >Please select an option</span
            >
          </mat-hint>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="end" class="add-button footer-pad">
        <button
          mat-fab
          color="primary"
          aria-label="Add question"
          [disabled]="form.invalid"
          (click)="addQuestion(form.value.name, form.value.questionType)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div>
      <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>
