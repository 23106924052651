import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-question-response-figure',
  templateUrl: './question-response-figure.component.html',
  styleUrls: ['./question-response-figure.component.scss'],
})
export class QuestionResponseFigureComponent implements OnInit {
  @Input() barcolour: string;
  @Input() chartData: any;
  @Input() questionType: string;
  @Input() enableDownloads: boolean;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            //Function to 'wrap' axis labels rather than have them go diagonally
            callback: function (label: any) {
              if (/\s/.test(label) && label.length > 10) {
                return label.split(' ');
              } else {
                return label;
              }
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Number of participants',
          },
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      ],
    },
  };
  public barChartLabels: Label[] = [
    'Strongly Agree',
    'Slightly Agree',
    'Neither Agree Nor Disagree',
    'Slightly Disagree',
    'Strongly Disagree',
  ];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    {
      data: [45, 40, 22, 10, 17],
      label: 'Number of participants',
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderColor: [
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
      ],
      hoverBorderColor: [
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
        'rgba(136, 14, 79, 1)',
      ],
    },
  ];

  boxplotOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  };

  public boxplotData = {
    labels: [''],
    datasets: [
      {
        label: 'Group Summary',
        backgroundColor: 'rgba(136, 14, 79, 0.5)',
        borderColor: 'rgba(136, 14, 79, 1)',
        hoverBackgroundColor: 'rgba(136, 14, 79, 0.8)',
        hoverBorderColor: 'rgba(136, 14, 79, 1)',
        borderWidth: 1,
        outlierColor: '#999999',
        padding: 10,
        itemRadius: 0,
        data: [],
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {
    if (this.questionType === 'Numeric') {
      this.boxplotData.datasets[0].data[0] = this.chartData.values;
      this.boxplotData.datasets[0].backgroundColor = this.hexToRgb(
        this.barcolour
      );
      this.boxplotData.datasets[0].borderColor = this.barcolour;
      this.boxplotData.datasets[0].hoverBackgroundColor = this.barcolour;
      this.boxplotData.datasets[0].hoverBorderColor = this.barcolour;
    } else if (this.questionType !== 'Ranking') {
      //Set chart data
      this.barChartLabels = this.chartData.option_labels;
      this.barChartData[0].data = this.chartData.option_counts;

      //Set bar colours
      if (
        this.chartData.option_colours.includes('       ') ||
        this.chartData.option_colours.includes(null)
      ) {
        this.chartData.option_colours.forEach((colour) => {
          (<any[]>this.barChartData[0].backgroundColor).push(
            this.hexToRgb(this.barcolour)
          );
        });
        this.barChartData[0].hoverBackgroundColor = this.barcolour;
        this.barChartData[0].borderColor = this.barcolour;
        this.barChartData[0].hoverBorderColor = this.barcolour;
      } else {
        this.chartData.option_colours.forEach((colour) => {
          (<any[]>this.barChartData[0].backgroundColor).push(
            this.hexToRgb(colour)
          );
        });
        this.barChartData[0].hoverBackgroundColor =
          this.chartData.option_colours;
        this.barChartData[0].borderColor = this.chartData.option_colours;
        this.barChartData[0].hoverBorderColor = this.chartData.option_colours;
      }
    } else {
      // Set chart data
      this.barChartLabels = this.chartData.option_labels;
      const numRanks = this.chartData.option_labels.length;

      var tempData = [];

      for (let position = 0; position < numRanks; position++) {
        tempData.push({
          data: [],
          label: 'Rank ' + (position + 1),
          stack: 'a',
          backgroundColor: [],
          hoverBackgroundColor: [],
          borderColor: [],
          hoverBorderColor: [],
        });

        for (let option = 0; option < numRanks; option++) {
          var ranking = this.chartData.ranks.find((rank) => {
            return (
              rank.label === this.chartData.option_labels[option] &&
              rank.rank === position
            );
          });
          var count = 0;
          var colour = this.barcolour;
          if (ranking) {
            count = ranking.count;
            //REMOVE individual colouring for different option labels
            // if(ranking.colour !== "       ") {
            //   colour = ranking.colour;
            // }
          }
          tempData[position].data.push(count);
          tempData[position].backgroundColor.push(
            this.hexToRgb(colour, (numRanks - position) / numRanks - 0.15)
          );
          tempData[position].hoverBackgroundColor.push(
            this.hexToRgb(colour, (numRanks - position) / numRanks)
          );
          tempData[position].borderColor.push(
            this.hexToRgb(colour, (numRanks - position) / numRanks)
          );
          tempData[position].hoverBorderColor.push(
            this.hexToRgb(colour, (numRanks - position) / numRanks)
          );
        }
      }
      this.barChartData = tempData;
      this.barChartOptions.legend.display = true;
    }
  }

  hexToRgb(hex, a_value = 0.5) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? 'rgba(' +
          parseInt(result[1], 16) +
          ', ' +
          parseInt(result[2], 16) +
          ', ' +
          parseInt(result[3], 16) +
          ', ' +
          a_value +
          ')'
      : null;
  }

  downloadChart() {
    // Export png version of chart (if not a boxplot)
    var a = document.createElement('a');
    a.href = this.chart?.toBase64Image();
    a.download = 'Question_Export.png';
    a.click();
  }
}
