<div class="cookie-footer" *ngIf="showCookies | async as showCookies else standardFooter">
    <a>This site uses cookies in order to operate correctly. By logging in, you agree to our <a href="/privacy-policy" target="_blank">Privacy Policy</a> and to the use of cookies as described in our <a href="/cookie-statement" target="_blank">Cookie Statement</a>.</a>
    <span class="cookie-spacer"></span>
    <button mat-icon-button fxHide fxShow.lt-md="true" (click)="cookiePreferences()"
        matTooltip="Cookie Preferences">
        <mat-icon>settings</mat-icon></button>
    <button mat-icon-button fxHide fxShow.lt-md="true" (click)="acceptCookies()"
        matTooltip="Accept Cookies">
        <mat-icon>close</mat-icon></button>
    <button mat-button fxShow fxHide.lt-md="true" (click)="cookiePreferences()">Cookie Preferences</button>
    <span class="cookie-padding"></span>
    <button mat-button fxShow fxHide.lt-md="true" (click)="acceptCookies()">Accept</button>
</div>

<ng-template #standardFooter>
    <div class="standard-footer">
        <div fxLayout="row">
            <a href="/privacy-policy" target="_blank">Privacy Notice</a>
            <span class="cookie-padding"></span>
            <a href="/cookie-statement" target="_blank">Cookie Statement</a>
        </div>
        <span class="cookie-spacer"></span>
        <a>© {{ year }} Costello Medical Consulting Limited</a>
    </div>
</ng-template>
