import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from '../../../../_services/user.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  year: Number;
  errMsg: string;

  loginForm: UntypedFormGroup;

  siteKey = '6LcgJ1kaAAAAANz8ev8f7_4PKlAoRgVtaqBQ0ywx';
  token = this.route.snapshot.paramMap.get('token');

  constructor(
    public route: ActivatedRoute,
    fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    public snackBar: MatSnackBar
  ) {
    this.loginForm = fb.group(
      {
        email: [''],
        password: ['', Validators.required],
        repeatPassword: ['', Validators.required],
        expiredToken: [false],
      },
      { validators: this.checkPasswords }
    );
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

  checkPasswords(group: UntypedFormGroup) {
    return group.value.password === group.value.repeatPassword
      ? null
      : { notSame: true };
  }

  savePassword() {
    this.userService
      .resetPassword(this.loginForm.value.password, this.token)
      .pipe(first())
      .subscribe(
        () => {
          const ref = this.snackBar.open('Password saved!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
          ref
            .afterDismissed()
            .pipe(first())
            .subscribe(() => {
              this.router.navigate(['/login']);
            });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  expiredToken() {
    this.loginForm.controls.expiredToken.setValue(true);
    this.errMsg = '';
  }

  resetPassword() {
    this.userService
      .requestReset(this.loginForm.value.email)
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open(
            'Request sent! If the username entered is correct, you will receive further instructions via email.',
            'Close'
          );
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}
