import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SideNavService } from '../../../../../_services/side-nav.service';
import { UserService } from '../../../../../_services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  ngDestroy$ = new Subject();

  constructor(
    public router: Router,
    private sidenav: SideNavService,
    private userService: UserService
  ) {}

  username: string;
  is_client: boolean;
  is_admin: boolean;

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.username = user.email;
                this.is_client = user.client_account;
                this.is_admin = user.admin_account;
              }
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  jiraBug() {
    const url =
      'https://costelloinnovation.atlassian.net/servicedesk/customer/portal/3/create/34';
    window.open(url);
  }

  logOut() {
    this.userService.destroyUserCredentials();
    this.router.navigate(['/login']);
  }

  toggleSideNav() {
    this.sidenav.toggle();
  }
}
