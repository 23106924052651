import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RoundService } from '../../../_services/round.service';
import { RoundLikertOptionsComponent } from './components/round-likert-options/round-likert-options.component';
import { MatDialog } from '@angular/material/dialog';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { QuestionOptionsService } from '../../../_services/question-options.service';

@Component({
  selector: 'app-round-options',
  templateUrl: './round-options.component.html',
  styleUrls: ['./round-options.component.scss'],
})
export class RoundOptionsComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  round: Observable<any>;
  ngDestroy$ = new Subject();
  round_status: string;
  note: string;

  form: UntypedFormGroup;

  TABLE_DATA = [
    {
      question_type: 'Free Text',
      use: 'use_freetext',
      consensus: 'N/A',
      threshold: 'N/A',
    },
    {
      question_type: 'Likert',
      use: 'use_likert',
      consensus: 'likert_consensus',
      threshold: 'likert_threshold',
      guidance:
        '<Guidance on selecting a threshold and how consensus is calculated to be added here>',
    },
    {
      question_type: 'Multiple Choice',
      use: 'use_mcq',
      consensus: 'mcq_consensus',
      threshold: 'mcq_threshold',
      guidance:
        '<Guidance on selecting a threshold and how consensus is calculated to be added here>',
    },
    {
      question_type: 'Numeric',
      use: 'use_numeric',
      consensus: 'N/A',
      threshold: 'N/A',
      guidance:
        '<Guidance on selecting a threshold and how consensus is calculated to be added here>',
    },
    {
      question_type: 'Ranking',
      use: 'use_ranking',
      consensus: 'ranking_consensus',
      threshold: 'ranking_threshold',
      guidance:
        '<Guidance on selecting a threshold and how consensus is calculated to be added here>',
    },
    {
      question_type: 'Single Choice',
      use: 'use_scq',
      consensus: 'scq_consensus',
      threshold: 'scq_threshold',
      guidance:
        '<Guidance on selecting a threshold and how consensus is calculated to be added here>',
    },
  ];

  displayedColumns: string[] = [
    'question_type',
    'use',
    'consensus',
    'threshold',
    'actions',
  ];

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private fb: UntypedFormBuilder,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionOptionsService: QuestionOptionsService,
    private roundService: RoundService,
    public router: Router,
    private userService: UserService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      zinc_code: [
        '',
        [
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      use_freetext: [true, Validators.required],
      use_likert: [true, Validators.required],
      use_numeric: [true, Validators.required],
      use_ranking: [true, Validators.required],
      use_mcq: [true, Validators.required],
      use_scq: [true, Validators.required],
      likert_consensus: [true, Validators.required],
      numeric_consensus: [true, Validators.required],
      ranking_consensus: [true, Validators.required],
      mcq_consensus: [true, Validators.required],
      scq_consensus: [true, Validators.required],
      likert_threshold: [0.7, Validators.required],
      numeric_threshold: [0.7, Validators.required],
      ranking_threshold: [0.7, Validators.required],
      mcq_threshold: [0.7, Validators.required],
      scq_threshold: [0.7, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.client_account) {
                this.router.navigate(['/main/project/' + this.project_id]);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.round = this.roundService
                  .getRound(this.project_id, this.round_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading round:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );

                this.round.pipe(first()).subscribe(
                  (round) => {
                    this.round_status = round.data.status;
                    this.note = round.note;
                    this.form.controls.name.setValue(round.data.name);
                    this.form.controls.zinc_code.setValue(round.data.zinc_code);
                    this.form.controls.use_freetext.setValue(
                      round.data.use_freetext
                    );
                    this.form.controls.use_likert.setValue(
                      round.data.use_likert
                    );
                    this.form.controls.use_numeric.setValue(
                      round.data.use_numeric
                    );
                    this.form.controls.use_ranking.setValue(
                      round.data.use_ranking
                    );
                    this.form.controls.use_mcq.setValue(round.data.use_mcq);
                    this.form.controls.use_scq.setValue(round.data.use_scq);
                    this.form.controls.likert_consensus.setValue(
                      round.data.likert_consensus
                    );
                    this.form.controls.numeric_consensus.setValue(
                      round.data.numeric_consensus
                    );
                    this.form.controls.ranking_consensus.setValue(
                      round.data.ranking_consensus
                    );
                    this.form.controls.mcq_consensus.setValue(
                      round.data.mcq_consensus
                    );
                    this.form.controls.scq_consensus.setValue(
                      round.data.scq_consensus
                    );
                    this.form.controls.likert_threshold.setValue(
                      round.data.likert_threshold
                    );
                    this.form.controls.numeric_threshold.setValue(
                      round.data.numeric_threshold
                    );
                    this.form.controls.ranking_threshold.setValue(
                      round.data.ranking_threshold
                    );
                    this.form.controls.mcq_threshold.setValue(
                      round.data.mcq_threshold
                    );
                    this.form.controls.scq_threshold.setValue(
                      round.data.scq_threshold
                    );
                    //Disable form controls when round is no longer in draft state
                    if (this.round_status !== 'Draft') {
                      this.form.controls.use_freetext.disable();
                      this.form.controls.use_likert.disable();
                      this.form.controls.use_numeric.disable();
                      this.form.controls.use_ranking.disable();
                      this.form.controls.use_mcq.disable();
                      this.form.controls.use_scq.disable();
                      this.form.controls.likert_consensus.disable();
                      this.form.controls.numeric_consensus.disable();
                      this.form.controls.ranking_consensus.disable();
                      this.form.controls.mcq_consensus.disable();
                      this.form.controls.scq_consensus.disable();
                      this.form.controls.likert_threshold.disable();
                      this.form.controls.numeric_threshold.disable();
                      this.form.controls.ranking_threshold.disable();
                      this.form.controls.mcq_threshold.disable();
                      this.form.controls.scq_threshold.disable();
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.errMsg = error;
                  }
                );

                // Monitor use_ controls to toggle corresponding threshold/consensus controls
                this.form.controls.use_likert.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue) {
                      this.form.controls.likert_consensus.enable();
                      this.form.controls.likert_threshold.enable();
                      if (this.form.controls.likert_consensus.value) {
                        this.form.controls.likert_threshold.setValidators(
                          Validators.required
                        );
                      }
                    } else {
                      this.form.controls.likert_consensus.disable();
                      this.form.controls.likert_threshold.disable();
                      this.form.controls.likert_threshold.clearValidators();
                    }
                    this.form.controls.likert_threshold.updateValueAndValidity();
                  });
                this.form.controls.use_numeric.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue) {
                      this.form.controls.numeric_consensus.enable();
                      this.form.controls.numeric_threshold.enable();
                      if (this.form.controls.numeric_consensus.value) {
                        this.form.controls.numeric_threshold.setValidators(
                          Validators.required
                        );
                      }
                    } else {
                      this.form.controls.numeric_consensus.disable();
                      this.form.controls.numeric_threshold.disable();
                      this.form.controls.numeric_threshold.clearValidators();
                    }
                    this.form.controls.numeric_threshold.updateValueAndValidity();
                  });
                this.form.controls.use_ranking.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue) {
                      this.form.controls.ranking_consensus.enable();
                      this.form.controls.ranking_threshold.enable();
                      if (this.form.controls.ranking_consensus.value) {
                        this.form.controls.ranking_threshold.setValidators(
                          Validators.required
                        );
                      }
                    } else {
                      this.form.controls.ranking_consensus.disable();
                      this.form.controls.ranking_threshold.disable();
                      this.form.controls.ranking_threshold.clearValidators();
                    }
                    this.form.controls.ranking_threshold.updateValueAndValidity();
                  });
                this.form.controls.use_mcq.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue) {
                      this.form.controls.mcq_consensus.enable();
                      this.form.controls.mcq_threshold.enable();
                      if (this.form.controls.mcq_consensus.value) {
                        this.form.controls.mcq_threshold.setValidators(
                          Validators.required
                        );
                      }
                    } else {
                      this.form.controls.mcq_consensus.disable();
                      this.form.controls.mcq_threshold.disable();
                      this.form.controls.mcq_threshold.clearValidators();
                    }
                    this.form.controls.mcq_threshold.updateValueAndValidity();
                  });
                this.form.controls.use_scq.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue) {
                      this.form.controls.scq_consensus.enable();
                      this.form.controls.scq_threshold.enable();
                      if (this.form.controls.scq_consensus.value) {
                        this.form.controls.scq_threshold.setValidators(
                          Validators.required
                        );
                      }
                    } else {
                      this.form.controls.scq_consensus.disable();
                      this.form.controls.scq_threshold.disable();
                      this.form.controls.scq_threshold.clearValidators();
                    }
                    this.form.controls.scq_threshold.updateValueAndValidity();
                  });

                // Monitor consensus controls to toggle corresponding threshold controls
                this.form.controls.likert_consensus.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue && this.form.controls.use_likert.value) {
                      this.form.controls.likert_threshold.setValidators(
                        Validators.required
                      );
                    } else {
                      this.form.controls.likert_threshold.clearValidators();
                    }
                    this.form.controls.likert_threshold.updateValueAndValidity();
                  });
                this.form.controls.numeric_consensus.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue && this.form.controls.use_numeric.value) {
                      this.form.controls.numeric_threshold.setValidators(
                        Validators.required
                      );
                    } else {
                      this.form.controls.numeric_threshold.clearValidators();
                    }
                    this.form.controls.numeric_threshold.updateValueAndValidity();
                  });
                this.form.controls.ranking_consensus.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue && this.form.controls.use_ranking.value) {
                      this.form.controls.ranking_threshold.setValidators(
                        Validators.required
                      );
                    } else {
                      this.form.controls.ranking_threshold.clearValidators();
                    }
                    this.form.controls.ranking_threshold.updateValueAndValidity();
                  });
                this.form.controls.mcq_consensus.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue && this.form.controls.use_mcq.value) {
                      this.form.controls.mcq_threshold.setValidators(
                        Validators.required
                      );
                    } else {
                      this.form.controls.mcq_threshold.clearValidators();
                    }
                    this.form.controls.mcq_threshold.updateValueAndValidity();
                  });
                this.form.controls.scq_consensus.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue && this.form.controls.use_scq.value) {
                      this.form.controls.scq_threshold.setValidators(
                        Validators.required
                      );
                    } else {
                      this.form.controls.scq_threshold.clearValidators();
                    }
                    this.form.controls.scq_threshold.updateValueAndValidity();
                  });
              }
            }
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate([
      '/main/project/' + this.project_id + '/round/' + this.round_id,
    ]);
  }

  configureLikert() {
    const Ref = this.dialog.open(RoundLikertOptionsComponent, {
      data: {
        project_id: this.project_id,
        round_id: this.round_id,
        round_status: this.round_status,
      },
      width: '600px',
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.questionOptionsService
            .deleteLikertOptions(this.project_id, this.round_id)
            .pipe(first())
            .subscribe(
              () => {
                result.forEach((element) => {
                  this.addLikertOptions(
                    element.label,
                    element.position,
                    element.consensus_group,
                    element.colour
                  )
                    .then((outcome) => {
                      console.log('Options saved');
                    })
                    .catch((error) => {
                      console.error(error);
                      this.errMsg = error;
                    });
                });
              },
              (error) => {
                console.error(error);
                this.errMsg = error;
              }
            );
        }
      });
  }

  async addLikertOptions(label, position, consensus_group, colour?) {
    try {
      await this.questionOptionsService
        .addLikertOption(
          this.project_id,
          this.round_id,
          label,
          position,
          consensus_group,
          colour
        )
        .pipe(first())
        .subscribe(
          (result) => {
            return result;
          },
          (error) => {
            return error;
          }
        );
    } catch (error) {
      console.error(error);
      this.errMsg = error;
    }
  }

  save() {
    this.roundService
      .updateRound(
        this.project_id,
        this.round_id,
        this.form.value.name,
        this.round_status,
        this.form.value.use_freetext,
        this.form.value.use_likert,
        this.form.value.use_numeric,
        this.form.value.use_ranking,
        this.form.value.use_mcq,
        this.form.value.use_scq,
        this.form.value.zinc_code,
        this.note,
        this.form.value.likert_consensus,
        this.form.value.numeric_consensus,
        this.form.value.ranking_consensus,
        this.form.value.mcq_consensus,
        this.form.value.scq_consensus,
        this.form.value.likert_threshold,
        this.form.value.numeric_threshold,
        this.form.value.ranking_threshold,
        this.form.value.mcq_threshold,
        this.form.value.scq_threshold
      )
      .pipe(first())
      .subscribe(
        () => {
          this.form.markAsPristine();
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}
