import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  QuestionTypeFunctions,
  RoutedQuestionTypes,
} from '../../_shared/questionTypes';

@Component({
  selector: 'app-round-add-question-sheet',
  templateUrl: './round-add-question-sheet.component.html',
  styleUrls: ['./round-add-question-sheet.component.scss'],
})
export class RoundAddQuestionSheetComponent implements OnInit {
  form: UntypedFormGroup;
  questionTypes = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<RoundAddQuestionSheetComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      questionType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.questionTypes = QuestionTypeFunctions.getRoutedTypes(
      this.data.use_freetext,
      this.data.use_likert,
      this.data.use_mcq,
      this.data.use_numeric,
      this.data.use_ranking,
      this.data.use_scq
    );
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }

  save(): void {
    this._bottomSheetRef.dismiss(this.form.value);
  }
}
