import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  Input,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionService } from '../../../../../_services/question.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserService } from '../../../../../_services/user.service';
import { first, takeUntil } from 'rxjs/operators';
import {
  ConfirmDeleteModalComponent,
  ConfirmDeleteSheetComponent,
} from '../../../../../components/confirm-delete/confirm-delete.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-round-dash-table',
  templateUrl: './round-dash-table.component.html',
  styleUrls: ['./round-dash-table.component.scss'],
})
export class RoundDashTableComponent implements OnInit, OnDestroy {
  @Input() round_status: string;
  @Input() hide_incomplete_results: boolean;

  is_client: boolean;

  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');

  dataSource: TableDataSource;
  displayedColumns = [
    'question_number',
    'name',
    'num_responses',
    'consensus_status',
    'action_view',
    'action_edit',
    'action_delete',
  ];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'question_number';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageSub: Subscription;
  pageIndex = 0;
  pageSize = 10;

  ngDestroy$ = new Subject();

  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private questionService: QuestionService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(first())
      .subscribe(
        (user) => {
          if (user) {
            if (user.participant_account) {
              this.router.navigate(['/login']);
            } else {
              this.is_client = user.client_account;
              this.adjustColumns(window.innerWidth);
            }
          }
        },
        (error) => {
          this.errMsg = error;
        }
      );

    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.questionService,
      this.processHTTPMsgService
    );
    this.dataSource.loadQuestions(this.project_id, this.round_id).then(
      (res) => {
        this.tableData = res;
        this.showTable = true;
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (this.is_client) {
      if (screenWidth <= 959) {
        this.displayedColumns = ['question_number', 'name', 'num_responses'];
      } else {
        this.displayedColumns = [
          'question_number',
          'name',
          'num_responses',
          'consensus_status',
        ];
      }
    } else {
      if (screenWidth <= 959) {
        this.displayedColumns = [
          'question_number',
          'name',
          'action_view',
          'action_edit',
          'action_delete',
        ];
      } else if (screenWidth <= 1279) {
        this.displayedColumns = [
          'question_number',
          'name',
          'num_responses',
          'consensus_status',
          'action_view',
          'action_edit',
          'action_delete',
        ];
      } else {
        this.displayedColumns = [
          'question_number',
          'name',
          'num_responses',
          'consensus_status',
          'action_view',
          'action_edit',
          'action_delete',
        ];
      }
    }
  }

  editQuestion(row, index) {
    this.router.navigate([
      '/main/project/' +
        this.project_id +
        '/round/' +
        this.round_id +
        '/question/' +
        row.question_number +
        '/edit',
    ]);
  }

  viewResponses(row, index) {
    this.router.navigate([
      '/main/project/' +
        this.project_id +
        '/round/' +
        this.round_id +
        '/question/' +
        row.question_number +
        '/responses',
    ]);
  }

  deleteQuestion(row, index) {
    // TODO: add a block that prevents deletion if there are active non-default branches to said question (or a warning)?
    // currently these are updated to go to the next question in line or end the survey, or be deleted if it's part of the branch condition
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ConfirmDeleteSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.questionService
              .deleteQuestion(
                this.project_id,
                this.round_id,
                row.question_number
              )
              .pipe(first())
              .subscribe(
                (result) => {
                  this.snackBar.open('Question deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.dataSource
                    .loadQuestions(this.project_id, this.round_id)
                    .then(
                      (res) => {
                        this.tableData = res;
                        this.showTable = true;
                      },
                      (error) => {
                        console.error(error);
                        this.errMsg = error;
                      }
                    );
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
        width: '400px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.questionService
              .deleteQuestion(
                this.project_id,
                this.round_id,
                row.question_number
              )
              .pipe(first())
              .subscribe(
                (result) => {
                  this.snackBar.open('Question deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.dataSource
                    .loadQuestions(this.project_id, this.round_id)
                    .then(
                      (res) => {
                        this.tableData = res;
                        this.showTable = true;
                      },
                      (error) => {
                        console.error(error);
                        this.errMsg = error;
                      }
                    );
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  onRowClicked(row, index) {
    if (this.is_client) {
      this.viewResponses(row, index);
    }
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private questionService: QuestionService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadQuestions(project_id, round_id): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.questionService
        .getQuestions(project_id, round_id)
        .pipe(first())
        .subscribe(
          (questions) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(questions.data);
            resolve(questions.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
