import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  OnDestroy,
  Input,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ProjectService } from '../../../../../_services/project.service';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserService } from '../../../../../_services/user.service';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.scss'],
})
export class OverviewTableComponent implements OnInit, OnDestroy {
  @Input() admin: boolean;
  @Input() user_id: number;

  dataSource: TableDataSource;
  displayedColumns = [
    'name',
    'project_code',
    'client',
    'disease_area',
    'status',
  ];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'name';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageSub: Subscription;
  pageIndex = 0;
  pageSize = 10;
  ngDestroy$ = new Subject();

  constructor(
    private projectService: ProjectService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.projectService,
      this.processHTTPMsgService
    );

    this.dataSource.loadProjects().then(
      (res) => {
        this.tableData = res;
        this.showTable = true;
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (screenWidth <= 959) {
      this.displayedColumns = ['name', 'status'];
    } else if (screenWidth <= 1279) {
      this.displayedColumns = ['name', 'client', 'status'];
    } else {
      this.displayedColumns = [
        'name',
        'project_code',
        'client',
        'disease_area',
        'status',
      ];
    }
  }

  onRowClicked(row, index) {
    this.router.navigate(['/main/project/' + row.id]);
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private projectService: ProjectService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadProjects(): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.projectService
        .getProjects('%', '{"%"}', '%')
        .pipe(first())
        .subscribe(
          (projects) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(projects.data);
            resolve(projects.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
