<div
  fxLayout="row"
  fxFlex="100%"
  fxLayoutAlign="space-evenly center"
  fxLayout.lt-md="column"
  class="footer"
>
  <div
    *ngIf="
      copyApprovalDetails | async as copyApprovalDetails;
      else processAsync
    "
  >
    <a class="dark-grey" style="font-size: small; text-align: center">
      {{ copyApprovalDetails.data.row_to_json.zinc_code }}</a
    >
    <a class="dark-grey date" style="font-size: small; text-align: center">
      Produced on: {{ copyApprovalDetails.data.row_to_json.updated_on }}</a
    >
  </div>

</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div>
      <!-- <mat-spinner diameter="50"></mat-spinner> -->
    </div>
  </ng-template>
</ng-template>
