<div fxLayout="row" fxLayout.lt-md="column">
  <!-- Left Panel -->
  <div
    class="accent-back"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="50%"
    fxFlex.lt-md="100%"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-md="center center"
      class="half-panel"
    >
      <a class="heading dark-grey">COSTELLO MEDICAL</a>
      <a class="name dark-grey">DELPHI APP</a>
      <a class="underline">-</a>
    </div>
  </div>
  <!-- Right Panel -->
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="50%"
    fxFlex.lt-md="100%"
  >
    <div fxLayout="column" fxLayoutAlign="center center" class="half-panel">
      <form
        [formGroup]="loginForm"
        autocomplete="off"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-form-field
          class="form-control"
          *ngIf="!loginForm.value.expiredToken"
        >
          <input
            matInput
            placeholder="Enter New Password"
            type="password"
            id="password"
            formControlName="password"
            required
          />
          <mat-hint>
            <span
              *ngIf="
                loginForm.controls.password.errors?.email &&
                !loginForm.controls.password.pristine
              "
              >Please enter a new password</span
            >
          </mat-hint>
        </mat-form-field>
        <mat-form-field
          class="form-control"
          *ngIf="!loginForm.value.expiredToken"
        >
          <input
            matInput
            placeholder="Confirm Password"
            type="password"
            id="password"
            formControlName="repeatPassword"
            required
          />
          <mat-hint>
            <span
              *ngIf="
                loginForm.controls.password.errors?.email &&
                !loginForm.controls.password.pristine
              "
              >Please re-enter the password</span
            >
          </mat-hint>
        </mat-form-field>
        <mat-form-field
          class="form-control"
          *ngIf="loginForm.value.expiredToken"
        >
          <input
            matInput
            placeholder="Email"
            type="email"
            id="email"
            formControlName="email"
            email
            required
          />
          <mat-hint>
            <span
              *ngIf="
                !loginForm.value.email && !loginForm.controls.email.pristine
              "
              >Please enter a valid email address</span
            >
          </mat-hint>
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          id="submit"
          *ngIf="!loginForm.value.expiredToken"
          [disabled]="loginForm.invalid"
          class="button"
          (click)="savePassword()"
        >
          Save Password
        </button>
      </form>
      <a
        *ngIf="errMsg"
        class="warn-fore error-message"
        fxLayout="row"
        fxLayoutAlign="center"
        >{{ errMsg }}</a
      >
      <button
        *ngIf="
          errMsg ===
          'We were unable to find a valid token. Your token may have expired.'
        "
        type="button"
        mat-button
        (click)="expiredToken()"
      >
        Request New Token
      </button>
      <div *ngIf="loginForm.value.expiredToken">
        <button
          mat-flat-button
          color="primary"
          [disabled]="loginForm.controls.email.invalid"
          class="button"
          (click)="resetPassword()"
        >
          Send Request
        </button>
      </div>
      <a class="footnote dark-grey error-message" style="font-size: small"
        >© {{ year }} Costello Medical Consulting Limited</a
      >
    </div>
  </div>
</div>
