<div class="figure" *ngIf="chartData | async as chartData else processAsync">
    <canvas baseChart *ngIf="showChart"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType">
  </canvas>
</div>

<ng-template #processAsync>
  <ng-template #loading>
      <div>
          <mat-spinner color="accent" diameter="50"></mat-spinner>
      </div>
  </ng-template>
</ng-template>