<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<div class="table-container">
<mat-table mat-table [dataSource]="tableData" *ngIf="showTable" matSort matSortActive={{sortField}} matSortDirection={{direction}}>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Round</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.name}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="num_questions">
        <mat-header-cell *matHeaderCellDef>Number of Questions</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.questions}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="progress">
        <mat-header-cell *matHeaderCellDef>Progress</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a *ngIf="item.questions === '0'">No questions have been added</a>
          <a *ngIf="item.completed_participants === 0 && item.questions !== '0'">No responses have been collected</a>
          <a *ngIf="item.completed_participants > 0">{{getProgress(item)}} participants have responded</a>
        </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="results">
        <mat-header-cell *matHeaderCellDef>Results</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.consensus}}/{{item.questions}} questions have reached consensus</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index;">
        <a>{{item.status}}</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" (click)="onRowClicked(row, i)"></mat-row>
</mat-table>
</div>
<!-- <mat-paginator 
  [length]="numRecords"
  [pageSize]="pageSize"
  [pageSizeOptions]="[5]" 
  showFirstLastButtons
></mat-paginator> -->

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>
