import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { RoundService } from '../../../_services/round.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  UntypedFormArray,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { UserService } from '../../../_services/user.service';
import { first, takeUntil } from 'rxjs/operators';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from '../../../_shared/dateFormating';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-round-participants',
  templateUrl: './round-participants.component.html',
  styleUrls: ['./round-participants.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class RoundParticipantsComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');

  dataSource: TableDataSource;
  displayedColumns = ['participant', 'alias', 'include'];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  participation = new UntypedFormArray([]);
  aliases = new UntypedFormArray([]);
  deadlines = new UntypedFormArray([]);
  uniqueAliases = true;

  ngDestroy$ = new Subject();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private roundService: RoundService,
    public router: Router,
    private userService: UserService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.client_account) {
                this.router.navigate(['/main/project/' + this.project_id]);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              }
            }
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }

    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.roundService,
      this.processHTTPMsgService
    );
    this.dataSource.loadParticipants(this.project_id, this.round_id).then(
      (res) => {
        this.tableData = res;

        //Reset form arrays to remove existing controls
        while (this.participation.length !== 0) {
          this.participation.removeAt(0);
        }
        while (this.aliases.length !== 0) {
          this.aliases.removeAt(0);
        }
        while (this.deadlines.length !== 0) {
          this.deadlines.removeAt(0);
        }

        res.forEach((participant) => {
          this.participation.push(
            new UntypedFormControl(participant.confirmed)
          );
          this.aliases.push(
            new UntypedFormControl(participant.alias, Validators.required)
          );
          this.deadlines.push(new UntypedFormControl(participant.deadline));
        });

        this.showTable = true;

        // Validating aliases are still unique
        this.aliases.valueChanges
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe((val) => {
            const uniques = new Set(val);
            this.uniqueAliases = uniques.size === val.length;
          });
      },
      (error) => {
        console.error(error);
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (screenWidth <= 959) {
      this.displayedColumns = ['participant', 'alias', 'include'];
    } else {
      this.displayedColumns = [
        'participant',
        'alias',
        'include',
        'progress',
        'deadline',
        'reminders',
      ];
    }
  }

  goBack(): void {
    this.router.navigate([
      '/main/project/' + this.project_id + '/round/' + this.round_id,
    ]);
    this.router.navigate([
      '/main/project/' + this.project_id + '/round/' + this.round_id,
    ]);
  }

  saveChanges(): void {
    for (let i = 0; i < this.tableData.length; i++) {
      if (this.deadlines.value[i]) {
        this.saveParticipation(
          this.aliases.value[i],
          this.participation.value[i],
          this.tableData[i].id,
          new Date(this.deadlines.value[i]).getDate(),
          new Date(this.deadlines.value[i]).getMonth(),
          new Date(this.deadlines.value[i]).getFullYear()
        )
          .then((outcome) => {
            console.log('Participation saved');
          })
          .catch((error) => {
            console.error(error);
            this.errMsg = error;
          });
      } else {
        this.saveParticipation(
          this.aliases.value[i],
          this.participation.value[i],
          this.tableData[i].id
        )
          .then((outcome) => {
            console.log('Participation saved');
          })
          .catch((error) => {
            console.error(error);
            this.errMsg = error;
          });
      }
    }
    this.deadlines.markAsPristine();
    this.aliases.markAsPristine();
    this.participation.markAsPristine();
  }

  async saveParticipation(
    alias,
    confirmed,
    participant_id,
    day?,
    month?,
    year?
  ) {
    try {
      await this.roundService
        .updateParticipation(
          this.project_id,
          this.round_id,
          participant_id,
          alias,
          confirmed,
          day,
          month,
          year
        )
        .pipe(first())
        .subscribe(
          (result) => {
            return result;
          },
          (error) => {
            return error;
          }
        );
    } catch (error) {
      console.error(error);
      this.errMsg = error;
    }
  }

  sendInvites() {
    const users = [];
    for (let i = 0; i < this.tableData.length; i++) {
      if (this.participation.value[i]) {
        users.push({
          email: this.tableData[i].email,
          title: this.tableData[i].title,
          last_name: this.tableData[i].last_name,
          deadline: this.deadlines.value[i],
        });
      }
    }
    this.roundService
      .sendRoundInvites(this.project_id, this.round_id, users)
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open('Invitation emails sent!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  sendInvite(participant, deadline) {
    const user = {
      email: participant.email,
      title: participant.title,
      last_name: participant.last_name,
      deadline: deadline,
    };
    this.roundService
      .sendRoundInvites(this.project_id, this.round_id, [user])
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open('Invitation email sent!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private roundService: RoundService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadParticipants(project_id, round_id): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.roundService
        .getParticipants(project_id, round_id)
        .pipe(first())
        .subscribe(
          (participants) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(participants.data);
            resolve(participants.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
