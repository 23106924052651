<mat-toolbar color="primary">
    <span>Edit Client Company</span>
</mat-toolbar>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<mat-dialog-content [formGroup]="form" autocomplete="off" fxLayout="column" *ngIf="client_company | async as client_company else processAsync">
    <p><b>Please note that these settings apply to all projects with this client company</b></p>
    <mat-form-field fxFlex="95%">
        <input matInput class="message" type="text" formControlName="name" placeholder="Client Company Name" maxlength="30" required>
        <mat-hint>
            <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                class="warn-fore">Please enter a company name</span>
            <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <h3>Colour Scheme</h3>
    <p>Participants will view the app with this colour scheme. Aim to use colours that are visible with white text.</p>
    <div fxLayout="column">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-evenly center"
            [style.background]="primary_colour" [style.color]="'white'">
            Primary Colour
            <a [(colorPicker)]="primary_colour" [cpToggle]="true"
            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
            [cpFallbackColor]="'#bdbdbd'"  [cpDialogDisplay]="'inline'"></a>
        </div>
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-evenly center" 
            [style.background]="secondary_colour" [style.color]="'white'">
            Secondary Colour
            <a [(colorPicker)]="secondary_colour" [cpToggle]="true"
            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
            [cpFallbackColor]="'#bdbdbd'"  [cpDialogDisplay]="'inline'"></a>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <span class="flex-spacer"></span>
    <button mat-flat-button color="accent" (click)="close()">Cancel</button>
    <button cdkFocusInitial type="submit" mat-flat-button color="primary" 
        (click)="save()" [disabled]="form.invalid">Save</button>
</mat-dialog-actions>

<!-- LOADING -->
<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>
