import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  Input,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ProcessHttpmsgService } from '../../_services/process-httpmsg.service';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserService } from '../../_services/user.service';
import { first, takeUntil } from 'rxjs/operators';
import { ProfilingQuestionService } from '../../_services/profiling-question.service';
import {
  ConfirmDeleteModalComponent,
  ConfirmDeleteSheetComponent,
} from '../confirm-delete/confirm-delete.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profiling-questions-table',
  templateUrl: './profiling-questions-table.component.html',
  styleUrls: ['./profiling-questions-table.component.scss'],
})
export class ProfilingQuestionsTableComponent implements OnInit, OnDestroy {
  is_client: boolean;
  @Input() started_rounds: number;

  project_id = this.route.snapshot.paramMap.get('project_id');

  dataSource: TableDataSource;
  displayedColumns = [
    'question_number',
    'name',
    'num_responses',
    'consensus_status',
    'consensus_percent',
    'action_view',
    'action_edit',
    'action_delete',
  ];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'question_number';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageSub: Subscription;
  pageIndex = 0;
  pageSize = 10;

  ngDestroy$ = new Subject();

  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private profilingQuestionService: ProfilingQuestionService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(first())
      .subscribe(
        (user) => {
          if (user) {
            if (user.participant_account) {
              this.router.navigate(['/login']);
            }
            this.is_client = user.client_account;
            this.adjustColumns();
          }
        },
        (error) => {
          this.errMsg = error;
        }
      );

    this.adjustColumns();

    this.dataSource = new TableDataSource(
      this.profilingQuestionService,
      this.processHTTPMsgService
    );
    this.dataSource
      .loadQuestions(this.project_id)
      .then((res) => {
        this.tableData = res;
        this.showTable = true;
      })
      .catch((error) => {
        console.error(error);
        this.errMsg = error;
      });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  adjustColumns() {
    if (this.is_client) {
      this.displayedColumns = ['question_number', 'name'];
    } else {
      this.displayedColumns = [
        'question_number',
        'name',
        'action_view',
        'action_edit',
        'action_delete',
      ];
    }
  }

  editQuestion(row, index) {
    this.router.navigate([
      '/main/project/' +
        this.project_id +
        '/profiling/question/' +
        row.question_number +
        '/edit',
    ]);
  }

  viewResponses(row, index) {
    this.router.navigate([
      '/main/project/' +
        this.project_id +
        '/profiling/question/' +
        row.question_number +
        '/responses',
    ]);
  }

  onRowClicked(row, index) {
    if (this.is_client) {
      this.viewResponses(row, index);
    }
  }

  deleteQuestion(row, index) {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ConfirmDeleteSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.profilingQuestionService
              .deleteProfilingQuestion(this.project_id, row.question_number)
              .pipe(first())
              .subscribe(
                (result) => {
                  this.snackBar.open('Question deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.dataSource.loadQuestions(this.project_id).then(
                    (res) => {
                      this.tableData = res;
                      this.showTable = true;
                    },
                    (error) => {
                      console.error(error);
                      this.errMsg = error;
                    }
                  );
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
        width: '400px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.profilingQuestionService
              .deleteProfilingQuestion(this.project_id, row.question_number)
              .pipe(first())
              .subscribe(
                (result) => {
                  this.snackBar.open('Question deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.dataSource.loadQuestions(this.project_id).then(
                    (res) => {
                      this.tableData = res;
                      this.showTable = true;
                    },
                    (error) => {
                      console.error(error);
                      this.errMsg = error;
                    }
                  );
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private profilingQuestionService: ProfilingQuestionService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadQuestions(project_id): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.profilingQuestionService
        .getProfilingQuestions(project_id)
        .pipe(first())
        .subscribe(
          (questions) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(questions.data);
            resolve(questions.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
