<div class="figure" *ngIf="questionType !== 'Numeric'">
  <button
    *ngIf="enableDownloads"
    mat-flat-button
    color="primary"
    (click)="downloadChart()"
    style="margin-bottom: 10px"
  >
    Download Chart
  </button>
  <canvas
    baseChart
    id="my-chart"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
  >
  </canvas>

  <a fxLayoutAlign="end" class="footnote"
    >Number of responses: {{ chartData.num_responses }}</a
  >
</div>
<div class="figure-numeric" *ngIf="questionType === 'Numeric'">
  <chart
    type="horizontalBoxplot"
    [data]="boxplotData"
    [options]="boxplotOptions"
  ></chart>
  <a fxLayoutAlign="end" class="footnote"
    >Number of responses: {{ chartData.num_responses }}</a
  >
</div>
