<div fxLayout="column" *ngIf="question | async as question; else processAsync">
  <!-- PAGE HEADER -->
  <div class="menubar accent-back" fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <h3>Question Responses: "{{ question.data.question }}"</h3>
    <span class="flex-spacer"></span>
  </div>

  <!-- UPPER PANEL -->
  <div *ngIf="question.data.num_responses > 0">
    <!-- Summary figure -->
    <div
      *ngIf="question.data.question_type !== 'Free Text'"
      fxFlex="75%"
      fxFlex.sm="70%"
      fxFlex.xs="100%"
    >
      <h3 class="subheader">SUMMARY OF GROUP'S RESPONSES</h3>
      <mat-divider></mat-divider>
      <app-question-response-figure
        *ngIf="chartData | async as chartData; else processAsync"
        [barcolour]="'#880e4f'"
        [chartData]="chartData.data"
        [questionType]="question.data.question_type"
        [enableDownloads]="true"
      >
      </app-question-response-figure>
    </div>

    <!-- Consensus Stats -->
    <div
      *ngIf="
        (question.data.question_type !== 'Free Text' &&
          question.data.calculate_consensus) ||
        question.data.question_type === 'Numeric'
      "
      fxFlex="25%"
      fxFlex.sm="30%"
      fxHide.lt-sm="true"
      fxLayout="column"
      fxLayoutAlign="start space-between"
    >
      <h3 fxLayout="row" fxLayoutAlign="start" class="subheader">
        CONSENSUS STATS
      </h3>
      <!-- SUMMARY DATA? -->
      <mat-divider></mat-divider>
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="stats-panel new-form"
      >
        <!-- LIKERT VERSION -->
        <div
          *ngIf="question.data.question_type === 'Likert'"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div>
            <h3 *ngIf="!question.data.consensus_reached" class="primary-fore">
              CONSENSUS NOT REACHED
            </h3>
            <h3 *ngIf="question.data.consensus_reached" class="primary-fore">
              CONSENSUS REACHED
            </h3>
          </div>
          <div>
            <mat-progress-spinner
              color="primary"
              mode="determinate"
              value="{{ formatPercent(question.data.agree_strength) }}"
              aria-label="Percent agreement"
            >
            </mat-progress-spinner>
            <div
              style="position: relative; top: -70px; left: 35px; width: 30px"
            >
              {{ formatPercent(question.data.agree_strength) }}%
            </div>
            <div
              style="position: relative; top: -70px; left: 25px; width: 30px"
            >
              AGREE
            </div>
            <mat-progress-spinner
              color="primary"
              mode="determinate"
              value="{{ formatPercent(question.data.disagree_strength) }}"
              aria-label="Percent agreement"
            >
            </mat-progress-spinner>
            <div
              style="position: relative; top: -70px; left: 35px; width: 30px"
            >
              {{ formatPercent(question.data.disagree_strength) }}%
            </div>
            <div
              style="position: relative; top: -70px; left: 13px; width: 30px"
            >
              DISAGREE
            </div>
          </div>
        </div>
        <!-- NUMERIC VERSION -->
        <div
          *ngIf="question.data.question_type === 'Numeric'"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <a class="consensus-option primary-fore"><b>Mean:</b></a>
            <a class="consensus-option"
              ><b>{{ round(question.data.mean, 1) }}</b></a
            >
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <a class="consensus-option primary-fore"
              ><b>Standard Deviation:</b></a
            >
            <a class="consensus-option"
              ><b>{{ round(question.data.standard_deviation, 1) }}</b></a
            >
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <a class="consensus-option primary-fore"><b>Median:</b></a>
            <a class="consensus-option"
              ><b>{{ question.data.median }}</b></a
            >
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <a class="consensus-option primary-fore"><b>Range:</b></a>
            <a class="consensus-option"
              ><b
                >{{ question.data.group_min }} -
                {{ question.data.group_max }}</b
              ></a
            >
          </div>
        </div>
        <!-- RANKING VERSION -->
        <div
          *ngIf="question.data.question_type === 'Ranking'"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div>
            <h3 *ngIf="!question.data.consensus_reached" class="primary-fore">
              CONSENSUS NOT REACHED
            </h3>
            <h3 *ngIf="question.data.consensus_reached" class="primary-fore">
              CONSENSUS REACHED
            </h3>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <a class="consensus-option primary-fore"><b>Kendall's W:</b></a>
            <a class="consensus-option"
              ><b>{{ round(question.data.w_value, 2) }}</b></a
            >
          </div>
        </div>
        <!-- M/SCQ VERSION -->
        <div
          *ngIf="
            question.data.question_type === 'Multiple Choice' ||
            question.data.question_type === 'Single Choice'
          "
          fxLayout="column"
          fxLayoutAlign="center center"
          class="panel-padding"
        >
          <div>
            <h3 *ngIf="!question.data.consensus_reached" class="primary-fore">
              CONSENSUS NOT REACHED
            </h3>
            <h3 *ngIf="question.data.consensus_reached" class="primary-fore">
              CONSENSUS REACHED
            </h3>
          </div>
          <div
            *ngFor="let option of question.data.question_options"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <a class="consensus-option primary-fore"
              ><b>{{ option.label }}:</b></a
            >
            <a class="consensus-option"
              ><b
                >{{ formatPercent(option.consensus_strength) }}% agreement</b
              ></a
            >
            <mat-icon
              *ngIf="option.consensus_reached"
              color="primary"
              matTooltip="Consensus reached for this option"
              >thumb_up_alt</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- RESPONSES TABLE -->
  <div
    class="footer-pad"
    fxFlex="100%"
    *ngIf="responses | async as responses; else processAsync"
  >
    <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
      <h3>PARTICIPANT RESPONSES</h3>
    </div>
    <mat-divider></mat-divider>
    <a
      class="stats-panel"
      fxLayoutAlign="center center"
      *ngIf="responses.data.length === 0"
      >There are no responses for this question</a
    >
    <app-question-response-table
      *ngIf="responses.data.length > 0"
    ></app-question-response-table>
  </div>
</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div>
      <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>
