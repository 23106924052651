<div
  fxLayout="row"
  fxFlex="100%"
  fxLayoutAlign="space-evenly center"
  class="footer"
>
  <!-- <a class="dark-grey" style="font-size: small; text-align: center"
    >© {{ year }} Costello Medical Consulting Limited</a
  > -->
</div>
