<mat-toolbar color="primary">
    <span>Configure Likert Question Options</span>
    <!-- <span class="flex-spacer"></span>
    <button mat-icon-button mat-dialog-close>&times;</button> -->
</mat-toolbar>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<mat-dialog-content>
    <!-- List of existing options -->
    <div class="pad-top" fxLayout="column">
        <a>The options below will be used for all likert questions in this round.</a> 
        <p>Options in the same consensus group will be pooled together for consensus calculations. It is strongly recommended that you have at least one neutral option that is not associated with a consensus group.</p>
    </div>
    <div cdkDropList class="drop-list" (cdkDropListDropped)="drop($event)">
        <div class="drop-box" *ngFor="let option of options" cdkDrag >
            <button mat-icon-button color="warn" (click)="delete(option.id)" [disabled]="data.round_status !== 'Draft'"><mat-icon>delete_outline</mat-icon></button>
            
            <mat-form-field class="form-control name-field">
                <input matInput type="text" [formControl]="optionLabels.controls[option.position]" autocomplete="off" placeholder="Label" 
                    maxlength="30" required>
                <mat-hint>
                    <span class="warn-fore" [hidden]="!optionLabels.controls[option.position].errors?.pattern">This field contains unsupported characters!</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="form-control">
                <mat-label >Consensus Group</mat-label>
                <mat-select [formControl]="consensusGrouping.controls[option.position]"
                    required>
                    <mat-option *ngFor="let group of consensusGroups" [value]="group">
                        {{group}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button (click)="openColourPalette(option)" [style.background]="option.colour"><mat-icon>palette</mat-icon></button>
            <mat-icon cdkDragHandle>drag_handle</mat-icon>
        </div>
    </div>
    
    <!-- New option -->
    <div class="drop-list" [formGroup]="newOption" autocomplete="off">
        <div class="drop-box">
            <mat-form-field class="form-control name-field">
                <input matInput type="text" formControlName="label" placeholder="Label" maxlength="50" required>
                <mat-hint>
                    <span class="warn-fore" [hidden]="!newOption.controls.label.errors?.pattern">This field contains unsupported characters!</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="form-control">
                <mat-label>Consensus Group</mat-label>
                <mat-select formControlName="consensusGroup" required>
                    <mat-option *ngFor="let group of consensusGroups" [value]="group">
                        {{group}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button color="primary" (click)="add()" [disabled]="data.round_status !== 'Draft'">
                <mat-icon>add_circle</mat-icon>
            </button>
        </div>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <span class="flex-spacer"></span>
    <button cdkFocusInitial mat-raised-button mat-dialog-close color="accent">Cancel</button>
    <button  type="submit" mat-raised-button color="primary" (click)="save()" 
        [disabled]="optionLabels.invalid || consensusGrouping.invalid || (pristine && optionLabels.pristine && consensusGrouping.pristine)">
    Save</button>
</mat-dialog-actions>