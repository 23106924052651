
<div class="policy-bounds">
    <div class="policy-header">
        <h1>Privacy Notice</h1>
        <span class="policy-spacer"></span>
        <button mat-icon-button (click)="goBack()"><mat-icon>close</mat-icon></button>
    </div>
    
    <p><b>Last updated: January 17, 2022</b></p>
    <p>Costello Medical provides scientific support to the healthcare industry in the analysis, interpretation and communication of clinical and health economic data.</p>
    <p>Costello Medical are committed to responsibly handling your personal data with respect for individual privacy. This notice is meant to help you to understand how Costello Medical will process any personal data we collect when you visit this website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you. By using the site you agree to the collection and use of information in accordance with this notice.</p>

    <h2>Important Information</h2>
    <h3>Purpose of this Notice</h3>
    <p>This privacy notice aims to give you information on how Costello Medical collects and processes your personal data through your use of this website.</p>
    <p>This website is not intended for children and we do not knowingly collect data relating to children.</p>
    <p>It is important that you read this privacy notice together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements other notices and privacy policies and is not intended to override them.</p>

    <h3>Information About Us</h3>
    <p>Costello Medical Consulting Limited is the data controller responsible for this website and protecting personal data which you provide to us. We are registered in England and Wales at 4th Floor, 50/60 Station Road, Cambridge, CB1 2JH, England, under company number 06746770.</p>
    <p>Costello Medical Consulting Limited and its affiliated companies will be collectively referred to as “Costello Medical”, “we”, “our” and “us”, and this privacy notice will apply to any interactions with such affiliated companies.</p>

    <h3>Contact Details</h3>
    <p>If you have any queries about this policy, please contact our Data Protection Team by emailing <a href="mailto:dataprotection@costellomedical.com">dataprotection@costellomedical.com</a>.</p>
    <p>You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK regulator for data protection issues (<a href="https://ico.org.uk/">ico.org.uk</a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>

    <h3>Changes to the Privacy Notice and Your Duty to Inform Us of Changes</h3>
    <p>We keep our privacy notice under regular review. The date at the top of this privacy notice indicates when it was most recently updated.</p>
    <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

    <h3>Third-Party Links</h3>
    <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share personal data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy/notice of every website you visit.</p>

    <h2>The Data We Collect About You</h2>
    <p>For the purposes of this Privacy Notice, personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We may collect, use, store and transfer different kinds of personal data about you. Personally identifiable information may include, but is not limited to:</p>
    
    <!-- TODO: Update with app-specifics -->
    <ul>
        <li>
            <p>Email address</p>
        </li>
        <li>
            <p>Title, first name and last name</p>
        </li>
        <li>
            <p>Any personal data included in your answers to the questionnaire(s) in this website</p>
        </li>
        <li>
            <p>Usage data</p>
        </li>
    </ul>
    <!-- <p>We do not collect any Special Categories of Personal Data about you.</p> -->

    <h3>Usage Data</h3>
    <p>Usage Data is collected automatically when using the Service.</p>
    <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
    <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
    <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

    <h2>How Your Data is Collected</h2>
    <p>We use different methods to collect data from and about you including through:</p>
    <ul>
        <li>
            <p><b>Direct interactions.</b> You may give us your identity and contact data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you give us feedback or contact us.</p>
        </li>
        <li>
            <p><b>Third parties.</b> We will receive data about you from third party providers such as Cloudflare and Sentry. Please see our <a href="/cookie-statement">Cookie Statement</a> for further details.</p>
        </li>
    </ul>

    <h2>How We Use Your Data</h2>
    <p>We will only use your personal data when the law allows us to. We have set out below a description of all the ways we may use your personal data.</p>
    <!-- TODO: Update with app-specifics -->
    <ul>
        <li>
            <p><b>To provide and maintain this website</b>, including to monitor the usage of this website</p>
        </li>
        <li>
            <p><b>To manage your account</b>, to grant you access to restricted areas of the website and to tailor the content provided to you</p>
        </li>
        <li>
            <p><b>To contact you by email</b>, for example to send you notifications relating to events in the website</p>
        </li>
    </ul>

    <h2>Disclosure of Data</h2>
    <p>We do not sell or otherwise disclose your personal data except as described herein or otherwise disclosed to you. We may share your personal data with the parties set out below for the purposes set out above.</p>
    <h3>International Transfers</h3>
    <ul>
        <li>
            <p>Costello Medical Group Companies as set out in the Glossary</p>
        </li>
        <li>
            <p>External Third Parties as set out in the Glossary</p>
        </li>
    </ul>
    <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

    <h2>Data Security</h2>
    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
    <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

    <h2>Data Retention</h2>
    <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for. To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
    <p>In some circumstances you can ask us to delete your personal data: see Your legal rights below for further information.</p>

    <h2>Your Legal Rights</h2>
    <p>You have the right to:</p>
    <ul>
        <li>
            <p><b>Request access</b> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
        </li>
        <li>
            <p><b>Request correction</b> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
        </li>
        <li>
            <p><b>Request erasure</b> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
        </li>
        <li>
            <p><b>Object to processing</b> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
        </li>
        <li>
            <p><b>Request restriction of processing</b> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</p>
            <ul>
                <li>
                    <p>If you want us to establish the data’s accuracy.</p>
                </li>
                <li>
                    <p>Where our use of the data is unlawful but you do not want us to erase it.</p>
                </li>
                <li>
                    <p>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</p>
                </li>
                <li>
                    <p>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
                </li>
            </ul>
        </li>
        <li>
            <p><b>Request the transfer</b> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
        </li>
        <li>
            <p><b>Withdraw consent</b> at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
        </li>
    </ul>
    <h3>How to Exercise Your Rights</h3>
    <p>If you would like to make a data subject access request in exercise of the above rights, please submit a request by contacting <a href="mailto:dataprotection@costellomedical.com">dataprotection@costellomedical.com</a>. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
    <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
    <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
    
    <h2>Glossary</h2>
    <p><b>Costello Medical Group Companies:</b></p>
    <ul>
        <li>
            <p>Costello Medical Singapore Pte. Ltd., located in Singapore</p>
        </li>
        <li>
            <p>Costello Medical Inc., based in Boston, Massachusetts, USA</p>
        </li>
        <li>
            <p>Costello Medical Shanghai</p>
        </li>
    </ul>
    <!-- TODO: Update with app-specifics -->
    <p><b>External Third Parties:</b></p>
    <ul>
        <li>
            <p>Accredited third party service providers for our legitimate business interests, including IT infrastructure management or hosting our data on cloud platforms;</p>
        </li>
        <li>
            <p>Professional advisers including solicitors, accountants, auditors and insurers who provide consultancy, legal, insurance and accounting services to us;</p>
        </li>
        <li>
            <p>Legal and regulatory bodies which require such disclosure.</p>
        </li>
    </ul>
</div>