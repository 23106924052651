import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { QuestionService } from '../../../../../_services/question.service';
import { ResponseService } from '../../../../../_services/response.service';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { Functions } from '../../../../../_shared/functions';
import { UserService } from '../../../../../_services/user.service';

@Component({
  selector: 'app-question-response',
  templateUrl: './question-response.component.html',
  styleUrls: ['./question-response.component.scss'],
})
export class QuestionResponseComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_number = this.route.snapshot.paramMap.get('question_number');

  chartData: Observable<any>;
  question: Observable<any>;
  responses: Observable<any[]>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionService: QuestionService,
    private responseService: ResponseService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    //watch for changes in route params
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      if (this.userService.isAuthenticated) {
        this.userService
          .getUser()
          .pipe(first())
          .subscribe(
            (user) => {
              if (user) {
                if (user.participant_account) {
                  this.router.navigate(['/login']);
                } else {
                  this.project_id = params.project_id;
                  this.round_id = params.round_id;
                  this.question_number = params.question_number;

                  this.question = this.questionService
                    .getQuestion(
                      this.project_id,
                      this.round_id,
                      this.question_number
                    )
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading question:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.question.pipe(first()).subscribe(
                    (question) => {
                      // Load chart data if applicable
                      if (
                        question.data.num_responses > 0 &&
                        question.data.question_type === 'Likert'
                      ) {
                        this.chartData = this.responseService
                          .likertChoiceSummary(
                            this.project_id,
                            question.data.id
                          )
                          .pipe(
                            catchError((error) => {
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (
                        question.data.num_responses > 0 &&
                        question.data.question_type === 'Multiple Choice'
                      ) {
                        this.chartData = this.responseService
                          .multipleChoiceSummary(
                            this.project_id,
                            question.data.id
                          )
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (
                        question.data.num_responses > 0 &&
                        question.data.question_type === 'Numeric'
                      ) {
                        this.chartData = this.responseService
                          .numericSummary(this.project_id, question.data.id)
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (
                        question.data.num_responses > 0 &&
                        question.data.question_type === 'Ranking'
                      ) {
                        this.chartData = this.responseService
                          .rankingSummary(this.project_id, question.data.id)
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (
                        question.data.num_responses > 0 &&
                        question.data.question_type === 'Single Choice'
                      ) {
                        this.chartData = this.responseService
                          .singleChoiceSummary(
                            this.project_id,
                            question.data.id
                          )
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      }
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );
                  this.responses = this.responseService
                    .getQuestionResponses(
                      this.project_id,
                      this.round_id,
                      this.question_number
                    )
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading responses:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                }
              }
            },
            (error) => {
              this.errMsg = error;
            }
          );
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate([
      '/main/project/' + this.project_id + '/round/' + this.round_id,
    ]);
  }

  formatPercent(decimal: number) {
    return Functions.round(decimal * 100, 0);
  }

  round(number, precision) {
    return Functions.round(Number(number), precision);
  }
}
