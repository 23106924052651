<!-- PAGE HEADER -->
<div class="toolbar accent-back" 
    fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
    <h3>Round Participants</h3>
</div>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<!-- PARTICIPANTS TABLE -->
<div class="table-note" fxLayout="row" fxLayoutAlign="start center">
    <span ><b>Note: </b> Changes made to participant aliases will apply to all other rounds in this project</span>
    <span class="flex-spacer"></span>
    <button mat-flat-button color="primary" (click)="sendInvites()"
        matTooltip="Send an email with details to access the round to all participants included in the round">
        Send Round Invites</button>
</div>

<mat-table mat-table [dataSource]="tableData" *ngIf="showTable" class="form-table" >

    <ng-container matColumnDef="participant">
        <mat-header-cell *matHeaderCellDef>Participant</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.first_name}} {{item.last_name}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alias">
        <mat-header-cell *matHeaderCellDef>Alias</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <mat-form-field>
                <span>Participant</span>
                <input matInput [formControl]="aliases.controls[n]" autocomplete="off" type="number" min="1">
                <mat-hint *ngIf="!uniqueAliases" class="warn-fore">Aliases must be unique!</mat-hint>
            </mat-form-field>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="include">
        <mat-header-cell *matHeaderCellDef>Include in Round?</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <mat-checkbox color="primary" [formControl]="participation.controls[n]"></mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="progress">
        <mat-header-cell *matHeaderCellDef>Round Progress</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <mat-icon *ngIf="item.num_nonresponses + item.num_profiling + item.num_responses === 0"
                matTooltip="Not Started" color="accent">pending_actions</mat-icon>
            <mat-icon *ngIf="item.num_nonresponses + item.num_profiling + item.num_responses > 0 && item.profiling_failed !== true && item.completed !== true"
                matTooltip="Round Started" color="accent">pending</mat-icon>
            <mat-icon *ngIf="item.profiling_failed === true"
                matTooltip="Profiling Failed" color="warn">unpublished</mat-icon>
            <mat-icon *ngIf="item.completed === true" 
                matTooltip="Completed" color="primary">check_circle</mat-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="deadline">
        <mat-header-cell *matHeaderCellDef>Deadline for Responses</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <mat-form-field>
                <input matInput [formControl]="deadlines.controls[n]" autocomplete="off" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reminders">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <button mat-icon-button color="primary" (click)="sendInvite(item, deadlines.value[n])"
                matTooltip="Re-send round invitation email">
                <mat-icon>email</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"></mat-row>
</mat-table>
<a class="table-note" fxLayoutAlign="center center" *ngIf="tableData.length === 0">No participants have been added to this project!</a>
<div fxLayout="row" fxLayoutAlign="end" class="footer-pad save-button form-control"> 
    <button mat-flat-button color="primary" aria-label="Save changes" (click)="saveChanges()"
        [disabled]="participation.invalid || aliases.invalid || !uniqueAliases || deadlines.invalid || (participation.pristine && aliases.pristine && deadlines.pristine)">
        Save Changes
    </button>
</div>

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>