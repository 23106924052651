<mat-toolbar class="approw" color="primary">
    <span>Upload Profiling Questions</span>
  </mat-toolbar>

<input type="file" #file style="display: none" (change)="onFilesAdded()" single accept=".csv" />
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">    
  <div>
      
  </div>
  <ul class="padbelow">
    <li>Questions must be uploaded in <strong>UTF-8 csv format</strong>, with column headings matching those in the template linked below (column order does not matter, additional columns will be ignored)</li>
    <li>The <strong>question_type</strong> and <strong>question</strong> columns <b>must be completed</b> for all questions; other fields can be blank</li>
    <li>Valid question_type values are (<b>case sensitive</b>): <b>Free Text</b>, <b>Likert</b>, <b>Multiple Choice</b>, <b>Numeric</b>, <b>Ranking</b>, or <b>Single Choice</b></li>
    <li>The required and note_use fields expect a value of TRUE or FALSE</li>
    <li>Question numbers will automatically be assigned based on the order that questions appear in the file. If the round already has questions in it, the imported questions will be added after them.</li>
</ul>
  <div>
    <button mat-raised-button color="primary" class="add-files-btn" (click)="getTemplate()">Download Template</button>
    <button [disabled]="uploading || uploadSuccessful" mat-raised-button color="primary" class="add-files-btn" (click)="addFiles()">
      Select File for Upload
    </button>
  </div>

  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content fxFlex>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{file.name}}</h4>
      </mat-list-item>
    </mat-list>

    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <a *ngIf="uploading" >Processing upload...please wait!</a>
    <div fxLayout="column" *ngIf="status">
      <a>{{status.uploaded}}/{{status.target}} questions were uploaded!</a>
      <a class="warn-fore"><b>Please still check questions in the app to ensure there are no errors</b></a>
    </div>
    <span class="flex-spacer"></span>
    <button *ngIf="showCancelButton" mat-button mat-dialog-close>Cancel</button>
    <button *ngIf="!uploading" mat-raised-button color="primary" [disabled]="!canBeClosed" (click)="closeDialog()">{{primaryButtonText}}</button>
    <mat-spinner *ngIf="uploading" diameter=30 strokeWidth=4></mat-spinner>
  </mat-dialog-actions>
</div>
