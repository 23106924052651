import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHttpmsgService } from 'src/app/_services/process-httpmsg.service';
import { ResponseService } from 'src/app/_services/response.service';

@Component({
  selector: 'app-group-response-sheet',
  templateUrl: './group-response-sheet.component.html',
  styleUrls: ['./group-response-sheet.component.scss'],
})
export class GroupResponseSheetComponent implements OnInit, OnDestroy {
  chartData: Observable<any>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private responseService: ResponseService
  ) {}

  ngOnInit(): void {
    // Load chart data
    if (this.data.question.question_type === 'Likert') {
      this.chartData = this.responseService
        .likertChoiceSummary(
          this.data.project_id,
          this.data.question.question_id
        )
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading question:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    } else if (this.data.question.question_type === 'Multiple Choice') {
      this.chartData = this.responseService
        .multipleChoiceSummary(
          this.data.project_id,
          this.data.question.question_id
        )
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading question:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    } else if (this.data.question.question_type === 'Ranking') {
      this.chartData = this.responseService
        .rankingSummary(this.data.project_id, this.data.question.question_id)
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading question:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    } else if (this.data.question.question_type === 'Single Choice') {
      this.chartData = this.responseService
        .singleChoiceSummary(
          this.data.project_id,
          this.data.question.question_id
        )
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading question:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
