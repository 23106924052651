<div [formGroup]="form" class="form-container" autocomplete="off">
    <!-- Section Information -->
    <div *ngIf="section_id">
        <div *ngIf="section | async as section else processAsync">
            <h2>{{section.data.name}}</h2>
            <p class="wrap-text">
                {{section.data.instructions}}
            </p>
            <p class="wrap-text">
                {{section.data.details}}
            </p>
        </div>
    </div>

    <!-- Question Field -->
    <h3 class="question" *ngIf="show_header">{{question}}</h3>
    <p class="wrap-text">{{question_instructions}}</p>
    
    <mat-radio-group 
        formControlName="response"
        class="radio"
        fxLayout="column" fxLayoutAlign="start"
        >
        <mat-radio-button class="radio-button" *ngFor="let option of options"
        color="primary" [value]="option.id">{{option.label}}</mat-radio-button> 
    </mat-radio-group>

    <!-- Optional Notes Field -->
    <mat-form-field *ngIf="useNote" class="form-field"> 
        <mat-label>Include any notes or justification for your response here</mat-label>
        <textarea matInput formControlName="note"></textarea>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.note.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>

    <!-- Opt Out Options -->
    <div>
        <div *ngIf="useSkip1 && skipLabel1">
            <mat-checkbox formControlName="skip1">{{skipLabel1}}</mat-checkbox>
        </div>
        <div *ngIf="useSkip2 && skipLabel2" >
            <mat-checkbox formControlName="skip2">{{skipLabel2}}</mat-checkbox>
        </div>
    </div>

</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>