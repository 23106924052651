<h1 mat-dialog-title>New Section</h1>
<div [formGroup]="form" autocomplete="off" fxLayout="column">
    <mat-form-field class="form-control">
        <input matInput type="text" formControlName="name" placeholder="Section Name" maxlength="50" required>
        <mat-hint>
            <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                class="warn-fore">Please enter a section name</span>
            <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field class="form-control">
        <textarea matInput type="text" formControlName="instructions" placeholder="Instructions"></textarea>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.instructions.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field class="form-control">
        <textarea matInput type="text" formControlName="details" placeholder="Details"></textarea>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.details.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end"> 
    <button mat-flat-button color="accent" aria-label="Cancel" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" aria-label="Add section" [disabled]="form.invalid" (click)="save()">Add</button>
</div>
