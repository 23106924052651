<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<div *ngIf="project | async as project else processAsync" 
    fxLayout="row" fxLayoutAlign="space-evenly start" 
    fxLayout.lt-md="column" fxLayoutAlign.lt-md="center">

<!-- TODO: fix display issues in small screen -->

  <!-- QUESTION -->
  <div fxLayout="column" fxLayoutAlign="center center">
    <div [class.full-container]="!question.linked_qu_id" [class.half-container]="question.linked_qu_id">
        <div *ngIf="question.linked_qu_id" class="lq-instructions">
            <a fxShow fxHide.lt-md="true">
                <b>
                In responding to this question, please refer to the the summary panel to the right of this question.
                </b>
            </a>
            <a fxHide fxShow.lt-md="true">
                <b>
                In responding to this question, please refer to the the summary panel below this question.
                </b>
            </a>
        </div>
            
        <app-display-freetext *ngIf="question.question_type === 'Free Text'"
            [useSkip1]="project.data.opt_out1_use"
            [useSkip2]="project.data.opt_out2_use"
            [skipLabel1]="project.data.opt_out_label1"
            [skipLabel2]="project.data.opt_out_label2"
            [client_colour_primary]="project.data.client_colour_primary"
            [client_colour_secondary]="project.data.client_colour_secondary"
            [question]="question.question"
            [show_header]="true"
            [required]="question.required"
            [response]="existingResponse"
            [section_id]="question.section"
            [question_instructions]="question.instructions"
            (formCopy)="onResponse($event)"
        ></app-display-freetext>
        <app-display-likert *ngIf="question.question_type === 'Likert'" 
            [useSkip1]="project.data.opt_out1_use"
            [useSkip2]="project.data.opt_out2_use"
            [skipLabel1]="project.data.opt_out_label1"
            [skipLabel2]="project.data.opt_out_label2"
            [client_colour_primary]="project.data.client_colour_primary"
            [client_colour_secondary]="project.data.client_colour_secondary"
            [options]="likertOptions"
            [question]="question.question"
            [show_header]="true"
            [useNote]="question.note_use"
            [required]="question.required"
            [response]="existingResponse"
            [section_id]="question.section"
            [question_instructions]="question.instructions"
            (formCopy)="onResponse($event)"
        ></app-display-likert>
        <app-display-numeric *ngIf="question.question_type === 'Numeric'" 
            [useSkip1]="project.data.opt_out1_use"
            [useSkip2]="project.data.opt_out2_use"
            [skipLabel1]="project.data.opt_out_label1"
            [skipLabel2]="project.data.opt_out_label2"
            [client_colour_primary]="project.data.client_colour_primary"
            [client_colour_secondary]="project.data.client_colour_secondary"
            [question]="question.question"
            [show_header]="true"
            [minimum]="question.min_value"
            [maximum]="question.max_value"
            [decimalPlaces]="question.num_dps"
            [units]="question.unit_label"
            [useNote]="question.note_use"
            [required]="question.required"
            [response]="existingResponse"
            [section_id]="question.section"
            [question_instructions]="question.instructions"
            (formCopy)="onResponse($event)"
        ></app-display-numeric>
        <app-display-mcq *ngIf="question.question_type === 'Multiple Choice'"
            [useSkip1]="project.data.opt_out1_use"
            [useSkip2]="project.data.opt_out2_use"
            [skipLabel1]="project.data.opt_out_label1"
            [skipLabel2]="project.data.opt_out_label2"
            [client_colour_primary]="project.data.client_colour_primary"
            [client_colour_secondary]="project.data.client_colour_secondary" 
            [options]="question.question_options"
            [question]="question.question"
            [show_header]="true"
            [useNote]="question.note_use"
            [required]="question.required"
            [response]="existingResponse"
            [section_id]="question.section"
            [question_instructions]="question.instructions"
            (formCopy)="onResponse($event)"
        ></app-display-mcq>
        <app-display-ranking *ngIf="question.question_type === 'Ranking'" 
            [useSkip1]="project.data.opt_out1_use"
            [useSkip2]="project.data.opt_out2_use"
            [skipLabel1]="project.data.opt_out_label1"
            [skipLabel2]="project.data.opt_out_label2"
            [client_colour_primary]="project.data.client_colour_primary"
            [client_colour_secondary]="project.data.client_colour_secondary"
            [options]="question.question_options"
            [top_label]="question.top_label" 
            [bottom_label]="question.bottom_label"
            [question]="question.question"
            [show_header]="true"
            [useNote]="question.note_use"
            [required]="question.required"
            [response]="existingResponse"
            [section_id]="question.section"
            [question_instructions]="question.instructions"
            (formCopy)="onResponse($event)"
        ></app-display-ranking>
        <app-display-scq *ngIf="question.question_type === 'Single Choice'" 
            [useSkip1]="project.data.opt_out1_use"
            [useSkip2]="project.data.opt_out2_use"
            [skipLabel1]="project.data.opt_out_label1"
            [skipLabel2]="project.data.opt_out_label2"
            [client_colour_primary]="project.data.client_colour_primary"
            [client_colour_secondary]="project.data.client_colour_secondary"
            [options]="question.question_options"
            [question]="question.question"
            [show_header]="true"
            [useNote]="question.note_use"
            [required]="question.required"
            [response]="existingResponse"
            [section_id]="question.section"
            [question_instructions]="question.instructions"
            (formCopy)="onResponse($event)"
        ></app-display-scq>
    </div>
  </div>

    <!-- LINKED QUESTION -->
    <div fxLayout="column" *ngIf="question.linked_qu_id" fxLayoutAlign="center center" class="figure-container">
        <mat-card class="card">
            <mat-card-header>
            <mat-card-title>Context from Previous Round</mat-card-title>
            </mat-card-header>
            <mat-card-content >
            <h4>Group responses to: </h4>

            <mat-card *ngIf="!panelOpenState">
                <h4 class="emphasis">{{question.linked_qu_title}}</h4>
                <div fxLayout="row" fxLayoutAlign="end">
                    <a *ngIf="question.linked_qu_instructions || question.linked_qu_section_instructions || question.linked_qu_section_details"
                    class="expand-link" (click)="toggleExpand()">See more</a>
                </div>
            </mat-card>
            <mat-card *ngIf="panelOpenState">
                <h3 *ngIf="question.linked_qu_section_name">
                    {{question.linked_qu_section_name}}</h3>
                <p>{{question.linked_qu_section_instructions}}</p>
                <p>{{question.linked_qu_section_details}}</p>
                <h4 class="emphasis">{{question.linked_qu_title}}</h4>
                <p>{{question.linked_qu_instructions}}</p>
                <div fxLayout="row" fxLayoutAlign="end">
                    <a class="expand-link" (click)="toggleExpand()">See less</a>
                </div> 
            </mat-card>

            <app-question-response-figure *ngIf="chartData | async as chartData else processAsync"
                [barcolour]="project.data.client_colour_primary"
                [chartData]="chartData.data"
                [questionType]="question.linked_qu_type"
            ></app-question-response-figure>
            <div class="text-responses" *ngIf="textData | async as textData else processAsync">
                <app-question-text-summary 
                    [textData]="textData.data"
                    [questionType]="question.linked_qu_type">
                </app-question-text-summary>
            </div>
            
            
            <div *ngIf="linkedResponse | async as linkedResponse else processAsync">
                <h4 *ngIf="linkedResponse.data">
                    Your Response: <a class="emphasis">{{linkedResponse.data.processed_value}}
                        <a *ngIf="linkedResponse.data.other">[{{linkedResponse.data.other}}]</a>
                    </a>
                     
                </h4>
            </div>
            
            </mat-card-content>
        </mat-card>
    </div>

</div>

<div class="floating-section">
    <div fxShow fxShow.xs="false" fxLayout="row" fxLayoutAlign="center" >
        <button mat-flat-button (click)="getPrev()" color="accent" class="button" [disabled]="blockProgress && touched">Back</button>
        <button mat-flat-button *ngIf="num_questions !== question_num" 
            (click)="getNext()" id="next" class="button button-fwd" [disabled]="blockProgress">Next</button>
        <button mat-flat-button *ngIf="num_questions === question_num" 
            (click)="getNext()" id="next" class="button button-fwd" [disabled]="blockProgress">End</button>
    </div>
    <div fxHide fxShow.xs="true" fxLayout="row" fxLayoutAlign="center" >
        <button mat-icon-button (click)="getPrev()" class="button icon-fwd" 
            [disabled]="blockProgress && touched" matTooltip="Back">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <button mat-icon-button *ngIf="num_questions !== question_num" 
            (click)="getNext()" id="next" class="button icon-fwd" 
            [disabled]="blockProgress" matTooltip="Next">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
        <button mat-icon-button *ngIf="num_questions === question_num" 
            (click)="getNext()" id="next" class="button button-fwd" 
            [disabled]="blockProgress" matTooltip="End">
            <mat-icon>done</mat-icon>
        </button>
    </div>
</div>
<!-- <a *ngIf="blockProgress" fxLayout="row" fxLayoutAlign="center" class="warn-fore button-warning">Please complete the question to progress</a> -->


<!-- LOADING -->
<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>