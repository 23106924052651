<div class="figure" *ngIf="chartData | async as chartData else processAsync">
    <canvas baseChart 
    *ngIf="chartData.data.consensus + chartData.data.no_consensus + chartData.data.unmeasured + chartData.data.awaiting !== '0000'"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType">
  </canvas>
  <a *ngIf="chartData.data.consensus + chartData.data.no_consensus + chartData.data.unmeasured + chartData.data.awaiting === '0000'" 
    fxLayoutAlign="center">
    No questions have been added</a>
</div>

<ng-template #processAsync>
  <ng-template #loading>
      <div>
          <mat-spinner color="accent" diameter="50"></mat-spinner>
      </div>
  </ng-template>
</ng-template>