import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ProfilingQuestionService } from '../../../../../_services/profiling-question.service';
import { ProfilingResponseService } from '../../../../../_services/profiling-response.service';
import { UserService } from '../../../../../_services/user.service';

@Component({
  selector: 'app-profiling-responses',
  templateUrl: './profiling-responses.component.html',
  styleUrls: ['./profiling-responses.component.scss'],
})
export class ProfilingResponsesComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  question_number = this.route.snapshot.paramMap.get('question_number');

  chartData: Observable<any>;
  question: Observable<any>;
  responses: Observable<any[]>;
  ngDestroy$ = new Subject();

  dummyRounds = ['Profiling 1', 'Profiling 2'];

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    private profilingQuestionService: ProfilingQuestionService,
    private profilingResponseService: ProfilingResponseService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    //watch for changes in route params
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      if (this.userService.isAuthenticated) {
        this.userService
          .getUser()
          .pipe(first())
          .subscribe(
            (user) => {
              if (user) {
                if (user.participant_account) {
                  this.router.navigate(['/login']);
                } else {
                  this.project_id = params.project_id;
                  this.question_number = params.question_number;

                  this.question = this.profilingQuestionService
                    .getProfilingQuestion(this.project_id, this.question_number)
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading question:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.question.pipe(first()).subscribe(
                    (question) => {
                      // Load chart data if applicable
                      if (question.data.question_type === 'Numeric') {
                        this.chartData = this.profilingResponseService
                          .numericSummary(this.project_id, question.data.id)
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (
                        question.data.question_type === 'Multiple Choice'
                      ) {
                        this.chartData = this.profilingResponseService
                          .multipleChoiceSummary(
                            this.project_id,
                            question.data.id
                          )
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (question.data.question_type === 'Ranking') {
                        this.chartData = this.profilingResponseService
                          .rankingSummary(this.project_id, question.data.id)
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      } else if (
                        question.data.question_type === 'Single Choice'
                      ) {
                        this.chartData = this.profilingResponseService
                          .singleChoiceSummary(
                            this.project_id,
                            question.data.id
                          )
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading question:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      }
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );
                  this.responses = this.profilingResponseService
                    .getQuestionResponses(this.project_id, this.question_number)
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading responses:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                }
              }
            },
            (error) => {
              this.errMsg = error;
            }
          );
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id + '/profiling']);
  }
}
