import { Injectable } from '@angular/core';
import { catchError, map, scan } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
} from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  emailCopyApproval(project_id, round_id): Observable<any> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/copy_approval'
      )
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadCopyApproval(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/copy_approval',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'Copy Approval.pdf')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadProfilingQuestions(project_id): Observable<Download> {
    return this.http
      .get(
        baseURL + '/projects/' + project_id + '/export_profiling_questions/',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'ProfilingQuestionsExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadProfilingTemplate(project_id): Observable<Download> {
    return this.http
      .get(
        baseURL + '/projects/' + project_id + '/import_profiling_questions/',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(
        download((blob) => saveAs(blob, 'profiling_questions_template.csv'))
      )
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadProfilingOptions(project_id): Observable<Download> {
    return this.http
      .get(baseURL + '/projects/' + project_id + '/export_profiling_options/', {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(download((blob) => saveAs(blob, 'ProfilingOptionsExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadProfilingOptionsTemplate(project_id): Observable<Download> {
    return this.http
      .get(baseURL + '/projects/' + project_id + '/import_profiling_options/', {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(download((blob) => saveAs(blob, 'profiling_options_template.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadQuestions(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/export_questions/',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'QuestionsExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadQuestionTemplate(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/import_questions/',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'questions_template.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadQuestionOptions(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/export_options/',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'QuestionOptionsExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadOptionsTemplate(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/import_options/',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'question_options_template.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadProfilingResponses(project_id): Observable<Download> {
    return this.http
      .get(
        baseURL + '/projects/' + project_id + '/export_profiling_responses',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'ProfilingResponsesExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadRoundResponses(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/export_responses',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'ResponsesExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadRoundIndividualResponses(project_id, round_id): Observable<Download> {
    return this.http
      .get(
        baseURL +
          '/projects/' +
          project_id +
          '/rounds/' +
          round_id +
          '/export_individual_responses',
        {
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(download((blob) => saveAs(blob, 'IndividualResponsesExport.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  downloadParticipantUploadTemplate(project_id): Observable<Download> {
    return this.http
      .get(baseURL + '/projects/' + project_id + '/import_participants/', {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(download((blob) => saveAs(blob, 'participant_upload_template.csv')))
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }
}

export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  progress: number;
  content: Blob | null;
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

export function download(
  saver?: (b: Blob) => void
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan(
        (previous: Download, event: HttpEvent<Blob>): Download => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : previous.progress,
              state: 'IN_PROGRESS',
              content: null,
            };
          }
          if (isHttpResponse(event)) {
            if (saver && event.body) {
              saver(event.body);
            }
            return {
              progress: 100,
              state: 'DONE',
              content: event.body,
            };
          }
          return previous;
        },
        { state: 'PENDING', progress: 0, content: null }
      )
    );
}
