import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieSelectionsService {

  private showCookies = new BehaviorSubject(true);
  showCookies$ = this.showCookies.asObservable();

  private optionalCookiesEnabled = new BehaviorSubject(true);
  optionalCookiesEnabled$ = this.optionalCookiesEnabled.asObservable();

  hideCookieStatement() {
    this.showCookies.next(false);
  }

  setOptionalCookies(value: boolean) {
    this.optionalCookiesEnabled.next(value);
  }

  constructor() { }
}
