import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  year: Number;
  errMsg: string;
  showForm = true;

  loginForm: UntypedFormGroup;

  siteKey = '6LcgJ1kaAAAAANz8ev8f7_4PKlAoRgVtaqBQ0ywx';
  loginFailure = false;

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    public snackBar: MatSnackBar
  ) {
    this.loginForm = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      forgottenPassword: [false],
    });
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.userService
      .getUser()
      .pipe(first())
      .subscribe(
        (user) => {
          if (user) {
            if (user.client_account) {
              this.userService
                .getActiveProject()
                .pipe(first())
                .subscribe(
                  (project) => {
                    this.router.navigate([
                      '/main/project/' + project.data.project,
                    ]);
                  },
                  (error) => {
                    console.error(error);
                  }
                );
            } else if (user.participant_account) {
              if (user.admin_account === true) {
                this.router.navigate(['/login/apocalypse']);
              } else {
                this.userService
                  .getActiveProject()
                  .pipe(first())
                  .subscribe(
                    (project) => {
                      this.router.navigate(['/panel/' + project.data.project]);
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
              }
            } else {
              this.router.navigate(['/main']);
            }
          }
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }

  signIn() {
    this.showForm = false;
    this.userService
      .logIn(this.loginForm.value)
      .pipe(first())
      .subscribe(
        (res) => {
          if (res.success) {
            if (res.client_account) {
              this.userService
                .getActiveProject()
                .pipe(first())
                .subscribe(
                  (project) => {
                    this.router.navigate([
                      '/main/project/' + project.data.project,
                    ]);
                  },
                  (error) => {
                    console.error(error);
                  }
                );
            } else if (res.participant_account) {
              if (res.admin_account === true) {
                this.router.navigate(['/login/apocalypse']);
              } else {
                this.userService
                  .getActiveProject()
                  .pipe(first())
                  .subscribe(
                    (project) => {
                      this.router.navigate(['/panel/' + project.data.project]);
                    },
                    (error) => {
                      // this.errMsg = error;
                      console.error(error);
                    }
                  );
              }
            } else {
              this.router.navigate(['/main']);
            }
          } else {
            this.errMsg = 'Unable to sign in';
          }
        },
        (error) => {
          this.loginFailure = true;
          this.errMsg = error;
          this.showForm = true;
        }
      );
  }

  forgottenPassword() {
    this.loginForm.controls.forgottenPassword.setValue(true);
  }

  cancelReset() {
    this.loginForm.controls.forgottenPassword.setValue(false);
  }

  resetPassword() {
    this.userService
      .requestReset(this.loginForm.value.email)
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open('Request sent! Please check your email', 'Close');
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}
