<mat-toolbar color="warn" fxLayoutAlign="center center">
    <span>Are you sure?</span>
</mat-toolbar>

<mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <p *ngIf="data">{{data.message}}</p>
    <p><b>This can't be undone!</b></p>
    
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxHide.lt-sm>
    <button mat-flat-button color="accent" (click)="close(false)" cdkFocusInitial>Cancel</button>
    <button type="submit" mat-flat-button color="warn" 
        (click)="close(true)">Confirm</button>
</mat-dialog-actions>

<div fxHide.gt-xs fxLayout="row" fxLayoutAlign="center center">
    <button mat-mini-fab color="accent" cdkFocusInitial  (click)="close(false)" class="fab-button">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-mini-fab color="warn" (click)="close(true)" class="fab-button">
        <mat-icon>check</mat-icon>
    </button>
</div>