import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DownloadService } from 'src/app/_services/download.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-participant-upload',
  templateUrl: './participant-upload.component.html',
  styleUrls: ['./participant-upload.component.scss'],
})
export class ParticipantUploadComponent implements OnDestroy {
  @ViewChild('file', { static: true }) file;

  ngDestroy$ = new Subject();
  canBeClosed = false;
  primaryButtonText = 'Upload';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  public files: Set<File> = new Set();
  status = null;
  errMsg: string;

  constructor(
    public dialogRef: MatDialogRef<ParticipantUploadComponent>,
    private papa: Papa,
    private downloadService: DownloadService,
    private userService: UserService,
    @Inject('BaseURL') private BaseURL,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  getTemplate() {
    this.downloadService
      .downloadParticipantUploadTemplate(this.data.project_id)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        () => {
          console.log('Download started');
        },
        (error) => {
          this.errMsg = error;
        },
        () => {
          console.log('Completed file download.');
        }
      );
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    this.files.clear();
    for (const key in files) {
      // tslint:disable-next-line:radix
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
    if (this.files.size < 1) {
      this.canBeClosed = false;
    } else {
      this.canBeClosed = true;
    }
  }

  closeDialog() {
    // if everything was uploaded already, just close the dialog
    if (this.uploadSuccessful) {
      return this.dialogRef.close(true);
    }

    // set the component state to "uploading"
    this.uploading = true;

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;

    // Hide the cancel-button
    this.showCancelButton = false;

    this.files.forEach((file) => {
      this.papa.parse(file, {
        dynamicTyping: true,
        header: true,
        skipEmptyLines: true,
        delimiter: ',',
        complete: (result) => {
          this.userService
            .importParticipants(result.data, this.data.project_id)
            .pipe(first())
            .subscribe(
              (response) => {
                console.log(response);
                this.status = response;
                this.canBeClosed = true;
                this.dialogRef.disableClose = false;
                this.uploadSuccessful = true;
                this.uploading = false;
                this.primaryButtonText = 'Finish';
              },
              (error) => {
                this.errMsg = error;
                this.canBeClosed = true;
                this.dialogRef.disableClose = false;
                this.uploading = false;
                this.primaryButtonText = 'Retry';
              }
            );
        },
      });
    });
  }
}
