<div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
    <mat-form-field class="form-control">
        <input matInput type="text" formControlName="name" placeholder="Name" maxlength="30" required>
        <mat-hint>
            <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                class="warn-fore">Please enter a name for the round</span>
            <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field class="form-control">
        <mat-label>Included Question Types</mat-label>
        <mat-select formControlName="questionTypes" required multiple>
            <mat-option *ngFor="let type of questionTypes" [value]="type">
                {{type}}
            </mat-option>
        </mat-select>
        <mat-hint>
            <span *ngIf="form.controls.questionTypes.errors?.required && form.controls.questionTypes.touched"
                class="warn-fore">Please select an option</span>
        </mat-hint>
    </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="end"> 
    <button mat-fab color="primary" aria-label="Add round" [disabled]="form.invalid" (click)="save()">
        <mat-icon>add</mat-icon>
    </button>
</div>
