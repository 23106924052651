import { MatSnackBar } from '@angular/material/snack-bar';
import {tap} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    public snackBar: MatSnackBar,
  ) {}
  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Intercept http responses
    const _handleResponse = (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    };
    const _handleResponseError = (event: HttpEvent<any>) => {
      
      if (event instanceof HttpErrorResponse) {
        if (event.status == 0) {
            this.snackBar.open(
                'An error occurred. Please try again in a few minutes.', 
                'Close', {verticalPosition: 'top'});
        }
        
        if (event.status == 401) {
        }

        const errorMessage: string = event.error.message
          ? event.error.message
          : 'Unknown error occurred';
      }
    };

    return next.handle(req).pipe(tap(_handleResponse, _handleResponseError));
  }
}