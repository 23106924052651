import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ClientCompanyService } from '../../../_services/client-company.service';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProjectService } from '../../../_services/project.service';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ClientEditSheetComponent } from './components/client-edit-sheet/client-edit-sheet.component';
import { ClientEditModalComponent } from './components/client-edit-modal/client-edit-modal.component';

@Component({
  selector: 'app-project-options',
  templateUrl: './project-options.component.html',
  styleUrls: ['./project-options.component.scss'],
})
export class ProjectOptionsComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  project: Observable<any>;
  primary_contact: Number;
  status: String;
  note: String;
  ngDestroy$ = new Subject();

  form: UntypedFormGroup;
  companies: any[] = [
    {
      id: -1,
      name: 'Create New Company...',
    },
  ];

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private location: Location,
    private clientCompanyService: ClientCompanyService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    public router: Router,
    private userService: UserService,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      project_code: [
        '',
        [
          Validators.maxLength(20),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      disease_area: [
        '',
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      hide_incomplete_results: [true, Validators.required],
      clientCompany: [, Validators.required],
      newClientCompanyName: [
        '',
        [
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      colour_primary: [''],
      colour_secondary: [''],
      opt_out_label1: [
        'Insufficient experience to answer',
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      opt_out_label2: [
        'Prefer not to answer',
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      opt_out1_use: [true],
      opt_out2_use: [true],
    });
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.client_account) {
                this.router.navigate(['/main/project/' + this.project_id]);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.project = this.projectService
                  .getProject(this.project_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading project:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
                this.project.pipe(first()).subscribe(
                  (project) => {
                    this.primary_contact = project.data.primary_contact_id;
                    this.status = project.data.status;
                    this.note = project.data.note;
                    this.form.controls.name.setValue(project.data.name);
                    this.form.controls.project_code.setValue(
                      project.data.project_code
                    );
                    this.form.controls.disease_area.setValue(
                      project.data.disease_area
                    );
                    this.form.controls.hide_incomplete_results.setValue(
                      project.data.hide_incomplete_results
                    );
                    this.form.controls.clientCompany.setValue(
                      project.data.client_company
                    );

                    //Disable opt_out controls if at least one project round has started
                    if (project.data.started_rounds > 0) {
                      this.form.controls.name.disable();
                      this.form.controls.opt_out_label1.disable();
                      this.form.controls.opt_out_label2.disable();
                      this.form.controls.opt_out1_use.disable();
                      this.form.controls.opt_out2_use.disable();
                      this.form.controls.opt_out_label1.setValue(
                        project.data.opt_out_label1,
                        { emitEvent: false }
                      );
                      this.form.controls.opt_out_label2.setValue(
                        project.data.opt_out_label2,
                        { emitEvent: false }
                      );
                      this.form.controls.opt_out1_use.setValue(
                        project.data.opt_out1_use,
                        { emitEvent: false }
                      );
                      this.form.controls.opt_out2_use.setValue(
                        project.data.opt_out2_use,
                        { emitEvent: false }
                      );
                    } else {
                      this.form.controls.opt_out_label1.setValue(
                        project.data.opt_out_label1
                      );
                      this.form.controls.opt_out_label2.setValue(
                        project.data.opt_out_label2
                      );
                      this.form.controls.opt_out1_use.setValue(
                        project.data.opt_out1_use
                      );
                      this.form.controls.opt_out2_use.setValue(
                        project.data.opt_out2_use
                      );

                      this.form.controls.opt_out1_use.valueChanges
                        .pipe(takeUntil(this.ngDestroy$))
                        .subscribe((val) => {
                          if (val) {
                            this.form.controls.opt_out_label1.enable();
                          } else {
                            this.form.controls.opt_out_label1.disable();
                          }
                        });
                      this.form.controls.opt_out2_use.valueChanges
                        .pipe(takeUntil(this.ngDestroy$))
                        .subscribe((val) => {
                          if (val) {
                            this.form.controls.opt_out_label2.enable();
                          } else {
                            this.form.controls.opt_out_label2.disable();
                          }
                        });
                    }
                  },
                  (error) => {
                    console.error(error);
                    this.errMsg =
                      this.processHTTPMsgService.extractH1Error(error);
                  }
                );

                this.form.controls.clientCompany.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue === -1) {
                      this.form.controls.newClientCompanyName.setValidators([
                        Validators.required,
                        Validators.maxLength(30),
                        Validators.pattern(
                          '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
                        ),
                      ]);
                    } else {
                      this.form.controls.newClientCompanyName.setValidators([
                        Validators.maxLength(30),
                        Validators.pattern(
                          '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
                        ),
                      ]);
                    }
                    this.form.controls.newClientCompanyName.updateValueAndValidity();
                  });

                this.clientCompanyService
                  .getClientCompanies()
                  .pipe(first())
                  .subscribe(
                    (result) => {
                      this.companies = [
                        {
                          id: -1,
                          name: 'Create New Company...',
                        },
                      ];
                      this.companies = this.companies.concat(result.data);
                    },
                    (error) => {
                      console.error(error);
                      this.errMsg = error;
                    }
                  );
              }
            }
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id]);
  }

  clientSettings(client_id) {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ClientEditSheetComponent, {
        data: { client_id: client_id },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            window.location.reload();
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ClientEditModalComponent, {
        data: { client_id: client_id },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            window.location.reload();
          }
        });
    }
  }

  saveChanges() {
    if (
      this.form.controls.newClientCompanyName.value === '' &&
      this.form.controls.clientCompany.value
    ) {
      //Chose an existing client company
      this.projectService
        .updateProject(
          this.project_id,
          this.form.controls.name.value,
          this.form.controls.clientCompany.value,
          this.primary_contact,
          this.status,
          this.form.controls.project_code.value,
          this.form.controls.disease_area.value,
          this.form.controls.hide_incomplete_results.value,
          false,
          this.form.controls.opt_out_label1.value,
          this.form.controls.opt_out_label2.value,
          this.form.controls.opt_out1_use.value,
          this.form.controls.opt_out2_use.value,
          this.note
        )
        .pipe(first())
        .subscribe(
          (update) => {
            this.form.markAsPristine();
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    } else {
      //Create the new client company first
      this.clientCompanyService
        .addClientCompany(this.form.controls.newClientCompanyName.value)
        .pipe(first())
        .subscribe(
          (newClient) => {
            const clientCompany = newClient.data.id;
            this.projectService
              .updateProject(
                this.project_id,
                this.form.controls.name.value,
                clientCompany,
                this.primary_contact,
                this.status,
                this.form.controls.project_code.value,
                this.form.controls.disease_area.value,
                this.form.controls.hide_incomplete_results.value,
                false,
                this.form.controls.opt_out_label1.value,
                this.form.controls.opt_out_label2.value,
                this.form.controls.opt_out1_use.value,
                this.form.controls.opt_out2_use.value,
                this.note
              )
              .pipe(first())
              .subscribe(
                (update) => {
                  this.form.markAsPristine();
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    }
  }
}
