<h1 mat-dialog-title>New Question</h1>
<div [formGroup]="form" autocomplete="off" fxLayout="column">
    <mat-form-field class="form-control">
        <textarea matInput type="text" formControlName="name" placeholder="Question" required></textarea>
        <mat-hint>
            <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                class="warn-fore">Please enter the question</span>
            <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field class="form-control">
        <mat-label>Question Type</mat-label>
        <mat-select formControlName="questionType" required>
            <mat-option *ngFor="let type of questionTypes" [value]="type">
                {{type}}
            </mat-option>
        </mat-select>
        <mat-hint>
            <span *ngIf="form.controls.questionType.errors?.required && form.controls.questionType.touched"
                class="warn-fore">Please select an option</span>
        </mat-hint>
    </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end"> 
    <!-- <button mat-fab color="primary" aria-label="Add question" [disabled]="form.invalid" (click)="save()">
        <mat-icon>add</mat-icon>
    </button> -->
    <button mat-flat-button color="accent" aria-label="Cancel" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" aria-label="Add question" [disabled]="form.invalid" (click)="save()">Add</button>
</div>
