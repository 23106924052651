import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoundService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getRounds(project_id, status='{"%"}'): Observable<any> {
    let params = new HttpParams()
    .set('roundStatus', status)

    return this.http.get(baseURL + '/projects/' + project_id + '/rounds', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addRound(project_id, name, use_freetext?, use_likert?, 
      use_numeric?, use_ranking?, use_mcq?, use_scq?, zinc_code?, note?): Observable<any> {
    const content = {
      name: name,
      zinc_code: zinc_code,
      use_freetext: use_freetext,
      use_likert: use_likert,
      use_numeric: use_numeric,
      use_ranking: use_ranking,
      use_mcq: use_mcq,
      use_scq: use_scq,
      note: note
    };
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getParticipantRounds(project_id, status='{"%"}'): Observable<any> {
    let params = new HttpParams()
    .set('roundStatus', status)

    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/participant', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getRound(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    ); 
  }

  updateRound(project_id, round_id, name, round_status, use_freetext?, use_likert?, use_numeric?, use_ranking?,
    use_mcq?, use_scq?, zinc_code?, note?, likert_consensus?, numeric_consensus?, ranking_consensus?,
    mcq_consensus?, scq_consensus?, likert_threshold?, numeric_threshold?, ranking_threshold?, mcq_threshold?, 
    scq_threshold?
  ): Observable<any> {
    const content = {
      name: name,
      zinc_code: zinc_code,
      round_status: round_status,
      use_freetext: use_freetext,
      use_likert: use_likert,
      use_numeric: use_numeric,
      use_ranking: use_ranking,
      use_mcq: use_mcq,
      use_scq: use_scq,
      likert_consensus: likert_consensus,
      numeric_consensus: numeric_consensus,
      ranking_consensus: ranking_consensus,
      mcq_consensus: mcq_consensus,
      scq_consensus: scq_consensus,
      likert_threshold: likert_threshold,
      numeric_threshold: numeric_threshold,
      ranking_threshold: ranking_threshold,
      mcq_threshold: mcq_threshold,
      scq_threshold: scq_threshold,
      note: note
    };
    return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteRound(project_id, round_id): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/rounds/' + round_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  roundPrep(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/check').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  openRound(project_id, round_id): Observable<any> {
    return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/open', {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  revertRound(project_id, round_id): Observable<any> {  
    return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/revert', {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  closeRound(project_id, round_id): Observable<any> {
    return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/close', {}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getParticipants(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/permissions').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getRoundProgress(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/progress').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addParticipant(project_id, round_id, participant_id): Observable<any> {
    const content = {
      participant: participant_id,
      confirmed: true
    }
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/permissions', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateParticipation(project_id, round_id, participant_id, alias, confirmed,
      day?, month?, year?): Observable<any> {
    const content = {
      alias: alias,
      confirmed: confirmed,
      day: day,
      month: month,
      year: year
    }
    return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/permissions/' + participant_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateRoundProgression(project_id, round_id, profiling_failed,
    profiling_passed, completed): Observable<any> {
      const content = {
        profiling_failed: profiling_failed,
        profiling_passed: profiling_passed,
        completed: completed
      }
      return this.http.put(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/progression', content).pipe(
        catchError(error => this.processHTTPMsgService.handleError(error))
      );
  }

  sendRoundInvites(project_id, round_id, users): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/send_reminders', users).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

}
