import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-section-edit-modal',
  templateUrl: './section-edit-modal.component.html',
  styleUrls: ['./section-edit-modal.component.scss']
})
export class SectionEditModalComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SectionEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
  ) { 
    this.form = fb.group({
      name: [this.data.section.name, [Validators.required, Validators.maxLength(50), Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\/^\\^\@^\^^;^\`^#]*$')]],
      instructions: [this.data.section.instructions, Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')],
      details: [this.data.section.details, Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')]
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.form.value);
  }

}
