import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ClientCompanyService } from '../../../_services/client-company.service';
import { ProjectService } from '../../../_services/project.service';
import { Observable, of, Subject } from 'rxjs';
import { OverviewAddProjectSheetComponent } from './components/overview-add-project-sheet/overview-add-project-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { Router } from '@angular/router';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overview-dashboard',
  templateUrl: './overview-dashboard.component.html',
  styleUrls: ['./overview-dashboard.component.scss'],
})
export class OverviewDashboardComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  companies: any[];
  newCompany = [
    {
      id: -1,
      name: 'Create New Company...',
    },
  ];
  ngDestroy$ = new Subject();
  projects: Observable<any>;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  user_id: number;
  admin: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    private clientCompanyService: ClientCompanyService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    public router: Router,
    private userService: UserService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      project_code: [
        '',
        [
          Validators.maxLength(20),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      clientCompany: [, Validators.required],
      newClientCompanyName: [
        '',
        [
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      diseaseArea: [
        '',
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      hideIncompleteResults: [true, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.client_account) {
                this.router.navigate(['/login']);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.admin = user.admin_account;
                this.user_id = user.id;
                this.form.controls.clientCompany.valueChanges
                  .pipe(takeUntil(this.ngDestroy$))
                  .subscribe((newValue) => {
                    if (newValue === -1) {
                      this.form.controls.newClientCompanyName.setValidators([
                        Validators.required,
                        Validators.maxLength(30),
                        Validators.pattern(
                          '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
                        ),
                      ]);
                    } else {
                      this.form.controls.newClientCompanyName.setValidators([
                        Validators.maxLength(30),
                        Validators.pattern(
                          '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
                        ),
                      ]);
                    }
                    this.form.controls.newClientCompanyName.updateValueAndValidity();
                  });

                this.clientCompanyService
                  .getClientCompanies()
                  .pipe(first())
                  .subscribe(
                    (result) => {
                      this.companies = [];
                      this.companies = this.companies.concat(result.data);
                      this.companies = this.companies.concat(this.newCompany);
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );

                this.projects = this.projectService
                  .getProjects('%', '{"%"}', '%')
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading projects:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              }
            }
          },
          (error) => {
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  addSmallScreen() {
    const Ref = this._bottomSheet.open(OverviewAddProjectSheetComponent);
    Ref.afterDismissed()
      .pipe(first())
      .subscribe((result) => {
        this.addProject(
          result.newClientCompanyName,
          result.clientCompany,
          result.name,
          result.project_code,
          result.diseaseArea,
          result.hideIncompleteResults
        );
      });
  }

  addProject(
    newClientCompanyName,
    clientCompany,
    projectName,
    projectCode,
    diseaseArea,
    hideConsensus
  ) {
    if (newClientCompanyName === '' && clientCompany) {
      //Chose an existing client company
      this.projectService
        .addProject(
          projectName,
          clientCompany,
          this.user_id,
          projectCode,
          diseaseArea,
          hideConsensus
        )
        .pipe(first())
        .subscribe(
          (newProject) => {
            this.router.navigate(['/main/project/' + newProject.data.id]);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    } else {
      //Create the new client company first
      this.clientCompanyService
        .addClientCompany(newClientCompanyName)
        .pipe(first())
        .subscribe(
          (newClient) => {
            clientCompany = newClient.data.id;
            // Create the project
            this.projectService
              .addProject(
                projectName,
                clientCompany,
                this.user_id,
                projectCode,
                diseaseArea,
                hideConsensus
              )
              .pipe(first())
              .subscribe(
                (newProject) => {
                  // Create a profiling round
                  this.router.navigate(['/main/project/' + newProject.data.id]);
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    }
  }
}
