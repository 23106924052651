import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColourPairs, ColourShades } from '../../_shared/colourPairs';

@Component({
  selector: 'app-colour-palette',
  templateUrl: './colour-palette.component.html',
  styleUrls: ['./colour-palette.component.scss'],
})
export class ColourPaletteModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ColourPaletteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  colourShades = ColourShades;
  colourPairs = ColourPairs;
  colourIndex = 0;

  ngOnInit(): void {
    const initialSelection = this.colourShades.indexOf(this.data.selected);
    if (initialSelection > -1) {
      this.colourIndex = initialSelection;
    }
  }

  select(index) {
    this.colourIndex = index;
  }

  close(value) {
    this.dialogRef.close(value);
  }
}
