<mat-toolbar color="primary">
    <span>Add Costello Member</span>
</mat-toolbar>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<mat-dialog-content [formGroup]="form" autocomplete="off" fxLayout="column">
    <p>Please use the form below to add a new costello member to the project. <b>Please note they will receive an email notifying them that an account has been created for them.</b></p>
    <mat-form-field fxFlex="95%">
        <input matInput type="text" formControlName="first_name" 
            placeholder="First Name" maxLength="100" required>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.first_name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field fxFlex="95%">
        <input matInput type="text" formControlName="last_name" 
            placeholder="Last Name" maxLength="100" required>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.last_name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field fxFlex="95%">
        <input matInput type="email" formControlName="email" 
            placeholder="Confirm Email" maxLength="100" email required>
        <mat-hint><span class="warn-fore" [hidden]="form.controls.email.pristine || form.value.email === data.email">Please confirm this email is correct!</span></mat-hint>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <span class="flex-spacer"></span>
    <button mat-flat-button color="accent" (click)="close()">Cancel</button>
    <button cdkFocusInitial type="submit" mat-flat-button color="primary" 
        (click)="send()" [disabled]="form.invalid || form.value.email !== data.email">Add</button>
</mat-dialog-actions>

