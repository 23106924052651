import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CookieSelectionsService } from '../cookie-selections.service';
import { PolicyModalComponent, PolicySheetComponent } from '../policy-modal/policy-modal.component';

@Component({
  selector: 'app-policy-footer',
  templateUrl: './policy-footer.component.html',
  styleUrls: ['./policy-footer.component.scss'],
})
export class PolicyFooterComponent implements OnInit {
  year: Number;
  showCookies: Observable<boolean>;

  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private cookieSelectionsService: CookieSelectionsService
  ) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.showCookies = this.cookieSelectionsService.showCookies$;
  }

  acceptCookies() {
    this.cookieSelectionsService.hideCookieStatement();
  }

  cookiePreferences() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(PolicySheetComponent);
    } else {
      const Ref = this.dialog.open(PolicyModalComponent, { width: '800px' });
    }
  }
}
