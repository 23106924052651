import { BreakpointObserver } from '@angular/cdk/layout';
import {
  StepperOrientation,
  STEPPER_GLOBAL_OPTIONS,
} from '@angular/cdk/stepper';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ProfilingQuestionService } from '../../../../../_services/profiling-question.service';
import { ProfilingResponseService } from '../../../../../_services/profiling-response.service';
import { ProjectService } from '../../../../../_services/project.service';
import { QuestionOptionsService } from '../../../../../_services/question-options.service';
import { RoundService } from '../../../../../_services/round.service';
import { UserService } from '../../../../../_services/user.service';

@Component({
  selector: 'app-participant-profiling-question',
  templateUrl: './participant-profiling-question.component.html',
  styleUrls: ['./participant-profiling-question.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false }, // Since linear, don't need to show error icons any more...?
    },
  ],
})
export class ParticipantProfilingQuestionComponent
  implements OnInit, OnDestroy
{
  @Input() terminate: boolean;
  @Input() profiling_passed: boolean;
  @Input() completed: boolean;

  project_id = this.route.parent.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');

  project: Observable<any>;
  user: Observable<any>;
  questionData: Observable<any>;

  stepperOrientation: Observable<StepperOrientation>;

  profilingForm = new UntypedFormArray([]);
  questions = [];
  responses = [];
  storedResponses = new Subject<any[]>();
  last_answered = 0;

  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private router: Router,
    private profilingResponseService: ProfilingResponseService,
    private projectService: ProjectService,
    private roundService: RoundService,
    private userService: UserService,
    breakpointObserver: BreakpointObserver
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 8000px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    this.project = this.projectService.getProject(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading project:', error);
        this.loadingError$.next(true);
        return of();
      })
    );
    this.user = this.userService.getUser().pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading user: ', error);
        this.loadingError$.next(true);
        return of();
      })
    );

    this.questionData = this.profilingResponseService
      .getParticipantResponses(this.project_id, this.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading profiling responses: ', error);
          this.loadingError$.next(true);
          return of();
        })
      );

    this.questionData.pipe(first()).subscribe(
      (questions) => {
        this.questions = questions.data;
        // Find index of last answered question
        const with_response = this.questions.filter((x) => {
          return x.existing_response;
        });
        if (with_response.length > 0) {
          this.last_answered = Number(
            with_response[with_response.length - 1].question_number
          );
        }

        // Reset form arrays to remove existing controls
        // while (this.profilingForm.length !== 0) {
        //   this.profilingForm.removeAt(0);
        // }
        this.responses = [];
        var stepMoved = false;
        var startIndex = 0;
        questions.data.forEach((question, index) => {
          var validators = [];
          if (question.question_type === 'Free Text') {
            validators = [
              Validators.pattern(
                '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
              ),
            ];
          }

          if (question.existing_response) {
            // Set up appropriate response validators
            if (
              question.required &&
              !question.existing_response.opt_out1 &&
              !question.existing_response.opt_out2
            ) {
              validators.push(Validators.required);
            }
            // Handle response values passed as [null]
            var response = question.existing_response.value;
            if (Array.isArray(response) && response[0] === null) {
              response = null;
            }
            this.profilingForm.push(
              new UntypedFormGroup({
                note: new UntypedFormControl(
                  question.existing_response.note,
                  Validators.pattern(
                    '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
                  )
                ),
                other: new UntypedFormControl(question.existing_response.other),
                response: new UntypedFormControl(response, validators),
                skip1: new UntypedFormControl(
                  question.existing_response.opt_out1
                ),
                skip2: new UntypedFormControl(
                  question.existing_response.opt_out2
                ),
              })
            );
          } else {
            // Set up appropriate response validators
            if (question.required) {
              validators.push(Validators.required);
            }
            this.profilingForm.push(
              new UntypedFormGroup({
                note: new UntypedFormControl(
                  '',
                  Validators.pattern(
                    '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
                  )
                ),
                other: new UntypedFormControl(''),
                response: new UntypedFormControl('', validators),
                skip1: new UntypedFormControl(false),
                skip2: new UntypedFormControl(false),
              })
            );
          }

          // If stepper has loaded, advance through steps to latest
          if (this.stepper?.steps.length > 0 && !stepMoved) {
            stepMoved = this.advanceStepper(index);
          }
        });

        // Catch stepper if initial check was before loading completed
        this.stepper?.steps.changes.pipe(first()).subscribe((x) => {
          for (let i = 0; i < x.length; i++) {
            if (!stepMoved) {
              stepMoved = this.advanceStepper(i);
            }
          }
        });
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  advanceStepper(index) {
    this.stepper.selectedIndex = index;
    // Stop stepper if the question is >= last answered
    if (index >= this.last_answered) {
      return true;

      // Stop stepper if the question has been failed (return true)
    } else if (this.questions[index].question_type === 'Multiple Choice') {
      var tracking = false;
      this.questions[index].existing_response?.value.forEach((selection) => {
        var selectedOption = this.questions[index].question_options.find(
          (option) => {
            return option.id === selection;
          }
        );
        if (!tracking && selectedOption) {
          tracking = selectedOption.is_terminating;
        }
      });
      if (tracking) {
        return true;
      } else {
        return false;
      }
    } else if (this.questions[index].question_type === 'Single Choice') {
      const selectedOption = this.questions[index].question_options.find(
        (option) => {
          return option.id === this.questions[index].existing_response.value;
        }
      );
      if (selectedOption) {
        if (selectedOption.is_terminating) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      // Else continue (return false)
      return false;
    }
  }

  // Receive form from the active display component
  onResponse(formCopy, index) {
    if (this.questions[index]?.question_type === 'Single Choice') {
      const selectedOption = this.questions[index].question_options.find(
        (option) => {
          return option.id === formCopy.value.response;
        }
      );
      if (selectedOption) {
        this.terminate = selectedOption.is_terminating;
      }
    } else if (this.questions[index]?.question_type === 'Multiple Choice') {
      var subTerminate = false;
      formCopy.value.response.forEach((element) => {
        var selectedOption = this.questions[index].question_options.find(
          (option) => {
            return option.id === element;
          }
        );
        if (selectedOption && !subTerminate) {
          subTerminate = selectedOption.is_terminating;
        }
      });
      this.terminate = subTerminate;
    }

    this.profilingForm.controls[index] = formCopy;
    this.profilingForm.controls[index].markAsTouched();
    this.profilingForm.markAsTouched();
    this.profilingForm.markAsDirty();
    this.profilingForm.updateValueAndValidity();
  }

  save() {
    this.saveResponses()
      .then(() => {
        this.profilingForm.markAsPristine();
        // Clear profiling_failed on save
        this.roundService
          .updateRoundProgression(
            this.project_id,
            this.round_id,
            this.terminate,
            this.profiling_passed,
            this.completed
          )
          .pipe(first())
          .subscribe(
            () => {
              console.log('Responses saved');
            },
            (error) => {
              this.errMsg = error;
            }
          );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  completeSection() {
    this.saveResponses()
      .then(() => {
        //Mark profiling_passed as true
        this.roundService
          .updateRoundProgression(
            this.project_id,
            this.round_id,
            false,
            true,
            false
          )
          .pipe(first())
          .subscribe(
            () => {
              this.router.navigate([
                '/panel/' +
                  this.project_id +
                  '/round/' +
                  this.round_id +
                  '/respond/1',
              ]);
            },
            (error) => {
              this.errMsg = error;
            }
          );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  endQuestionnaire() {
    this.saveResponses()
      .then(() => {
        //Mark profiling_failed as true
        this.roundService
          .updateRoundProgression(
            this.project_id,
            this.round_id,
            true,
            false,
            false
          )
          .pipe(first())
          .subscribe(
            () => {
              this.router.navigate([
                '/panel/' +
                  this.project_id +
                  '/round/' +
                  this.round_id +
                  '/exit',
              ]);
            },
            (error) => {
              this.errMsg = error;
            }
          );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  refreshToken() {
    this.userService
      .refreshJWTtoken()
      .pipe(first())
      .subscribe(() => console.log('Refreshed'));
  }

  async saveResponses() {
    try {
      for (let i = 0; i < this.profilingForm.controls.length; i++) {
        if (
          this.profilingForm.controls[i].status === 'VALID' &&
          this.profilingForm.controls[i].touched
        ) {
          var response = null;
          if (
            this.profilingForm.controls[i].value.skip1 !== true &&
            this.profilingForm.controls[i].value.skip2 !== true
          ) {
            response = this.profilingForm.controls[i].value.response;
          }
          if (this.questions[i].question_type === 'Free Text') {
            this.profilingResponseService
              .saveTextResponse(
                this.project_id,
                this.questions[i].id,
                this.round_id,
                response,
                this.profilingForm.controls[i].value.skip1,
                this.profilingForm.controls[i].value.skip2
              )
              .pipe(first())
              .subscribe(
                (savedResponse) => {
                  console.log('response saved');
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          } else if (this.questions[i].question_type === 'Multiple Choice') {
            this.profilingResponseService
              .saveMultipleChoiceResponse(
                this.project_id,
                this.questions[i].id,
                this.round_id,
                response,
                this.profilingForm.controls[i].value.other,
                this.profilingForm.controls[i].value.skip1,
                this.profilingForm.controls[i].value.skip2,
                this.profilingForm.controls[i].value.note
              )
              .pipe(first())
              .subscribe(
                (savedResponse) => {
                  console.log('response saved');
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          } else if (this.questions[i].question_type === 'Numeric') {
            this.profilingResponseService
              .saveNumericResponse(
                this.project_id,
                this.questions[i].id,
                this.round_id,
                response,
                this.profilingForm.controls[i].value.skip1,
                this.profilingForm.controls[i].value.skip2,
                this.profilingForm.controls[i].value.note
              )
              .pipe(first())
              .subscribe(
                (savedResponse) => {
                  console.log('response saved');
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          } else if (this.questions[i].question_type === 'Ranking') {
            this.profilingResponseService
              .saveRankingResponse(
                this.project_id,
                this.questions[i].id,
                this.round_id,
                response,
                this.profilingForm.controls[i].value.skip1,
                this.profilingForm.controls[i].value.skip2,
                this.profilingForm.controls[i].value.note
              )
              .pipe(first())
              .subscribe(
                (savedResponse) => {
                  console.log('response saved');
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          } else if (this.questions[i].question_type === 'Single Choice') {
            this.profilingResponseService
              .saveSingleChoiceResponse(
                this.project_id,
                this.questions[i].id,
                this.round_id,
                response,
                this.profilingForm.controls[i].value.other,
                this.profilingForm.controls[i].value.skip1,
                this.profilingForm.controls[i].value.skip2,
                this.profilingForm.controls[i].value.note
              )
              .pipe(first())
              .subscribe(
                (savedResponse) => {
                  console.log('response saved');
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        }
      }
      return 'Complete';
    } catch (error) {
      this.errMsg = error;
      return error;
    }
  }
}
