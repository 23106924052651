import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { RoundService } from '../../../_services/round.service';
import { Observable, of, Subject } from 'rxjs';
import { QuestionService } from '../../../_services/question.service';
import { RoundAddQuestionSheetComponent } from '../../../components/round-add-question-sheet/round-add-question-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import {
  ConfirmDeleteModalComponent,
  ConfirmDeleteSheetComponent,
} from '../../../components/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionUploadComponent } from './components/question-upload/question-upload.component';
import { QuestionOptionsService } from '../../../_services/question-options.service';
import { QuestionOptionUploadComponent } from './components/question-option-upload/question-option-upload.component';
import { Download, DownloadService } from '../../../_services/download.service';
import { RoundOpenModalComponent } from './components/round-open-modal/round-open-modal.component';
import { QuestionTypeFunctions } from '../../../_shared/questionTypes';
import { RoundRevertModalComponent } from './components/round-revert-modal/round-revert-modal.component';

@Component({
  selector: 'app-round-dashboard',
  templateUrl: './round-dashboard.component.html',
  styleUrls: ['./round-dashboard.component.scss'],
})
export class RoundDashboardComponent implements OnInit, OnDestroy {
  is_client: boolean;

  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');

  form: UntypedFormGroup;
  questionTypes = [];

  download$: Observable<Download>;
  round: Observable<any>;
  questions: Observable<any>;
  ngDestroy$ = new Subject();
  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private location: Location,
    private downloadService: DownloadService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionService: QuestionService,
    private questionOptionService: QuestionOptionsService,
    private roundService: RoundService,
    public router: Router,
    public snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      questionType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      //watch for changes in route params
      this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
        this.project_id = params.project_id;
        this.round_id = params.round_id;
        this.userService
          .getUser()
          .pipe(first())
          .subscribe(
            (user) => {
              if (user) {
                if (user.participant_account) {
                  this.router.navigate(['/login']);
                } else {
                  this.is_client = user.client_account;
                }
              }
            },
            (error) => {
              console.error(error);
              this.errMsg = error;
            }
          );

        this.round = this.roundService
          .getRound(this.project_id, this.round_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading round:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
        this.round.pipe(first()).subscribe(
          (round) => {
            this.questionTypes = QuestionTypeFunctions.getQuestionTypes(
              round.data.use_freetext,
              round.data.use_likert,
              round.data.use_mcq,
              round.data.use_numeric,
              round.data.use_ranking,
              round.data.use_scq
            );
          },
          (error) => {
            this.errMsg = error;
          }
        );
        this.questions = this.questionService
          .getQuestions(this.project_id, this.round_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading questions:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id]);
  }

  openRound() {
    const Ref = this.dialog.open(RoundOpenModalComponent, {
      data: {
        project_id: this.project_id,
        round_id: this.round_id,
      },
      width: '500px',
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((confirmation) => {
        if (confirmation) {
          this.roundService
            .openRound(this.project_id, this.round_id)
            .pipe(first())
            .subscribe(
              (result) => {
                this.snackBar.open('Round opened!', 'Close', {
                  duration: 1500,
                  verticalPosition: 'top',
                });
                this.round = this.roundService
                  .getRound(this.project_id, this.round_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading round:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              },
              (error) => {
                this.errMsg = error;
              }
            );
        }
      });
  }

  revertRound() {
    const Ref = this.dialog.open(RoundRevertModalComponent, {
      width: '500px',
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((confirmation) => {
        if (confirmation) {
          this.roundService
            .revertRound(this.project_id, this.round_id)
            .pipe(first())
            .subscribe(
              (result) => {
                this.snackBar.open('Round reverted!', 'Close', {
                  duration: 1500,
                  verticalPosition: 'top',
                });
                this.round = this.roundService
                  .getRound(this.project_id, this.round_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading round:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              },
              (error) => {
                this.errMsg = error;
              }
            );
        }
      });
  }

  closeRound() {
    const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
      data: {
        message:
          'Once the round has been closed participants will no longer be able to respond.',
      },
      width: '400px',
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((confirmation) => {
        if (confirmation) {
          this.roundService
            .closeRound(this.project_id, this.round_id)
            .pipe(first())
            .subscribe(
              (result) => {
                this.snackBar.open('Round closed!', 'Close', {
                  duration: 1500,
                  verticalPosition: 'top',
                });
                this.round = this.roundService
                  .getRound(this.project_id, this.round_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading round:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              },
              (error) => {
                this.errMsg = error;
              }
            );
        }
      });
  }

  addQuestion(question, questionType) {
    this.questionService
      .addQuestion(this.project_id, this.round_id, question, questionType)
      .pipe(first())
      .subscribe(
        (newQuestion) => {
          this.router.navigate([
            '/main/project/' +
              this.project_id +
              '/round/' +
              this.round_id +
              '/question/' +
              newQuestion.data.question_number +
              '/edit',
          ]);
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }

  addSmallScreen(
    use_freetext,
    use_likert,
    use_mcq,
    use_numeric,
    use_ranking,
    use_scq
  ) {
    const Ref = this._bottomSheet.open(RoundAddQuestionSheetComponent, {
      data: {
        use_freetext: use_freetext,
        use_likert: use_likert,
        use_mcq: use_mcq,
        use_numeric: use_numeric,
        use_ranking: use_ranking,
        use_scq: use_scq,
      },
    });
    Ref.afterDismissed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.addQuestion(result.name, result.questionType);
        }
      });
  }

  importQuestions() {
    const dialogRef = this.dialog.open(QuestionUploadComponent, {
      data: { project_id: this.project_id, round_id: this.round_id },
      height: '50%',
      width: '60%',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }

  importQuestionOptions() {
    const dialogRef = this.dialog.open(QuestionOptionUploadComponent, {
      data: { project_id: this.project_id, round_id: this.round_id },
      height: '50%',
      width: '60%',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }

  exportQuestions() {
    this.download$ = this.downloadService.downloadQuestions(
      this.project_id,
      this.round_id
    );
  }

  exportQuestionOptions() {
    this.download$ = this.downloadService.downloadQuestionOptions(
      this.project_id,
      this.round_id
    );
  }

  exportResponses() {
    this.download$ = this.downloadService.downloadRoundResponses(
      this.project_id,
      this.round_id
    );
  }

  exportIndividualResponses() {
    this.download$ = this.downloadService.downloadRoundIndividualResponses(
      this.project_id,
      this.round_id
    );
  }

  exportCopyApproval() {
    this.downloadService
      .emailCopyApproval(this.project_id, this.round_id)
      .pipe(first())
      .subscribe(
        () => {
          this.snackBar.open(
            'The PDF is being generated! Please check your email!',
            'Close',
            { verticalPosition: 'top' }
          );
        },
        (error) => {
          this.errMsg = error;
        }
      );
    // this.download$ = this.downloadService.downloadCopyApproval(this.project_id, this.round_id);
  }

  deleteRound() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ConfirmDeleteSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.roundService
              .deleteRound(this.project_id, this.round_id)
              .pipe(first())
              .subscribe(
                () => {
                  this.snackBar.open('Round deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.router.navigate(['/main/project/' + this.project_id]);
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
        width: '400px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.roundService
              .deleteRound(this.project_id, this.round_id)
              .pipe(first())
              .subscribe(
                () => {
                  this.snackBar.open('Round deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.router.navigate(['/main/project/' + this.project_id]);
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }
}
