<h1 mat-dialog-title>Set Branching Logic</h1>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<mat-dialog-content *ngIf="savedBranches | async as savedBranches else processAsync">
    <div *ngIf="roundQuestions | async as roundQuestions else processAsync">
        <div *ngFor="let option of data.options; let i = index">
            <div *ngIf="!option.is_other" fxLayout="row" fxLayoutAlign="space-between center">
                <a class="label form-control">{{option.label}}</a>
                <mat-form-field class="form-control">
                    <mat-label>Go To</mat-label>
                    <mat-select [formControl]="branches.controls[i]">
                        <mat-option *ngFor="let choice of choices" [value]="choice.id">
                            {{choice.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <span class="flex-spacer"></span>
    <button mat-flat-button color="accent" (click)="close()">Cancel</button>
    <button cdkFocusInitial type="submit" mat-flat-button color="primary" 
        (click)="save()" [disabled]="branches.pristine">Save</button>
</mat-dialog-actions>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>