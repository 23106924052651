import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ProjectService } from '../../../_services/project.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-layout-participant',
  templateUrl: './layout-participant.component.html',
  styleUrls: ['./layout-participant.component.scss'],
})
export class LayoutParticipantComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');

  ngDestroy$ = new Subject();
  colour_primary: string = '#880e4f'; //Default to costello purple
  colour_secondary: string = '#bdbdbd'; //Default to accent grey

  constructor(
    public route: ActivatedRoute,
    private projectService: ProjectService,
    public router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
          (user) => {
            if (user) {
              if (!user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.projectService
                  .getProject(this.project_id)
                  .pipe(first())
                  .subscribe(
                    (result) => {
                      if (result.data.client_colour_primary) {
                        this.colour_primary = result.data.client_colour_primary;
                      }
                      if (result.data.client_colour_secondary) {
                        this.colour_secondary =
                          result.data.client_colour_secondary;
                      }
                      this.setClientTheme(
                        this.colour_primary,
                        this.colour_secondary
                      );
                    },
                    (error) => {
                      console.error(error);
                    }
                  );
              }
            } else {
              this.router.navigate(['/login']);
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  setClientTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-colour', primary);
    document.documentElement.style.setProperty('--secondary-colour', secondary);
  }
}
