<div fxLayout="column" *ngIf="question | async as question else processAsync">

    <!-- PAGE HEADER -->
    <div class="toolbar accent-back" 
      fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
      <h3>Profiling Question Responses: "{{question.data.question}}"</h3>
      <span class="flex-spacer"></span>
    </div>

    <!-- SUMMARY FIGURE -->
    <div fxHide.lt-sm="true">
        <div class="footer-pad" fxFlex="100%" *ngIf="question.data.question_type !== 'Free Text'">
            <h3 class="subheader">SUMMARY OF PARTICIPANT'S RESPONSES</h3>
            <mat-divider></mat-divider>
            <div *ngIf="chartData | async as chartData else processAsync">
                <div *ngIf="question.data.question_type === 'Multiple Choice'" 
                    fxLayout="row wrap" fxLayoutAlign="space-evenly">
                    <app-profiling-figure-mcq
                        *ngFor="let round of chartData.data; let n = index"
                        [chartData]="chartData.data[n]"
                        class="figure-container"
                    ></app-profiling-figure-mcq>
                </div>
                <app-profiling-figure-numeric
                    *ngIf="question.data.question_type === 'Numeric'"
                    [chartData]="chartData.data"
                ></app-profiling-figure-numeric>
                <div *ngIf="question.data.question_type === 'Ranking'"
                    fxLayout="row wrap" fxLayoutAlign="space-evenly">
                    <app-profiling-figure-ranking
                        *ngFor="let round of chartData.data; let n = index"
                        [chartData]="chartData.data[n]"
                    ></app-profiling-figure-ranking>
                </div>
                <div *ngIf="question.data.question_type === 'Single Choice'"
                    fxLayout="row wrap" fxLayoutAlign="space-evenly">
                    <app-profiling-figure-scq
                        *ngFor="let round of chartData.data; let n = index"
                        [chartData]="chartData.data[n]"
                    ></app-profiling-figure-scq>
                </div>
                
            </div>
            
        </div>

    </div>

    <!-- RESPONSES TABLE -->
    <div fxHide.lt-sm="true" class="footer-pad" fxFlex="100%" *ngIf="responses | async as responses else processAsync">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
            <h3>PARTICIPANT RESPONSES</h3>
        </div>
        <mat-divider></mat-divider>
        <a class="stats-panel" fxLayoutAlign="center center" *ngIf="responses.data.length === 0">There are no responses for this question</a>
        <app-profiling-response-table *ngIf="responses.data.length > 0"></app-profiling-response-table>
    </div>

    <!-- Alternative prompt for mobile users -->
    <div fxHide.gt-xs="true" fxLayout="column" fxLayoutAlign="center center" class="mobile-note" >
        <mat-icon>screen_rotation</mat-icon>
        <a class="rotate-note">Rotate screen for participant responses!</a>
    </div>

</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>
