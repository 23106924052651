import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SideNavService } from '../../../_services/side-nav.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-layout-costello',
  templateUrl: './layout-costello.component.html',
  styleUrls: ['./layout-costello.component.scss'],
})
export class LayoutCostelloComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureSideNav();
  }

  @ViewChild('sidenav') public sidenav: MatSidenav;

  opened = true;
  mode = 'side';

  ngDestroy$ = new Subject();

  constructor(
    public router: Router,
    private userService: UserService,
    private sidenavService: SideNavService
  ) {}

  ngOnInit(): void {
    // if(window.innerWidth < 959) {
    this.opened = false;
    this.mode = 'over';
    // }

    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
          (user) => {
            if (user) {
              if (user.participant_account) {
                this.router.navigate(['/login']);
              }
            } else {
              this.router.navigate(['/login']);
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  configureSideNav() {
    // if(window.innerWidth < 959) {
    this.sidenav.mode = 'over';
    this.sidenav.opened = false;
    // } else {
    //   this.sidenav.mode="side"
    //   this.sidenav.open();
    // }
  }
}
