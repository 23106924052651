export const QuestionTypes = [
    'Free Text',
    'Likert',
    'Multiple Choice',
    'Numeric',
    'Ranking',
    'Single Choice'
];

export const RoutedQuestionTypes = [
    {label: 'Free Text', route: 'text'},
    {label: 'Likert', route: 'likert'},
    {label: 'Multiple Choice', route: 'mc'},
    {label: 'Numeric', route: 'numeric'},
    {label: 'Ranking', route: 'ranking'},
    {label: 'Single Choice', route: 'sc'}
];

export class QuestionTypeFunctions {
    static getQuestionTypes(useFreeText=true, useLikert=true, useMCQ=true, useNumeric=true, useRanking=true, useSCQ=true) {
        var permittedTypes = [];
        if(useFreeText===true) {
            permittedTypes.push('Free Text');
        }
        if(useLikert===true) {
            permittedTypes.push('Likert');
        }
        if(useMCQ===true) {
            permittedTypes.push('Multiple Choice');
        }
        if(useNumeric===true) {
            permittedTypes.push('Numeric');
        }
        if(useRanking===true) {
            permittedTypes.push('Ranking');
        }
        if(useSCQ===true) {
            permittedTypes.push('Single Choice');
        }
        return permittedTypes
    }

    static getRoutedTypes(useFreeText=true, useLikert=true, useMCQ=true, useNumeric=true, useRanking=true, useSCQ=true) {
        var permittedTypes = [];
        if(useFreeText===true) {
            permittedTypes.push({label: 'Free Text', route: 'text'});
        }
        if(useLikert===true) {
            permittedTypes.push({label: 'Likert', route: 'likert'});
        }
        if(useMCQ===true) {
            permittedTypes.push({label: 'Multiple Choice', route: 'mc'});
        }
        if(useNumeric===true) {
            permittedTypes.push({label: 'Numeric', route: 'numeric'});
        }
        if(useRanking===true) {
            permittedTypes.push({label: 'Ranking', route: 'ranking'});
        }
        if(useSCQ===true) {
            permittedTypes.push({label: 'Single Choice', route: 'sc'});
        }
        return permittedTypes
    }
}