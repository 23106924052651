import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { ClientCompanyService } from '../../../../../_services/client-company.service';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-client-edit-modal',
  templateUrl: './client-edit-modal.component.html',
  styleUrls: ['./client-edit-modal.component.scss'],
})
export class ClientEditModalComponent implements OnInit, OnDestroy {
  client_company: Observable<any>;
  ngDestroy$ = new Subject();
  loadingError$ = new Subject<boolean>();

  form: UntypedFormGroup;
  errMsg: String;
  primary_colour: string = '#880e4f'; //Default to costello purple
  secondary_colour: string = '#bdbdbd'; //Default to accent grey

  constructor(
    public dialogRef: MatDialogRef<ClientEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private clientCompanyService: ClientCompanyService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.client_company = this.clientCompanyService
      .getClientCompany(this.data.client_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading project:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.client_company.pipe(first()).subscribe(
      (client_company) => {
        this.form.controls.name.setValue(client_company.data.name);
        if (client_company.data.colour_primary) {
          this.primary_colour = client_company.data.colour_primary;
        }
        if (client_company.data.colour_secondary) {
          this.secondary_colour = client_company.data.colour_secondary;
        }
      },
      (error) => {
        console.error(error);
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.clientCompanyService
      .updateClientCompany(
        this.data.client_id,
        this.form.value.name,
        this.primary_colour,
        this.secondary_colour
      )
      .pipe(first())
      .subscribe(
        () => {
          this.dialogRef.close(true);
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }
}
