<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
<table mat-table [dataSource]="tableData" *ngIf="showTable" matSort matSortActive={{sortField}} matSortDirection={{direction}} multiTemplateDataRows>

    <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef>Question</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
          <a>{{item.question}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef>Your Response</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
          <!-- <a fxHide.lt-sm>{{item.value.label}}</a> -->
          <a fxHide.lt-sm *ngIf="item.question_type==='Free Text'">{{item.text_value}}</a>
            <a fxHide.lt-sm *ngIf="item.question_type==='Likert'">{{item.likert_value}}</a>
            <a fxHide.lt-sm *ngIf="item.question_type==='Multiple Choice'">{{item.options}}</a>
            <a fxHide.lt-sm *ngIf="item.question_type==='Numeric'">{{item.numeric_value}} {{item.unit_label}}</a>
            <a fxHide.lt-sm *ngIf="item.question_type==='Ranking'"><b>{{item.top_label}} to {{item.bottom_label}}: </b>{{item.options}}</a>
            <a fxHide.lt-sm *ngIf="item.question_type==='Single Choice'">{{item.options[0]}}</a>
          <button fxHide.gt-xs mat-icon-button (click)="expand_contract(item)" class="button">
              <mat-icon *ngIf="expandedElement !== item">expand_more</mat-icon>
              <mat-icon *ngIf="expandedElement === item">expand_less</mat-icon>
          </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
          <a *ngIf="item.note" (click)="expand_contract(item)"><i>Click to expand note</i></a>
          <a *ngIf="!item.note">-</a>
        </td>
    </ng-container>

    <!-- Expanded note row -->
    <ng-container matColumnDef="expandedElement">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">
                <!-- <a fxHide.gt-xs class="element-description"><b>Your response: </b>{{element.value.label}}</a> -->
                <a fxHide.gt-xs *ngIf="element.question_type==='Free Text'">{{element.text_value}}</a>
                <a fxHide.gt-xs *ngIf="element.question_type==='Likert'">{{element.likert_value}}</a>
                <a fxHide.gt-xs *ngIf="element.question_type==='Multiple Choice'">{{element.options}}</a>
                <a fxHide.gt-xs *ngIf="element.question_type==='Numeric'">{{element.numeric_value}} {{element.unit_label}}</a>
                <a fxHide.gt-xs *ngIf="element.question_type==='Ranking'"><b>{{element.top_label}} to {{element.bottom_label}}: </b>{{element.options}}</a>
                <a fxHide.gt-xs *ngIf="element.question_type==='Single Choice'">{{element.options[0]}}</a>
                <a class="element-description"><b>Note: </b>{{element.note}}</a>
            </div>  
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Group Responses</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <button *ngIf="item.question_type !== 'Free Text' && item.question_type !== 'Numeric'"
                mat-icon-button (click)="groupResponses(item)" class="button"
                matTooltip="Click to view the group's responses to this question">
                <mat-icon>leaderboard</mat-icon>
            </button>
            <a *ngIf="item.question_type === 'Free Text' || item.question_type === 'Numeric'">-</a>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
        class="element-row"
        [class.expanded-row]="expandedElement === row"
        
    ></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: ['expandedElement']" class="detail-row"></tr>
</table>
<!-- <mat-paginator 
  [length]="numRecords"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20]" 
  showFirstLastButtons
></mat-paginator> -->

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>
