import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ProjectService } from '../../../../../_services/project.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ResponseService } from '../../../../../_services/response.service';
import { QuestionService } from '../../../../../_services/question.service';
import { UserService } from '../../../../../_services/user.service';

@Component({
  selector: 'app-participant-question',
  templateUrl: './participant-question.component.html',
  styleUrls: ['./participant-question.component.scss'],
})
export class ParticipantQuestionComponent implements OnInit, OnDestroy {
  @Input() question: any;
  @Input() existingResponse: any;
  @Input() likertOptions: any;
  @Input() num_questions: string;

  @Output() response = new EventEmitter<object>();
  @Output() invalid = new EventEmitter<boolean>();

  project_id = this.route.parent.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_num = this.route.snapshot.paramMap.get('question_num');

  chartData: Observable<any>;
  textData: Observable<any>;
  linkedResponse: Observable<any>;
  project: Observable<any>;
  ngDestroy$ = new Subject();

  touched = false;
  blockProgress = true;
  formValue: object;

  panelOpenState = false;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private responseService: ResponseService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.loadUserCredentials(); //ensure jwt stays refreshed

    if (this.existingResponse || this.question.required === false) {
      this.blockProgress = false;
    }
    this.project = this.projectService.getProject(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading project:', error);
        this.loadingError$.next(true);
        return of();
      })
    );

    // Retrieve linked question data as needed
    if (this.question.linked_qu_id) {
      // Load text data
      this.textData = this.responseService
        .textSummary(this.project_id, this.question.linked_qu_id)
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading linked question data:', error);
            this.loadingError$.next(true);
            return of();
          })
        );

      if (this.question.linked_qu_type === 'Likert') {
        this.chartData = this.responseService
          .likertChoiceSummary(this.project_id, this.question.linked_qu_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading question:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      } else if (this.question.linked_qu_type === 'Multiple Choice') {
        this.chartData = this.responseService
          .multipleChoiceSummary(this.project_id, this.question.linked_qu_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading question:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      } else if (this.question.linked_qu_type === 'Numeric') {
        this.chartData = this.responseService
          .numericSummary(this.project_id, this.question.linked_qu_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading question:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      } else if (this.question.linked_qu_type === 'Ranking') {
        this.chartData = this.responseService
          .rankingSummary(this.project_id, this.question.linked_qu_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading question:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      } else if (this.question.linked_qu_type === 'Single Choice') {
        this.chartData = this.responseService
          .singleChoiceSummary(this.project_id, this.question.linked_qu_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading question:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      }

      this.linkedResponse = this.responseService
        .getResponse(
          this.project_id,
          this.round_id,
          this.question.linked_qu_id,
          this.question.linked_qu_type
        )
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading response:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  toggleExpand() {
    this.panelOpenState = !this.panelOpenState;
  }

  // Receive form from the active display component
  onResponse(formCopy) {
    this.touched = true;
    this.blockProgress = formCopy.status === 'INVALID';
    this.formValue = formCopy.value;
    this.invalid.emit(this.blockProgress);
  }

  getPrev() {
    this.response.emit({
      forward: false,
      touched: this.touched,
      form: this.formValue,
      question: {
        id: this.question.id,
        num: this.question.question_number,
        question_type: this.question.question_type,
        calculate_consensus: this.question.calculate_consensus,
        branches: this.question.branches,
      },
      existingResponse: this.existingResponse,
    });
  }

  getNext() {
    this.response.emit({
      forward: true,
      touched: this.touched,
      form: this.formValue,
      question: {
        id: this.question.id,
        num: this.question.question_number,
        question_type: this.question.question_type,
        calculate_consensus: this.question.calculate_consensus,
        branches: this.question.branches,
      },
      existingResponse: this.existingResponse,
    });
  }
}
