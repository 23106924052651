<div class="policy-bounds">
    <div class="policy-header">
        <h1>Cookie Statement</h1>
        <span class="policy-spacer"></span>
        <button mat-icon-button (click)="goBack()"><mat-icon>close</mat-icon></button>
    </div>

    <!-- TODO: Select as appropriate -->
    <p>This website uses cookies and related technologies ("Cookies") to distinguish you from other users and to improve your experience of the website.</p>
    <!-- <p>This website does not directly set cookies or related technologies ("Cookies"), however we may make use of Cookies set by third party services.</p> -->
    <p>This Cookie Statement does not cover third-party cookie practices. A list of third-party services using Cookies in this website is provided below. Please refer to the third party's website for their Cookie Policy/Statement.</p>

    <h2>What Are Cookies</h2>
    <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer, if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>

    <h2>Cookies We Use</h2>
    <!-- TODO: Update with app-specifics -->
    <h3>Strictly Necessary</h3>
    <p>These are required for our website to work correctly, and include allowing us to:</p>
    <ul>
        <li>
            <p>Authenticate you and keep you logged in to the website</p>
        </li>
    </ul>
    <p>These Cookies cannot be disabled without affecting your access to the website.</p>

    <!-- <h3>Improve Your Experience</h3>
    <p>These Cookies allow us to provide enhanced functionality and personalisation, for example to:</p>
    <ul>
        <li>
            <p>Store filter preferences</p>
        </li>
    </ul>
    <p>You can manage the use of these Cookies by setting your preferences below.</p>
    <button mat-stroked-button (click)="cookiePreferences()">Cookie Preferences</button> -->

    <h2>Third Party Services</h2>
    <!-- TODO: Update with app-specifics -->
    <h3>Strictly Necessary</h3>
    <ul>
        <!-- <li>
            <h4>Google reCAPTCHA</h4>
            <p>We use Google reCAPTCHA to monitor traffic to the website and prevent malicious activity on the website. <a href="https://policies.google.com/privacy?hl=en">Google Privacy Policy</a></p>
        </li> -->
        <!-- <li>
            <h4>Microsoft Azure Active Directory</h4>
            <p>We use Azure Active Directory to authenticate you and keep you logged in to the website. <a href="https://privacy.microsoft.com/en-us/privacystatement">Microsoft Privacy Statement</a></p>
        </li> -->
        <li>
            <h4>Sentry</h4>
            <p>We use Sentry to trace bugs or issues that may arise through the course of using the website. <a href="https://sentry.io/privacy/">Sentry Privacy Policy</a></p>
        </li>
    </ul>

    <!-- <h3>Improve Your Experience</h3>
    <ul>
        <li>
            <h4>Atlassian</h4>
            <p>We use Atlassian to provide a form for you to send us feedback about the website. <a href="https://www.atlassian.com/legal/privacy-policy">Atlassian Privacy Policy</a></p>
        </li>
    </ul>    -->

</div>
