import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profiling-figure-numeric',
  templateUrl: './profiling-figure-numeric.component.html',
  styleUrls: ['./profiling-figure-numeric.component.scss']
})
export class ProfilingFigureNumericComponent implements OnInit {

  @Input() chartData: any;

  colour_primary: string = '#880e4f'; //Default to costello purple

  boxplotOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    }
  };

  public boxplotData = {
    labels: [],
    datasets: [
      {
        label: "",
        backgroundColor: "rgba(136, 14, 79, 0.5)",
        borderColor: "rgba(136, 14, 79, 1)",
        hoverBackgroundColor: "rgba(136, 14, 79, 0.8)",
        hoverBorderColor: "rgba(136, 14, 79, 1)",
        borderWidth: 1,
        outlierColor: "#999999",
        padding: 10,
        itemRadius: 0,
        data: []
      }
    ]
  }

  constructor() { }

  ngOnInit(): void {
    if(this.chartData) {
      this.chartData.forEach((round, index) => {
        var data = [];
        if(round.x !== null) {
          data = round.x;
        }
        this.boxplotData.datasets[0].data[index]=data;
        this.boxplotData.labels.push([round.name, '(N=' + round.num_responses + ')']);
      });
    }
  }

  hexToRgb(hex, a_value=0.5) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 
      'rgba(' + parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + ", " + parseInt(result[3], 16) + ', ' + a_value + ')'
    : null;
  }

}
