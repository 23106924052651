<div [formGroup]="form" class="form-container" autocomplete="off">
    <div *ngIf="section_id">
        <div *ngIf="section | async as section else processAsync">
            <h2>{{section.data.name}}</h2>
            <p class="wrap-text">
                {{section.data.instructions}}
            </p>
            <p class="wrap-text">
                {{section.data.details}}
            </p>
        </div>
    </div>

    <!-- Question Field -->
    <h3 class="question" *ngIf="show_header">{{question}}</h3>
    <p class="wrap-text">{{question_instructions}}</p>
    <mat-form-field class="form-field"> 
        <mat-label>Enter response</mat-label>
        <textarea matInput formControlName="response" pattern="^[^=\+\-@\t\r\/\\\^;\`#][^\/\\@\^;\`#]*$"></textarea>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.response.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>

    <!-- Opt Out Options -->
    <div>
        <div *ngIf="useSkip1 && skipLabel1">
            <mat-checkbox formControlName="skip1">{{skipLabel1}}</mat-checkbox>
        </div>
        <div *ngIf="useSkip2 && skipLabel2" >
            <mat-checkbox formControlName="skip2">{{skipLabel2}}</mat-checkbox>
        </div>
    </div>

</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>