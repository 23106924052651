import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientCompanyService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getClientCompanies(name='%', skip=0, limit=50): Observable<any> {
    let params = new HttpParams()
    .set('name', name)
    // .set('skip', skip.toString())
    // .set('limit', limit.toString())

    return this.http.get(baseURL + '/clients', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addClientCompany(name, colour_primary?, colour_secondary?): Observable<any> {
    const content = {
      name: name,
      colour_primary: colour_primary,
      colour_secondary: colour_secondary
    };
    return this.http.post(baseURL + '/clients', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getClientCompany(client_id): Observable<any> {
    return this.http.get(baseURL + '/clients/' + client_id).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateClientCompany(client_id, name, colour_primary?, colour_secondary?): Observable<any> {
    const content = {
      name: name,
      colour_primary: colour_primary,
      colour_secondary: colour_secondary
    };
    return this.http.put(baseURL + '/clients/' + client_id, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }
}
