import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ColourPairs } from '../../../../../_shared/colourPairs';

@Component({
  selector: 'app-profiling-round-figure',
  templateUrl: './profiling-round-figure.component.html',
  styleUrls: ['./profiling-round-figure.component.scss'],
})
export class ProfilingRoundFigureComponent implements OnInit {
  colourPairs = ColourPairs;

  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Participant',
          },
          ticks: {
            max: 6,
            min: 0,
            stepSize: 1,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Round',
          },
          ticks: {
            max: 4,
            min: 0,
            stepSize: 1,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return data.datasets[tooltipItem.datasetIndex].label;
        },
      },
    },
  };
  public chartLabels: Label[] = [
    'Strongly Agree',
    'Slightly Agree',
    'Neutral',
    'Slightly Disagree',
    'Strongly Disagree',
  ];
  public chartType: ChartType = 'scatter';
  public chartLegend = true;
  public chartPlugins = [];

  public chartColors: Color[] = [
    {
      borderColor: this.colourPairs[6].light,
      backgroundColor: this.colourPairs[6].light,
    },
    {
      borderColor: this.colourPairs[5].light,
      backgroundColor: this.colourPairs[5].light,
    },
    {
      borderColor: this.colourPairs[4].light,
      backgroundColor: this.colourPairs[4].light,
    },
    {
      borderColor: this.colourPairs[3].light,
      backgroundColor: this.colourPairs[3].light,
    },
    {
      borderColor: this.colourPairs[1].light,
      backgroundColor: this.colourPairs[1].light,
    },
  ];

  public chartData: ChartDataSets[] = [
    {
      data: [
        { x: 1, y: 1 },
        { x: 2, y: 3 },
        { x: 3, y: 2 },
        { x: 4, y: 1 },
        { x: 5, y: 3 },
      ],
      label: 'Strongly Agree',
      pointRadius: 10,
      pointHoverRadius: 15,
      pointBackgroundColor: [
        this.colourPairs[6].light,
        this.colourPairs[6].light,
        this.colourPairs[6].light,
        this.colourPairs[6].light,
        this.colourPairs[6].light,
      ],
      pointHoverBackgroundColor: [
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
      ],
      pointBorderColor: [
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
      ],
      pointHoverBorderColor: [
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
        this.colourPairs[6].shade,
      ],
    },
    {
      data: [
        { x: 2, y: 1 },
        { x: 3, y: 3 },
        { x: 1, y: 2 },
        { x: 3, y: 1 },
        { x: 4, y: 3 },
      ],
      label: 'Slightly Agree',
      pointRadius: 10,
      pointHoverRadius: 15,
      pointBackgroundColor: [
        this.colourPairs[5].light,
        this.colourPairs[5].light,
        this.colourPairs[5].light,
        this.colourPairs[5].light,
        this.colourPairs[5].light,
      ],
      pointHoverBackgroundColor: [
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
      ],
      pointBorderColor: [
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
      ],
      pointHoverBorderColor: [
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
        this.colourPairs[5].shade,
      ],
    },
    {
      data: [
        { x: 1, y: 3 },
        { x: 5, y: 2 },
      ],
      label: 'Neither Agree Nor Disagree',
      pointRadius: 10,
      pointHoverRadius: 15,
      pointBackgroundColor: [
        this.colourPairs[4].light,
        this.colourPairs[4].light,
      ],
      pointHoverBackgroundColor: [
        this.colourPairs[4].shade,
        this.colourPairs[4].shade,
      ],
      pointBorderColor: [this.colourPairs[4].shade, this.colourPairs[4].shade],
      pointHoverBorderColor: [
        this.colourPairs[4].shade,
        this.colourPairs[4].shade,
      ],
    },
    {
      data: [
        { x: 2, y: 2 },
        { x: 4, y: 2 },
      ],
      label: 'Slightly Disagree',
      pointRadius: 10,
      pointHoverRadius: 15,
      pointBackgroundColor: [
        this.colourPairs[3].light,
        this.colourPairs[3].light,
      ],
      pointHoverBackgroundColor: [
        this.colourPairs[3].shade,
        this.colourPairs[3].shade,
      ],
      pointBorderColor: [this.colourPairs[3].shade, this.colourPairs[3].shade],
      pointHoverBorderColor: [
        this.colourPairs[3].shade,
        this.colourPairs[3].shade,
      ],
    },
    {
      data: [{ x: 5, y: 1 }],
      label: 'Strongly Disagree',
      pointRadius: 10,
      pointHoverRadius: 15,
      pointBackgroundColor: [this.colourPairs[1].light],
      pointHoverBackgroundColor: [this.colourPairs[1].shade],
      pointBorderColor: [this.colourPairs[1].shade],
      pointHoverBorderColor: [this.colourPairs[1].shade],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
