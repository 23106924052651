import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { baseURL } from '../_shared/baseurl';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getQuestionResponses(project_id, round_id, question_number): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_number + '/responses').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getParticipantResponses(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/participants/responses').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getResponseHistory(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/response_history').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteResponseHistory(project_id, round_id, start_question_num): Observable<any> {
    let params = new HttpParams()
    .set('start_question_num', start_question_num);
    
    return this.http.delete(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/response_history', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getResponse(project_id, round_id, question_id, question_type): Observable<any> {
    let params = new HttpParams()
    .set('question_type', question_type)

    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveNonResponse(project_id, round_id, question_id, calculate_consensus, question_type): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      question_type: question_type
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/non_response', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveLikertResponse(project_id, round_id, question_id, calculate_consensus, response_value,
      opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };
    
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/likert', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveMultipleChoiceResponse(project_id, round_id, question_id, calculate_consensus, 
      response_value, other_value, opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      response_value: response_value,
      other_value: other_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/multiple_choice', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveNumericResponse(project_id, round_id, question_id, calculate_consensus, response_value,
      opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };
    
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/numeric', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveRankingResponse(project_id, round_id, question_id, calculate_consensus, response_value,
      opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/ranking', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveSingleChoiceResponse(project_id, round_id, question_id, calculate_consensus, 
      response_value, other_value, opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      response_value: response_value,
      other_value: other_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/single_choice', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveTextResponse(project_id, round_id, question_id, response_value, opt_out1, opt_out2) : Observable<any> {
    const content = {
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/participant/free_text', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  responseInclusion(project_id, question_id, question_type, calculate_consensus: boolean, 
    analysis_inclusions: boolean[], response_ids: number[]): Observable<any> {
    const content = {
      calculate_consensus: calculate_consensus,
      analysis_inclusions: analysis_inclusions,
      response_ids: response_ids
    };
    return this.http.put(baseURL + '/projects/' + project_id + '/questions/' + question_id + '/response_inclusion/' + question_type, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  likertChoiceSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/response_summary/' + question_id + '/likert').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  multipleChoiceSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/response_summary/' + question_id + '/mcq').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  numericSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/response_summary/' + question_id + '/numeric').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  rankingSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/response_summary/' + question_id + '/ranking').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  singleChoiceSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/response_summary/' + question_id + '/scq').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  textSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/response_summary/' + question_id + '/text').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }
  
}