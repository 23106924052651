import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import {
  UntypedFormArray,
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { QuestionOptionsService } from '../../../../../_services/question-options.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ColourPairs } from '../../../../../_shared/colourPairs';
import { ColourPaletteModalComponent } from '../../../../../components/colour-palette/colour-palette.component';

@Component({
  selector: 'app-round-likert-options',
  templateUrl: './round-likert-options.component.html',
  styleUrls: ['./round-likert-options.component.scss'],
})
export class RoundLikertOptionsComponent implements OnInit, OnDestroy {
  project_id = this.data.project_id;
  round_id = this.data.round_id;

  likertOptions: Observable<any>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  optionLabels = new UntypedFormArray([]);
  consensusGrouping = new UntypedFormArray([]);
  pristine = true;

  newOption: UntypedFormGroup;
  newColour: String;

  colourPairs = ColourPairs;

  consensusGroups = ['Agree', 'Disagree', 'Neutral'];

  options = [];
  colours = [];

  menuConfig = {
    isOpenByHover: false,
    isSymmetrical: true,
    isClockwiseMovement: true,
    // mainRadius: 184,
    // subRadius: 150,
    animateTime: 600,
    animatePause: 1,
    // subStep: 75,
    // mainStep: 95,
    startedDeg: 0,
    animateName: 'come-in',
    animationType: 'cubic-bezier(.16,1.84,.33,1.49)',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RoundLikertOptionsComponent>,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private questionOptionService: QuestionOptionsService,
    public dialog: MatDialog
  ) {
    this.newOption = fb.group({
      label: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      consensusGroup: [],
    });
  }

  ngOnInit(): void {
    this.likertOptions = this.questionOptionService
      .getLikertOptions(this.project_id, this.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading likert options:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.likertOptions.pipe(first()).subscribe(
      (likertOptions) => {
        this.options = likertOptions.data;
        this.options.forEach((option) => {
          this.colours.push(option.colour);
          this.optionLabels.push(
            new UntypedFormControl(option.label, [
              Validators.required,
              Validators.maxLength(50),
              Validators.pattern(
                '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
              ),
            ])
          );
          this.consensusGrouping.push(
            new UntypedFormControl(option.consensus_group, [
              Validators.required,
            ])
          );
        });

        //disable controls if round_status isn't draft
        if (this.data.round_status !== 'Draft') {
          this.optionLabels.disable();
          this.consensusGrouping.disable();
          this.newOption.disable();
        }
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.pristine = false;
    moveItemInArray(this.options, event.previousIndex, event.currentIndex);
  }

  openColourPalette(option) {
    const Ref = this.dialog.open(ColourPaletteModalComponent, {
      data: { selected: option.colour },
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          option.colour = result;
          this.pristine = false;
        }
      });
  }

  save() {
    //save updated positions, label names & consensus grouping values
    var index = 0;
    this.options.forEach((option) => {
      option.label = this.optionLabels.controls[option.position].value;
      option.consensus_group =
        this.consensusGrouping.controls[option.position].value;
      option.position = index;
      index += 1;
    });

    this.dialogRef.close(this.options);
  }

  add() {
    // Create new form controls
    this.optionLabels.push(
      new UntypedFormControl(this.newOption.value.label, [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ])
    );
    this.consensusGrouping.push(
      new UntypedFormControl(this.newOption.value.consensusGroup, [
        Validators.required,
      ])
    );

    // Push option to table
    this.options.push({
      id: null,
      label: this.newOption.value.label,
      position: this.options.length,
      consensus_group: this.newOption.value.consensusGroup,
      colour: this.newColour,
    });

    // Clear new option controls
    this.newOption.reset();

    this.pristine = false;
  }

  delete(option_id) {
    // Find option to be deleted
    var fullOption = this.options.find((option) => {
      return option.id === option_id;
    });

    // Sort through updated options, remove selected option and corresponding form controls
    var index = 0;
    this.options.forEach((option) => {
      if (option.id === option_id) {
        this.optionLabels.removeAt(option.position);
        this.consensusGrouping.removeAt(option.position);
        this.colours.splice(index, 1);
        this.options.splice(index, 1);
      }
      index += 1;
    });

    //Resort and reduce position of those below
    var index = 0;
    this.options.forEach((option) => {
      if (option.position > fullOption.position) {
        this.options[index].position = this.options[index].position - 1;
      }
      index += 1;
    });

    this.pristine = false;
  }
}
