<!-- ERROR BANNER -->
<span *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b>{{errMsg}}</span>
<div fxLayout="column" *ngIf="project | async as project else processAsync">
    
    
    <!-- PAGE HEADER -->
    <div class="toolbar accent-back" 
        fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="goBack()" *ngIf="!is_client"><mat-icon>navigate_before</mat-icon></button>
        <h3 fxHide.gt-xs="true">{{project.data.name}}</h3>
        <h3 fxHide.xs="true"><a>{{project.data.name}}</a><a *ngIf="project.data.project_code && !is_client"> ({{project.data.project_code}}) </a><a> - {{project.data.status}}</a></h3>
        <span class="flex-spacer"></span>
        <!-- <button fxHide.lt-sm="true" mat-flat-button color="warn" (click)="openProject()" *ngIf="project.data.status==='Completed' && !is_client">
            <mat-icon>lock_open</mat-icon>Reopen Project
        </button> -->
        <button fxHide.lt-sm="true" mat-flat-button color="warn" (click)="closeProject()" *ngIf="project.data.status==='Active' && !is_client">
            <mat-icon>lock</mat-icon>Complete Project
        </button>
        <button mat-icon-button *ngIf="!is_client" (click)="deleteProject()">
            <mat-icon>delete_outline</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="optionsMenu" *ngIf="!is_client">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #optionsMenu="matMenu">
            <button mat-menu-item routerLink="/main/project/{{project_id}}/options" routerLinkActive="active">Options</button>
            <button mat-menu-item routerLink="/main/project/{{project_id}}/team" routerLinkActive="active">Team</button>
            <button mat-menu-item routerLink="/main/project/{{project_id}}/homepage_setup" routerLinkActive="active">Participant Home Page</button>
            <button mat-menu-item routerLink="/main/project/{{project_id}}/profiling" routerLinkActive="active">Profiling Questions</button>
        </mat-menu>
    </div>

    <!-- SUMMARY FIGURES -->
    <div fxLayout="row" fxLayout.xs="column" *ngIf="rounds | async as rounds else processRoundsAsync">
        <!-- Progress -->
        <div fxFlex="50%" fxFlex.xs="100%" *ngIf="rounds.data.length > 0 && project.data.status === 'Active'">
            <h3 class="figure-header">PROGRESS</h3>
            <mat-divider></mat-divider>
            <app-project-progress-figure></app-project-progress-figure>
        </div>
            
        <!-- Results -->
        <div fxFlex="50%" fxFlex.xs="100%" *ngIf="rounds.data.length > 0 && project.data.status === 'Active'">
            <h3 class="figure-header">CURRENT RESULTS</h3>
            <mat-divider></mat-divider>
            <app-project-results-figure></app-project-results-figure>
            <a class="disclaimer" fxLayoutAlign="center" *ngIf="project.data.status === 'Active' && rounds.data[0].questions > 0">
                Note that results in this figure are subject to change until all participants have completed the round</a>
        </div>

        <!-- Completed Project Summary -->
        <div fxFlex="100%" *ngIf="rounds.data.length > 0 && project.data.status === 'Completed'">
            <h3 *ngIf="project.data.status === 'Completed'" class="figure-header">FINAL RESULTS</h3>
            <mat-divider></mat-divider>
            <app-project-results-figure></app-project-results-figure>
        </div>
    </div>

    <!-- ROUNDS PANEL -->
    <!-- Costello Version -->
    <div *ngIf="!is_client && project.data.status === 'Active'">
        <!-- Rounds table -->
        <div class="footer-pad" fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%" *ngIf="rounds | async as rounds else processRoundsAsync">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
                <h3>DELPHI ROUNDS</h3>
                <button mat-mini-fab fxHide.gt-xs="true" color="primary" aria-label="Add round" (click)="addSmallScreen()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <mat-divider></mat-divider>
            <span class="empty-warning" fxLayoutAlign="center center" *ngIf="rounds.data.length === 0">No rounds have been added</span>
            <app-project-dash-table *ngIf="rounds.data.length > 0"></app-project-dash-table>
        </div>

        <!-- Add round -->
        <div class="new-form"
            fxFlex="25%" fxFlex.sm="30%" fxHide.lt-sm="true"
            fxLayout="column" fxLayoutAlign="space-between space-between">
            <h3 fxLayout="row" fxLayoutAlign="start" class="subheader">NEW ROUND</h3>
            <mat-divider></mat-divider>
            <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
                <mat-form-field class="form-control">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="name" placeholder="Round 1" maxlength="30" required>
                    <mat-hint>
                        <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                            class="warn-fore">Please enter a name for the round</span>
                        <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="form-control">
                    <mat-label>Included Question Types</mat-label>
                    <mat-select formControlName="questionTypes" required multiple>
                        <mat-option *ngFor="let type of questionTypes" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <span *ngIf="form.controls.questionTypes.errors?.required && form.controls.questionTypes.touched"
                            class="warn-fore">Please select an option</span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" class="add-button footer-pad"> 
                <button mat-fab color="primary" aria-label="Add round" [disabled]="form.invalid"
                    (click)="addRound()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!-- Client Version -->
    <div *ngIf="is_client || project.data.status === 'Completed'" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between start" fxLayout.lt-md="column">
        <!-- Profiling questions table -->
        <div class="footer-pad" fxFlex="49%" fxFlex.lt-md="100%" *ngIf="profilingQuestions | async as profilingQuestions else processRoundsAsync">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
                <h3>PROFILING QUESTIONS</h3>
            </div>
            <mat-divider></mat-divider>
            <span class="empty-warning" fxLayoutAlign="center center" *ngIf="profilingQuestions.data.length === 0">No profiling questions have been added</span>
            <app-profiling-questions-table *ngIf="profilingQuestions.data.length > 0"></app-profiling-questions-table>
        </div>

        <!-- Rounds table -->
        <div class="footer-pad" fxFlex="49%" fxFlex.lt-md="100%" *ngIf="rounds | async as rounds else processRoundsAsync">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
                <h3>DELPHI ROUNDS</h3>
            </div>
            <mat-divider></mat-divider>
            <span class="empty-warning" fxLayoutAlign="center center" *ngIf="rounds.data.length === 0">No rounds have been added</span>
            <app-project-dash-table *ngIf="rounds.data.length > 0"></app-project-dash-table>
        </div>
    </div>  

</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>

<ng-template #processRoundsAsync>
    <ng-template #loading>
        <div fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%">
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>