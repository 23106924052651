<!-- PAGE HEADER -->
<div class="toolbar accent-back" 
    fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
    <h3>Project Options</h3>
</div>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<!-- OPTIONS PANEL -->
<div *ngIf="project | async as project else processAsync"
    fxLayout="row" fxLayout.xs="column">
    <div class="form-column" 
        fxFlex="50%" fxFlex.lt-sm="100%"
        fxLayout="column" fxLayoutAlign="space-between space-between">
        <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
            <mat-form-field class="form-control">
                <input matInput type="text" formControlName="name" placeholder="Project Title" maxlength="50" required>
                <mat-hint>
                    <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                        class="warn-fore">Please enter a project title</span>
                    <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
                </mat-hint>
            </mat-form-field>
            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="start center">
                <mat-form-field class="form-control form-wrap">
                    <mat-label>Client Company</mat-label>
                    <mat-select formControlName="clientCompany" required>
                        <mat-option *ngFor="let company of companies" [value]="company.id">
                            {{company.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <span *ngIf="form.controls.clientCompany.errors?.required && form.controls.clientCompany.touched"
                            class="warn-fore">Please select an option</span>
                    </mat-hint>
                </mat-form-field>
                <button mat-icon-button color="primary"
                    matTooltip="Set client company information"
                    [disabled]="form.value.clientCompany===-1"
                    (click)="clientSettings(form.value.clientCompany)"
                ><mat-icon>settings</mat-icon></button>
            </div>
            
            <mat-form-field *ngIf="form.value.clientCompany ===-1">
                <input matInput type="text" formControlName="newClientCompanyName" placeholder="New Client Company Name"
                    maxlength="30" [required]="form.value.clientCompany ===-1">
                <mat-hint>
                    <span *ngIf="form.controls.newClientCompanyName.errors?.required && form.controls.newClientCompanyName.touched"
                        class="warn-fore">Please enter the name of the new company</span>
                    <span class="warn-fore" [hidden]="!form.controls.newClientCompanyName.errors?.pattern">This field contains unsupported characters!</span>
                </mat-hint>
            </mat-form-field>  
            <mat-form-field class="form-control">
                <input matInput type="text" formControlName="disease_area" placeholder="Disease Area">
                <mat-hint>
                    <span class="warn-fore" [hidden]="!form.controls.disease_area.errors?.pattern">This field contains unsupported characters!</span>
                </mat-hint>
            </mat-form-field>  
        </div>
    </div>

    <div class="form-column" 
        fxFlex="50%" fxFlex.lt-sm="100%"
        fxLayout="column" fxLayoutAlign="space-between space-between">
        <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
            <mat-form-field class="form-control">
                <input matInput type="text" formControlName="project_code" placeholder="Project Code" maxlength="20">
                <mat-hint>
                    <span>This will only be visible to Costello Medical app users</span>
                    <span class="warn-fore" [hidden]="!form.controls.project_code.errors?.pattern">This field contains unsupported characters!</span>
                </mat-hint>
            </mat-form-field>
            <div class="form-wrap" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="form-control form-wrap">
                    <input matInput type="text" formControlName="opt_out_label1" placeholder="Opt Out Label 1">
                    <mat-hint>
                        <span>This is the description used next to the checkbox allowing participants to skip a question</span>
                        <span class="warn-fore" [hidden]="!form.controls.opt_out_label1.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-checkbox formControlName="opt_out1_use" color="primary">Use?</mat-checkbox>
            </div>
            <div class="form-wrap" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="form-control form-wrap">
                    <input matInput type="text" formControlName="opt_out_label2" placeholder="Opt Out Label 2">
                    <mat-hint>
                        <span>This is the description used next to the checkbox allowing participants to skip a question</span>
                        <span class="warn-fore" [hidden]="!form.controls.opt_out_label2.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-checkbox formControlName="opt_out2_use" color="primary">Use?</mat-checkbox>
            </div>
            
            <mat-checkbox class="form-control" formControlName="hide_incomplete_results" color="primary">
                Hide consensus results from clients for open rounds</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutAlign="end" class="footer-pad save-button"> 
            <button mat-flat-button color="primary" aria-label="Add project" [disabled]="form.invalid || form.pristine" (click)="saveChanges()">
                Save Changes
            </button>
        </div>
    </div>
</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>


