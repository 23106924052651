<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
<div class="table-container">
  <mat-table mat-table [dataSource]="tableData" *ngIf="showTable" matSort matSortActive={{sortField}} matSortDirection={{direction}}>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.name}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="project_code">
      <mat-header-cell *matHeaderCellDef>Project Code</mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index;">
        <a>{{item.project_code}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="client">
        <mat-header-cell *matHeaderCellDef>Client Company</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.client_name}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="disease_area">
        <mat-header-cell *matHeaderCellDef>Disease Area</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.disease_area}}</a>
        </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.status}}</a>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" (click)="onRowClicked(row, i)"></mat-row>
  </mat-table>
</div>

<!-- <mat-paginator 
  [length]="numRecords"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10]" 
  showFirstLastButtons
></mat-paginator> -->

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>