import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ProcessHttpmsgService } from 'src/app/_services/process-httpmsg.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  form: UntypedFormGroup;
  ngDestroy$ = new Subject();
  users: Observable<any>;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private userService: UserService,
    public router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    public snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {
    // Detect if user is admin, and if not, redirect
    if (!userService.admin) {
      router.navigate(['/']);
    } else {
      this.form = fb.group({
        title: [
          ,
          [Validators.maxLength(10), Validators.pattern('^[A-Za-z0-9- ]+$')],
        ],
        first_name: [
          ,
          [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern('^[A-Za-z0-9- ]+$'),
          ],
        ],
        last_name: [
          ,
          [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern('^[A-Za-z0-9- ]+$'),
          ],
        ],
        email: [, [Validators.required, Validators.maxLength(100)]],
      });
    }
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (
                (user.client_account || user.participant_account) &&
                !user.admin_account
              ) {
                this.router.navigate(['/login']);
              } else {
                this.users = this.userService
                  .getUsers('false', '{""}', 'false')
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading projects:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              }
            }
          },
          (error) => {
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  addUser(first_name, last_name, email) {
    this.userService
      .createCostelloUser(first_name, last_name, email)
      .pipe(first())
      .subscribe(
        (response) => {
          // Refresh page
          window.location.reload();
        },
        (error) => {
          this.errMsg = error;
          this.snackBar.open(error, 'Close', {
            duration: 5000,
          });
        }
      );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
