import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfilingQuestionService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getProfilingQuestions(project_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_questions').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addProfilingQuestion(project_id, question, question_type): Observable<any> {
    const content = {
      question: question,
      question_type: question_type
    };

    return this.http.post(baseURL + '/projects/' +  project_id + '/profiling_questions', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getProfilingQuestion(project_id, question_number): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_questions/' + question_number).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  updateProfilingQuestion(project_id, question_number, question, question_type, standard_question,
    required, filter_use, note_use, instructions?, section?, top_label?, bottom_label?, unit_label?,
    min_value?, max_value?, num_dps?
  ): Observable<any> {
    const content = {
      question: question,
      question_type: question_type,
      standard_question: standard_question,
      required: required,
      filter_use: filter_use,
      note_use: note_use,
      instructions: instructions,
      section: section,
      top_label: top_label,
      bottom_label: bottom_label,
      unit_label: unit_label,
      min_value: min_value,
      max_value: max_value,
      num_dps: num_dps
    };
    return this.http.put(baseURL + '/projects/' + project_id + '/profiling_questions/' + question_number, content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteProfilingQuestion(project_id, question_number): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/profiling_questions/' + question_number).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  importQuestions(questions: any[], project_id): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/import_profiling_questions/', questions).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }
}
