import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  QuestionTypeFunctions,
  QuestionTypes,
} from '../../_shared/questionTypes';

@Component({
  selector: 'app-question-add-modal',
  templateUrl: './question-add-modal.component.html',
  styleUrls: ['./question-add-modal.component.scss'],
})
export class QuestionAddModalComponent implements OnInit {
  form: UntypedFormGroup;
  questionTypes = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<QuestionAddModalComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      questionType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.questionTypes = QuestionTypeFunctions.getQuestionTypes(
      this.data.use_freetext,
      this.data.use_likert,
      this.data.use_mcq,
      this.data.use_numeric,
      this.data.use_ranking,
      this.data.use_scq
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.form.value);
  }
}
