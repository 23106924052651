import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-team-add-participant',
  templateUrl: './project-team-add-participant.component.html',
  styleUrls: ['./project-team-add-participant.component.scss'],
})
export class AddParticipantModalComponent implements OnInit {
  form: UntypedFormGroup;
  errMsg: String;

  constructor(
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddParticipantModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      title: [
        '',
        [
          // Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[A-Za-z0-9- ]+$'),
        ],
      ],
      first_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern('^[A-Za-z0-9- ]+$'),
        ],
      ],
      last_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern('^[A-Za-z0-9- ]+$'),
        ],
      ],
      email: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  send() {
    this.dialogRef.close(this.form.value);
  }
}

@Component({
  selector: 'app-project-team-add-participant',
  templateUrl: './project-team-add-participant.component.html',
  styleUrls: ['./project-team-add-participant.component.scss'],
})
export class AddParticipantSheetComponent implements OnInit {
  form: UntypedFormGroup;
  errMsg: String;

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _bottomSheetRef: MatBottomSheetRef<AddParticipantSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.form = fb.group({
      title: [
        '',
        [
          // Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[A-Za-z0-9- ]+$'),
        ],
      ],
      first_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern('^[A-Za-z0-9- ]+$'),
        ],
      ],
      last_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern('^[A-Za-z0-9- ]+$'),
        ],
      ],
      email: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  ngOnInit(): void {}

  close() {
    this._bottomSheetRef.dismiss();
  }

  send() {
    this._bottomSheetRef.dismiss(this.form.value);
  }
}
