<mat-toolbar *ngIf="router.url !== '/'" color="primary" class="header">
  <mat-toolbar-row>
    <button
      mat-icon-button
      matTooltip="Open menu"
      *ngIf="!is_client"
      (click)="toggleSideNav()"
    >
      <mat-icon aria-label="Menu">menu</mat-icon>
    </button>
    <a fxShow.xs="false">COSTELLO MEDICAL DELPHI APP</a>
    <a fxShow.gt-xs="false">DELPHI</a>

    <button
      *ngIf="is_admin"
      fxShow.xs="false"
      mat-button
      style="margin-left: 5px"
      routerLink="/main"
      routerLinkActive="active"
    >
      Projects
    </button>
    <button
      *ngIf="is_admin"
      fxShow.xs="false"
      mat-button
      style="margin-left: 5px"
      routerLink="/main/user_management"
      routerLinkActive="active"
    >
      Users
    </button>
    <!--sign in status and button-->
    <span class="flex-spacer"></span>
    <a *ngIf="username" fxShow.xs="false">{{ username }}</a>
    <a *ngIf="username" fxShow.xs="false" mat-button (click)="logOut()"
      >Logout</a
    >
    <button
      mat-icon-button
      fxShow.gt-xs="false"
      (click)="logOut()"
      matTooltip="Log out of the app"
    >
      <mat-icon aria-label="Log out">exit_to_app</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Manage your account"
      routerLink="/my_account"
    >
      <mat-icon aria-label="Manage Account">account_circle</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="jiraBug()"
      matTooltip="Report a problem in the app"
    >
      <mat-icon aria-label="Report Issue">bug_report</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
