import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ChartsModule } from 'ng2-charts';
import { ChartModule } from 'angular2-chartjs';
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js';
import { ColorPickerModule } from 'ngx-color-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import * as Sentry from '@sentry/angular';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { baseURL } from './_shared/baseurl';

import { MatOptionSelectAllModule } from './components/mat-option-select-all/mat-option-select-all.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthInterceptor,
  UnauthorizedInterceptor,
} from './_services/auth.interceptor';
import { ProcessHttpmsgService } from './_services/process-httpmsg.service';
import { SideNavService } from './_services/side-nav.service';
import { ClientCompanyService } from './_services/client-company.service';
import { ProfilingQuestionService } from './_services/profiling-question.service';
import { ProfilingResponseService } from './_services/profiling-response.service';
import { ProjectService } from './_services/project.service';
import { QuestionService } from './_services/question.service';
import { QuestionOptionsService } from './_services/question-options.service';
import { ResponseService } from './_services/response.service';
import { RoundService } from './_services/round.service';
import { UserService } from './_services/user.service';

import { HomeComponent } from './pages/common/login/home/home.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { QuestionEditComponent } from './pages/main/question-toolbar/components/question-edit/question-edit.component';
import { ProjectDashboardComponent } from './pages/main/project-dashboard/project-dashboard.component';
import { HeaderComponent } from './pages/main/layout-costello/components/header/header.component';
import { SidenavComponent } from './pages/main/layout-costello/components/sidenav/sidenav.component';
import { FooterComponent } from './pages/main/layout-costello/components/footer/footer.component';
import { LayoutLoginComponent } from './pages/common/login/layout-login/layout-login.component';
import { LayoutCostelloComponent } from './pages/main/layout-costello/layout-costello.component';
import { OverviewDashboardComponent } from './pages/main/overview-dashboard/overview-dashboard.component';
import { RoundDashboardComponent } from './pages/main/round-dashboard/round-dashboard.component';
import { OverviewTableComponent } from './pages/main/overview-dashboard/components/overview-table/overview-table.component';
import { ProjectDashTableComponent } from './pages/main/project-dashboard/components/project-dash-table/project-dash-table.component';
import { ProjectProgressFigureComponent } from './pages/main/project-dashboard/components/project-progress-figure/project-progress-figure.component';
import { ProjectResultsFigureComponent } from './pages/main/project-dashboard/components/project-results-figure/project-results-figure.component';
import { RoundDashTableComponent } from './pages/main/round-dashboard/components/round-dash-table/round-dash-table.component';
import { RoundProgressFigureComponent } from './pages/main/round-dashboard/components/round-progress-figure/round-progress-figure.component';
import { ProjectTeamComponent } from './pages/main/project-team/project-team.component';
import { ProjectOptionsComponent } from './pages/main/project-options/project-options.component';
import { RoundOptionsComponent } from './pages/main/round-options/round-options.component';
import { RoundParticipantsComponent } from './pages/main/round-participants/round-participants.component';
import { QuestionResponseComponent } from './pages/main/question-toolbar/components/question-response/question-response.component';
import { RoundLikertOptionsComponent } from './pages/main/round-options/components/round-likert-options/round-likert-options.component';
import { OverviewAddProjectSheetComponent } from './pages/main/overview-dashboard/components/overview-add-project-sheet/overview-add-project-sheet.component';
import { ProjectAddRoundSheetComponent } from './pages/main/project-dashboard/components/project-add-round-sheet/project-add-round-sheet.component';
import { RoundAddQuestionSheetComponent } from './components/round-add-question-sheet/round-add-question-sheet.component';
import { QuestionToolbarComponent } from './pages/main/question-toolbar/question-toolbar.component';
import { QuestionResponseTableComponent } from './pages/main/question-toolbar/components/question-response-table/question-response-table.component';
import { QuestionResponseFigureComponent } from './components/question-response-figure/question-response-figure.component';
import { QuestionAddModalComponent } from './components/question-add-modal/question-add-modal.component';
import { SectionEditModalComponent } from './components/section-edit-modal/section-edit-modal.component';
import { SectionEditSheetComponent } from './components/section-edit-sheet/section-edit-sheet.component';
import { SectionAddModalComponent } from './components/section-add-modal/section-add-modal.component';
import { SectionAddSheetComponent } from './components/section-add-sheet/section-add-sheet.component';
import { BranchingLogicModalComponent } from './pages/main/question-toolbar/components/branching-logic/branching-logic.component';
import { BranchingLogicSheetComponent } from './pages/main/question-toolbar/components/branching-logic/branching-logic.component';
import { ProfilingSetupComponent } from './pages/main/profiling-setup/profiling-setup.component';
import { ProfilingQuestionsTableComponent } from './components/profiling-questions-table/profiling-questions-table.component';
import { ProfilingQuestionsEditComponent } from './pages/main/profiling-toolbar/components/profiling-questions-edit/profiling-questions-edit.component';
import { ProfilingResponsesComponent } from './pages/main/profiling-toolbar/components/profiling-responses/profiling-responses.component';
import { ProfilingToolbarComponent } from './pages/main/profiling-toolbar/profiling-toolbar.component';
import { ProfilingResponseTableComponent } from './pages/main/profiling-toolbar/components/profiling-response-table/profiling-response-table.component';
import { ProfilingRoundFigureComponent } from './pages/main/profiling-toolbar/components/profiling-round-figure/profiling-round-figure.component';
import { LayoutParticipantComponent } from './pages/participant/layout-participant/layout-participant.component';
import { HeaderParticipantComponent } from './pages/participant/layout-participant/components/header-participant/header-participant.component';
import { ParticipantDashboardComponent } from './pages/participant/participant-dashboard/participant-dashboard.component';
import { HelpFormModalComponent } from './pages/participant/layout-participant/components/help-form/help-form.component';
import { HelpFormSheetComponent } from './pages/participant/layout-participant/components/help-form/help-form.component';
import { ParticipantResponsesComponent } from './pages/participant/participant-responses/participant-responses.component';
import { ParticipantQuestionToolbarComponent } from './pages/participant/participant-question-toolbar/participant-question-toolbar.component';
import { ParticipantQuestionComponent } from './pages/participant/participant-question-toolbar/components/participant-question/participant-question.component';
import { DisplayFreetextComponent } from './components/display-freetext/display-freetext.component';
import { DisplayLikertComponent } from './components/display-likert/display-likert.component';
import { DisplayNumericComponent } from './components/display-numeric/display-numeric.component';
import { DisplayRankingComponent } from './components/display-ranking/display-ranking.component';
import { DisplayMcqComponent } from './components/display-mcq/display-mcq.component';
import { DisplayScqComponent } from './components/display-scq/display-scq.component';
import { GroupResponseModalComponent } from './pages/participant/participant-responses/components/group-response-modal/group-response-modal.component';
import { GroupResponseSheetComponent } from './pages/participant/participant-responses/components/group-response-sheet/group-response-sheet.component';
import { ClientEditModalComponent } from './pages/main/project-options/components/client-edit-modal/client-edit-modal.component';
import { ClientEditSheetComponent } from './pages/main/project-options/components/client-edit-sheet/client-edit-sheet.component';
import { ConfirmDeleteModalComponent } from './components/confirm-delete/confirm-delete.component';
import { ConfirmDeleteSheetComponent } from './components/confirm-delete/confirm-delete.component';
import { ColourPaletteModalComponent } from './components/colour-palette/colour-palette.component';
import {
  AddParticipantModalComponent,
  AddParticipantSheetComponent,
} from './pages/main/project-team/components/project-team-add-participant/project-team-add-participant.component';
import {
  AddClientModalComponent,
  AddClientSheetComponent,
} from './pages/main/project-team/components/project-team-add-client/project-team-add-client.component';
import { ParticipantCompletionComponent } from './pages/participant/participant-completion/participant-completion.component';
import { ParticipantProfilingQuestionComponent } from './pages/participant/participant-question-toolbar/components/participant-profiling-question/participant-profiling-question.component';
import { ProfilingFigureScqComponent } from './pages/main/profiling-toolbar/components/profiling-figure-scq/profiling-figure-scq.component';
import { ProfilingFigureMcqComponent } from './pages/main/profiling-toolbar/components/profiling-figure-mcq/profiling-figure-mcq.component';
import { ProfilingFigureRankingComponent } from './pages/main/profiling-toolbar/components/profiling-figure-ranking/profiling-figure-ranking.component';
import { ProfilingFigureNumericComponent } from './pages/main/profiling-toolbar/components/profiling-figure-numeric/profiling-figure-numeric.component';
import { QuestionUploadComponent } from './pages/main/round-dashboard/components/question-upload/question-upload.component';
import { QuestionOptionUploadComponent } from './pages/main/round-dashboard/components/question-option-upload/question-option-upload.component';
import { QuestionTextSummaryComponent } from './components/question-text-summary/question-text-summary.component';
import { ParticipantExitComponent } from './pages/participant/participant-exit/participant-exit.component';
import { DashboardSetupComponent } from './pages/main/dashboard-setup/dashboard-setup.component';
import { FooterParticipantComponent } from './pages/participant/layout-participant/components/footer-participant/footer-participant.component';
import { DownloadService } from './_services/download.service';
import { ProfilingOptionUploadComponent } from './pages/main/profiling-setup/components/profiling-option-upload/profiling-option-upload.component';
import { ProfilingQuestionUploadComponent } from './pages/main/profiling-setup/components/profiling-question-upload/profiling-question-upload.component';
import { CrawlingHomeComponent } from './pages/common/login/crawling-home/crawling-home.component';
import { RoundOpenModalComponent } from './pages/main/round-dashboard/components/round-open-modal/round-open-modal.component';
import { PasswordResetComponent } from './pages/common/login/password-reset/password-reset.component';
import {
  AddCostelloModalComponent,
  AddCostelloSheetComponent,
} from './pages/main/project-team/components/project-team-add-costello/project-team-add-costello.component';
import { AccountManagementComponent } from './pages/common/account-management/account-management.component';
import {
  PolicyModalComponent,
  PolicySheetComponent,
} from './components/policy-components/policy-modal/policy-modal.component';
import { PolicyFooterComponent } from './components/policy-components/policy-footer/policy-footer.component';
import { PrivacyPolicyComponent } from './components/policy-components/privacy-policy/privacy-policy.component';
import { CookieStatementComponent } from './components/policy-components/cookie-statement/cookie-statement.component';
import { ErrorHandlerInterceptor } from './_services/error-handler.interceptor';
import { RoundRevertModalComponent } from './pages/main/round-dashboard/components/round-revert-modal/round-revert-modal.component';
import { UsersComponent } from './pages/main/users/users.component';
import { UsersTableComponent } from './pages/main/users/components/users-table/users-table.component';
import { UserEditModalComponent } from './components/user-edit/user-edit.component';
import { UserEditSheetComponent } from './components/user-edit/user-edit.component';
import { ParticipantUploadComponent } from './pages/main/project-team/components/participant-upload/participant-upload.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    QuestionEditComponent,
    ProjectDashboardComponent,
    HeaderComponent,
    SidenavComponent,
    FooterComponent,
    LayoutLoginComponent,
    LayoutCostelloComponent,
    OverviewDashboardComponent,
    RoundDashboardComponent,
    OverviewTableComponent,
    ProjectDashTableComponent,
    ProjectProgressFigureComponent,
    ProjectResultsFigureComponent,
    RoundDashTableComponent,
    RoundProgressFigureComponent,
    ProjectTeamComponent,
    ProjectOptionsComponent,
    RoundOptionsComponent,
    RoundParticipantsComponent,
    QuestionResponseComponent,
    RoundLikertOptionsComponent,
    OverviewAddProjectSheetComponent,
    ProjectAddRoundSheetComponent,
    RoundAddQuestionSheetComponent,
    QuestionToolbarComponent,
    QuestionResponseTableComponent,
    QuestionResponseFigureComponent,
    QuestionAddModalComponent,
    SectionEditModalComponent,
    SectionEditSheetComponent,
    SectionAddModalComponent,
    SectionAddSheetComponent,
    BranchingLogicModalComponent,
    BranchingLogicSheetComponent,
    ProfilingSetupComponent,
    ProfilingQuestionsTableComponent,
    ProfilingQuestionsEditComponent,
    ProfilingResponsesComponent,
    ProfilingToolbarComponent,
    ProfilingResponseTableComponent,
    ProfilingRoundFigureComponent,
    LayoutParticipantComponent,
    HeaderParticipantComponent,
    ParticipantDashboardComponent,
    HelpFormModalComponent,
    HelpFormSheetComponent,
    ParticipantResponsesComponent,
    ParticipantQuestionToolbarComponent,
    ParticipantQuestionComponent,
    DisplayFreetextComponent,
    DisplayLikertComponent,
    DisplayNumericComponent,
    DisplayRankingComponent,
    DisplayMcqComponent,
    DisplayScqComponent,
    GroupResponseModalComponent,
    GroupResponseSheetComponent,
    ClientEditModalComponent,
    ClientEditSheetComponent,
    ConfirmDeleteModalComponent,
    ConfirmDeleteSheetComponent,
    ColourPaletteModalComponent,
    AddParticipantModalComponent,
    AddParticipantSheetComponent,
    AddClientModalComponent,
    AddClientSheetComponent,
    ParticipantCompletionComponent,
    ParticipantProfilingQuestionComponent,
    ProfilingFigureScqComponent,
    ProfilingFigureMcqComponent,
    ProfilingFigureRankingComponent,
    ProfilingFigureNumericComponent,
    QuestionUploadComponent,
    QuestionOptionUploadComponent,
    QuestionTextSummaryComponent,
    ParticipantExitComponent,
    DashboardSetupComponent,
    FooterParticipantComponent,
    ProfilingOptionUploadComponent,
    ProfilingQuestionUploadComponent,
    CrawlingHomeComponent,
    RoundOpenModalComponent,
    PasswordResetComponent,
    AddCostelloModalComponent,
    AddCostelloSheetComponent,
    AccountManagementComponent,
    PolicyModalComponent,
    PolicySheetComponent,
    PolicyFooterComponent,
    PrivacyPolicyComponent,
    CookieStatementComponent,
    RoundRevertModalComponent,
    UsersComponent,
    UsersTableComponent,
    UserEditModalComponent,
    UserEditSheetComponent,
    ParticipantUploadComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ChartsModule,
    ChartModule,
    ColorPickerModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatGridListModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatOptionSelectAllModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, //TODO: Set to false for production!
      }),
    },
    ClientCompanyService,
    DownloadService,
    MatDatepickerModule,
    MatNativeDateModule,
    ProcessHttpmsgService,
    ProfilingQuestionService,
    ProfilingResponseService,
    ProjectService,
    QuestionService,
    QuestionOptionsService,
    ResponseService,
    RoundService,
    SideNavService,
    UserService,
    { provide: 'BaseURL', useValue: baseURL },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
