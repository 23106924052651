import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../_services/process-httpmsg.service';
import { ProjectService } from '../../_services/project.service';
import { QuestionService } from '../../_services/question.service';

@Component({
  selector: 'app-display-mcq',
  templateUrl: './display-mcq.component.html',
  styleUrls: ['./display-mcq.component.scss'],
})
export class DisplayMcqComponent implements OnInit, OnChanges, OnDestroy {
  @Input() options: any[];
  @Input() question: string;
  @Input() show_header: boolean;
  @Input() required: boolean;
  @Input() useNote: boolean;
  @Input() response: any;
  @Input() section_id: number;
  @Input() question_instructions: string;
  @Input() useSkip1: boolean;
  @Input() useSkip2: boolean;
  @Input() skipLabel1: string;
  @Input() skipLabel2: string;
  @Input() client_colour_primary: string;
  @Input() client_colour_secondary: string;

  @Output() formCopy = new EventEmitter<UntypedFormGroup>();

  form: UntypedFormGroup;
  checkBoxes: UntypedFormArray;

  project_id = this.route.snapshot.paramMap.get('project_id');

  section: Observable<any>;
  ngDestroy$ = new Subject();
  colour_primary: string = '#880e4f'; //Default to costello purple
  colour_secondary: string = '#bdbdbd'; //Default to accent grey
  other_option: any;
  other_selected = false;
  dontupdate = false;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionService: QuestionService
  ) {
    this.form = fb.group({
      response: this.fb.array([], Validators.required),
      // response: [[], Validators.required],
      other: [],
      note: [
        ,
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      skip1: [false],
      skip2: [false],
    });
    this.checkBoxes = this.fb.array([]);
  }

  ngOnInit(): void {
    this.options.forEach((option) => {
      this.checkBoxes.push(new UntypedFormControl());
    });

    //Handle access to project_id from participant view
    if (!this.project_id) {
      this.project_id = this.route.parent.snapshot.paramMap.get('project_id');
    }
    if (this.section_id) {
      this.section = this.questionService
        .getSection(this.project_id, this.section_id)
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading section:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    }

    if (this.client_colour_primary) {
      this.colour_primary = this.client_colour_primary;
    }
    if (this.client_colour_secondary) {
      this.colour_secondary = this.client_colour_secondary;
    }
    this.setClientTheme(this.colour_primary, this.colour_secondary);

    // Set required validator if needed
    if (this.required) {
      this.form.controls.response.setValidators(Validators.required);
      this.form.controls.response.updateValueAndValidity();
    }

    // Find 'other' option if it exists
    this.other_option = this.options.find((option) => {
      return option.is_other;
    });

    // Send current form to participant-question component
    this.form.valueChanges.pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      if (!this.dontupdate) {
        this.formCopy.emit(this.form);
      }
    });

    //Handle skip selection
    this.form.controls.response.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((responseValue) => {
        if (responseValue && !this.dontupdate) {
          this.form.controls.skip1.setValue(false, { emitEvent: false });
          this.form.controls.skip2.setValue(false, { emitEvent: false });
          if (!this.form.controls.response.validator && this.required) {
            this.form.controls.response.setValidators(Validators.required);
            this.form.controls.response.updateValueAndValidity({
              emitEvent: false,
            });
          }
          //Handle selection of an 'other' option
          if (this.other_option) {
            if (responseValue.includes(this.other_option.id)) {
              this.other_selected = true;
              if (!this.form.controls.other.validator && this.required) {
                this.form.controls.other.setValidators(Validators.required);
                this.form.controls.other.updateValueAndValidity({
                  emitEvent: false,
                });
              }
            } else {
              this.other_selected = false;
              this.form.controls.other.reset();
              this.form.controls.other.clearValidators();
              this.form.controls.other.updateValueAndValidity({
                emitEvent: false,
              });
            }
          }
        }
      });
    this.form.controls.skip1.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((skip1Value) => {
        if (skip1Value) {
          this.form.controls.skip2.setValue(false, { emitEvent: false });
          // this.form.controls.response.setValue([], {emitEvent: false});
          this.clearCheckboxes();
          this.form.controls.response.clearValidators();
          this.form.controls.response.updateValueAndValidity({
            emitEvent: false,
          });
          this.other_selected = false;
          this.form.controls.other.setValue(null, { emitEvent: false });
          this.form.controls.other.clearValidators();
          this.form.controls.other.updateValueAndValidity({ emitEvent: false });
        } else if (!this.form.value.skip2 && this.required) {
          this.form.controls.response.setValidators(Validators.required);
          this.form.controls.response.updateValueAndValidity({
            emitEvent: false,
          });
        }
      });
    this.form.controls.skip2.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((skip2Value) => {
        if (skip2Value) {
          this.form.controls.skip1.setValue(false, { emitEvent: false });
          // this.form.controls.response.setValue([], {emitEvent: false});
          this.clearCheckboxes();
          this.form.controls.response.clearValidators();
          this.form.controls.response.updateValueAndValidity({
            emitEvent: false,
          });
          this.other_selected = false;
          this.form.controls.other.setValue(null, { emitEvent: false });
          this.form.controls.other.clearValidators();
          this.form.controls.other.updateValueAndValidity({ emitEvent: false });
        } else if (!this.form.value.skip1 && this.required) {
          this.form.controls.response.setValidators(Validators.required);
          this.form.controls.response.updateValueAndValidity({
            emitEvent: false,
          });
        }
      });

    // Add current response if exists
    if (this.response) {
      // this.form.controls.response.setValue(this.response.value, {emitEvent: false});
      this.setCheckboxes(this.response.value);
      this.form.controls.note.setValue(this.response.note, {
        emitEvent: false,
      });
      this.form.controls.other.setValue(this.response.other, {
        emitEvent: false,
      });
      this.form.controls.skip1.setValue(this.response.opt_out1, {
        emitEvent: false,
      });
      this.form.controls.skip2.setValue(this.response.opt_out2, {
        emitEvent: false,
      });
      // handle existing other option selection
      if (this.response.other) {
        this.other_selected = true;
        if (!this.form.controls.other.validator && this.required) {
          this.form.controls.other.setValidators(Validators.required);
          this.form.controls.other.updateValueAndValidity({ emitEvent: false });
        }
      }
    }
  }

  ngOnChanges(changes) {
    //handle changes to required validator from parent component (question config)
    if (changes.required) {
      if (this.required && !this.form.value.skip1 && !this.form.value.skip2) {
        this.form.controls.response.setValidators(Validators.required);
        this.form.controls.response.updateValueAndValidity();
      } else {
        this.form.controls.response.clearValidators();
        this.form.controls.response.updateValueAndValidity();
        this.form.controls.other.clearValidators();
        this.form.controls.other.updateValueAndValidity();
      }
    }
    //handle changes in associated section from parent component (question config)
    if (changes.section_id) {
      if (this.section_id) {
        this.section = this.questionService
          .getSection(this.project_id, this.section_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading section:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      }
    }
    //handle changes in use of 'other' option from parent component (question config)
    if (changes.options) {
      // Find 'other' option if it exists
      this.other_option = this.options.find((option) => {
        return option.is_other;
      });
      if (this.other_option && this.form.value.response) {
        if (this.form.value.response.includes(this.other_option.id)) {
          this.other_selected = true;
          if (!this.form.controls.other.validator && this.required) {
            this.form.controls.other.setValidators(Validators.required);
            this.form.controls.other.updateValueAndValidity();
          }
        }
      } else {
        this.other_selected = false;
        this.form.controls.other.clearValidators();
        this.form.controls.other.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  clearCheckboxes() {
    this.dontupdate = true;
    const checkArray: UntypedFormArray = this.form.get(
      'response'
    ) as UntypedFormArray;
    checkArray.clear();
    // checkArray.clear({emitEvent: false}); // Supported in Angular 12
    this.checkBoxes.reset();
    this.dontupdate = false;
  }

  onCheckboxChange(e, option_id) {
    const checkArray: UntypedFormArray = this.form.get(
      'response'
    ) as UntypedFormArray;
    if (e.checked) {
      checkArray.push(new UntypedFormControl(option_id));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == option_id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  setCheckboxes(values) {
    if (Array.isArray(values)) {
      this.dontupdate = true;
      const checkArray: UntypedFormArray = this.form.get(
        'response'
      ) as UntypedFormArray;
      values.forEach((value) => {
        checkArray.push(new UntypedFormControl(value));
        var index = this.options
          .map(function (e) {
            return e.id;
          })
          .indexOf(value);
        this.checkBoxes.controls[index]?.setValue(true);
      });
      this.dontupdate = false;
    }
  }

  setClientTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-colour', primary);
    document.documentElement.style.setProperty('--secondary-colour', secondary);
  }
}
