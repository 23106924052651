<div fxLayout="column" *ngIf="question | async as question else processAsync" [formGroup]="form" autocomplete="off">

    <!-- PAGE HEADER -->
    <div class="toolbar accent-back" 
        fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
        <h3>Edit Profiling Question</h3>
        <span class="flex-spacer"></span>
        <button mat-flat-button [disabled]="form.invalid || form.pristine" color="primary" (click)="saveChanges()">
            Save changes
        </button>
    </div>

    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

    <div fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" class="container">
        <!-- Question Options -->
        <div class="card"  
        fxFlex="49%" fxFlex.lt-md="100%">
            <mat-card>
                <mat-card-header>
                <mat-card-title>Question Options</mat-card-title>
                <mat-card-subtitle>Configure the question using the options below</mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content fxLayout="column" >
                
                <!-- Question type -->
                <div>
                    <mat-form-field class="padRight questionPad">
                    <mat-label>Question type</mat-label>
                    <mat-select formControlName="questionType" required>
                        <mat-option *ngFor="let option of questionTypes" [value]="option">
                            {{option}}
                        </mat-option>  
                    </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <!-- Section set up -->
                    <mat-form-field *ngIf="sections | async as sections else processAsync">
                    <mat-label>Section</mat-label>
                    <mat-select formControlName="section">
                        <mat-option *ngFor="let section of sectionOptions" [value]="section.id">
                        {{section.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                    <button mat-icon-button color="accent" *ngIf="form.value.section">
                    <mat-icon (click)="clearSection()">clear</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" matTooltip="Create new section" (click)="addSection()">
                    <mat-icon>add</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" matTooltip="Edit selected section" [disabled]="!form.value.section" (click)="editSection()">
                    <mat-icon>edit</mat-icon>
                    </button>
                </div>               

                <!-- Question text -->
                <mat-form-field class="full-width"> 
                    <mat-label>Enter question</mat-label>
                    <textarea matInput formControlName="questionTitle" required></textarea>
                    <mat-hint>
                        <span class="warn-fore" [hidden]="!form.controls.questionTitle.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width"> 
                    <mat-label>Add further instructions or details</mat-label>
                    <textarea matInput formControlName="questionInstructions"></textarea>
                    <mat-hint><span class="warn-fore" [hidden]="!form.controls.questionInstructions.errors?.pattern">This field contains unsupported characters!</span></mat-hint>
                </mat-form-field>

                <!-- Ranking question options -->
                <div *ngIf="form.value.questionType==='Ranking'">
                    <mat-form-field class="padRight">
                    <mat-label>Top label</mat-label>
                    <input matInput formControlName="top_label">
                    <mat-hint>
                        <span class="warn-fore" [hidden]="!form.controls.top_label.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                    </mat-form-field>
                    <mat-form-field class="padRight">
                    <mat-label>Bottom label</mat-label>
                    <input matInput formControlName="bottom_label">
                    <mat-hint>
                        <span class="warn-fore" [hidden]="!form.controls.bottom_label.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                    </mat-form-field>
                </div>
                
                <!-- Number of options -->
                <div *ngIf="form.value.questionType!=='Free Text' && form.value.questionType!=='Likert' && form.value.questionType!=='Numeric'">
                    <p>Select number of choices</p>
                    <mat-slider
                    formControlName="numOptions"
                        color="primary"
                        min="0"
                        max="10"
                        step="1"
                        thumbLabel="true"
                    ></mat-slider>
                    <a class="primary-fore" [class.warn-fore]="form.controls.numOptions.invalid"><strong>{{form.value.numOptions}}</strong></a>
                    <a class="warn-fore" *ngIf="form.controls.numOptions.invalid">
                    At least two choices are needed!
                    </a>
                </div>

                <!-- Selection options -->
                <div *ngIf="form.value.questionType!=='Free Text' && form.value.questionType!=='Likert' && form.value.questionType!=='Numeric'">
                    <div fxLayout="row" *ngFor="let option of form.controls.optionLabels.controls; let i = index" fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field class="padRight label-form">
                            <mat-label>Option {{i + 1}} label</mat-label>
                            <input matInput [formControl]="option" autocomplete="off" maxlength="50" required>
                            <mat-hint>
                                <span class="warn-fore" [hidden]="!option.errors?.pattern">This field contains unsupported characters!</span>
                            </mat-hint>
                        </mat-form-field>
                        <button mat-icon-button *ngIf="form.value.questionType==='Multiple Choice' || form.value.questionType==='Single Choice'"
                            (click)="openColourPalette(i)" [style.background]="form.value.optionColours[i]"
                            ><mat-icon>palette</mat-icon>
                        </button>
                        <div fxLayout="column" class="checkbox-padding"
                            *ngIf="form.value.questionType==='Multiple Choice' || form.value.questionType==='Single Choice'">
                            <div fxLayout="row" >
                                <mat-checkbox color="primary" [formControl]="form.controls.optionIsOther.controls[i]">Use as 'Other' option</mat-checkbox>
                                <mat-icon matTooltip="Check this box if you would like the participant to be able to add a custom free text response when this option is selected">
                                info</mat-icon>
                            </div>
                            <div fxLayout="row">
                                <mat-checkbox color="primary" [formControl]="form.controls.optionIsTerminating.controls[i]">Terminating option</mat-checkbox>
                                <mat-icon matTooltip="Check this box if the round should end when a participant selects this option">
                                info</mat-icon>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <!-- Numeric field options -->
                <div *ngIf="form.value.questionType==='Numeric'">
                    <mat-form-field class="padRight">
                        <mat-label>Units</mat-label>
                        <input matInput formControlName="unit_label" maxlength="20">
                        <mat-hint>
                            <span class="warn-fore" [hidden]="!form.controls.unit_label.errors?.pattern">This field contains unsupported characters!</span>
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field class="padRight">
                        <mat-label>Max number of decimal places</mat-label>
                        <input matInput formControlName="num_dps" type="number">
                    </mat-form-field>
                    <mat-form-field class="padRight">
                        <mat-label>Minimum value</mat-label>
                        <input matInput formControlName="min_value" type="number">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Maximum value</mat-label>
                        <input matInput formControlName="max_value" type="number">
                    </mat-form-field>
                </div>
                
                <!-- Use note field -->
                <div class="questionPad" *ngIf="form.value.questionType!=='Free Text'">
                    <mat-checkbox color="primary" formControlName="note_use">Include a notes field?</mat-checkbox>
                </div>
                
                
                <div fxLayout="column">
                    <!-- Required checkbox -->
                    <mat-checkbox color="primary" formControlName="required">Is this question required?</mat-checkbox>

                    <!-- Use filter checkbox -->
                    <!-- <mat-checkbox *ngIf="form.value.questionType!=='Free Text'" color="primary" formControlName="filter_use">Use responses to this question to filter responses to other questions?</mat-checkbox> -->
                </div>
                
                </mat-card-content>
            </mat-card>
        </div>

        <!-- Question Preview -->

        <div class="card" fxFlex="49%" fxFlex.lt-md="100%">
        <mat-card>
            <mat-card-header>
            <mat-card-title>Question Preview</mat-card-title>
            <mat-card-subtitle>The question will appear as below for participants</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content *ngIf="project | async as project else processAsync">
            <div class="questionPad" *ngIf="form.invalid">
                <a>
                Complete the missing values in the Question Options panel to see the preview!
                </a>
            </div>

            <div *ngIf="form.valid">
                <app-display-freetext *ngIf="form.value.questionType === 'Free Text'"
                    [useSkip1]="project.data.opt_out1_use"
                    [useSkip2]="project.data.opt_out2_use"
                    [skipLabel1]="project.data.opt_out_label1"
                    [skipLabel2]="project.data.opt_out_label2"
                    [client_colour_primary]="project.data.client_colour_primary"
                    [client_colour_secondary]="project.data.client_colour_secondary"
                    [required]="form.value.required"
                    [question]="form.value.questionTitle"
                    [show_header]="true"
                    [section_id]="form.value.section"
                    [question_instructions]="form.value.questionInstructions"
                ></app-display-freetext>
                <app-display-numeric *ngIf="form.value.questionType === 'Numeric'" 
                    [useSkip1]="project.data.opt_out1_use"
                    [useSkip2]="project.data.opt_out2_use"
                    [skipLabel1]="project.data.opt_out_label1"
                    [skipLabel2]="project.data.opt_out_label2"
                    [client_colour_primary]="project.data.client_colour_primary"
                    [client_colour_secondary]="project.data.client_colour_secondary"
                    [question]="form.value.questionTitle"
                    [show_header]="true"
                    [minimum]="form.value.min_value"
                    [maximum]="form.value.max_value"
                    [decimalPlaces]="form.value.num_dps"
                    [units]="form.value.unit_label"
                    [useNote]="form.value.note_use"
                    [required]="form.value.required"
                    [section_id]="form.value.section"
                    [question_instructions]="form.value.questionInstructions"
                ></app-display-numeric>
                <app-display-mcq *ngIf="form.value.questionType === 'Multiple Choice'" 
                    [useSkip1]="project.data.opt_out1_use"
                    [useSkip2]="project.data.opt_out2_use"
                    [skipLabel1]="project.data.opt_out_label1"
                    [skipLabel2]="project.data.opt_out_label2"
                    [client_colour_primary]="project.data.client_colour_primary"
                    [client_colour_secondary]="project.data.client_colour_secondary"
                    [options]="questionOptions"
                    [question]="form.value.questionTitle"
                    [show_header]="true"
                    [useNote]="form.value.note_use"
                    [required]="form.value.required"
                    [section_id]="form.value.section"
                    [question_instructions]="form.value.questionInstructions"
                ></app-display-mcq>
                <app-display-ranking *ngIf="form.value.questionType === 'Ranking'" 
                    [useSkip1]="project.data.opt_out1_use"
                    [useSkip2]="project.data.opt_out2_use"
                    [skipLabel1]="project.data.opt_out_label1"
                    [skipLabel2]="project.data.opt_out_label2"
                    [client_colour_primary]="project.data.client_colour_primary"
                    [client_colour_secondary]="project.data.client_colour_secondary"
                    [options]="questionOptions"
                    [top_label]="form.value.top_label" 
                    [bottom_label]="form.value.bottom_label"
                    [question]="form.value.questionTitle"
                    [show_header]="true"
                    [useNote]="form.value.note_use"
                    [required]="form.value.required"
                    [section_id]="form.value.section"
                    [question_instructions]="form.value.questionInstructions"
                ></app-display-ranking>
                <app-display-scq *ngIf="form.value.questionType === 'Single Choice'" 
                    [useSkip1]="project.data.opt_out1_use"
                    [useSkip2]="project.data.opt_out2_use"
                    [skipLabel1]="project.data.opt_out_label1"
                    [skipLabel2]="project.data.opt_out_label2"
                    [client_colour_primary]="project.data.client_colour_primary"
                    [client_colour_secondary]="project.data.client_colour_secondary"
                    [options]="questionOptions"
                    [question]="form.value.questionTitle"
                    [show_header]="true"
                    [useNote]="form.value.note_use"
                    [required]="form.value.required"
                    [section_id]="form.value.section"
                    [question_instructions]="form.value.questionInstructions"
                ></app-display-scq>
            </div>
            </mat-card-content>
            <!-- <mat-card-actions fxLayout="row">
            <span class="flex-spacer"></span>
            <div fxLayout="column" fxLayoutAlign="center center">
                <button mat-button>PREVIOUS</button>
                <mat-hint>(Qu 1)</mat-hint>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
                <button mat-button color="primary">NEXT</button>
                <mat-hint>(Qu 2)</mat-hint>
            </div>
            </mat-card-actions>   -->
        </mat-card>
        </div>
    </div> 
</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>
  