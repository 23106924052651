export const ColourPairs = [
    {shade: '#ec407a', light: '#ff77a9'},
    {shade: '#ef5350', light: '#ff867c'},
    {shade: '#ff7043', light: '#ffa270'},
    {shade: '#ffa726', light: '#ffd95b'},
    {shade: '#ffee58', light: '#ffff8b'},
    {shade: '#9ccc65', light: '#cfff95'},
    {shade: '#66bb6a', light: '#98ee99'},
    {shade: '#26a69a', light: '#64d8cb'},
    {shade: '#26c6da', light: '#6ff9ff'},
    {shade: '#42a5f5', light: '#80d6ff'},
    {shade: '#5c6bc0', light: '#8e99f3'},
    {shade: '#7e57c2', light: '#b085f5'},
    {shade: '#ab47bc', light: '#df78ef'}
];

export const ColourShades = [
    '#ec407a', '#ef5350', '#ff7043', '#ffa726', '#ffee58', '#9ccc65', 
    '#66bb6a', '#26a69a', '#26c6da', '#42a5f5', '#5c6bc0', '#7e57c2', '#ab47bc'
];