import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class QuestionOptionsService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getLikertOptions(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/likert_options')
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addLikertOption(project_id, round_id, label, position, consensus_group, colour?): Observable<any> {
    const content = {
      label: label,
      position: position,
      consensus_group: consensus_group,
      colour: colour
    };
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/likert_options', content)
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteLikertOptions(project_id, round_id): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/likert_options')
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addProfilingOption(project_id, question_id, label, position, is_other, is_terminating, colour?): Observable<any> {
    const content = {
      label: label,
      position: position,
      colour: colour,
      is_other: is_other,
      is_terminating: is_terminating
    };
    return this.http.post(baseURL + '/projects/' + project_id + '/questions/' + question_id + '/profiling_options', content)
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteProfilingOptions(project_id, question_id): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/questions/' + question_id + '/profiling_options')
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  addQuestionOption(project_id, round_id, question_id, label, position, is_other, colour?): Observable<any> {
    const content = {
      label: label,
      position: position,
      colour: colour,
      is_other
    };
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/options', content)
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  deleteQuestionOptions(project_id, round_id, question_id): Observable<any> {
    return this.http.delete(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/questions/' + question_id + '/options')
    .pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  importProfilingOptions(options: any[], project_id): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/import_profiling_options/', options).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  importQuestionOptions(options: any[], project_id, round_id): Observable<any> {
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/import_options/', options).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

}
