<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<!-- FILTER BAR -->
<mat-toolbar>
    <mat-toolbar-row [formGroup]="form" autocomplete="off">
        <mat-form-field class="form-control" *ngIf="rounds | async as rounds else processAsync">
            <mat-label>Round</mat-label>
            <mat-select formControlName="round" required multiple>
                <mat-option-select-all></mat-option-select-all>
                <mat-option *ngFor="let item of rounds.data" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
            <mat-hint>
                <span *ngIf="form.controls.round.errors?.required && form.controls.round.touched"
                    class="warn-fore">Please select at least one round</span>
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control" *ngIf="participants | async as participants else processAsync">
            <mat-label>Participant</mat-label>
            <mat-select formControlName="participant" required multiple>
                <mat-option-select-all></mat-option-select-all>
                <mat-option *ngFor="let item of participants.data" [value]="item.participant">
                    {{item.alias}}
                </mat-option>
            </mat-select>
            <mat-hint>
                <span *ngIf="form.controls.participant.errors?.required && form.controls.participant.touched"
                    class="warn-fore">Please select at least one participant</span>
            </mat-hint>
        </mat-form-field>
        <button mat-icon-button [disabled]="form.invalid" (click)="applyFilter()"><mat-icon>filter_alt</mat-icon></button>
    </mat-toolbar-row>
</mat-toolbar>

<!-- TABLE -->
<div class="table-container">
<table mat-table [dataSource]="tableData" *ngIf="showTable" matSort matSortActive={{sortField}} matSortDirection={{direction}} multiTemplateDataRows>

    <ng-container matColumnDef="round">
        <th mat-header-cell *matHeaderCellDef>Round</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <a>{{item.round}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="participant">
        <th mat-header-cell *matHeaderCellDef>Participant</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
          <a>{{item.alias}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef>Response</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <div *ngIf="!item.opt_out1 && !item.opt_out2">
                <a *ngIf="item.question_type==='Free Text'">{{item.text_value}}</a>
                <a *ngIf="item.question_type==='Likert'">{{item.likert_value}}</a>
                <a *ngIf="item.question_type==='Multiple Choice'">{{item.options}}
                    <a *ngIf="item.text_value">[{{item.text_value}}]</a>
                </a>
                <a *ngIf="item.question_type==='Numeric'">{{item.numeric_value}} {{item.unit_label}}</a>
                <a *ngIf="item.question_type==='Ranking'"><b>{{item.top_label}} to {{item.bottom_label}}: </b>{{item.options}}</a>
                <a *ngIf="item.question_type==='Single Choice'">{{item.options}}
                    <a *ngIf="item.text_value">[{{item.text_value}}]</a>
                </a>
            </div>
            <div *ngIf="project | async as project else processAsync">
                <a *ngIf="item.opt_out1"><i>"{{project.data.opt_out_label1}}"</i></a>
                <a *ngIf="item.opt_out2"><i>"{{project.data.opt_out_label2}}"</i></a>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let item; let n = dataIndex;">
            <button mat-button *ngIf="item.note" (click)="expand_contract(item)">
                Click to expand note
            </button>
          <a *ngIf="!item.note">-</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item;">
            <button mat-icon-button matTooltip="Click to expand note"><mat-icon>note</mat-icon></button>
        </td>
    </ng-container>

    <!-- Expanded note row -->
    <ng-container matColumnDef="expandedElement">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <a class="element-description"><b>Note: </b>{{element.note}}</a>
            </div>  
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
        class="element-row"
        [class.expanded-row]="expandedElement === row"
    ></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: ['expandedElement']" class="detail-row"></tr>
</table>
</div>
<!-- <mat-paginator 
  [length]="numRecords"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20]" 
  showFirstLastButtons
></mat-paginator> -->

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>