<div class="figure">
  <canvas baseChart 
  [datasets]="chartData"
  [labels]="chartLabels"
  [options]="chartOptions"
  [plugins]="chartPlugins"
  [legend]="chartLegend"
  [colors]="chartColors"
  [chartType]="chartType">
</canvas>
</div>
