<h2>Group Responses</h2>
<app-question-response-figure
    *ngIf="chartData | async as chartData else processAsync"
    [barcolour]="data.barcolour"
    [chartData]="chartData.data"
    [questionType]="data.question.question_type"
></app-question-response-figure>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>