<mat-toolbar class="menubar">
    <span>Contact Us for Help</span>
</mat-toolbar>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<mat-dialog-content [formGroup]="form" fxLayout="column" autocomplete="off">
    <p>Please use the form below to contact the Costello Medical project team, or alternatively <a href="mailto:{{data.contact}}">send us an email</a>.</p>
    <mat-form-field fxFlex="95%">
        <textarea matInput class="message" type="text" formControlName="message" placeholder="Enter your message here..." required></textarea>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.message.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <span class="flex-spacer"></span>
    <button mat-flat-button color="accent" (click)="close()">Cancel</button>
    <button cdkFocusInitial type="submit" mat-flat-button class="button" 
        (click)="send()" [disabled]="form.invalid">Send</button>
</mat-dialog-actions>