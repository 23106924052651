<mat-toolbar>
    <span>Cookie Preferences</span>
    <span class="cookie-spacer"></span>
    <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
</mat-toolbar>

<mat-dialog-content>
    <p>Options for configuring the types of cookies used by this website are given below. For more information about the cookies this site uses, please refer to our <a href="/cookie-statement">Cookie Statement</a>. You can return to amend these options at any time via the Cookie Statement link in the website footer.</p>
    <div class="policy-header">
        <h3><b>Strictly necessary - required for the site to work and to provide services to you</b></h3>
        <span class="cookie-spacer"></span>
        <mat-checkbox [checked]="true" [disabled]="true">Allow</mat-checkbox>
    </div>
    
    <p>These cookies allow us to record your login information</p>

    <!-- <mat-divider class="policy-divider"></mat-divider>

    <div class="policy-header">
        <h3><b>Improve your experience - allows us to show what is relevant to you</b></h3>
        <span class="cookie-spacer"></span>
        <mat-checkbox [(ngModel)]="enableOptional">Allow</mat-checkbox>
    </div>

    <p>These cookies enable us to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we use.</p> -->
</mat-dialog-content>

<mat-dialog-actions>
    <div fxLayout="row" fxFlex="100%">
        <span class="cookie-spacer"></span>
        <button mat-stroked-button cdkFocusInitial (click)="save()">Submit</button>
    </div>
</mat-dialog-actions>