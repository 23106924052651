<mat-toolbar *ngIf="project | async as project else processAsync" 
    class="header">
    <mat-toolbar-row class="menubar">
        <a fxShow fxShow.xs="false">COSTELLO MEDICAL DELPHI APP</a>
        <button fxHide fxShow.xs="true" fxFlexAlign="center start" mat-icon-button routerLink="/panel/{{project_id}}"
            ><mat-icon>home</mat-icon></button>
        <a fxHide fxShow.xs="true">DELPHI</a>
        <!--sign in status and button-->
        <span class="flex-spacer"></span>
        <a *ngIf="username" fxShow fxShow.xs="false">{{username}}</a>
        <a *ngIf="username" fxShow fxShow.xs="false" mat-button (click)="logOut()">Logout</a>
        <button mat-icon-button fxHide fxShow.xs="true" (click)="logOut()" matTooltip="Log out of the app">
            <mat-icon aria-label="Log out">exit_to_app</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Manage your account" routerLink="/my_account">
            <mat-icon aria-label="Manage Account">account_circle</mat-icon>
        </button>
        <button mat-icon-button (click)="help()" matTooltip="Contact the project team for help">
            <mat-icon aria-label="Get help">help</mat-icon>
        </button>
    </mat-toolbar-row>
    <mat-toolbar-row color="accent" fxShow fxShow.xs="false">
        <button fxFlexAlign="center start" mat-icon-button routerLink="/panel/{{project_id}}"
        ><mat-icon>home</mat-icon></button>
        <a fxFlexAlign="center center">{{project.data.name}}</a>
    </mat-toolbar-row>
</mat-toolbar>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>