<div fxLayout="column" fxLayoutAlign="center center">
  <h3>{{chartData.name}} (N={{chartData.num_responses}})</h3>
  <div class="figure" *ngIf="total > 0">
    <canvas baseChart
      [datasets]="formattedData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [plugins]="chartPlugins"
      [legend]="chartLegend"
      [colors]="chartColors"
      [chartType]="chartType">
    </canvas>
  </div>
  <a *ngIf="total===0" class="no-responses">
    There are no responses for this round
  </a>
</div>