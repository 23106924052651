import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { baseURL } from '../_shared/baseurl';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfilingResponseService {

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) { }

  getQuestionResponses(project_id, question_number, participant='{"%"}', round='{"%"}'): Observable<any> {
    let params = new HttpParams()
    .set('participant', participant)
    .set('round', round);

    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_questions/' + question_number + '/responses', {params}).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  getParticipantResponses(project_id, round_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/profiling_questions/participants/responses').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveMultipleChoiceResponse(project_id, question_id, round_id, 
      response_value, other_value, opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      response_value: response_value,
      other_value: other_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/profiling_questions/' + question_id + '/participant/multiple_choice', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveNumericResponse(project_id, question_id, round_id, response_value,
      opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };
    
    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/profiling_questions/' + question_id + '/participant/numeric', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveRankingResponse(project_id, question_id, round_id, response_value,
      opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/profiling_questions/' + question_id + '/participant/ranking', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveSingleChoiceResponse(project_id, question_id, round_id, 
      response_value, other_value, opt_out1, opt_out2, note?): Observable<any> {
    const content = {
      response_value: response_value,
      other_value: other_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2,
      note: note
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/profiling_questions/' + question_id + '/participant/single_choice', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  saveTextResponse(project_id, question_id, round_id, response_value, opt_out1, opt_out2) : Observable<any> {
    const content = {
      response_value: response_value,
      opt_out1: opt_out1,
      opt_out2: opt_out2
    };

    return this.http.post(baseURL + '/projects/' + project_id + '/rounds/' + round_id + '/profiling_questions/' + question_id + '/participant/free_text', content).pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  multipleChoiceSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_response_summary/' + question_id + '/mcq').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  numericSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_response_summary/' + question_id + '/numeric').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  rankingSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_response_summary/' + question_id + '/ranking').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }

  singleChoiceSummary(project_id, question_id): Observable<any> {
    return this.http.get(baseURL + '/projects/' + project_id + '/profiling_response_summary/' + question_id + '/scq').pipe(
      catchError(error => this.processHTTPMsgService.handleError(error))
    );
  }
}
