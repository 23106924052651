<!-- PAGE HEADER -->
<div class="toolbar accent-back" 
    fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
    <h3>Round Options</h3>
</div>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<!-- OPTIONS PANEL -->
<div fxHide.lt-sm="true" *ngIf="round | async as round else processAsync" [formGroup]="form" autocomplete="off">
    <div fxLayout="row">
        <mat-form-field class="form-control name-field">
            <input matInput type="text" formControlName="name" placeholder="Round Name" maxlength="30" required>
            <mat-hint>
                <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                    class="warn-fore">Please enter a name for the round</span>
                <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control name-field">
            <input matInput type="text" formControlName="zinc_code" placeholder="Copy Approval Code" maxlength="30">
            <mat-icon matSuffix matTooltip="This code will appear in the footer section on all pages visible to the participant (except for the login page)">help</mat-icon>
            <mat-hint>
                <span class="warn-fore" [hidden]="!form.controls.zinc_code.errors?.pattern">This field contains unsupported characters!</span>
            </mat-hint>
        </mat-form-field>
    </div>
    
    <mat-table mat-table [dataSource]="TABLE_DATA" class="form-table">
        <ng-container matColumnDef="question_type">
            <mat-header-cell *matHeaderCellDef>Question Type</mat-header-cell>
            <mat-cell *matCellDef="let item; let n = index;">
              <a>{{item.question_type}}</a>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="use">
            <mat-header-cell *matHeaderCellDef>Use in Round?</mat-header-cell>
            <mat-cell *matCellDef="let item; let n = index;">
              <mat-checkbox formControlName="{{item.use}}" color="primary"></mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="consensus">
            <mat-header-cell *matHeaderCellDef>Calculate Consensus?</mat-header-cell>
            <mat-cell *matCellDef="let item; let n = index;">
                <a *ngIf="item.consensus==='N/A'">N/A</a>
                <mat-checkbox *ngIf="item.consensus !== 'N/A'" 
                    formControlName="{{item.consensus}}" color="primary"></mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="threshold">
            <mat-header-cell *matHeaderCellDef>Consensus Threshold</mat-header-cell>
            <mat-cell *matCellDef="let item; let n = index;">
                <a *ngIf="item.threshold==='N/A'">N/A</a>
                <mat-form-field *ngIf="item.threshold!=='N/A'">
                    <input matInput type="number" formControlName="{{item.threshold}}">
                </mat-form-field>
                <div *ngIf="item.threshold!=='N/A'">
                    <mat-icon color="warn" *ngIf="form.controls[item.threshold].errors?.required">error</mat-icon>
                </div>
                <!-- <mat-icon *ngIf="item.threshold!=='N/A'" matTooltip="{{item.guidance}}">help</mat-icon> -->
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item; let n=index;">
                <button mat-icon-button *ngIf="item.question_type==='Likert'" color="primary" (click)="configureLikert()">
                    <mat-icon>settings</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div fxLayout="row" fxLayoutAlign="end" class="footer-pad save-button form-control"> 
        <button mat-flat-button color="primary" aria-label="Add project" [disabled]="form.invalid || form.pristine"
            (click)="save()">
            Save Changes
        </button>
    </div>
</div>
<!-- Alternative prompt for mobile users -->
<div fxHide.gt-xs="true" fxLayout="column" fxLayoutAlign="center center" class="mobile-note" [formGroup]="form" >
    <mat-form-field class="form-control small-name-field">
        <input matInput type="text" formControlName="name" placeholder="Name" maxlength="30" required>
        <mat-hint>
            <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                class="warn-fore">Please enter a name for the round</span>
            <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-form-field class="form-control small-name-field">
        <input matInput type="text" formControlName="zinc_code" placeholder="Copy Approval Code" maxlength="30">
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.zinc_code.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>
    <mat-icon>screen_rotation</mat-icon>
    <a class="rotate-note">Rotate screen for question options!</a>
    <div fxLayout="row" fxLayoutAlign="end" class="footer-pad save-button"> 
        <button mat-flat-button color="primary" aria-label="Add project" [disabled]="form.invalid || form.pristine" 
            (click)="save()">
            Save Changes
        </button>
    </div>
</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>