import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { PolicyModalComponent, PolicySheetComponent } from '../policy-modal/policy-modal.component';

@Component({
  selector: 'app-cookie-statement',
  templateUrl: './cookie-statement.component.html',
  styleUrls: ['./cookie-statement.component.scss']
})
export class CookieStatementComponent implements OnInit {

  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private location: Location
    ) { }

  ngOnInit(): void {
  }

  cookiePreferences() {
    if(window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(PolicySheetComponent);
    } else {
      const Ref = this.dialog.open(PolicyModalComponent, {width: '800px'});
    }
  }

  goBack() {
    this.location.back();
  }

}
