<div fxLayout="row" fxLayout.lt-md="column">
  <!-- Left Panel -->
  <div
    class="accent-back"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="50%"
    fxFlex.lt-md="100%"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-md="center center"
      class="left-panel"
    >
      <a class="heading dark-grey">COSTELLO MEDICAL</a>
      <a class="name dark-grey">DELPHI APP</a>
      <a fxShow fxShow.xs="false" class="underline">-</a>
    </div>
  </div>
  <!-- Right Panel -->
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxFlex="50%"
    fxFlex.lt-md="100%"
  >
    <div fxLayout="column" fxLayoutAlign="center center" class="half-panel">
      <form
        [formGroup]="loginForm"
        autocomplete="off"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-form-field class="form-control">
          <input
            matInput
            placeholder="Email"
            type="email"
            id="email"
            formControlName="email"
            email
            required
          />
          <mat-hint>
            <span
              *ngIf="
                loginForm.controls.email.errors?.email &&
                !loginForm.controls.email.pristine
              "
              >Please enter a valid email address</span
            >
          </mat-hint>
        </mat-form-field>
        <mat-form-field
          class="form-control"
          *ngIf="!loginForm.value.forgottenPassword"
        >
          <input
            matInput
            placeholder="Password"
            type="password"
            id="password"
            formControlName="password"
            required
          />
          <mat-hint>
            <span
              *ngIf="
                loginForm.controls.password.errors?.email &&
                !loginForm.controls.password.pristine
              "
              >Please enter a password</span
            >
          </mat-hint>
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          id="submit"
          *ngIf="!loginForm.value.forgottenPassword && showForm"
          [disabled]="loginForm.invalid"
          class="button"
          (click)="signIn()"
        >
          Log In
        </button>
        <mat-spinner
          color="primary"
          diameter="35"
          *ngIf="!showForm"
        ></mat-spinner>
        <button
          *ngIf="!loginForm.value.forgottenPassword"
          type="button"
          mat-button
          (click)="forgottenPassword()"
        >
          Forgot password?
        </button>
        <div *ngIf="loginForm.value.forgottenPassword">
          <button
            mat-flat-button
            color="accent"
            class="button"
            (click)="cancelReset()"
          >
            Cancel
          </button>
          <button
            mat-flat-button
            color="primary"
            [disabled]="loginForm.controls.email.invalid"
            class="button"
            (click)="resetPassword()"
          >
            Reset Password
          </button>
        </div>
      </form>
      <a
        *ngIf="errMsg"
        class="warn-fore error-message"
        fxLayout="row"
        fxLayoutAlign="center"
        >{{ errMsg }}</a
      >
      <!-- <a
        class="footnote dark-grey error-message"
        style="font-size: small; text-align: center"
        >© {{ year }} Costello Medical Consulting Limited</a
      > -->
    </div>
  </div>
</div>
