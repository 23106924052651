<div fxLayout="column" fxLayoutAlign="center center">
  <!-- ERROR BANNER -->
  <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
    ><b>Error:&nbsp;</b> {{ errMsg }}</a
  >

  <div
    fxLayout="row"
    fxLayoutAlign="center start"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start center"
    *ngIf="confirmation | async as confirmation; else processAsync"
    class="page-padding"
  >
    <!-- DELPHI PANEL INFORMATION -->
    <div
      fxFlex="60%"
      class="panel-info"
      *ngIf="dashContent | async as dashContent; else processAsync"
    >
      <h2 class="name">Introduction to the Study</h2>
      <p>
        <b>{{ dashContent.data.participant_disclaimer }}</b> Thank you for
        participating in this study.
      </p>

      <div *ngFor="let paragraph of dashContent.data.content">
        <h3 class="subheader" *ngIf="paragraph">{{ paragraph.header }}</h3>
        <p *ngIf="paragraph" class="wrap-text">{{ paragraph.content }}</p>
      </div>

      <div>
        <p>
          Your personal data will be used only for the purposes of administering
          the questionnaire(s) in this study, or for following up in the context
          of adverse event reporting if applicable, and will be deleted from our
          records once the study is complete. Your responses will remain
          anonymous except to the questionnaire administrators.
        </p>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-checkbox [formControl]="confirm">
          <b
            >I confirm that I have understood the instructions provided above
            and agree to continue on this basis</b
          >
        </mat-checkbox>
        <button
          mat-flat-button
          class="confirm-button"
          fxFlexAlign="center"
          *ngIf="!confirmation.data || confirmation.data.confirmed === false"
          [disabled]="confirm.value === false"
          (click)="confirmParticipation()"
        >
          Confirm
        </button>
      </div>
    </div>

    <!-- ROUND LIST -->
    <div
      fxFlex="40%"
      fxFlex.lt-md="100%"
      fxLayout="column"
      class="list"
      *ngIf="confirmation.data && confirmation.data.confirmed === true"
    >
      <h2 class="name" fxLayoutAlign="center">Rounds</h2>
      <mat-list *ngIf="rounds | async as rounds; else processAsync" role="list">
        <mat-list-item
          *ngFor="let round of rounds.data; let i = index"
          role="listitem"
          class="item"
        >
          <a class="name"
            ><b>{{ round.name }}</b></a
          >
          <mat-chip-list style="padding-left: 10px">
            <mat-chip [class.active-chip]="round.status === 'Open'">{{
              round.status
            }}</mat-chip>
          </mat-chip-list>
          <span class="flex-spacer"></span>
          <div
            *ngIf="round.status !== 'Closed'"
            fxShow
            fxHide.lt-sm="true"
            class="progress"
            fxLayout="column"
          >
            <a *ngIf="round.deadline" class="name"
              ><b>Deadline: {{ getDeadline(round.deadline) }}</b></a
            >
            <a *ngIf="round.profiling_failed">Ineligible to participate</a>
            <a *ngIf="!round.profiling_failed && !round.completed"
              >In progress</a
            >
            <a *ngIf="round.completed">Completed</a>
          </div>

          <button
            mat-flat-button
            color="primary"
            *ngIf="round.status === 'Open'"
            (click)="respond(round)"
          >
            Respond
          </button>
          <div *ngIf="round.status !== 'Open'" style="width: 88.05px"></div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div>
      <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>
