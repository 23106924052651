import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-section-add-modal',
  templateUrl: './section-add-modal.component.html',
  styleUrls: ['./section-add-modal.component.scss']
})
export class SectionAddModalComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SectionAddModalComponent>,
    private fb: UntypedFormBuilder,
  ) { 
    this.form = fb.group({
      name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\/^\\^\@^\^^;^\`^#]*$')]],
      instructions: ['', Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')],
      details: ['', Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')]
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.form.value);
  }

}
