
    <div [formGroup]="form" autocomplete="off" fxLayout="column">
        <a *ngIf="errMsg" class="warn-fore"><b>Error:&nbsp;</b> {{errMsg}}</a>
        <mat-form-field class="form-control">
            <input matInput type="text" formControlName="name" placeholder="Project Title" maxlength="50" required>
            <mat-hint>
                <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                    class="warn-fore">Please enter a project title</span>
                <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control">
            <input matInput type="text" formControlName="project_code" placeholder="Project Code" maxlength="20">
            <mat-hint>
                <span class="warn-fore" [hidden]="!form.controls.project_code.errors?.pattern">This field contains unsupported characters!</span>
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control">
            <mat-label>Client Company</mat-label>
            <mat-select formControlName="clientCompany" required>
                <mat-option *ngFor="let company of companies" [value]="company.id">
                    {{company.name}}
                </mat-option>
            </mat-select>
            <mat-hint>
                <span *ngIf="form.controls.clientCompany.errors?.required && form.controls.clientCompany.touched"
                    class="warn-fore">Please select an option</span>
            </mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="form.value.clientCompany ===-1">
            <input matInput type="text" formControlName="newClientCompanyName" placeholder="New Client Company Name" 
                maxlength="30" [required]="form.value.clientCompany ===-1">
            <mat-hint>
                <span *ngIf="form.controls.newClientCompanyName.errors?.required && form.controls.newClientCompanyName.touched"
                    class="warn-fore">Please enter the name of the new company</span>
                <span class="warn-fore" [hidden]="!form.controls.newClientCompanyName.errors?.pattern">This field contains unsupported characters!</span>
            </mat-hint>
        </mat-form-field>  
        <mat-form-field class="form-control">
            <input matInput type="text" formControlName="diseaseArea" placeholder="Disease Area">
            <mat-hint>
                <span class="warn-fore" [hidden]="!form.controls.diseaseArea.errors?.pattern">This field contains unsupported characters!</span>
            </mat-hint>
        </mat-form-field>  
        <mat-checkbox formControlName="hideIncompleteResults" color="primary" class="form-control">
            Hide consensus results from open rounds</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" > 
        <button mat-fab color="primary" aria-label="Add project" [disabled]="form.invalid" (click)="save()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
