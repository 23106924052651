<mat-toolbar color="warn" fxLayoutAlign="center center">
  <span>Open Round for Responses?</span>
</mat-toolbar>
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
  ><b>Error:&nbsp;</b> {{ errMsg }}</a
>

<div *ngIf="roundChecks | async as roundChecks; else processAsync">
  <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="roundChecks.passed_check === false">
      <p>You need to correct the following before the round can be opened:</p>
      <ul>
        <li *ngIf="roundChecks.data.num_questions === 0">
          Add at least one question
        </li>
        <li *ngIf="roundChecks.data.num_participants === 0">
          Add at least one participant
        </li>
        <li *ngIf="roundChecks.data.missing_profiling_opts !== '0'">
          Ensure all multiple choice, ranking and single choice profiling
          questions have options
        </li>
        <li
          *ngIf="
            roundChecks.data.num_likert !== '0' &&
            roundChecks.data.likert_opts === 0
          "
        >
          Add likert options (Options page from round dashboard)
        </li>
        <li *ngIf="roundChecks.data.missing_opts !== '0'">
          Ensure all multiple choice, ranking and single choice questions have
          options
        </li>
      </ul>
    </div>
    <div *ngIf="roundChecks.passed_check === true">
      <p>
        Once the round has been opened it will be visible to participants and it
        will no longer be possible to edit questions in the round.
      </p>
      <p *ngIf="roundChecks.data.opened_rounds === 0">
        Please check you are happy with the project name and use of 'opt out'
        options in the project <b>before opening this round</b> as you will not
        be able to edit them after it has been opened.
      </p>
      <p
        *ngIf="
          roundChecks.data.opened_rounds === 0 &&
          roundChecks.data.num_profiling === 0
        "
      >
        Please also note that profiling questions can't be created once this
        round has been opened.
      </p>
      <p
        *ngIf="
          roundChecks.data.opened_rounds === 0 &&
          roundChecks.data.num_profiling > 0
        "
      >
        Please also note that profiling questions can't be changed once this
        round has been opened.
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxHide.lt-sm>
    <button
      mat-flat-button
      color="accent"
      (click)="close(false)"
      cdkFocusInitial
    >
      Cancel
    </button>
    <button
      type="submit"
      mat-flat-button
      color="warn"
      [disabled]="roundChecks.passed_check === false"
      (click)="close(true)"
    >
      Open Round
    </button>
  </mat-dialog-actions>

  <div fxHide.gt-xs fxLayout="row" fxLayoutAlign="center center">
    <button
      mat-mini-fab
      color="accent"
      cdkFocusInitial
      (click)="close(false)"
      class="fab-button"
    >
      <mat-icon>close</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="warn"
      (click)="close(true)"
      class="fab-button"
      [disabled]="roundChecks.passed_check === false"
    >
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div>
      <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>
