import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, pairwise, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { ProfilingQuestionService } from '../../../_services/profiling-question.service';
import { QuestionOptionsService } from '../../../_services/question-options.service';
import { QuestionService } from '../../../_services/question.service';
import { ResponseService } from '../../../_services/response.service';
import { RoundService } from '../../../_services/round.service';

@Component({
  selector: 'app-participant-question-toolbar',
  templateUrl: './participant-question-toolbar.component.html',
  styleUrls: ['./participant-question-toolbar.component.scss'],
})
export class ParticipantQuestionToolbarComponent implements OnInit, OnDestroy {
  project_id = this.route.parent.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_num = this.route.snapshot.paramMap.get('question_num');

  question: Observable<any>;
  responseHistory: Observable<any>;
  existingResponse: Observable<any>;
  likertOptions: Observable<any>;
  profilingQuestions: Observable<any>;
  retrievedQuestions = [];
  required: boolean;
  calculate_consensus: boolean;
  question_type: string;
  question_id: number;
  num_questions = 1;
  progress = 0;
  invalid = false;

  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private router: Router,
    private profilingQuestionService: ProfilingQuestionService,
    private questionService: QuestionService,
    private questionOptionsService: QuestionOptionsService,
    private responseService: ResponseService,
    private roundService: RoundService
  ) {}

  ngOnInit(): void {
    this.responseHistory = this.responseService
      .getResponseHistory(this.project_id, this.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading response history: ', error);
          this.loadingError$.next(true);
          return of();
        })
      );

    this.responseHistory.pipe(first()).subscribe(
      (history) => {
        this.num_questions = history.data.num_questions;
        // Check if they have passed profiling criteria (or there are none)
        if (history.data.profiling_passed === false) {
          this.retrievedQuestions = [];
          this.watchRouteParams();
          // Check if they have started answering main questions
        } else if (history.data.response_history !== null) {
          this.retrievedQuestions = history.data.response_history;
          //watch for changes in route params
          this.watchRouteParams();

          //navigate to last question
          if (
            this.question_num !== '1' &&
            this.route.snapshot.url[2].path !== 'background_questions' &&
            history.data.admin !== true
          ) {
            this.getNext(
              this.retrievedQuestions[this.retrievedQuestions.length - 1].num
            );
          }
        } else {
          this.retrievedQuestions = [{ num: '1' }];
          //watch for changes in route params
          this.watchRouteParams();
        }
      },
      (error) => {
        this.errMsg = error;
      }
    );

    this.likertOptions = this.questionOptionsService
      .getLikertOptions(this.project_id, this.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading project:', error);
          this.loadingError$.next(true);
          return of();
        })
      );

    this.profilingQuestions = this.profilingQuestionService
      .getProfilingQuestions(this.project_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading project:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
  }

  watchRouteParams() {
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      this.round_id = params.round_id;
      this.question_num = params.question_num;
      if (this.route.snapshot.url[2].path !== 'background_questions') {
        this.getQuestion(this.question_num.toString());
        this.progress = (100 * Number(this.question_num)) / this.num_questions;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  getQuestion(question_number) {
    this.question = this.questionService
      .getParticipantQuestion(this.project_id, this.round_id, question_number)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading question:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.question.pipe(first()).subscribe(
      (question) => {
        if (question.data) {
          this.required = question.data.required;
          this.calculate_consensus = question.data.calculate_consensus;
          this.question_type = question.data.question_type;
          this.question_id = question.data.id;
          this.getResponse(question.data.id, question.data.question_type);
        } else {
          // redirect to home page
          this.router.navigate([
            '/panel/' + this.project_id + '/round/' + this.round_id + '/end',
          ]);
        }
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  getResponse(question_id, question_type) {
    this.existingResponse = this.responseService
      .getResponse(this.project_id, this.round_id, question_id, question_type)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading question:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
  }

  onFormChange(validity) {
    this.invalid = validity;
  }

  onResponse(response) {
    // Check if form exists (if not, just retrieved existing response)
    if (response.form) {
      // check if question has branching logic & that response has been changed
      if (
        response.question.branches > 1 &&
        response.form.response !== response.existingResponse?.value
      ) {
        // if it does, update retrievedQuestions, send command to update search History for the questions whose numbers were removed
        var questionIndex = this.retrievedQuestions.findIndex((qu) => {
          return qu.num === response.question.num;
        });
        const removedQuestions = this.retrievedQuestions.splice(
          questionIndex + 1
        );
        // remove non-responses and responses for removed questions/questions with number > response.question.num
        this.responseService
          .deleteResponseHistory(
            this.project_id,
            this.round_id,
            response.question.num + 1
          )
          .pipe(first())
          .subscribe(
            () => {
              console.log('Response history was updated!');
            },
            (error) => {
              this.errMsg = error;
            }
          );
      }
      // Check if a response was passed
      if (
        response.form.response === null &&
        response.form.skip1 === false &&
        response.form.skip2 === false
      ) {
        // Save a non-response
        this.responseService
          .saveNonResponse(
            this.project_id,
            this.round_id,
            response.question.id,
            response.question.calculate_consensus,
            response.question.question_type
          )
          .pipe(first())
          .subscribe(
            () => {
              if (response.forward) {
                this.getNext(response.question.num);
              } else {
                this.getPrev(response.question.num);
              }
            },
            (error) => {
              this.errMsg = error;
            }
          );
      } else {
        // Save a response
        if (response.question.question_type === 'Free Text') {
          this.responseService
            .saveTextResponse(
              this.project_id,
              this.round_id,
              response.question.id,
              response.form.response,
              response.form.skip1,
              response.form.skip2
            )
            .pipe(first())
            .subscribe(
              (savedResponse) => {
                if (response.forward) {
                  this.getNext(response.question.num);
                } else {
                  this.getPrev(response.question.num);
                }
              },
              (error) => {
                this.errMsg = error;
              }
            );
        } else if (response.question.question_type === 'Likert') {
          this.responseService
            .saveLikertResponse(
              this.project_id,
              this.round_id,
              response.question.id,
              response.question.calculate_consensus,
              response.form.response,
              response.form.skip1,
              response.form.skip2,
              response.form.note
            )
            .pipe(first())
            .subscribe(
              (savedResponse) => {
                if (response.forward) {
                  this.getNext(response.question.num);
                } else {
                  this.getPrev(response.question.num);
                }
              },
              (error) => {
                this.errMsg = error;
              }
            );
        } else if (response.question.question_type === 'Multiple Choice') {
          this.responseService
            .saveMultipleChoiceResponse(
              this.project_id,
              this.round_id,
              response.question.id,
              response.question.calculate_consensus,
              response.form.response,
              response.form.other,
              response.form.skip1,
              response.form.skip2,
              response.form.note
            )
            .pipe(first())
            .subscribe(
              (savedResponse) => {
                if (response.forward) {
                  this.getNext(response.question.num);
                } else {
                  this.getPrev(response.question.num);
                }
              },
              (error) => {
                this.errMsg = error;
              }
            );
        } else if (response.question.question_type === 'Numeric') {
          this.responseService
            .saveNumericResponse(
              this.project_id,
              this.round_id,
              response.question.id,
              response.question.calculate_consensus,
              response.form.response,
              response.form.skip1,
              response.form.skip2,
              response.form.note
            )
            .pipe(first())
            .subscribe(
              (savedResponse) => {
                if (response.forward) {
                  this.getNext(response.question.num);
                } else {
                  this.getPrev(response.question.num);
                }
              },
              (error) => {
                this.errMsg = error;
              }
            );
        } else if (response.question.question_type === 'Ranking') {
          this.responseService
            .saveRankingResponse(
              this.project_id,
              this.round_id,
              response.question.id,
              response.question.calculate_consensus,
              response.form.response,
              response.form.skip1,
              response.form.skip2,
              response.form.note
            )
            .pipe(first())
            .subscribe(
              (savedResponse) => {
                if (response.forward) {
                  this.getNext(response.question.num);
                } else {
                  this.getPrev(response.question.num);
                }
              },
              (error) => {
                this.errMsg = error;
              }
            );
        } else if (response.question.question_type === 'Single Choice') {
          this.responseService
            .saveSingleChoiceResponse(
              this.project_id,
              this.round_id,
              response.question.id,
              response.question.calculate_consensus,
              response.form.response,
              response.form.other,
              response.form.skip1,
              response.form.skip2,
              response.form.note
            )
            .pipe(first())
            .subscribe(
              (savedResponse) => {
                if (response.forward) {
                  this.getNext(response.question.num);
                } else {
                  this.getPrev(response.question.num);
                }
              },
              (error) => {
                this.errMsg = error;
              }
            );
        }
      }
    } else {
      // Check if response is required. If not, log as a skip
      if (this.required === false && !response.existingResponse) {
        // Save a non-response
        this.responseService
          .saveNonResponse(
            this.project_id,
            this.round_id,
            this.question_id,
            this.calculate_consensus,
            this.question_type
          )
          .pipe(first())
          .subscribe(
            () => {
              if (response.forward) {
                this.getNext(response.question.num);
              } else {
                this.getPrev(response.question.num);
              }
            },
            (error) => {
              this.errMsg = error;
            }
          );
      } else {
        //No changes so no need to save
        if (response.forward) {
          this.getNext(response.question.num);
        } else {
          this.getPrev(response.question.num);
        }
      }
    }
  }

  getNext(currentQuestionNumber) {
    this.questionService
      .getNextQuestion(this.project_id, this.round_id, currentQuestionNumber)
      .pipe(first())
      .subscribe(
        (nextQuestion) => {
          // redirect if end of survey
          if (nextQuestion.data.is_end) {
            //update round_participation completed field
            this.roundService
              .updateRoundProgression(
                this.project_id,
                this.round_id,
                false,
                true,
                true
              )
              .pipe(first())
              .subscribe(
                (update) => {
                  this.router.navigate([
                    '/panel/' +
                      this.project_id +
                      '/round/' +
                      this.round_id +
                      '/end',
                  ]);
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
          // check if retrieved question number is in retrievedQuestions
          var questionIndex = this.retrievedQuestions.findIndex((qu) => {
            return qu.num === nextQuestion.data.question_number;
          });
          if (questionIndex === -1) {
            //question doesn't exist
            this.retrievedQuestions.push({
              num: nextQuestion.data.question_number,
            });
          }
          this.router.navigate([
            '/panel/' +
              this.project_id +
              '/round/' +
              this.round_id +
              '/respond/' +
              nextQuestion.data.question_number,
          ]);
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  getPrev(currentQuestionNumber) {
    // Find index of current question in retrievedQuestions and go back if not the first
    var questionIndex = this.retrievedQuestions.findIndex((qu) => {
      return qu.num === currentQuestionNumber;
    });
    if (questionIndex === 0) {
      // Go back to project dashboard if first question
      this.router.navigate(['/panel/' + this.project_id]);
    } else {
      // go to previous question otherwise
      this.router.navigate([
        '/panel/' +
          this.project_id +
          '/round/' +
          this.round_id +
          '/respond/' +
          this.retrievedQuestions[questionIndex - 1].num,
      ]);
    }
  }
}
