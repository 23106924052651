import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatOptionSelectAllComponent } from './mat-option-select-all.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    // MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    // MatTreeModule
  } from '@angular/material/select';

import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatPseudoCheckboxModule} from '@angular/material/core';
  
@NgModule({
  declarations: [
    MatOptionSelectAllComponent
  ],
  exports: [
    MatOptionSelectAllComponent
  ],
  imports: [
    // MatAutocompleteModule,
    // MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    MatCheckboxModule,
    // MatChipsModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    // MatInputModule,
    // MatListModule,
    // MatMenuModule,
    // MatNativeDateModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    // MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    // MatSnackBarModule,
    // MatTabsModule,
    // MatToolbarModule,
    // MatTooltipModule,
    // MatTreeModule
  ]
})
export class MatOptionSelectAllModule { }
