<mat-toolbar class="approw" color="primary">
  <span>Upload Participants</span>
</mat-toolbar>

<input
  type="file"
  #file
  style="display: none"
  (change)="onFilesAdded()"
  single
  accept=".csv"
/>
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
  <a class="padUpDown">Use this form to upload participants to the panel.</a>
  <ul class="padbelow">
    <li>
      Participants must be uploaded in <strong>UTF-8 csv format</strong>, with
      column headings matching those in the template linked below (column order
      does not matter, additional columns will be ignored)
    </li>
    <li><b>All columns must be completed</b> for all participants</li>
    <li>
      Participants must have unique email addresses - if the email address has
      already been added to this or another active project, it will not be
      uploaded
    </li>
  </ul>
  <div>
    <button
      mat-raised-button
      color="primary"
      class="add-files-btn"
      (click)="getTemplate()"
    >
      Download Template
    </button>
    <button
      [disabled]="uploading || uploadSuccessful"
      mat-raised-button
      color="primary"
      class="add-files-btn"
      (click)="addFiles()"
    >
      Select File for Upload
    </button>
  </div>

  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content fxFlex>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{ file.name }}</h4>
      </mat-list-item>
    </mat-list>

    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
      ><b>Error:&nbsp;</b> {{ errMsg }}</a
    >
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <a *ngIf="uploading">Processing upload...please wait!</a>
    <div fxLayout="column" *ngIf="status">
      <a>{{ status.data.created }} participants were uploaded!</a>
      <a *ngIf="status.data.failed">{{ status.data.failed }}</a>
    </div>
    <span class="flex-spacer"></span>
    <button *ngIf="showCancelButton" mat-button mat-dialog-close>Cancel</button>
    <button
      *ngIf="!uploading"
      mat-raised-button
      color="primary"
      [disabled]="!canBeClosed"
      (click)="closeDialog()"
    >
      {{ primaryButtonText }}
    </button>
    <mat-spinner *ngIf="uploading" diameter="30" strokeWidth="4"></mat-spinner>
  </mat-dialog-actions>
</div>
