import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ClientCompanyService } from '../../../../../_services/client-company.service';
import { Subject } from 'rxjs';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-overview-add-project-sheet',
  templateUrl: './overview-add-project-sheet.component.html',
  styleUrls: ['./overview-add-project-sheet.component.scss'],
})
export class OverviewAddProjectSheetComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  ngDestroy$ = new Subject();
  companies: any[];
  newCompany = [
    {
      id: -1,
      name: 'Create New Company...',
    },
  ];

  errMsg: string;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<OverviewAddProjectSheetComponent>,
    private fb: UntypedFormBuilder,
    private clientCompanyService: ClientCompanyService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      project_code: [
        '',
        [
          Validators.maxLength(20),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      clientCompany: [, Validators.required],
      newClientCompanyName: [
        '',
        [
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      diseaseArea: [
        '',
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      hideIncompleteResults: [true, Validators.required],
    });
  }

  ngOnInit(): void {
    this.form.controls.clientCompany.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        (newValue) => {
          if (newValue === -1) {
            this.form.controls.newClientCompanyName.setValidators([
              Validators.required,
              Validators.maxLength(20),
              Validators.pattern(
                '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
              ),
            ]);
          } else {
            this.form.controls.newClientCompanyName.setValidators([
              Validators.maxLength(20),
              Validators.pattern(
                '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
              ),
            ]);
          }
          this.form.controls.newClientCompanyName.updateValueAndValidity();
        },
        (error) => {
          this.errMsg = error;
        }
      );

    this.clientCompanyService
      .getClientCompanies()
      .pipe(first())
      .subscribe(
        (result) => {
          this.companies = [];
          this.companies = this.companies.concat(result.data);
          this.companies = this.companies.concat(this.newCompany);
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }

  save(): void {
    this._bottomSheetRef.dismiss(this.form.value);
  }
}
