<div fxLayout="column" >
    <mat-toolbar>
        <mat-toolbar-row>
            <a>Navigation</a>
        </mat-toolbar-row>
    </mat-toolbar>
    
    <mat-tree class="sidenav" fxFlex="25%" [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="projects | async as projects else processAsync">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <a [routerLink]="node.url" 
                [class.inactive]="router.url!==node.url"
                [class.active]="router.url===node.url">
                {{node.name}}</a>
            
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <a [routerLink]="node.url" 
                [class.inactive]="router.url!==node.url"
                [class.active]="router.url===node.url">
                {{node.name}}</a>

            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name">
            <mat-icon color="primary">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            </button>
            
        </mat-tree-node>
    </mat-tree>
</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>