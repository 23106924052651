<!-- PAGE HEADER -->
<div class="toolbar accent-back" 
    fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
    <h3>Participant Home Page</h3>
</div>

<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

<!-- INFORMATION BANNER -->
<div>
    <div class="toolbar info-banner" fxLayout="row" fxLayoutAlign="center center">
        <h3>Participants will see the content below when first logging in</h3>
        <button mat-flat-button color="primary" class="save-button"
            (click)="saveChanges()"
            [disabled]="disclaimer.pristine && headers.pristine && paragraphs.pristine">
            Save Changes</button>
    </div>

    <!--  -->
    <div fxLayout="row" fxLayoutAlign="center start" class="container">
        <div fxFlex="60%" class="panel-info" 
            *ngIf="dashboardContent | async as dashboardContent else processAsync">
                <h2 class="name">Introduction to the Study</h2>
            
            <p>
                <mat-form-field class="disclaimer-control" color="primary"
                    matTooltip="This text will be shown in bold. Include important information for participants such as who the study sponsor is.">
                    <input matInput type="text" [formControl]="disclaimer" autocomplete="off"
                        placeholder="Optional Disclaimer" >
                    <mat-hint>
                        <span class="warn-fore" [hidden]="!disclaimer.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field> 
                Thank you for participating in this study.
            </p>

            <!-- ADDED PARAGRAPH SECTIONS -->
            <div class="paragraph" *ngFor="let paragraph of paragraphs.controls; let i = index">
                <div fxLayout="column" fxLayoutAlign="start start" >
                    <div fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                        <mat-form-field class="header-control" color="primary">
                            <input matInput type="text" [formControl]="headers.controls[i]" autocomplete="off"
                                placeholder="Optional Paragraph Heading">
                            <mat-hint>
                                <span class="warn-fore" [hidden]="!headers.controls[i].errors?.pattern">This field contains unsupported characters!</span>
                            </mat-hint>
                        </mat-form-field>
                        <span class="flex-spacer"></span>
                        <button mat-mini-fab color="warn" (click)="removeParagraph(i)"
                            matTooltip="Remove paragraph">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                    
                    <mat-form-field class="content-control" color="primary">
                        <textarea matInput type="text" [formControl]="paragraph" autocomplete="off" required
                            matTooltip="Line breaks added within a paragraph will not be formatted as such for participants, therefore please add each new paragraph as a new field"
                            placeholder="Paragraph Text"></textarea> 
                        <mat-hint>
                            <span class="warn-fore" [hidden]="!paragraph.errors?.pattern">This field contains unsupported characters!</span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <!-- ADD PARAGRAPH SECTION -->
            <div class="paragraph">
                <div fxLayout="column" fxLayoutAlign="start start" >
                    <div fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                        <mat-form-field class="header-control" color="primary">
                            <input matInput type="text" [formControl]="newHeader" autocomplete="off"
                                placeholder="Optional Paragraph Heading">
                            <mat-hint>
                                <span class="warn-fore" [hidden]="!newHeader.errors?.pattern">This field contains unsupported characters!</span>
                            </mat-hint>
                        </mat-form-field>
                        <span class="flex-spacer"></span>
                        <button mat-mini-fab color="primary" (click)="addParagraph()"
                            matTooltip="Add paragraph" [disabled]="newParagraph.invalid">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    
                    <mat-form-field class="content-control" color="primary">
                        <textarea matInput type="text" [formControl]="newParagraph" autocomplete="off" required
                            placeholder="Paragraph Text"></textarea> 
                        <mat-hint>
                            <span class="warn-fore" [hidden]="!newParagraph.errors?.pattern">This field contains unsupported characters!</span>
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <p>Your personal data will be used only for the purposes of administering the questionnaire(s) in this study, or for following up in the context of adverse event reporting if applicable, and will be deleted from our records once the study is complete. Your responses will remain anonymous except to the questionnaire administrators.</p>
            </div>

            <!-- <div *ngFor="let paragraph of dashboardContent.data.content">
                <h3 class="subheader">{{paragraph.header}}</h3>
                <p>{{paragraph.content}}</p>
            </div> -->

            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-checkbox [disabled]="true">
                    <b>I confirm that I have understood the instructions provided above and agree to continue on this basis</b>
                </mat-checkbox>
                <button mat-flat-button class="confirm-button" fxFlexAlign="center">Confirm</button>
            </div>
        </div>
    </div>
</div>



<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>
