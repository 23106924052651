import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ProjectService } from '../../../_services/project.service';
import { QuestionTypes } from '../../../_shared/questionTypes';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RoundService } from '../../../_services/round.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProjectAddRoundSheetComponent } from './components/project-add-round-sheet/project-add-round-sheet.component';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import {
  ConfirmDeleteModalComponent,
  ConfirmDeleteSheetComponent,
} from '../../../components/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfilingQuestionService } from '../../../_services/profiling-question.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss'],
})
export class ProjectDashboardComponent implements OnInit, OnDestroy {
  is_client: boolean;

  project_id = this.route.snapshot.paramMap.get('project_id');

  form: UntypedFormGroup;
  questionTypes = QuestionTypes;
  project: Observable<any>;
  rounds: Observable<any>;
  profilingQuestions: Observable<any[]>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private location: Location,
    private processHTTPMsgService: ProcessHttpmsgService,
    private profilingQuestionService: ProfilingQuestionService,
    private projectService: ProjectService,
    private roundService: RoundService,
    public router: Router,
    public snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      questionTypes: [this.questionTypes, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      //watch for changes in route params
      this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
        this.project_id = params.project_id;
        this.userService
          .getUser()
          .pipe(first())
          .subscribe(
            (user) => {
              if (user) {
                if (user.participant_account) {
                  this.router.navigate(['/login']);
                } else {
                  this.is_client = user.client_account;
                }
              }
            },
            (error) => {
              console.error(error);
              this.errMsg = error;
            }
          );

        this.project = this.projectService.getProject(this.project_id).pipe(
          catchError((error) => {
            this.errMsg = error;
            console.error('Error loading project:', error);
            this.loadingError$.next(true);
            return of();
          })
        );

        this.rounds = this.roundService.getRounds(this.project_id);
        this.profilingQuestions =
          this.profilingQuestionService.getProfilingQuestions(this.project_id);
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main']);
  }

  closeProject() {
    const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
      data: {
        message:
          'Closing a project will permanently delete all associated client and participant accounts. Any participant responses will also be deleted so please ensure you have exported a csv copy if you wish to keep a record of them before marking the project as complete.',
      },
      width: '400px',
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((confirmation) => {
        if (confirmation) {
          this.projectService
            .closeProject(this.project_id)
            .pipe(first())
            .subscribe(
              (result) => {
                // TO DISCUSS: What happens on completion - participant and client accounts deleted, in which case responses etc will also be removed...
                this.snackBar.open('Project closed!', 'Close', {
                  duration: 1500,
                  verticalPosition: 'top',
                });
                this.project = this.projectService
                  .getProject(this.project_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading round:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              },
              (error) => {
                this.errMsg = error;
              }
            );
        }
      });
  }

  addRound() {
    this.roundService
      .addRound(
        this.project_id,
        this.form.value.name,
        this.form.value.questionTypes.includes('Free Text'),
        this.form.value.questionTypes.includes('Likert'),
        this.form.value.questionTypes.includes('Numeric'),
        this.form.value.questionTypes.includes('Ranking'),
        this.form.value.questionTypes.includes('Multiple Choice'),
        this.form.value.questionTypes.includes('Single Choice')
      )
      .pipe(first())
      .subscribe(
        (newRound) => {
          this.router.navigate([
            '/main/project/' + this.project_id + '/round/' + newRound.data.id,
          ]);
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }

  addSmallScreen() {
    const Ref = this._bottomSheet.open(ProjectAddRoundSheetComponent);
    Ref.afterDismissed()
      .pipe(first())
      .subscribe((result) => {
        this.roundService
          .addRound(
            this.project_id,
            result.name,
            result.questionTypes.includes('Free Text'),
            result.questionTypes.includes('Likert'),
            result.questionTypes.includes('Numeric'),
            result.questionTypes.includes('Ranking'),
            result.questionTypes.includes('Multiple Choice'),
            result.questionTypes.includes('Single Choice')
          )
          .pipe(first())
          .subscribe(
            (newRound) => {
              this.router.navigate([
                '/main/project/' +
                  this.project_id +
                  '/round/' +
                  newRound.data.id,
              ]);
            },
            (error) => {
              console.error(error);
              this.errMsg = error;
            }
          );
      });
  }

  async addRoundPermission(round_id, participant_id) {
    try {
      await this.roundService
        .addParticipant(this.project_id, round_id, participant_id)
        .pipe(first())
        .subscribe(
          (result) => {
            return result;
          },
          (error) => {
            return error;
          }
        );
    } catch (error) {
      console.error(error);
      this.errMsg = error;
    }
  }

  deleteProject() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ConfirmDeleteSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.projectService
              .deleteProject(this.project_id)
              .pipe(first())
              .subscribe(
                (result) => {
                  this.snackBar.open('Project deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.router.navigate(['/main']);
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
        width: '400px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.projectService
              .deleteProject(this.project_id)
              .pipe(first())
              .subscribe(
                (result) => {
                  this.snackBar.open('Project deleted!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                  this.router.navigate(['/main']);
                },
                (error) => {
                  console.error(error);
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }
}
