import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from '../../../_services/user.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ConfirmDeleteModalComponent,
  ConfirmDeleteSheetComponent,
} from '../../../components/confirm-delete/confirm-delete.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
})
export class AccountManagementComponent implements OnInit {
  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    fb: UntypedFormBuilder,
    public location: Location,
    public router: Router,
    public snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.form = fb.group(
      {
        password: ['', Validators.required],
        newPassword: ['', Validators.required],
        repeatPassword: ['', Validators.required],
      },
      { validators: this.checkPasswords }
    );
  }

  username: string;
  account_type: string;
  form: UntypedFormGroup;
  errMsg: string;

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              this.username = user.email;
              if (user.client_account === true) {
                this.account_type = 'Client';
              } else if (user.participant_account === true) {
                this.account_type = 'Participant';
              } else {
                this.account_type = 'Costello';
              }
            }
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  checkPasswords(group: UntypedFormGroup) {
    return group.value.newPassword === group.value.repeatPassword
      ? null
      : { notSame: true };
  }

  goBack() {
    this.location.back();
  }

  logOut() {
    this.userService.destroyUserCredentials();
    this.router.navigate(['/login']);
  }

  deleteAccount() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(ConfirmDeleteSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.userService
              .deleteAccount()
              .pipe(first())
              .subscribe(
                () => {
                  this.userService.destroyUserCredentials();
                  this.router.navigate(['/']);
                },
                (error) => {
                  console.error(error);
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
        width: '400px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((confirmation) => {
          if (confirmation) {
            this.userService
              .deleteAccount()
              .pipe(first())
              .subscribe(
                () => {
                  this.userService.destroyUserCredentials();
                  this.router.navigate(['/']);
                },
                (error) => {
                  console.error(error);
                }
              );
          }
        });
    }
  }

  updatePassword() {
    this.userService
      .updatePassword(this.form.value.password, this.form.value.newPassword)
      .pipe(first())
      .subscribe(
        () => {
          const ref = this.snackBar.open('Password saved!', 'Close', {
            duration: 1500,
            verticalPosition: 'top',
          });
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}
