import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-help-form',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.scss']
})
export class HelpFormModalComponent implements OnInit {

  form: UntypedFormGroup;
  errMsg: String;

  constructor(
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<HelpFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
  ) { 
    this.form = fb.group({
      message: ['', [Validators.required, Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\/^\\^\@^\^^;^\`^#]*$')]]
    });
  }

  ngOnInit(): void {
  }

  close() {         
    this.dialogRef.close();
  }

  send() {
    this.dialogRef.close(this.form.value.message);
  }

}

@Component({
  selector: 'app-help-form-smallscreen',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.scss']
})
export class HelpFormSheetComponent implements OnInit {
  form: UntypedFormGroup;
  errMsg: String;

  constructor(
    public route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _bottomSheetRef: MatBottomSheetRef<HelpFormSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) { 
    this.form = fb.group({
      message: ['', [Validators.required, Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\/^\\^\@^\^^;^\`^#]*$')]]
    });
  }

  ngOnInit(): void {
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

  send() {
    this._bottomSheetRef.dismiss(this.form.value.message);
  }

}
