<div *ngIf="questions | async as questions else processAsync">
    <nav mat-tab-nav-bar>
        <a mat-tab-link *ngFor="let question of questions.data"
        [routerLink]="'/main/project/' + project_id + '/profiling/question/' + question.question_number + '/' + route.snapshot.url[route.snapshot.url.length-1].path"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive"
        > {{question.question_number}}
        </a>
        <a mat-tab-link (click)="addQuestion()"
            *ngIf="route.snapshot.url[route.snapshot.url.length-1].path==='edit'">
            <mat-icon >add</mat-icon>
        </a>
    </nav>
</div>

<app-profiling-questions-edit *ngIf="route.snapshot.url[route.snapshot.url.length-1].path === 'edit'"></app-profiling-questions-edit>
<app-profiling-responses *ngIf="route.snapshot.url[route.snapshot.url.length-1].path === 'responses'"></app-profiling-responses>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>