import { Component, Inject, OnInit } from '@angular/core';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-section-edit-sheet',
  templateUrl: './section-edit-sheet.component.html',
  styleUrls: ['./section-edit-sheet.component.scss']
})
export class SectionEditSheetComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SectionEditSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private fb: UntypedFormBuilder,
  ) {
    this.form = fb.group({
      name: [this.data.section.name, [Validators.required, Validators.maxLength(50), Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\/^\\^\@^\^^;^\`^#]*$')]],
      instructions: [this.data.section.instructions, Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')],
      details: [this.data.section.details, Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')]
    });
   }

  ngOnInit(): void {
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }

  save(): void {
    this._bottomSheetRef.dismiss(this.form.value);
  }

}
