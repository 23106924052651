import { Component, OnInit, OnDestroy } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Router, NavigationEnd } from '@angular/router';
import { catchError, filter, first, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ProjectService } from '../../../../../_services/project.service';
import { RoundService } from '../../../../../_services/round.service';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { UserService } from '../../../../../_services/user.service';

interface MenuNode {
  name: string;
  children?: MenuNode[];
  url: string;
}

const TREE_DATA = [
  {
    name: 'Overview',
    url: '/main',
    children: [],
  },
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  user_id: number;
  rounds: any[];
  navData: Observable<any>;
  projects: Observable<any>;

  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      url: node.url,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    public router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private roundService: RoundService,
    private userService: UserService
  ) {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              this.user_id = user.id;
              if (!user.client_account && !user.participant_account) {
                this.projects = this.projectService
                  .getProjects('%', '{"%"}', '%')
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading projects:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
                this.fetchProjectData();
              }
            }
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }

  fetchProjectData() {
    var i = 0;
    this.projects.pipe(first()).subscribe(
      (projects) => {
        TREE_DATA[0].children = [];
        projects.data.forEach((project) => {
          TREE_DATA[0].children.push({
            name: project.name,
            children: [],
            url: '/main/project/' + project.id,
          });
          if (project.rounds) {
            project.rounds.forEach((round) => {
              if (round.first_question) {
                if (round.status === 'Draft') {
                  TREE_DATA[0].children[i].children.push({
                    name: round.name,
                    children: [
                      {
                        name: 'Edit Questions',
                        url:
                          '/main/project/' +
                          project.id +
                          '/round/' +
                          round.id +
                          '/question/1/edit',
                      },
                      {
                        name: 'View Results',
                        url:
                          '/main/project/' +
                          project.id +
                          '/round/' +
                          round.id +
                          '/question/1/responses',
                      },
                    ],
                    url: '/main/project/' + project.id + '/round/' + round.id,
                  });
                } else {
                  TREE_DATA[0].children[i].children.push({
                    name: round.name,
                    children: [
                      {
                        name: 'View Results',
                        url:
                          '/main/project/' +
                          project.id +
                          '/round/' +
                          round.id +
                          '/question/1/responses',
                      },
                    ],
                    url: '/main/project/' + project.id + '/round/' + round.id,
                  });
                }
              } else {
                TREE_DATA[0].children[i].children.push({
                  name: round.name,
                  url: '/main/project/' + project.id + '/round/' + round.id,
                });
              }
            });
          }
          i += 1;
        });
        this.dataSource.data = TREE_DATA;
        this.treeControl.expand(this.treeControl.dataNodes[0]);
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }
}
