<div fxLayout="column">
  <!-- PAGE HEADER -->
  <div class="toolbar accent-back" fxLayout="row" fxLayoutAlign="start center">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <h3>Project Team</h3>
  </div>

  <!-- ERROR BANNER -->
  <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
    ><b>Error:&nbsp;</b> {{ errMsg }}</a
  >

  <!-- TEAM LISTS -->
  <div
    class="list-padding"
    *ngIf="team | async as team; else processAsync"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayout.xs="column"
    fxLayoutAlign.xs="stretch"
  >
    <!-- Costello -->
    <div fxLayout="column" class="content" fxFlex="33%" fxFlex.xs="100%">
      <mat-list>
        <h3 mat-subheader>Costello Medical</h3>
        <mat-list-item *ngFor="let member of costelloTeam">
          <h4 mat-line>{{ member.first_name }} {{ member.last_name }}</h4>
          <mat-icon
            color="primary"
            *ngIf="member.id === primary_contact"
            class="contact_icon"
            matTooltip="Project contact - email notifications and participant help requests will be sent to this user"
            >contact_mail</mat-icon
          >
          <button
            *ngIf="member.id !== primary_contact"
            mat-icon-button
            color="accent"
            (click)="setContact(member.id)"
            matTooltip="Make primary contact"
          >
            <mat-icon>contact_mail</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="deleteInternal(member.id)"
            [disabled]="
              member.id === primary_contact || costelloTeam.length === 1
            "
            matTooltip="Remove user from the team"
          >
            <mat-icon aria-label="Delete member">close</mat-icon>
          </button>
          <p mat-line>{{ member.email }}</p>
        </mat-list-item>
        <mat-list-item
          *ngIf="allCostello | async as allCostello; else processAsync"
        >
          <mat-form-field mat-line>
            <input
              matInput
              placeholder="Enter user's email address to add them to the team"
              [formControl]="newCostelloMember"
              [matAutocomplete]="auto"
              autocomplete="off"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of filteredCostelloOptions | async"
                [value]="option"
              >
                {{ option.email }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint>
              <span [hidden]="newCostelloMember.pristine">
                <span [hidden]="!newCostelloMember.errors?.email"
                  >Please enter a valid email address</span
                >
              </span>
            </mat-hint>
          </mat-form-field>
          <button
            mat-icon-button
            color="primary"
            (click)="addMember(newCostelloMember.value)"
            [disabled]="
              newCostelloMember.value === null ||
              newCostelloMember.value.length === 0 ||
              newCostelloMember.invalid
            "
          >
            <mat-icon aria-label="Add to team">person_add</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>

    <!-- Client -->
    <div fxLayout="column" class="content" fxFlex="33%" fxFlex.xs="100%">
      <mat-list>
        <h3 mat-subheader>Clients</h3>
        <mat-list-item *ngFor="let member of clientTeam">
          <h4 mat-line>{{ member.first_name }} {{ member.last_name }}</h4>
          <button
            mat-icon-button
            color="primary"
            (click)="edit(member)"
            matTooltip="Edit user details"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="deleteExternal(member.id)"
            matTooltip="Delete user's account"
          >
            <mat-icon aria-label="Delete member">close</mat-icon>
          </button>
          <p mat-line>{{ member.email }}</p>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field mat-line>
            <input
              matInput
              placeholder="Enter client's email address to add them to the team"
              [formControl]="newClientMember"
              type="email"
              email
              autocomplete="off"
            />
          </mat-form-field>
          <button
            mat-icon-button
            color="primary"
            (click)="addClient(newClientMember.value)"
            [disabled]="
              newClientMember.value === null ||
              newClientMember.value.length === 0 ||
              newClientMember.invalid
            "
          >
            <mat-icon aria-label="Add to team">person_add</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>

    <!-- Participants -->
    <div fxLayout="column" class="content" fxFlex="33%" fxFlex.xs="100%">
      <mat-list>
        <h3 mat-subheader>Participants</h3>
        <mat-list-item>
          <!-- Row for participant account actions -->
          <button
            mat-flat-button
            color="primary"
            (click)="uploadParticipants()"
            class="mail-button"
            matTooltip="Upload new participants using CSV"
          >
            Upload Participants
          </button>
          <span class="flex-spacer"></span>
          <button
            mat-flat-button
            color="primary"
            (click)="sendActivationEmails()"
            class="mail-button"
            matTooltip="Send account activation emails to all participants"
          >
            Send Activation Emails
          </button>
        </mat-list-item>
        <mat-list-item *ngFor="let member of participants">
          <h4 mat-line>{{ member.first_name }} {{ member.last_name }}</h4>
          <button
            mat-icon-button
            color="primary"
            (click)="sendActivationEmail(member)"
            matTooltip="Send account activation email"
          >
            <mat-icon>email</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="editMember(member)"
            matTooltip="Edit user details"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            matTooltip="Delete user's account"
          >
            <mat-icon
              aria-label="Delete member"
              (click)="deleteExternal(member.id)"
              >close</mat-icon
            >
          </button>
          <p mat-line>{{ member.email }}</p>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field mat-line>
            <input
              matInput
              placeholder="Enter participant's email address to add them to the team"
              [formControl]="newParticipant"
              type="email"
              email
              autocomplete="off"
            />
          </mat-form-field>
          <button
            mat-icon-button
            color="primary"
            (click)="addParticipant(newParticipant.value)"
            [disabled]="
              newParticipant.value === null ||
              newParticipant.value.length === 0 ||
              newParticipant.invalid
            "
          >
            <mat-icon aria-label="Add to team">person_add</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div>
      <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>
