import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { RoundService } from '../../../_services/round.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ResponseService } from '../../../_services/response.service';
import { UserService } from '../../../_services/user.service';
import { UntypedFormControl } from '@angular/forms';
import { ProjectService } from '../../../_services/project.service';

@Component({
  selector: 'app-participant-dashboard',
  templateUrl: './participant-dashboard.component.html',
  styleUrls: ['./participant-dashboard.component.scss'],
})
export class ParticipantDashboardComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');

  rounds: Observable<any>;
  confirmation: Observable<any>;
  dashContent: Observable<any>;
  ngDestroy$ = new Subject();

  confirm = new UntypedFormControl(false);

  storedRounds = [];

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private roundService: RoundService,
    private responseService: ResponseService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.confirmation = this.userService.getActiveProject().pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading confirmation status: ', error);
        this.loadingError$.next(true);
        return of();
      })
    );
    this.confirmation.pipe(first()).subscribe(
      (res) => {
        if (res.data) {
          this.confirm.setValue(res.data.confirmed);
          if (res.data.confirmed === true) {
            this.confirm.disable();
            this.rounds = this.roundService
              .getParticipantRounds(this.project_id, '{"Open", "Closed"}')
              .pipe(
                catchError((error) => {
                  this.errMsg =
                    this.processHTTPMsgService.extractH1Error(error);
                  console.error('Error loading project:', error);
                  this.loadingError$.next(true);
                  return of();
                })
              );
            this.rounds.pipe(first()).subscribe(
              (result) => {
                this.storedRounds = result.data;
              },
              (error) => {
                console.error(error);
                this.errMsg = error;
              }
            );
          }
        }
      },
      (error) => {
        this.errMsg = error;
      }
    );

    this.dashContent = this.projectService.getDashContent(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading dashboard content:', error);
        this.loadingError$.next(true);
        return of();
      })
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  getDeadline(date) {
    if (date === 'dd MMM') {
      // Handle placeholder for admin account
      return '<dd MMM>';
    } else {
      const formattedDate = new Date(date);
      if (formattedDate.getMonth() === 0) {
        return formattedDate.getDate() + ' Jan';
      } else if (formattedDate.getMonth() === 1) {
        return formattedDate.getDate() + ' Feb';
      } else if (formattedDate.getMonth() === 2) {
        return formattedDate.getDate() + ' Mar';
      } else if (formattedDate.getMonth() === 3) {
        return formattedDate.getDate() + ' Apr';
      } else if (formattedDate.getMonth() === 4) {
        return formattedDate.getDate() + ' May';
      } else if (formattedDate.getMonth() === 5) {
        return formattedDate.getDate() + ' Jun';
      } else if (formattedDate.getMonth() === 6) {
        return formattedDate.getDate() + ' Jul';
      } else if (formattedDate.getMonth() === 7) {
        return formattedDate.getDate() + ' Aug';
      } else if (formattedDate.getMonth() === 8) {
        return formattedDate.getDate() + ' Sep';
      } else if (formattedDate.getMonth() === 9) {
        return formattedDate.getDate() + ' Oct';
      } else if (formattedDate.getMonth() === 10) {
        return formattedDate.getDate() + ' Nov';
      } else if (formattedDate.getMonth() === 11) {
        return formattedDate.getDate() + ' Dec';
      }
    }
  }

  respond(round) {
    // Case 1: Participant has completed main round
    if (round.completed === true) {
      this.router.navigate([
        '/panel/' + this.project_id + '/round/' + round.id + '/end',
      ]);
    }
    // Case 2: Participant has passed profiling questions (or there are none); main round not complete
    else if (round.profiling_passed === true) {
      this.responseService
        .getResponseHistory(this.project_id, round.id)
        .pipe(first())
        .subscribe(
          (history) => {
            if (history.data.response_history !== null) {
              this.router.navigate([
                '/panel/' +
                  this.project_id +
                  '/round/' +
                  round.id +
                  '/respond/' +
                  history.data.response_history[
                    history.data.response_history.length - 1
                  ].num,
              ]);
            } else {
              this.router.navigate([
                '/panel/' +
                  this.project_id +
                  '/round/' +
                  round.id +
                  '/respond/1',
              ]);
            }
          },
          (error) => {
            this.errMsg = error;
          }
        );
    }
    // Case 3: Profiling questions are not complete
    else if (round.profiling_failed === false) {
      this.router.navigate([
        '/panel/' +
          this.project_id +
          '/round/' +
          round.id +
          '/background_questions',
      ]);
    } else {
      // Case 4: Participant has answered a terminating question
      this.router.navigate([
        '/panel/' + this.project_id + '/round/' + round.id + '/exit',
      ]);
    }
  }

  confirmParticipation() {
    this.projectService
      .updateParticipantPermission(this.project_id, this.confirm.value)
      .pipe(first())
      .subscribe(
        () => {
          window.location.reload();
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }
}
