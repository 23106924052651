import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {
  HelpFormSheetComponent,
  HelpFormModalComponent,
} from '../help-form/help-form.component';
import { Observable, of, Subject } from 'rxjs';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ProjectService } from '../../../../../_services/project.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../../_services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-header-participant',
  templateUrl: './header-participant.component.html',
  styleUrls: ['./header-participant.component.scss'],
})
export class HeaderParticipantComponent implements OnInit, OnDestroy {
  username: string;

  project_id = this.route.snapshot.paramMap.get('project_id');

  project: Observable<any>;
  ngDestroy$ = new Subject();
  primary_contact_email: string = 'sara.steeves@costellomedical.com';

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public router: Router,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(first())
      .subscribe(
        (user) => {
          if (user) {
            this.username = user.first_name + ' ' + user.last_name;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    this.project = this.projectService.getProject(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading project:', error);
        this.loadingError$.next(true);
        return of();
      })
    );

    this.project.pipe(first()).subscribe(
      (result) => {
        this.primary_contact_email = result.data.primary_contact_email;
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  help() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(HelpFormSheetComponent, {
        data: { contact: this.primary_contact_email },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((helpMessage) => {
          if (helpMessage) {
            this.projectService
              .getHelp(this.project_id, helpMessage)
              .pipe(first())
              .subscribe(
                () => {
                  this.snackBar.open('Message sent!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(HelpFormModalComponent, {
        data: { contact: this.primary_contact_email },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((helpMessage) => {
          if (helpMessage) {
            this.projectService
              .getHelp(this.project_id, helpMessage)
              .pipe(first())
              .subscribe(
                () => {
                  this.snackBar.open('Message sent!', 'Close', {
                    duration: 1500,
                    verticalPosition: 'top',
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  logOut() {
    this.userService.destroyUserCredentials();
    this.router.navigate(['/login']);
  }
}
