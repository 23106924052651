import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../_services/process-httpmsg.service';
import { ProjectService } from '../../_services/project.service';
import { QuestionService } from '../../_services/question.service';

@Component({
  selector: 'app-display-numeric',
  templateUrl: './display-numeric.component.html',
  styleUrls: ['./display-numeric.component.scss'],
})
export class DisplayNumericComponent implements OnInit, OnDestroy, OnChanges {
  @Input() question: string;
  @Input() show_header: boolean;
  @Input() minimum: number;
  @Input() maximum: number;
  @Input() decimalPlaces: number;
  @Input() units: string;
  @Input() useNote: boolean;
  @Input() required: boolean;
  @Input() response: any;
  @Input() section_id: number;
  @Input() question_instructions: string;
  @Input() useSkip1: boolean;
  @Input() useSkip2: boolean;
  @Input() skipLabel1: string;
  @Input() skipLabel2: string;
  @Input() client_colour_primary: string;
  @Input() client_colour_secondary: string;

  @Output() formCopy = new EventEmitter<UntypedFormGroup>();

  form: UntypedFormGroup;
  validationNote: String;

  project_id = this.route.snapshot.paramMap.get('project_id');

  section: Observable<any>;
  ngDestroy$ = new Subject();
  colour_primary: string = '#880e4f'; //Default to costello purple
  colour_secondary: string = '#bdbdbd'; //Default to accent grey

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionService: QuestionService
  ) {
    this.form = fb.group({
      response: [, Validators.required],
      note: [
        ,
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'),
      ],
      skip1: [false],
      skip2: [false],
    });
  }

  ngOnInit(): void {
    //Handle access to project_id from participant view
    if (!this.project_id) {
      this.project_id = this.route.parent.snapshot.paramMap.get('project_id');
    }
    if (this.section_id) {
      this.section = this.questionService
        .getSection(this.project_id, this.section_id)
        .pipe(
          catchError((error) => {
            this.errMsg = this.processHTTPMsgService.extractH1Error(error);
            console.error('Error loading section:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    }

    if (this.client_colour_primary) {
      this.colour_primary = this.client_colour_primary;
    }
    if (this.client_colour_secondary) {
      this.colour_secondary = this.client_colour_secondary;
    }
    this.setClientTheme(this.colour_primary, this.colour_secondary);

    // Set up initial validators
    this.setNumericValidators();

    // Send current form to participant-question component
    this.form.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((value) => {
        this.formCopy.emit(this.form);
      });

    //Handle skip selection
    this.form.controls.response.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((responseValue) => {
        if (responseValue) {
          this.form.controls.skip1.setValue(false, { emitEvent: false });
          this.form.controls.skip2.setValue(false, { emitEvent: false });
          if (!this.form.controls.response.validator) {
            this.setNumericValidators();
          }
        }
      });
    this.form.controls.skip1.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((skip1Value) => {
        if (skip1Value) {
          this.form.controls.skip2.setValue(false, { emitEvent: false });
          this.form.controls.response.setValue(null, { emitEvent: false });
          this.form.controls.response.clearValidators();
          this.form.controls.response.updateValueAndValidity();
        } else if (!this.form.value.skip2) {
          this.setNumericValidators();
        }
      });
    this.form.controls.skip2.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((skip2Value) => {
        if (skip2Value) {
          this.form.controls.skip1.setValue(false, { emitEvent: false });
          this.form.controls.response.setValue(null, { emitEvent: false });
          this.form.controls.response.clearValidators();
          this.form.controls.response.updateValueAndValidity();
        } else if (!this.form.value.skip1) {
          this.setNumericValidators();
        }
      });

    // Add current response if exists
    if (this.response) {
      if (this.response.value !== null) {
        this.form.controls.response.setValue(Number(this.response.value), {
          emitEvent: false,
        });
      }
      this.form.controls.note.setValue(this.response.note, {
        emitEvent: false,
      });
      this.form.controls.skip1.setValue(this.response.opt_out1, {
        emitEvent: false,
      });
      this.form.controls.skip2.setValue(this.response.opt_out2, {
        emitEvent: false,
      });
    }
  }

  //Helper function for setting validators for numeric responses
  setNumericValidators() {
    var vals = [];
    this.validationNote = null;
    //check if required validator is needed
    if (!this.form.value.skip1 && !this.form.value.skip2 && this.required) {
      vals.push(Validators.required);
    }
    //check if min value validator is needed
    if (this.minimum !== null) {
      vals.push(Validators.min(this.minimum));
      this.validationNote =
        'Ensure the value meets the following requirements: Minimum ' +
        this.minimum;
    }
    //check if max value validator is needed
    if (this.maximum !== null) {
      vals.push(Validators.max(this.maximum));
      if (this.validationNote === null) {
        this.validationNote =
          'Ensure the value meets the following requirements: Maximum ' +
          this.maximum;
      } else {
        this.validationNote = this.validationNote + '; Maximum ' + this.maximum;
      }
    }
    //check if dp validator is needed
    if (this.decimalPlaces !== null) {
      var regex = new RegExp('^[0-9]+(.[0-9]{0,' + this.decimalPlaces + '})?$');
      vals.push(Validators.pattern(regex));
      if (this.validationNote === null) {
        this.validationNote =
          'Ensure the value meets the following requirements: ' +
          this.decimalPlaces +
          ' decimal places or fewer';
      } else {
        this.validationNote =
          this.validationNote +
          '; ' +
          this.decimalPlaces +
          ' decimal places or fewer';
      }
    }
    //update validators
    this.form.controls.response.setValidators(vals);
    this.form.controls.response.updateValueAndValidity();
  }

  ngOnChanges(changes) {
    //handle changes to validators from parent component (question config)
    if (
      changes.required ||
      changes.minimum ||
      changes.maximum ||
      changes.decimalPlaces
    ) {
      this.setNumericValidators();
    }
    //handle changes in associated section from parent component (question config)
    if (changes.section_id) {
      if (this.section_id) {
        this.section = this.questionService
          .getSection(this.project_id, this.section_id)
          .pipe(
            catchError((error) => {
              this.errMsg = this.processHTTPMsgService.extractH1Error(error);
              console.error('Error loading section:', error);
              this.loadingError$.next(true);
              return of();
            })
          );
      }
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  setClientTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-colour', primary);
    document.documentElement.style.setProperty('--secondary-colour', secondary);
  }
}
