import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { CookieSelectionsService } from '../cookie-selections.service';

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent implements OnInit {

  enableOptional: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PolicyModalComponent>,
    private cookieSelectionsService: CookieSelectionsService
  ) { }

  ngOnInit(): void {
    this.cookieSelectionsService.optionalCookiesEnabled$.pipe(first())
    .subscribe(value => { 
      this.enableOptional = value 
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.cookieSelectionsService.setOptionalCookies(this.enableOptional);
    this.cookieSelectionsService.hideCookieStatement();
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicySheetComponent implements OnInit {

  enableOptional: boolean = true;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<PolicySheetComponent>,
    private cookieSelectionsService: CookieSelectionsService
  ) { }

  ngOnInit(): void {
    this.cookieSelectionsService.optionalCookiesEnabled$.pipe(first())
    .subscribe(value => { 
      this.enableOptional = value 
    });
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

  save() {
    this.cookieSelectionsService.setOptionalCookies(this.enableOptional);
    this.cookieSelectionsService.hideCookieStatement();
    this._bottomSheetRef.dismiss();
  }

}
