<mat-toolbar class="approw" color="primary">
    <span>Upload Question Options</span>
  </mat-toolbar>

<input type="file" #file style="display: none" (change)="onFilesAdded()" single accept=".csv" />
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">    
  <a class="padUpDown">Use this form to upload options for <b>Multiple Choice</b>, <b>Ranking</b> or <b>Single Choice</b> questions in the round. Likert question options are configured at the round level from the Options menu in the round dashboard.</a>
  <ul class="padbelow">
    <li>Question options must be uploaded in <strong>UTF-8 csv format</strong>, with column headings matching those in the template linked below (column order does not matter, additional columns will be ignored)</li>
    <li>The <strong>label</strong> and <strong>question_number</strong> columns <b>must be completed</b> for all options; other fields can be blank</li>
    <li>Values in the question_number field should correspond to an existing question in the round</li>
    <li>The is_other field expects a value of TRUE or FALSE</li>
    <li>The colour field expects a hexadecimal colour value, e.g. #880e4f</li>
</ul>
  <div>
    <button mat-raised-button color="primary" class="add-files-btn" (click)="getTemplate()">Download Template</button>
    <button [disabled]="uploading || uploadSuccessful" mat-raised-button color="primary" class="add-files-btn" (click)="addFiles()">
      Select File for Upload
    </button>
  </div>

  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content fxFlex>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{file.name}}</h4>
      </mat-list-item>
    </mat-list>

    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <a *ngIf="uploading" >Processing upload...please wait!</a>
    <div fxLayout="column" *ngIf="status">
        <a>{{status.uploaded}}/{{status.target}} options were uploaded!</a>
        <a class="warn-fore"><b>Please still check options in the app to ensure there are no errors</b></a>
    </div>
    <span class="flex-spacer"></span>
    <button *ngIf="showCancelButton" mat-button mat-dialog-close>Cancel</button>
    <button *ngIf="!uploading" mat-raised-button color="primary" [disabled]="!canBeClosed" (click)="closeDialog()">{{primaryButtonText}}</button>
    <mat-spinner *ngIf="uploading" diameter=30 strokeWidth=4></mat-spinner>
  </mat-dialog-actions>
</div>
