import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { QuestionTypes } from '../../../../../_shared/questionTypes';

@Component({
  selector: 'app-project-add-round-sheet',
  templateUrl: './project-add-round-sheet.component.html',
  styleUrls: ['./project-add-round-sheet.component.scss'],
})
export class ProjectAddRoundSheetComponent implements OnInit {
  form: UntypedFormGroup;
  questionTypes = QuestionTypes;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ProjectAddRoundSheetComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      questionTypes: [this.questionTypes, Validators.required],
    });
  }

  ngOnInit(): void {}

  close(): void {
    this._bottomSheetRef.dismiss();
  }

  save(): void {
    this._bottomSheetRef.dismiss(this.form.value);
  }
}
