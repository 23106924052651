import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { RoundService } from '../../../../../_services/round.service';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { first, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../../_services/user.service';
import { Functions } from '../../../../../_shared/functions';

@Component({
  selector: 'app-project-dash-table',
  templateUrl: './project-dash-table.component.html',
  styleUrls: ['./project-dash-table.component.scss'],
})
export class ProjectDashTableComponent implements OnInit, OnDestroy {
  is_client: boolean;

  project_id = this.route.snapshot.paramMap.get('project_id');

  dataSource: TableDataSource;
  displayedColumns = ['name', 'num_questions', 'progress', 'results'];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'name';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageSub: Subscription;
  pageIndex = 0;
  pageSize = 5;

  ngDestroy$ = new Subject();

  constructor(
    private roundService: RoundService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(first())
      .subscribe(
        (user) => {
          if (user) {
            if (user.participant_account) {
              this.router.navigate(['/login']);
            } else {
              this.is_client = user.client_account;
              this.adjustColumns(window.innerWidth);
            }
          }
        },
        (error) => {
          this.errMsg = error;
        }
      );

    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.roundService,
      this.processHTTPMsgService
    );
    this.dataSource.loadRounds(this.project_id).then(
      (res) => {
        this.tableData = res;
        this.showTable = true;
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (screenWidth <= 959) {
      this.displayedColumns = ['name', 'progress'];
    } else if (screenWidth <= 1279) {
      this.displayedColumns = ['name', 'num_questions', 'status', 'progress'];
    } else {
      if (this.is_client) {
        this.displayedColumns = ['name', 'num_questions', 'status', 'progress'];
      } else {
        this.displayedColumns = [
          'name',
          'num_questions',
          'status',
          'progress',
          'results',
        ];
      }
    }
  }

  onRowClicked(row, index) {
    this.router.navigate([
      '/main/project/' + this.project_id + '/round/' + row.id,
    ]);
  }

  getProgress(row) {
    return (
      row.completed_participants +
      '/' +
      row.active_participants +
      ' (' +
      Functions.round(
        (row.completed_participants * 100) / row.active_participants,
        0
      ) +
      '%)'
    );
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private roundService: RoundService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadRounds(project_id): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.roundService
        .getRounds(project_id)
        .pipe(first())
        .subscribe(
          (rounds) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(rounds.data);
            resolve(rounds.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
