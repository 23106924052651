import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { RoundAddQuestionSheetComponent } from '../../../components/round-add-question-sheet/round-add-question-sheet.component';
import { QuestionAddModalComponent } from '../../../components/question-add-modal/question-add-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { ProfilingQuestionService } from '../../../_services/profiling-question.service';
import { ProjectService } from '../../../_services/project.service';

@Component({
  selector: 'app-profiling-toolbar',
  templateUrl: './profiling-toolbar.component.html',
  styleUrls: ['./profiling-toolbar.component.scss'],
})
export class ProfilingToolbarComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  question_number = this.route.snapshot.paramMap.get('question_number');

  questions: Observable<any[]>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private profilingQuestionService: ProfilingQuestionService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (
                user.client_account &&
                this.route.snapshot.url[5].path !== 'responses'
              ) {
                this.router.navigate(['/main/project/' + this.project_id]);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.projectService
                  .getProject(this.project_id)
                  .pipe(first())
                  .subscribe(
                    (project) => {
                      if (
                        this.route.snapshot.url[5].path !== 'responses' &&
                        project.data.started_rounds > 0
                      ) {
                        this.router.navigate([
                          '/main/project/' + this.project_id,
                        ]);
                      } else {
                        this.questions = this.profilingQuestionService
                          .getProfilingQuestions(this.project_id)
                          .pipe(
                            catchError((error) => {
                              this.errMsg =
                                this.processHTTPMsgService.extractH1Error(
                                  error
                                );
                              console.error('Error loading questions:', error);
                              this.loadingError$.next(true);
                              return of();
                            })
                          );
                      }
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );
              }
            }
          },
          (error) => {
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  addDBQuestion(question, questionType) {
    this.profilingQuestionService
      .addProfilingQuestion(this.project_id, question, questionType)
      .pipe(first())
      .subscribe(
        (newQuestion) => {
          this.questions = this.profilingQuestionService
            .getProfilingQuestions(this.project_id)
            .pipe(
              catchError((error) => {
                this.errMsg = this.processHTTPMsgService.extractH1Error(error);
                console.error('Error loading questions:', error);
                this.loadingError$.next(true);
                return of();
              })
            );
          this.router.navigate([
            '/main/project/' +
              this.project_id +
              '/profiling/question/' +
              newQuestion.data.question_number +
              '/edit',
          ]);
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }

  addQuestion() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(RoundAddQuestionSheetComponent, {
        data: {
          use_freetext: true,
          use_likert: false,
          use_mcq: true,
          use_numeric: true,
          use_ranking: true,
          use_scq: true,
        },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((values) => {
          if (values) {
            this.addDBQuestion(values.name, values.questionType);
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(QuestionAddModalComponent, {
        width: '400px',
        data: {
          use_freetext: true,
          use_likert: false,
          use_mcq: true,
          use_numeric: true,
          use_ranking: true,
          use_scq: true,
        },
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((values) => {
          if (values) {
            this.addDBQuestion(values.name, values.questionType);
          }
        });
    }
  }
}
