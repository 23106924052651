<div class="table-container" *ngIf="textData.length > 0">
    <table mat-table [dataSource]="textData" fxFlex="100%">
  
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef>
              <a *ngIf="questionType!=='Free Text'">Group Notes</a>
              <a *ngIf="questionType==='Free Text'">Responses</a>
          </th>
          <td mat-cell *matCellDef="let item"> {{item.text_value}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="flattened-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="flattened-row"></tr>
    </table>
</div>