import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { QuestionService } from '../../../_services/question.service';
import { RoundAddQuestionSheetComponent } from '../../../components/round-add-question-sheet/round-add-question-sheet.component';
import { QuestionAddModalComponent } from '../../../components/question-add-modal/question-add-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { RoundService } from '../../../_services/round.service';

@Component({
  selector: 'app-question-toolbar',
  templateUrl: './question-toolbar.component.html',
  styleUrls: ['./question-toolbar.component.scss'],
})
export class QuestionToolbarComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_number = this.route.snapshot.paramMap.get('question_number');

  use_freetext = false;
  use_likert = false;
  use_mcq = false;
  use_numeric = false;
  use_ranking = false;
  use_scq = false;

  round: Observable<any>;
  questions: Observable<any[]>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private processHTTPMsgService: ProcessHttpmsgService,
    private questionService: QuestionService,
    private roundService: RoundService,
    public router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.round = this.roundService
      .getRound(this.project_id, this.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading round:', error);
          this.loadingError$.next(true);
          return of();
        })
      );

    this.round.pipe(first()).subscribe(
      (round) => {
        if (
          this.route.snapshot.url[6].path !== 'responses' &&
          round.data.status !== 'Draft'
        ) {
          this.router.navigate([
            '/main/project/' + this.project_id + '/round/' + this.round_id,
          ]);
        } else {
          this.questions = this.questionService
            .getQuestions(this.project_id, this.round_id)
            .pipe(
              catchError((error) => {
                this.errMsg = this.processHTTPMsgService.extractH1Error(error);
                console.error('Error loading questions:', error);
                this.loadingError$.next(true);
                return of();
              })
            );
          this.use_freetext = round.data.use_freetext;
          this.use_likert = round.data.use_likert;
          this.use_mcq = round.data.use_mcq;
          this.use_numeric = round.data.use_numeric;
          this.use_ranking = round.data.use_ranking;
          this.use_scq = round.data.use_scq;
        }
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  addDBQuestion(question, questionType) {
    this.questionService
      .addQuestion(this.project_id, this.round_id, question, questionType)
      .pipe(first())
      .subscribe(
        (newQuestion) => {
          this.questions = this.questionService
            .getQuestions(this.project_id, this.round_id)
            .pipe(
              catchError((error) => {
                this.errMsg = this.processHTTPMsgService.extractH1Error(error);
                console.error('Error loading questions:', error);
                this.loadingError$.next(true);
                return of();
              })
            );
          this.router.navigate([
            '/main/project/' +
              this.project_id +
              '/round/' +
              this.round_id +
              '/question/' +
              newQuestion.data.question_number +
              '/edit',
          ]);
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }

  addQuestion() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(RoundAddQuestionSheetComponent, {
        data: {
          use_freetext: this.use_freetext,
          use_likert: this.use_likert,
          use_mcq: this.use_mcq,
          use_numeric: this.use_numeric,
          use_ranking: this.use_ranking,
          use_scq: this.use_scq,
        },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((values) => {
          if (values) {
            this.addDBQuestion(values.name, values.questionType);
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(QuestionAddModalComponent, {
        width: '400px',
        data: {
          use_freetext: this.use_freetext,
          use_likert: this.use_likert,
          use_mcq: this.use_mcq,
          use_numeric: this.use_numeric,
          use_ranking: this.use_ranking,
          use_scq: this.use_scq,
        },
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((values) => {
          if (values) {
            this.addDBQuestion(values.name, values.questionType);
          }
        });
    }
  }
}
