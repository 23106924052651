import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ColourPairs } from '../../../../../_shared/colourPairs';

@Component({
  selector: 'app-profiling-figure-ranking',
  templateUrl: './profiling-figure-ranking.component.html',
  styleUrls: ['./profiling-figure-ranking.component.scss'],
})
export class ProfilingFigureRankingComponent implements OnInit {
  @Input() chartData: any;

  colourPairs = ColourPairs;
  defaultColour = '#880e4f'; //Default to costello purple
  total = 0;

  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            //Function to 'wrap' axis labels rather than have them go diagonally
            callback: function (label: any) {
              if (/\s/.test(label) && label.length > 10) {
                return label.split(' ');
              } else {
                return label;
              }
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Number of participants',
          },
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      ],
    },
  };

  public chartLabels: Label[] = [];
  public chartType: ChartType = 'bar';
  public chartLegend = true;
  public chartPlugins = [];

  public chartColors: Color[] = [];

  public formattedData: ChartDataSets[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.chartData) {
      if (this.chartData.ranks) {
        this.chartLabels = this.chartData.option_labels;
        const numRanks = this.chartData.option_labels.length;

        var tempData = [];

        for (let position = 0; position < numRanks; position++) {
          tempData.push({
            data: [],
            label: 'Rank ' + (position + 1),
            stack: 'a',
            backgroundColor: [],
            hoverBackgroundColor: [],
            borderColor: [],
            hoverBorderColor: [],
          });

          for (let option = 0; option < numRanks; option++) {
            var ranking = this.chartData.ranks.find((rank) => {
              return (
                rank.label === this.chartData.option_labels[option] &&
                rank.rank === position
              );
            });
            var count = 0;
            var colour = this.defaultColour;
            if (ranking) {
              count = ranking.count;
              this.total += count;
              //REMOVE individual colouring for different option labels
              // if(ranking.colour !== "       ") {
              //   colour = ranking.colour;
              // }
            }
            tempData[position].data.push(count);
            tempData[position].backgroundColor.push(
              this.hexToRgb(colour, (numRanks - position) / numRanks - 0.15)
            );
            tempData[position].hoverBackgroundColor.push(
              this.hexToRgb(colour, (numRanks - position) / numRanks)
            );
            tempData[position].borderColor.push(
              this.hexToRgb(colour, (numRanks - position) / numRanks)
            );
            tempData[position].hoverBorderColor.push(
              this.hexToRgb(colour, (numRanks - position) / numRanks)
            );
          }
        }
        this.formattedData = tempData;
        this.chartOptions.legend.display = true;
      }
    }
  }

  hexToRgb(hex, a_value = 0.5) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? 'rgba(' +
          parseInt(result[1], 16) +
          ', ' +
          parseInt(result[2], 16) +
          ', ' +
          parseInt(result[3], 16) +
          ', ' +
          a_value +
          ')'
      : null;
  }
}
