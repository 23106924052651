import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  FormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { ProjectService } from '../../../_services/project.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'app-dashboard-setup',
  templateUrl: './dashboard-setup.component.html',
  styleUrls: ['./dashboard-setup.component.scss'],
})
export class DashboardSetupComponent implements OnInit {
  project_id = this.route.snapshot.paramMap.get('project_id');
  dashboardContent: Observable<any>;

  disclaimer = new UntypedFormControl(
    '',
    Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$')
  );
  headers = new UntypedFormArray([]);
  paragraphs = new UntypedFormArray([]);
  newHeader = new UntypedFormControl(
    '',
    Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$')
  );
  newParagraph = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'),
  ]);

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.client_account) {
                this.router.navigate(['/login']);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.dashboardContent = this.projectService
                  .getDashContent(this.project_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading home page content:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
                this.dashboardContent.pipe(first()).subscribe(
                  (content) => {
                    this.disclaimer.setValue(
                      content.data.participant_disclaimer
                    );
                    if (
                      content.data.content !== null &&
                      content.data.content.length > 0 &&
                      content.data.content[0] !== null
                    ) {
                      content.data.content.forEach((element) => {
                        this.headers.push(
                          new UntypedFormControl(
                            element.header,
                            Validators.pattern(
                              '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'
                            )
                          )
                        );
                        this.paragraphs.push(
                          new UntypedFormControl(
                            element.content,
                            Validators.pattern(
                              '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'
                            )
                          )
                        );
                      });
                    }
                  },
                  (error) => {
                    this.errMsg = error;
                  }
                );
              }
            }
          },
          (error) => {
            this.errMsg = error;
          }
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  addParagraph() {
    this.headers.push(
      new UntypedFormControl(
        this.newHeader.value,
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$')
      )
    );
    this.paragraphs.push(
      new UntypedFormControl(this.newParagraph.value, [
        Validators.required,
        Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'),
      ])
    );
    this.newHeader.reset();
    this.newParagraph.reset();
    this.headers.markAsDirty();
    this.paragraphs.markAsDirty();
  }

  removeParagraph(index) {
    this.headers.removeAt(index);
    this.paragraphs.removeAt(index);
    this.headers.markAsDirty();
    this.paragraphs.markAsDirty();
  }

  saveChanges() {
    this.projectService
      .addDashContent(this.project_id, {
        disclaimer: this.disclaimer.value,
        headers: this.headers.value,
        content: this.paragraphs.value,
      })
      .pipe(first())
      .subscribe(
        () => {
          this.disclaimer.markAsPristine();
          this.headers.markAsPristine();
          this.paragraphs.markAsPristine();
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id]);
  }
}
