<mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" class="selection">
            <a><b>Selection</b></a>
            <span class="flex-spacer"></span>
            <button mat-mini-fab [style.background-color]="colourPairs[colourIndex].shade" class="button"></button>
            <button mat-icon-button class="button" color="primary" (click)="close(colourPairs[colourIndex].shade)"><mat-icon>check</mat-icon></button>
            <!-- <button mat-mini-fab [style.background-color]="colourPairs[colourIndex].light" class="button"></button> -->
        </div>
        <div fxLayout="row">
            <button mat-mini-fab [style.background-color]="colourPairs[0].shade" (click)="select(0)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[1].shade" (click)="select(1)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[2].shade" (click)="select(2)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[3].shade" (click)="select(3)" class="button"></button>
        </div>
        <div fxLayout="row">
            <button mat-mini-fab [style.background-color]="colourPairs[4].shade" (click)="select(4)"class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[5].shade" (click)="select(5)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[6].shade" (click)="select(6)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[7].shade" (click)="select(7)" class="button"></button>
        </div>
        <div fxLayout="row">
            <button mat-mini-fab [style.background-color]="colourPairs[8].shade" (click)="select(8)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[9].shade" (click)="select(9)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[10].shade" (click)="select(10)" class="button"></button>
            <button mat-mini-fab [style.background-color]="colourPairs[11].shade" (click)="select(11)" class="button"></button>
        </div>
    </div>
    <!-- <button mat-fab *ngFor="let colour of colourPairs" [style.background-color]="colour.shade"></button> -->
</mat-dialog-content>

<!-- <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxHide.lt-sm>
    <button mat-flat-button color="accent" (click)="close(false)" cdkFocusInitial>Cancel</button>
    <button type="submit" mat-flat-button color="warn" 
        (click)="close(true)">Delete</button>
</mat-dialog-actions> -->