import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieSelectionsService } from './components/policy-components/cookie-selections.service';
import { UserService } from './_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'delphi-app';
  user: Observable<any>;

  constructor(public userService: UserService, private cookieSelectionsService: CookieSelectionsService) {}

  ngOnInit() {
    this.userService.loadUserCredentials();
    this.user = this.userService.getUser();
    this.user.subscribe(x => {
      if(x) {
        if(x.admin_account && x.participant_account) {
          this.cookieSelectionsService.hideCookieStatement();
        }
      }
    })
  }
}
