import { Routes } from '@angular/router';
import { HomeComponent } from '../pages/common/login/home/home.component';
import { ProjectDashboardComponent } from '../pages/main/project-dashboard/project-dashboard.component';
import { LayoutLoginComponent } from '../pages/common/login/layout-login/layout-login.component';
import { LayoutCostelloComponent } from '../pages/main/layout-costello/layout-costello.component';
import { OverviewDashboardComponent } from '../pages/main/overview-dashboard/overview-dashboard.component';
import { RoundDashboardComponent } from '../pages/main/round-dashboard/round-dashboard.component';
import { ProjectTeamComponent } from '../pages/main/project-team/project-team.component';
import { ProjectOptionsComponent } from '../pages/main/project-options/project-options.component';
import { RoundOptionsComponent } from '../pages/main/round-options/round-options.component';
import { RoundParticipantsComponent } from '../pages/main/round-participants/round-participants.component';
import { QuestionToolbarComponent } from '../pages/main/question-toolbar/question-toolbar.component';
import { ProfilingSetupComponent } from '../pages/main/profiling-setup/profiling-setup.component';
import { ProfilingToolbarComponent } from '../pages/main/profiling-toolbar/profiling-toolbar.component';
import { LayoutParticipantComponent } from '../pages/participant/layout-participant/layout-participant.component';
import { ParticipantDashboardComponent } from '../pages/participant/participant-dashboard/participant-dashboard.component';
import { ParticipantQuestionToolbarComponent } from '../pages/participant/participant-question-toolbar/participant-question-toolbar.component';
import { ParticipantCompletionComponent } from '../pages/participant/participant-completion/participant-completion.component';
import { ParticipantExitComponent } from '../pages/participant/participant-exit/participant-exit.component';
import { DashboardSetupComponent } from '../pages/main/dashboard-setup/dashboard-setup.component';
import { CrawlingHomeComponent } from '../pages/common/login/crawling-home/crawling-home.component';
import { PasswordResetComponent } from '../pages/common/login/password-reset/password-reset.component';
import { AccountManagementComponent } from '../pages/common/account-management/account-management.component';
import { PrivacyPolicyComponent } from '../components/policy-components/privacy-policy/privacy-policy.component';
import { CookieStatementComponent } from '../components/policy-components/cookie-statement/cookie-statement.component';
import { UsersComponent } from '../pages/main/users/users.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LayoutLoginComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'apocalypse', component: CrawlingHomeComponent },
      { path: 'password/:token', component: PasswordResetComponent },
    ],
  },
  {
    path: 'main',
    component: LayoutCostelloComponent,
    children: [
      { path: '', component: OverviewDashboardComponent },
      { path: 'project/:project_id', component: ProjectDashboardComponent },
      { path: 'project/:project_id/team', component: ProjectTeamComponent },
      {
        path: 'project/:project_id/options',
        component: ProjectOptionsComponent,
      },
      {
        path: 'project/:project_id/homepage_setup',
        component: DashboardSetupComponent,
      },
      {
        path: 'project/:project_id/profiling',
        component: ProfilingSetupComponent,
      },
      {
        path: 'project/:project_id/profiling/question/:question_number/edit',
        component: ProfilingToolbarComponent,
      },
      {
        path: 'project/:project_id/profiling/question/:question_number/responses',
        component: ProfilingToolbarComponent,
      },
      {
        path: 'project/:project_id/round/:round_id',
        component: RoundDashboardComponent,
      },
      {
        path: 'project/:project_id/round/:round_id/options',
        component: RoundOptionsComponent,
      },
      {
        path: 'project/:project_id/round/:round_id/participants',
        component: RoundParticipantsComponent,
      },
      {
        path: 'project/:project_id/round/:round_id/question/:question_number/edit',
        component: QuestionToolbarComponent,
      },
      {
        path: 'project/:project_id/round/:round_id/question/:question_number/responses',
        component: QuestionToolbarComponent,
      },
      {
        path: 'user_management',
        component: UsersComponent,
      },
    ],
  },
  {
    path: 'panel/:project_id',
    component: LayoutParticipantComponent,
    children: [
      { path: '', component: ParticipantDashboardComponent },
      {
        path: 'round/:round_id/background_questions',
        component: ParticipantQuestionToolbarComponent,
      },
      {
        path: 'round/:round_id/respond/:question_num',
        component: ParticipantQuestionToolbarComponent,
      },
      {
        path: 'round/:round_id/end',
        component: ParticipantCompletionComponent,
      },
      { path: 'round/:round_id/exit', component: ParticipantExitComponent },
      // DISABLED VIEWING responses
      // {path: 'round/:round_id/responses', component: ParticipantResponsesComponent}
    ],
  },
  {
    path: 'my_account',
    component: AccountManagementComponent,
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'cookie-statement', component: CookieStatementComponent },
  { path: '**', redirectTo: 'login' },
];
