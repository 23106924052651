import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { SectionAddSheetComponent } from '../../../../../components/section-add-sheet/section-add-sheet.component';
import { SectionAddModalComponent } from '../../../../../components/section-add-modal/section-add-modal.component';
import { SectionEditSheetComponent } from '../../../../../components/section-edit-sheet/section-edit-sheet.component';
import { SectionEditModalComponent } from '../../../../../components/section-edit-modal/section-edit-modal.component';
import { QuestionTypes } from '../../../../../_shared/questionTypes';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProfilingQuestionService } from '../../../../../_services/profiling-question.service';
import { QuestionService } from '../../../../../_services/question.service';
import { QuestionOptionsService } from '../../../../../_services/question-options.service';
import { ColourPairs } from '../../../../../_shared/colourPairs';
import { ColourPaletteModalComponent } from '../../../../../components/colour-palette/colour-palette.component';
import { UserService } from '../../../../../_services/user.service';
import { ProjectService } from '../../../../../_services/project.service';

@Component({
  selector: 'app-profiling-questions-edit',
  templateUrl: './profiling-questions-edit.component.html',
  styleUrls: ['./profiling-questions-edit.component.scss'],
})
export class ProfilingQuestionsEditComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = '1'; // Update to retrieve based on project id and profiling status
  question_number = this.route.snapshot.paramMap.get('question_number');
  question_id: Number;

  project: Observable<any>;
  question: Observable<any>;
  sections: Observable<any>;
  prior_questions: Observable<any[]>;
  ngDestroy$ = new Subject();

  form: UntypedFormGroup;

  questionTypes = QuestionTypes;
  colourOptions = ColourPairs;

  numOptions = 0;
  questionOptions = [];
  sectionOptions = [];

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private processHTTPMsgService: ProcessHttpmsgService,
    private profilingQuestionService: ProfilingQuestionService,
    private projectService: ProjectService,
    private questionOptionsService: QuestionOptionsService,
    private questionService: QuestionService,
    private fb: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private userService: UserService
  ) {
    //TODO: work out how editing question numbers can be done - maybe better from round dash question table?
    this.form = fb.group({
      section: [],
      // question_number: [, Validators.required], //Required for all questions
      questionType: ['', Validators.required], //Required for all questions
      questionTitle: [
        '',
        [
          Validators.required,
          Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'),
        ],
      ], //Required for all questions
      questionInstructions: [
        '',
        [Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$')],
      ],
      numOptions: [0, Validators.required],
      optionLabels: new UntypedFormArray([]), //Required for mc, ranking and sc
      optionColours: new UntypedFormArray([]), //Required for mc, ranking and sc
      optionIsOther: new UntypedFormArray([]), // for mcq, scq and likert(one day)
      optionIsTerminating: new UntypedFormArray([]), // for mcq, scq
      min_value: [],
      max_value: [],
      num_dps: [],
      unit_label: [
        '',
        [
          Validators.maxLength(20),
          Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'),
        ],
      ],
      note_use: [false], //Required for all non-text questions
      top_label: ['Highest', Validators.pattern('^[A-Za-z0-9- ]+$')], //Required for ranking questions
      bottom_label: ['Lowest', Validators.pattern('^[A-Za-z0-9- ]+$')], //Required for ranking questions
      filter_use: [true], //Required for all non-text questions
      required: [true],
    });
  }

  // TODISCUSS: LIKERT OPTIONS for profiling questions - are they used, and could they be configured for each question instead?
  ngOnInit(): void {
    //watch for changes in question settings
    this.formWatch();

    //watch for changes in route params
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      if (this.userService.isAuthenticated) {
        this.userService
          .getUser()
          .pipe(first())
          .subscribe(
            (user) => {
              if (user) {
                if (user.client_account) {
                  this.router.navigate(['/login']);
                } else if (user.participant_account) {
                  this.router.navigate(['/login']);
                } else {
                  this.project_id = params.project_id;
                  this.question_number = params.question_number;

                  //Retrieve data needed for the forms
                  this.sections = this.questionService
                    .getProfilingSections(this.project_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading sections:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.sections.pipe(first()).subscribe(
                    (sections) => {
                      this.sectionOptions = sections.data;
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );
                  this.question = this.profilingQuestionService
                    .getProfilingQuestion(this.project_id, this.question_number)
                    .pipe(
                      catchError((error) => {
                        this.errMsg = error;
                        console.error('Error loading question:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.project = this.projectService
                    .getProject(this.project_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading project:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );

                  this.question.pipe(first()).subscribe(
                    (question) => {
                      this.question_id = question.data.id;
                      this.form.controls.section.setValue(
                        question.data.section
                      );
                      this.form.controls.questionType.setValue(
                        question.data.question_type
                      );
                      this.form.controls.questionTitle.setValue(
                        question.data.question
                      );
                      this.form.controls.questionInstructions.setValue(
                        question.data.instructions
                      );
                      this.form.controls.min_value.setValue(
                        question.data.min_value
                      );
                      this.form.controls.max_value.setValue(
                        question.data.max_value
                      );
                      this.form.controls.num_dps.setValue(
                        question.data.num_dps
                      );
                      this.form.controls.unit_label.setValue(
                        question.data.unit_label
                      );
                      this.form.controls.top_label.setValue(
                        question.data.top_label
                      );
                      this.form.controls.bottom_label.setValue(
                        question.data.bottom_label
                      );
                      this.form.controls.filter_use.setValue(
                        question.data.filter_use
                      );
                      this.form.controls.note_use.setValue(
                        question.data.note_use
                      );
                      this.form.controls.required.setValue(
                        question.data.required
                      );
                      if (question.data.question_options) {
                        this.form.controls.numOptions.setValue(
                          question.data.question_options.length
                        );

                        //RESET OPTIONS
                        for (
                          let i = 0;
                          i <
                          (<UntypedFormArray>this.form.controls.optionLabels)
                            .length;
                          i++
                        ) {
                          (<UntypedFormArray>(
                            this.form.controls.optionLabels
                          )).controls[i].setValue(
                            question.data.question_options[i].label,
                            Validators.pattern(
                              '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'
                            )
                          );
                          (<UntypedFormArray>(
                            this.form.controls.optionColours
                          )).controls[i].setValue(
                            question.data.question_options[i].colour
                          );
                          (<UntypedFormArray>(
                            this.form.controls.optionIsOther
                          )).controls[i].setValue(
                            question.data.question_options[i].is_other
                          );
                          (<UntypedFormArray>(
                            this.form.controls.optionIsTerminating
                          )).controls[i].setValue(
                            question.data.question_options[i].is_terminating
                          );
                        }
                      } else {
                        this.form.controls.numOptions.setValue(0);
                      }
                      this.form.markAsPristine();
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );

                  this.prior_questions = this.profilingQuestionService
                    .getProfilingQuestions(this.project_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading questions:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                }
              }
            },
            (error) => {
              this.errMsg = error;
            }
          );
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id + '/profiling']);
  }

  clearSection() {
    this.form.controls.section.reset();
    this.form.controls.section.markAsDirty();
  }

  addSection() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(SectionAddSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((newSection) => {
          if (newSection) {
            this.questionService
              .addProfilingSection(
                this.project_id,
                this.question_id,
                newSection.name,
                newSection.instructions,
                newSection.details
              )
              .pipe(first())
              .subscribe(
                (addedSection) => {
                  // Add id to question and form control
                  this.form.controls.section.setValue(addedSection.data.id);
                  this.form.controls.section.markAsDirty();
                  // Add section to section options list
                  this.sectionOptions.push(addedSection.data);
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(SectionAddModalComponent, {
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((newSection) => {
          if (newSection) {
            this.questionService
              .addProfilingSection(
                this.project_id,
                this.question_id,
                newSection.name,
                newSection.instructions,
                newSection.details
              )
              .pipe(first())
              .subscribe(
                (addedSection) => {
                  // Add id to question and form control
                  this.form.controls.section.setValue(addedSection.data.id);
                  this.form.controls.section.markAsDirty();
                  // Add section to section options list
                  this.sectionOptions.push(addedSection.data);
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  editSection() {
    const sectionToEdit = this.sectionOptions.find((section) => {
      return section.id === this.form.value.section;
    });
    const indexToEdit = this.sectionOptions.findIndex((section) => {
      return section.id === this.form.value.section;
    });
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(SectionEditSheetComponent, {
        data: { section: sectionToEdit },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((updatedSection) => {
          if (updatedSection) {
            this.questionService
              .updateSection(
                this.project_id,
                sectionToEdit.id,
                updatedSection.name,
                updatedSection.instructions,
                updatedSection.details
              )
              .pipe(first())
              .subscribe(
                (newSection) => {
                  //Update sectionOptions
                  this.sectionOptions[indexToEdit] = newSection.data;
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(SectionEditModalComponent, {
        data: { section: sectionToEdit },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((updatedSection) => {
          if (updatedSection) {
            this.questionService
              .updateSection(
                this.project_id,
                sectionToEdit.id,
                updatedSection.name,
                updatedSection.instructions,
                updatedSection.details
              )
              .pipe(first())
              .subscribe(
                (newSection) => {
                  //Update sectionOptions
                  this.sectionOptions[indexToEdit] = newSection.data;
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  openColourPalette(index) {
    const Ref = this.dialog.open(ColourPaletteModalComponent, {
      data: { selected: this.form.value.optionColours[index] },
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          (<UntypedFormArray>this.form.controls.optionColours).controls[
            index
          ].setValue(result);
          this.form.markAsDirty();
        }
      });
  }

  saveChanges() {
    // Save question details
    this.profilingQuestionService
      .updateProfilingQuestion(
        this.project_id,
        this.question_number,
        this.form.value.questionTitle,
        this.form.value.questionType,
        false,
        this.form.value.required,
        this.form.value.filter_use,
        this.form.value.note_use,
        this.form.value.questionInstructions,
        this.form.value.section,
        this.form.value.top_label,
        this.form.value.bottom_label,
        this.form.value.unit_label,
        this.form.value.min_value,
        this.form.value.max_value,
        this.form.value.num_dps
      )
      .pipe(first())
      .subscribe(
        () => {
          // Save option changes
          this.questionOptionsService
            .deleteProfilingOptions(this.project_id, this.question_id)
            .pipe(first())
            .subscribe(
              () => {
                for (let i = 0; i < this.form.value.numOptions; i++) {
                  this.addOptions(
                    this.form.value.optionLabels[i],
                    i,
                    this.form.value.optionIsOther[i],
                    this.form.value.optionIsTerminating[i],
                    this.form.value.optionColours[i]
                  )
                    .then((outcome) => {
                      console.log('Options saved');
                    })
                    .catch((error) => {
                      console.error(error);
                      this.errMsg = error;
                    });
                }
              },
              (error) => {
                console.error(error);
                this.errMsg = error;
              }
            );

          this.form.markAsPristine();
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  async addOptions(label, position, is_other, is_terminating, colour) {
    try {
      await this.questionOptionsService
        .addProfilingOption(
          this.project_id,
          this.question_id,
          label,
          position,
          is_other,
          is_terminating,
          colour
        )
        .pipe(first())
        .subscribe(
          (result) => {
            return result;
          },
          (error) => {
            return error;
          }
        );
    } catch (error) {
      console.error(error);
      this.errMsg = error;
    }
  }

  formWatch() {
    //Track changes in selected question type and reset values, adjust validators as needed
    this.form.controls.questionType.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((val) => {
        if (val === 'Free Text') {
          this.form.controls.numOptions.setValue(0);
          this.form.controls.numOptions.clearValidators();
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.note_use.reset();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
          this.form.controls.filter_use.reset();
        } else if (val === 'Likert') {
          this.form.controls.numOptions.setValue(0);
          this.form.controls.numOptions.clearValidators();
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
        } else if (val === 'Multiple Choice' || val === 'Single Choice') {
          this.form.controls.numOptions.setValidators(Validators.min(2));
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
        } else if (val === 'Numeric') {
          this.form.controls.numOptions.setValue(0);
          this.form.controls.numOptions.clearValidators();
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
        } else if (val === 'Ranking') {
          this.form.controls.numOptions.setValidators(Validators.min(2));
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.top_label.setValidators([
            Validators.required,
            Validators.pattern('^[A-Za-z0-9- ]+$'),
          ]);
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.setValidators([
            Validators.required,
            Validators.pattern('^[A-Za-z0-9- ]+$'),
          ]);
          this.form.controls.bottom_label.updateValueAndValidity();
        }
      });

    //Track changes in number of options and adjust optionLabels & optionColours arrays as needed
    this.form.controls.numOptions.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((val) => {
        var difference =
          val - (<UntypedFormArray>this.form.controls.optionLabels).length;
        //increase number of controls if new value greater than previous number
        if (difference > 0) {
          for (let i = 0; i < difference; i++) {
            (<UntypedFormArray>this.form.controls.optionLabels).push(
              new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(50),
                Validators.pattern(
                  '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'
                ),
              ])
            );
            (<UntypedFormArray>this.form.controls.optionColours).push(
              new UntypedFormControl('')
            );
            (<UntypedFormArray>this.form.controls.optionIsOther).push(
              new UntypedFormControl(false)
            );
            (<UntypedFormArray>this.form.controls.optionIsTerminating).push(
              new UntypedFormControl(false)
            );
          }
        }
        //otherwise decrease number of controls if new value is less than previous number
        else if (difference < 0) {
          for (let i = 0; i < -difference; i++) {
            (<UntypedFormArray>this.form.controls.optionLabels).removeAt(
              (<UntypedFormArray>this.form.controls.optionLabels).length - 1
            );
            (<UntypedFormArray>this.form.controls.optionColours).removeAt(
              (<UntypedFormArray>this.form.controls.optionColours).length - 1
            );
            (<UntypedFormArray>this.form.controls.optionIsOther).removeAt(
              (<UntypedFormArray>this.form.controls.optionIsOther).length - 1
            );
            (<UntypedFormArray>this.form.controls.optionIsTerminating).removeAt(
              (<UntypedFormArray>this.form.controls.optionIsTerminating)
                .length - 1
            );
          }
        }
      });

    //Map changes to optionLables into object array for display components
    this.form.controls.optionLabels.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((options) => {
        this.questionOptions = [];
        options.forEach((element, index) => {
          this.questionOptions.push({
            label: element,
            id: element,
            is_other: this.form.value.optionIsOther[index],
          });
        });
      });
    //Carry through changes to 'other' option selection
    this.form.controls.optionIsOther.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((options) => {
        this.questionOptions = [];
        options.forEach((element, index) => {
          this.questionOptions.push({
            label: this.form.value.optionLabels[index],
            id: this.form.value.optionLabels[index],
            is_other: element,
          });
        });
      });
  }
}
