<div fxLayout="column" *ngIf="project | async as project else processAsync">
    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>

    <!-- PAGE HEADER -->
    <div class="toolbar accent-back" 
        fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="goBack()"><mat-icon>navigate_before</mat-icon></button>
        <h3>Profiling Questions</h3>
        <mat-icon class="icon-spacer" matTooltip="Profiling questions are repeated at the start of every round">help</mat-icon>
        <span class="flex-spacer"></span>
        <button *ngIf="project.data.started_rounds === '0'" 
            fxHide.xs="true" mat-button [matMenuTriggerFor]="importMenu"> 
            <mat-icon>publish</mat-icon>Import
        </button>
        <button *ngIf="project.data.started_rounds === '0'"
            fxHide.gt-xs="true" mat-icon-button [matMenuTriggerFor]="importMenu">
            <mat-icon>publish</mat-icon>
        </button>
            <mat-menu #importMenu="matMenu">
                <button mat-menu-item (click)="importQuestions()">Questions</button>
                <button mat-menu-item (click)="importQuestionOptions()">Question Options</button>
            </mat-menu>
        <button fxShow fxHide.xs="true" mat-button [matMenuTriggerFor]="exportMenu">
            <mat-icon>get_app</mat-icon>Export
        </button>
        <button fxHide fxShow.xs="true" mat-icon-button [matMenuTriggerFor]="exportMenu">
            <mat-icon>get_app</mat-icon>
        </button>
            <mat-menu #exportMenu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="exportCSV">CSV format</button>
                <!-- <button mat-menu-item [matMenuTriggerFor]="exportPDF">PDF format</button> -->
            </mat-menu>
                <mat-menu #exportCSV="matMenu">
                    <button mat-menu-item (click)="exportQuestions()">Questions</button>
                    <button mat-menu-item (click)="exportQuestionOptions()">Question Options</button>
                    <button mat-menu-item (click)="exportResponses()">Responses</button>
                </mat-menu>
                <!-- <mat-menu #exportPDF="matMenu">
                    <button mat-menu-item (click)="exportCopyApproval()">Copy Approval</button>
                </mat-menu> -->
        
        <!-- Download Status -->
        <div *ngIf="download$ | async as download" fxLayout="row" fxLayoutAlign="center center">
            <a *ngIf="download.state=='PENDING'">Building file...</a>
            <a *ngIf="download.state=='IN_PROGRESS'">Downloading...</a>
            <a *ngIf="download.state=='DONE'">Downloaded</a>
            <mat-progress-spinner *ngIf="download.state !== 'DONE'"
                [mode]="download.state == 'PENDING' ? 'indeterminate' : 'determinate'" 
                [value]="download.progress"
                diameter="25"
            ></mat-progress-spinner>
            <mat-icon *ngIf="download.state=='DONE'">done</mat-icon>
        </div>
    </div>

    <!-- QUESTIONS PANEL -->
    <div fxLayout="row" fxLayoutAlign="center start">
        <!-- Questions table -->
        <div class="footer-pad" fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%" *ngIf="questions | async as questions else processAsync">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
                <h3>QUESTIONS</h3>
                <button mat-mini-fab fxHide.gt-xs="true" color="primary" [disabled]="project.data.started_rounds > 0"
                    aria-label="Add question" (click)="addSmallScreen()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <mat-divider></mat-divider>
            <span class="empty-warning" fxLayoutAlign="center center" *ngIf="questions.data.length === 0">No profiling questions have been added</span>
            <app-profiling-questions-table *ngIf="questions.data.length > 0"
                [started_rounds]="project.data.started_rounds"
                ></app-profiling-questions-table>
        </div>

        <!-- Add question -->
        <div class="new-form" *ngIf="project.data.started_rounds === '0'"
            fxFlex="25%" fxFlex.sm="30%" fxHide.lt-sm="true"
            fxLayout="column" fxLayoutAlign="space-between space-between">
            <h3 fxLayout="row" fxLayoutAlign="start" class="subheader">NEW PROFILING QUESTION</h3>
            <mat-divider></mat-divider>
            <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
                <mat-form-field class="form-control">
                    <textarea matInput type="text" formControlName="name" placeholder="Question" required></textarea>
                    <mat-hint>
                        <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                            class="warn-fore">Please enter the question</span>
                        <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="form-control">
                    <mat-label>Question Type</mat-label>
                    <mat-select formControlName="questionType" required>
                        <mat-option *ngFor="let type of questionTypes" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <span *ngIf="form.controls.questionType.errors?.required && form.controls.questionType.touched"
                            class="warn-fore">Please select an option</span>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" class="add-button footer-pad"> 
                <button mat-fab color="primary" aria-label="Add question" [disabled]="form.invalid"
                    (click)="addQuestion(form.value.name, form.value.questionType)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>

</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>

<ng-template #processQuestionsAsync>
    <ng-template #loading>
        <div fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%">
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>