import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { ColourPairs } from '../../../../../_shared/colourPairs';

@Component({
  selector: 'app-profiling-figure-mcq',
  templateUrl: './profiling-figure-mcq.component.html',
  styleUrls: ['./profiling-figure-mcq.component.scss'],
})
export class ProfilingFigureMcqComponent implements OnInit {
  @Input() chartData: any;

  hideChart = true;
  colourPairs = ColourPairs;
  defaultColour = '#880e4f'; //Default to costello purple

  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
  };

  public chartLabels: Label[] = [];
  public chartType: ChartType = 'pie';
  public chartLegend = true;
  public chartPlugins = [];
  public chartColors = [];
  public formattedData = [
    {
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderColor: [],
      hoverBorderColor: [],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.chartData) {
      const numOptions = this.chartData.option_labels.length;
      this.chartData.option_labels.forEach((label, index) => {
        this.chartLabels.push(label);
        this.formattedData[0].data.push(
          Number(this.chartData.option_counts[index])
        );

        if (
          this.chartData.option_colours.includes('       ') ||
          this.chartData.option_colours.includes(null)
        ) {
          this.formattedData[0].backgroundColor.push(
            this.hexToRgb(
              this.defaultColour,
              (numOptions - index) / numOptions - 0.15
            )
          );
          this.formattedData[0].hoverBackgroundColor.push(
            this.hexToRgb(this.defaultColour, (numOptions - index) / numOptions)
          );
          // this.formattedData[0].borderColor.push(this.hexToRgb(this.defaultColour, ((numOptions - index)/numOptions)));
          // this.formattedData[0].hoverBorderColor.push(this.hexToRgb(this.defaultColour, ((numOptions - index)/numOptions)));
        } else {
          this.formattedData[0].backgroundColor.push(
            this.hexToRgb(this.chartData.option_colours[index])
          );
          this.formattedData[0].hoverBackgroundColor.push(
            this.hexToRgb(this.chartData.option_colours[index], 1)
          );
          // this.formattedData[0].borderColor.push(this.hexToRgb(this.chartData.option_colours[index], 1));
          // this.formattedData[0].hoverBorderColor.push(this.hexToRgb(this.chartData.option_colours[index], 1));
        }
      });
      // Check there are data to show
      if (this.formattedData[0].data.reduce((a, b) => a + b, 0) > 0) {
        this.hideChart = false;
      }
    }
  }

  hexToRgb(hex, a_value = 0.5) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? 'rgba(' +
          parseInt(result[1], 16) +
          ', ' +
          parseInt(result[2], 16) +
          ', ' +
          parseInt(result[3], 16) +
          ', ' +
          a_value +
          ')'
      : null;
  }
}
