<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
  ><b>Error:&nbsp;</b> {{ errMsg }}</a
>
<div class="table-container">
  <mat-table
    mat-table
    [dataSource]="tableData"
    *ngIf="showTable"
    matSort
    matSortActive="{{ sortField }}"
    matSortDirection="{{ direction }}"
  >
    <ng-container matColumnDef="first_name">
      <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index">
        <a>{{ item.first_name }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index">
        <a>{{ item.last_name }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index">
        <a>{{ item.email }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index">
        <button mat-button (click)="editUser(item)">Edit</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item; let n = index">
        <button mat-button color="warn" (click)="deleteUser(item.id)">
          Delete
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; let i = index; columns: displayedColumns"
    ></mat-row>
  </mat-table>
</div>

<div
  class="loading"
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="dataSource.loading$ | async"
>
  <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>
