import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ProcessHttpmsgService } from 'src/app/_services/process-httpmsg.service';
import { UserService } from 'src/app/_services/user.service';
import { ConfirmDeleteModalComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { UserEditModalComponent } from 'src/app/components/user-edit/user-edit.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit {
  dataSource: TableDataSource;
  displayedColumns = ['first_name', 'last_name', 'email', 'edit', 'delete'];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'name';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageSub: Subscription;
  pageIndex = 0;
  pageSize = 10;
  ngDestroy$ = new Subject();

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private userService: UserService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  ngOnInit(): void {
    this.dataSource = new TableDataSource(
      this.userService,
      this.processHTTPMsgService
    );

    this.loadTable();
  }

  loadTable() {
    this.dataSource.loadUsers().then(
      (res) => {
        this.tableData = res;
        this.showTable = true;
      },
      (error) => {
        console.error(error);
        this.errMsg = error;
      }
    );
  }

  editUser(user: any) {
    // open edit user modal
    const Ref = this.dialog.open(UserEditModalComponent, {
      width: '400px',
      data: {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      },
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          this.userService
            .updateUser(user.id, data.first_name, data.last_name, data.email)
            .pipe(first())
            .subscribe(
              () => {
                this.loadTable();
              },
              (error) => {
                console.error(error);
                this.snackBar.open(error, 'Close', {
                  duration: 5000,
                });
              }
            );
        }
      });
  }

  deleteUser(user_id: number) {
    // open confirm delete modal
    const Ref = this.dialog.open(ConfirmDeleteModalComponent, {
      width: '400px',
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((confirmation) => {
        if (confirmation) {
          this.userService
            .deleteUser(user_id)
            .pipe(first())
            .subscribe(
              () => {
                this.loadTable();
              },
              (error) => {
                console.error(error);
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private userService: UserService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadUsers(): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.userService
        .getUsers('false', '{""}', 'false')
        .pipe(first())
        .subscribe(
          (users) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(users.data);
            resolve(users.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
