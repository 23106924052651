<div [formGroup]="form" class="form-container" autocomplete="off"> 
    <!-- Section Information -->
    <div *ngIf="section_id">
        <div *ngIf="section | async as section else processAsync">
            <h2>{{section.data.name}}</h2>
            <p class="wrap-text">
                {{section.data.instructions}}
            </p>
            <p class="wrap-text">
                {{section.data.details}}
            </p>
        </div>
    </div>

    <!-- Question Field -->
    <h3 class="question" *ngIf="show_header">{{question}}</h3>
    <p class="wrap-text">{{question_instructions}}</p>

    <a>Drag items from the Options column to the Ranking column and arrange them in the desired order.</a>
    <div cdkDropListGroup fxLayout="row">
        <div class="example-container ">
            <h4>Options</h4>
            <div class="rank-spacer"></div>
            <div 
                cdkDropList
                [cdkDropListData]="storedOptions"
                class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of storedOptions" cdkDrag>{{item.label}}</div>
            </div>
        </div>
        <div class="example-container" 
            fxLayout="column">
            <h4>Ranking</h4>
            <a class="rank-label">{{top_label}}</a>
            <div
                cdkDropList
                [cdkDropListData]="dropList"
                class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of dropList" cdkDrag>{{item.label}}</div>
            </div>
            
            <a class="rank-label">{{bottom_label}}</a>
        </div>
    </div>

    <!-- Optional Notes Field -->
    <mat-form-field *ngIf="useNote" class="form-field"> 
        <mat-label>Include any notes or justification for your response here</mat-label>
        <textarea matInput formControlName="note"></textarea>
        <mat-hint>
            <span class="warn-fore" [hidden]="!form.controls.note.errors?.pattern">This field contains unsupported characters!</span>
        </mat-hint>
    </mat-form-field>

    <!-- Opt Out Options -->
    <div>
        <div *ngIf="useSkip1 && skipLabel1">
            <mat-checkbox formControlName="skip1">{{skipLabel1}}</mat-checkbox>
        </div>
        <div *ngIf="useSkip2 && skipLabel2" >
            <mat-checkbox formControlName="skip2">{{skipLabel2}}</mat-checkbox>
        </div>
    </div>

</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div>
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>