import { Component, OnInit } from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-section-add-sheet',
  templateUrl: './section-add-sheet.component.html',
  styleUrls: ['./section-add-sheet.component.scss']
})
export class SectionAddSheetComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SectionAddSheetComponent>,
    private fb: UntypedFormBuilder,
  ) {
    this.form = fb.group({
      name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\/^\\^\@^\^^;^\`^#]*$')]],
      instructions: ['', Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')],
      details: ['', Validators.pattern('^[^=^\+^\-^@^\t^\r^\/^\\^\^^;^\`^#][^\\^\@^\^^;^\`^#]*$')]
    });
   }

  ngOnInit(): void {
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }

  save(): void {
    this._bottomSheetRef.dismiss(this.form.value);
  }

}
