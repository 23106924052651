
<div fxLayout="column">
    <!-- ERROR BANNER -->
    <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
    
    <!-- PAGE BANNER -->
    <div class="banner accent-back" 
        fxLayout="row" fxLayoutAlign="start center"
        fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start">
        <div fxLayout="row" fxLayoutAlign="start center">
            <a class="counter primary-fore">OVERVIEW</a>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <a class="bold counter primary-fore">123</a>
            <a fxHide.lt-md="true" class="counter-label primary-fore">TOTAL NUMBER OF PROJECTS</a>
            <a fxHide.gt-sm="true" class="counter-label primary-fore">TOTAL PROJECTS</a>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <a class="bold counter primary-fore">86</a>
            <a fxHide.lt-md="true" class="counter-label primary-fore">NUMBER OF ACTIVE PROJECTS</a>
            <a fxHide.gt-sm="true" class="counter-label primary-fore">ACTIVE PROJECTS</a>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <a class="bold counter primary-fore">35</a>
            <a fxHide.lt-md="true" class="counter-label primary-fore end-label">NUMBER OF COMPLETED PROJECTS</a>
            <a fxHide.gt-sm="true"class="counter-label primary-fore end-label">COMPLETED PROJECTS</a>
        </div> -->
        
    </div>
    <div class="main-body" fxLayout="row">
        <!-- PROJECT LIST -->
        <div class="footer-pad" fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%" *ngIf="projects | async as projects else processAsync">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="subheader">
                <h3 >PROJECT LIST</h3>
                <button mat-mini-fab fxHide.gt-xs="true" color="primary" aria-label="Add project" (click)="addSmallScreen()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <mat-divider></mat-divider>
            <span fxLayoutAlign="center center" *ngIf="projects.data.length === 0">You don't have any projects yet!</span>
            <app-overview-table *ngIf="projects.data.length > 0" [admin]="admin" [user_id]="user_id"></app-overview-table>
        </div>
        <!-- NEW PROJECT FORM -->
        <div class="new-form" 
            fxFlex="25%" fxFlex.sm="30%" fxHide.lt-sm="true"
            fxLayout="column" fxLayoutAlign="space-between space-between">
            <h3 fxLayout="row" fxLayoutAlign="start" class="subheader">NEW PROJECT</h3>
            <mat-divider></mat-divider>
            <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
                <mat-form-field class="form-control">
                    <input matInput type="text" formControlName="name" placeholder="Project Title" maxlength="50" required>
                    <mat-hint>
                        <span *ngIf="form.controls.name.errors?.required && form.controls.name.touched"
                            class="warn-fore">Please enter a project title</span>
                        <span class="warn-fore" [hidden]="!form.controls.name.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="form-control">
                    <input matInput type="text" formControlName="project_code" placeholder="Project Code" maxlength="20">
                    <mat-hint>
                        <span class="warn-fore" [hidden]="!form.controls.project_code.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="form-control">
                    <mat-label>Client Company</mat-label>
                    <mat-select formControlName="clientCompany" required>
                        <mat-option *ngFor="let company of companies" [value]="company.id">
                            {{company.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <span *ngIf="form.controls.clientCompany.errors?.required && form.controls.clientCompany.touched"
                            class="warn-fore">Please select an option</span>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field *ngIf="form.value.clientCompany ===-1">
                    <input matInput type="text" formControlName="newClientCompanyName" placeholder="New Client Company Name"
                        maxlength="30" [required]="form.value.clientCompany ===-1">
                    <mat-hint>
                        <span *ngIf="form.controls.newClientCompanyName.errors?.required && form.controls.newClientCompanyName.touched"
                            class="warn-fore">Please enter the name of the new company</span>
                        <span class="warn-fore" [hidden]="!form.controls.newClientCompanyName.errors?.pattern">This field contains unsupported characters!</span>
                    </mat-hint>
                </mat-form-field>  
                <mat-form-field class="form-control">
                    <input matInput type="text" formControlName="diseaseArea" placeholder="Disease Area">
                </mat-form-field>  
                <mat-checkbox class="form-control" formControlName="hideIncompleteResults" color="primary">
                    Hide consensus results from clients for open rounds</mat-checkbox>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" class="footer-pad add-button"> 
                <button mat-fab color="primary" aria-label="Add project" [disabled]="form.invalid"
                    (click)="addProject(form.value.newClientCompanyName, form.value.clientCompany, form.value.name, form.value.project_code, form.value.diseaseArea, form.value.hideIncompleteResults)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #processAsync>
    <ng-template #loading>
        <div fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%">
            <mat-spinner color="accent" diameter="50"></mat-spinner>
        </div>
    </ng-template>
</ng-template>