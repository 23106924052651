import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { QuestionService } from '../../../../../_services/question.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { SectionAddSheetComponent } from '../../../../../components/section-add-sheet/section-add-sheet.component';
import { SectionAddModalComponent } from '../../../../../components/section-add-modal/section-add-modal.component';
import { SectionEditSheetComponent } from '../../../../../components/section-edit-sheet/section-edit-sheet.component';
import { SectionEditModalComponent } from '../../../../../components/section-edit-modal/section-edit-modal.component';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ColourPairs } from '../../../../../_shared/colourPairs';
import { ColourPaletteModalComponent } from '../../../../../components/colour-palette/colour-palette.component';
import { QuestionOptionsService } from '../../../../../_services/question-options.service';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ResponseService } from '../../../../../_services/response.service';
import { ProjectService } from '../../../../../_services/project.service';
import { RoundService } from '../../../../../_services/round.service';
import { QuestionTypeFunctions } from '../../../../../_shared/questionTypes';
import {
  BranchingLogicModalComponent,
  BranchingLogicSheetComponent,
} from '../branching-logic/branching-logic.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../../../_services/user.service';

@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss'],
})
export class QuestionEditComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_number = this.route.snapshot.paramMap.get('question_number');
  question_id: Number;

  project: Observable<any>;
  chartData: Observable<any>;
  textData: Observable<any>;
  question: Observable<any>;
  sections: Observable<any>;
  round: Observable<any>;
  linked_questions: Observable<any>;
  prior_questions: Observable<any>;
  questionTypes = [];
  stored_questions = [];
  sectionOptions = [];

  ngDestroy$ = new Subject();

  form: UntypedFormGroup;
  dummyForm: UntypedFormGroup;
  validationNote: String;

  colourOptions = ColourPairs;

  dropList = [];

  numOptions = 0;
  questionOptions = [];
  likertOptions = [];

  likert_consensus = false;
  mcq_consensus = false;
  ranking_consensus = false;
  scq_consensus = false;

  panelOpenState = false;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private questionService: QuestionService,
    private questionOptionsService: QuestionOptionsService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private responseService: ResponseService,
    private roundService: RoundService,
    private fb: UntypedFormBuilder,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private userService: UserService
  ) {
    //TODO: work out how editing question numbers can be done - maybe better from round dash question table?
    this.form = fb.group({
      section: [],
      // question_number: [, Validators.required], //Required for all questions
      copy_question: [],
      questionType: ['', Validators.required], //Required for all questions
      questionTitle: [
        '',
        [
          Validators.required,
          Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'),
        ],
      ], //Required for all questions
      questionInstructions: [
        '',
        [Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$')],
      ],
      numOptions: [0, Validators.required],
      optionLabels: new UntypedFormArray([]), //Required for mc, ranking and sc
      optionColours: new UntypedFormArray([]), //Required for mc, ranking and sc
      optionIsOther: new UntypedFormArray([]), // for mcq, scq and likert(one day)
      min_value: [],
      max_value: [],
      num_dps: [],
      unit_label: [
        '',
        [
          Validators.maxLength(20),
          Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'),
        ],
      ],
      note_use: [false], //Required for all non-text questions
      top_label: ['Highest', Validators.pattern('^[A-Za-z0-9- ]+$')], //Required for ranking questions
      bottom_label: ['Lowest', Validators.pattern('^[A-Za-z0-9- ]+$')], //Required for ranking questions
      standard_question: [false], //Required for all questions
      linked_question: [],
      filter_use: [false], //Required for all non-text questions
      calculate_consensus: [true], //Required for all questions
      required: [true],
    });

    this.dummyForm = fb.group({
      response: [, Validators.required], //Required for all questions unless skip is checked
      note: [],
      skip1: [false],
      skip2: [false],
    });
  }

  ngOnInit(): void {
    //watch for changes in question settings
    this.formWatch();

    //watch for changes in route params
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      if (this.userService.isAuthenticated) {
        this.userService
          .getUser()
          .pipe(first())
          .subscribe(
            (user) => {
              if (user) {
                if (user.client_account) {
                  this.router.navigate(['/login']);
                } else if (user.participant_account) {
                  this.router.navigate(['/login']);
                } else {
                  this.project_id = params.project_id;
                  this.round_id = params.round_id;
                  this.question_number = params.question_number;

                  //Retrieve data needed for the forms
                  this.question = this.questionService
                    .getQuestion(
                      this.project_id,
                      this.round_id,
                      this.question_number
                    )
                    .pipe(
                      catchError((error) => {
                        this.errMsg = error;
                        console.error('Error loading question:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.sections = this.questionService
                    .getSections(this.project_id, this.round_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg = error;
                        console.error('Error loading sections:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.sections.pipe(first()).subscribe(
                    (sections) => {
                      this.sectionOptions = sections.data;
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );

                  this.linked_questions = this.questionService
                    .getPriorQuestions(this.project_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg = error;
                        console.error('Error loading questions:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.questionOptionsService
                    .getLikertOptions(this.project_id, this.round_id)
                    .pipe(first())
                    .subscribe(
                      (likert_options) => {
                        this.likertOptions = likert_options.data;
                      },
                      (error) => {
                        this.errMsg = error;
                      }
                    );
                  this.project = this.projectService
                    .getProject(this.project_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg =
                          this.processHTTPMsgService.extractH1Error(error);
                        console.error('Error loading project:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.round = this.roundService
                    .getRound(this.project_id, this.round_id)
                    .pipe(
                      catchError((error) => {
                        this.errMsg = error;
                        console.error('Error loading round:', error);
                        this.loadingError$.next(true);
                        return of();
                      })
                    );
                  this.round.pipe(first()).subscribe(
                    (result) => {
                      this.questionTypes =
                        QuestionTypeFunctions.getQuestionTypes(
                          result.data.use_freetext,
                          result.data.use_likert,
                          result.data.use_mcq,
                          result.data.use_numeric,
                          result.data.use_ranking,
                          result.data.use_scq
                        );
                      this.likert_consensus = result.data.likert_consensus;
                      this.mcq_consensus = result.data.mcq_consensus;
                      this.ranking_consensus = result.data.ranking_consensus;
                      this.scq_consensus = result.data.scq_consensus;

                      this.prior_questions = this.questionService
                        .getPriorQuestions(
                          this.project_id,
                          JSON.stringify(this.questionTypes)
                            .replace('[', '{')
                            .replace(']', '}')
                        )
                        .pipe(
                          catchError((error) => {
                            this.errMsg = error;
                            console.error('Error loading questions:', error);
                            this.loadingError$.next(true);
                            return of();
                          })
                        );
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );

                  this.question.pipe(first()).subscribe(
                    (question) => {
                      this.question_id = question.data.id;
                      this.form.controls.section.setValue(
                        question.data.section
                      );
                      this.form.controls.questionType.setValue(
                        question.data.question_type
                      );
                      this.form.controls.questionTitle.setValue(
                        question.data.question
                      );
                      this.form.controls.questionInstructions.setValue(
                        question.data.instructions
                      );
                      this.form.controls.min_value.setValue(
                        question.data.min_value
                      );
                      this.form.controls.max_value.setValue(
                        question.data.max_value
                      );
                      this.form.controls.num_dps.setValue(
                        question.data.num_dps
                      );
                      this.form.controls.unit_label.setValue(
                        question.data.unit_label
                      );
                      this.form.controls.top_label.setValue(
                        question.data.top_label
                      );
                      this.form.controls.bottom_label.setValue(
                        question.data.bottom_label
                      );
                      this.form.controls.filter_use.setValue(
                        question.data.filter_use
                      );
                      this.form.controls.note_use.setValue(
                        question.data.note_use
                      );
                      this.form.controls.required.setValue(
                        question.data.required
                      );
                      this.form.controls.calculate_consensus.setValue(
                        question.data.calculate_consensus
                      );
                      // this.form.controls.linked_question.setValue(question.data.linked_question);
                      this.linked_questions.pipe(first()).subscribe(
                        (linked_questions) => {
                          this.stored_questions = linked_questions.data;
                          const matched_question = this.stored_questions.find(
                            (pq) => {
                              return pq.id === question.data.linked_qu_id;
                            }
                          );
                          this.form.controls.linked_question.setValue(
                            matched_question
                          );
                        },
                        (error) => {
                          this.errMsg = error;
                        }
                      );

                      if (question.data.question_options) {
                        this.form.controls.numOptions.setValue(
                          question.data.question_options.length
                        );

                        //RESET OPTIONS
                        for (
                          let i = 0;
                          i <
                          (<UntypedFormArray>this.form.controls.optionLabels)
                            .length;
                          i++
                        ) {
                          (<UntypedFormArray>(
                            this.form.controls.optionLabels
                          )).controls[i].setValue(
                            question.data.question_options[i].label,
                            Validators.pattern(
                              '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'
                            )
                          );
                          (<UntypedFormArray>(
                            this.form.controls.optionColours
                          )).controls[i].setValue(
                            question.data.question_options[i].colour
                          );
                          (<UntypedFormArray>(
                            this.form.controls.optionIsOther
                          )).controls[i].setValue(
                            question.data.question_options[i].is_other
                          );
                        }
                      } else {
                        this.form.controls.numOptions.setValue(0);
                      }
                      this.form.markAsPristine();
                    },
                    (error) => {
                      this.errMsg = error;
                    }
                  );
                }
              }
            },
            (error) => {
              this.errMsg = error;
            }
          );
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  toggleExpand() {
    this.panelOpenState = !this.panelOpenState;
  }

  goBack(): void {
    this.router.navigate([
      '/main/project/' + this.project_id + '/round/' + this.round_id,
    ]);
  }

  copy() {
    this.form.controls.section.setValue(this.form.value.copy_question.section);
    this.form.controls.questionType.setValue(
      this.form.value.copy_question.question_type
    );
    this.form.controls.questionTitle.setValue(
      this.form.value.copy_question.question
    );
    this.form.controls.questionInstructions.setValue(
      this.form.value.copy_question.instructions
    );
    this.form.controls.min_value.setValue(
      this.form.value.copy_question.min_value
    );
    this.form.controls.max_value.setValue(
      this.form.value.copy_question.max_value
    );
    this.form.controls.num_dps.setValue(this.form.value.copy_question.num_dps);
    this.form.controls.unit_label.setValue(
      this.form.value.copy_question.unit_label
    );
    this.form.controls.top_label.setValue(
      this.form.value.copy_question.top_label
    );
    this.form.controls.bottom_label.setValue(
      this.form.value.copy_question.bottom_label
    );
    this.form.controls.filter_use.setValue(
      this.form.value.copy_question.filter_use
    );
    this.form.controls.note_use.setValue(
      this.form.value.copy_question.note_use
    );
    this.form.controls.required.setValue(
      this.form.value.copy_question.required
    );

    if (
      this.form.value.copy_question.question_type === 'Likert' &&
      this.likert_consensus
    ) {
      this.form.controls.calculate_consensus.setValue(
        this.form.value.copy_question.calculate_consensus
      );
    } else if (
      this.form.value.copy_question.question_type === 'Multiple Choice' &&
      this.mcq_consensus
    ) {
      this.form.controls.calculate_consensus.setValue(
        this.form.value.copy_question.calculate_consensus
      );
    } else if (
      this.form.value.copy_question.question_type === 'Ranking' &&
      this.ranking_consensus
    ) {
      this.form.controls.calculate_consensus.setValue(
        this.form.value.copy_question.calculate_consensus
      );
    } else if (
      this.form.value.copy_question.question_type === 'Single Choice' &&
      this.scq_consensus
    ) {
      this.form.controls.calculate_consensus.setValue(
        this.form.value.copy_question.calculate_consensus
      );
    }

    //Disabled since unlikely to want to link to same question, and because selected question needs to be full question matching prior_questions object, not just the id (though could look up match from prior questions list)
    // this.form.controls.linked_question.setValue(this.form.value.copy_question.linked_question);

    //Add options
    if (this.form.value.copy_question.question_options) {
      this.form.controls.numOptions.setValue(
        this.form.value.copy_question.question_options.length
      );

      //RESET OPTIONS
      for (
        let i = 0;
        i < (<UntypedFormArray>this.form.controls.optionLabels).length;
        i++
      ) {
        (<UntypedFormArray>this.form.controls.optionLabels).controls[
          i
        ].setValue(
          this.form.value.copy_question.question_options[i].label,
          Validators.pattern('^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$')
        );
        (<UntypedFormArray>this.form.controls.optionColours).controls[
          i
        ].setValue(this.form.value.copy_question.question_options[i].colour);
        (<UntypedFormArray>this.form.controls.optionIsOther).controls[
          i
        ].setValue(this.form.value.copy_question.question_options[i].is_other);
      }
    } else {
      this.form.controls.numOptions.setValue(0);
    }
  }

  clearSection() {
    this.form.controls.section.reset();
    this.form.controls.section.markAsDirty();
  }

  addSection() {
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(SectionAddSheetComponent);
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((newSection) => {
          if (newSection) {
            this.questionService
              .addQuestionSection(
                this.project_id,
                this.question_id,
                newSection.name,
                newSection.instructions,
                newSection.details
              )
              .pipe(first())
              .subscribe(
                (addedSection) => {
                  // Add id to question and form control
                  this.form.controls.section.setValue(addedSection.data.id);
                  this.form.controls.section.markAsDirty();
                  // Add section to section options list
                  this.sectionOptions.push(addedSection.data);
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(SectionAddModalComponent, {
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((newSection) => {
          if (newSection) {
            this.questionService
              .addQuestionSection(
                this.project_id,
                this.question_id,
                newSection.name,
                newSection.instructions,
                newSection.details
              )
              .pipe(first())
              .subscribe(
                (addedSection) => {
                  // Add id to question and form control
                  this.form.controls.section.setValue(addedSection.data.id);
                  this.form.controls.section.markAsDirty();
                  // Add section to section options list
                  this.sectionOptions.push(addedSection.data);
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  editSection() {
    const sectionToEdit = this.sectionOptions.find((section) => {
      return section.id === this.form.value.section;
    });
    const indexToEdit = this.sectionOptions.findIndex((section) => {
      return section.id === this.form.value.section;
    });
    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(SectionEditSheetComponent, {
        data: { section: sectionToEdit },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((updatedSection) => {
          if (updatedSection) {
            this.questionService
              .updateSection(
                this.project_id,
                sectionToEdit.id,
                updatedSection.name,
                updatedSection.instructions,
                updatedSection.details
              )
              .pipe(first())
              .subscribe(
                (newSection) => {
                  //Update sectionOptions
                  this.sectionOptions[indexToEdit] = newSection.data;
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(SectionEditModalComponent, {
        data: { section: sectionToEdit },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((updatedSection) => {
          if (updatedSection) {
            this.questionService
              .updateSection(
                this.project_id,
                sectionToEdit.id,
                updatedSection.name,
                updatedSection.instructions,
                updatedSection.details
              )
              .pipe(first())
              .subscribe(
                (newSection) => {
                  //Update sectionOptions
                  this.sectionOptions[indexToEdit] = newSection.data;
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  clearLinkedQuestion() {
    this.form.controls.linked_question.reset();
  }

  openColourPalette(index) {
    const Ref = this.dialog.open(ColourPaletteModalComponent, {
      data: { selected: this.form.value.optionColours[index] },
    });
    Ref.afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          (<UntypedFormArray>this.form.controls.optionColours).controls[
            index
          ].setValue(result);
          this.form.markAsDirty();
        }
      });
  }

  branchingLogic() {
    var opts = [];
    if (this.form.value.questionType === 'Likert') {
      opts = this.likertOptions;
    } else {
      opts = this.questionOptions;
    }

    if (window.innerWidth <= 599) {
      // Small screen version:
      const Ref = this._bottomSheet.open(BranchingLogicSheetComponent, {
        data: {
          options: opts,
          project_id: this.project_id,
          round_id: this.round_id,
          question_id: this.question_id,
        },
      });
      Ref.afterDismissed()
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            this.questionService
              .addBranchingLogic(
                this.project_id,
                this.round_id,
                this.question_id,
                result
              )
              .pipe(first())
              .subscribe(
                () => {
                  this.snackBar.open('Branching logic updated!', 'Close', {
                    duration: 1500,
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    } else {
      // Large screen version:
      const Ref = this.dialog.open(BranchingLogicModalComponent, {
        data: {
          options: opts,
          project_id: this.project_id,
          round_id: this.round_id,
          question_id: this.question_id,
        },
        width: '600px',
      });
      Ref.afterClosed()
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            this.questionService
              .addBranchingLogic(
                this.project_id,
                this.round_id,
                this.question_id,
                result
              )
              .pipe(first())
              .subscribe(
                () => {
                  this.snackBar.open('Branching logic updated!', 'Close', {
                    duration: 1500,
                  });
                },
                (error) => {
                  this.errMsg = error;
                }
              );
          }
        });
    }
  }

  saveChanges() {
    // Save question details
    var linked_question = null;
    if (this.form.value.linked_question) {
      linked_question = this.form.value.linked_question.id;
    }
    this.questionService
      .updateQuestion(
        this.project_id,
        this.round_id,
        this.question_number,
        this.form.value.questionTitle,
        this.form.value.questionType,
        false,
        this.form.value.required,
        this.form.value.calculate_consensus,
        this.form.value.filter_use,
        this.form.value.note_use,
        this.form.value.questionInstructions,
        this.form.value.section,
        this.form.value.top_label,
        this.form.value.bottom_label,
        this.form.value.unit_label,
        this.form.value.min_value,
        this.form.value.max_value,
        this.form.value.num_dps,
        linked_question
      )
      .pipe(first())
      .subscribe(
        () => {
          // Save option changes
          this.questionOptionsService
            .deleteQuestionOptions(
              this.project_id,
              this.round_id,
              this.question_id
            )
            .pipe(first())
            .subscribe(
              () => {
                for (let i = 0; i < this.form.value.numOptions; i++) {
                  this.addOptions(
                    this.form.value.optionLabels[i],
                    i,
                    this.form.value.optionIsOther[i],
                    this.form.value.optionColours[i]
                  )
                    .then((outcome) => {
                      console.log('Options saved');
                    })
                    .catch((error) => {
                      console.error(error);
                      this.errMsg = error;
                    });
                }
              },
              (error) => {
                console.error(error);
                this.errMsg = error;
              }
            );

          this.form.markAsPristine();
        },
        (error) => {
          this.errMsg = error;
        }
      );
  }

  async addOptions(label, position, is_other, colour) {
    try {
      await this.questionOptionsService
        .addQuestionOption(
          this.project_id,
          this.round_id,
          this.question_id,
          label,
          position,
          is_other,
          colour
        )
        .pipe(first())
        .subscribe(
          (result) => {
            return result;
          },
          (error) => {
            return error;
          }
        );
    } catch (error) {
      console.error(error);
      this.errMsg = error;
    }
  }

  formWatch() {
    //Track changes in selected question type and reset values, adjust validators as needed
    this.form.controls.questionType.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((val) => {
        if (val === 'Free Text') {
          this.form.controls.numOptions.setValue(0);
          this.form.controls.numOptions.clearValidators();
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.note_use.reset();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
          this.form.controls.filter_use.reset();
          this.form.controls.calculate_consensus.setValue(false);
        } else if (val === 'Likert') {
          this.form.controls.numOptions.setValue(0);
          this.form.controls.numOptions.clearValidators();
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
          if (this.likert_consensus !== true) {
            this.form.controls.calculate_consensus.setValue(false);
          }
        } else if (val === 'Multiple Choice' || val === 'Single Choice') {
          this.form.controls.numOptions.setValidators(Validators.min(2));
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
          if (
            (val === 'Multiple Choice' && this.mcq_consensus !== true) ||
            (val === 'Single Choice' && this.scq_consensus !== true)
          ) {
            this.form.controls.calculate_consensus.setValue(false);
          }
        } else if (val === 'Numeric') {
          this.form.controls.numOptions.setValue(0);
          this.form.controls.numOptions.clearValidators();
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.top_label.clearValidators();
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.clearValidators();
          this.form.controls.bottom_label.updateValueAndValidity();
          this.form.controls.calculate_consensus.setValue(false);
        } else if (val === 'Ranking') {
          this.form.controls.numOptions.setValidators(Validators.min(2));
          this.form.controls.numOptions.updateValueAndValidity();
          this.form.controls.min_value.reset();
          this.form.controls.max_value.reset();
          this.form.controls.num_dps.reset();
          this.form.controls.unit_label.reset();
          this.form.controls.top_label.setValidators([
            Validators.required,
            Validators.pattern('^[A-Za-z0-9- ]+$'),
          ]);
          this.form.controls.top_label.updateValueAndValidity();
          this.form.controls.bottom_label.setValidators([
            Validators.required,
            Validators.pattern('^[A-Za-z0-9- ]+$'),
          ]);
          this.form.controls.bottom_label.updateValueAndValidity();
          if (this.ranking_consensus !== true) {
            this.form.controls.calculate_consensus.setValue(false);
          }
        }
      });

    //Track changes in number of options and adjust optionLabels array as needed
    this.form.controls.numOptions.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((val) => {
        var difference =
          val - (<UntypedFormArray>this.form.controls.optionLabels).length;
        //increase number of controls if new value greater than previous number
        if (difference > 0) {
          for (let i = 0; i < difference; i++) {
            (<UntypedFormArray>this.form.controls.optionLabels).push(
              new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(50),
                Validators.pattern(
                  '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^\\^@^^^;^`^#]*$'
                ),
              ])
            );
            (<UntypedFormArray>this.form.controls.optionColours).push(
              new UntypedFormControl('')
            );
            (<UntypedFormArray>this.form.controls.optionIsOther).push(
              new UntypedFormControl(false)
            );
          }
        }
        //otherwise decrease number of controls if new value is less than previous number
        else if (difference < 0) {
          for (let i = 0; i < -difference; i++) {
            (<UntypedFormArray>this.form.controls.optionLabels).removeAt(
              (<UntypedFormArray>this.form.controls.optionLabels).length - 1
            );
            (<UntypedFormArray>this.form.controls.optionColours).removeAt(
              (<UntypedFormArray>this.form.controls.optionColours).length - 1
            );
            (<UntypedFormArray>this.form.controls.optionIsOther).removeAt(
              (<UntypedFormArray>this.form.controls.optionIsOther).length - 1
            );
          }
        }
      });

    //Map changes to optionLables into object array for display components
    this.form.controls.optionLabels.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((options) => {
        this.questionOptions = [];
        options.forEach((element, index) => {
          this.questionOptions.push({
            label: element,
            id: element,
            is_other: this.form.value.optionIsOther[index],
          });
        });
      });
    //Carry through changes to 'other' option selection
    this.form.controls.optionIsOther.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((options) => {
        this.questionOptions = [];
        options.forEach((element, index) => {
          this.questionOptions.push({
            label: this.form.value.optionLabels[index],
            id: this.form.value.optionLabels[index],
            is_other: element,
          });
        });
      });

    // Track changes in linked question to reload associated summary data
    this.form.controls.linked_question.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((linked_qu) => {
        console.log(linked_qu);
        if (linked_qu) {
          // Load text data
          this.textData = this.responseService
            .textSummary(this.project_id, linked_qu.id)
            .pipe(
              catchError((error) => {
                this.errMsg = this.processHTTPMsgService.extractH1Error(error);
                console.error('Error loading linked question data:', error);
                this.loadingError$.next(true);
                return of();
              })
            );

          // Load chart data if applicable
          if (linked_qu.question_type === 'Likert') {
            this.chartData = this.responseService
              .likertChoiceSummary(this.project_id, linked_qu.id)
              .pipe(
                catchError((error) => {
                  this.errMsg =
                    this.processHTTPMsgService.extractH1Error(error);
                  console.error('Error loading linked question data:', error);
                  this.loadingError$.next(true);
                  return of();
                })
              );
          } else if (linked_qu.question_type === 'Multiple Choice') {
            this.chartData = this.responseService
              .multipleChoiceSummary(this.project_id, linked_qu.id)
              .pipe(
                catchError((error) => {
                  this.errMsg =
                    this.processHTTPMsgService.extractH1Error(error);
                  console.error('Error loading linked question data:', error);
                  this.loadingError$.next(true);
                  return of();
                })
              );
          } else if (linked_qu.question_type === 'Numeric') {
            this.chartData = this.responseService
              .numericSummary(this.project_id, linked_qu.id)
              .pipe(
                catchError((error) => {
                  this.errMsg =
                    this.processHTTPMsgService.extractH1Error(error);
                  console.error('Error loading linked question data:', error);
                  this.loadingError$.next(true);
                  return of();
                })
              );
          } else if (linked_qu.question_type === 'Ranking') {
            this.chartData = this.responseService
              .rankingSummary(this.project_id, linked_qu.id)
              .pipe(
                catchError((error) => {
                  this.errMsg =
                    this.processHTTPMsgService.extractH1Error(error);
                  console.error('Error loading linked question data:', error);
                  this.loadingError$.next(true);
                  return of();
                })
              );
          } else if (linked_qu.question_type === 'Single Choice') {
            this.chartData = this.responseService
              .singleChoiceSummary(this.project_id, linked_qu.id)
              .pipe(
                catchError((error) => {
                  this.errMsg =
                    this.processHTTPMsgService.extractH1Error(error);
                  console.error('Error loading linked question data:', error);
                  this.loadingError$.next(true);
                  return of();
                })
              );
          }
        }
      });
  }

  setClientTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-colour', primary);
    document.documentElement.style.setProperty('--secondary-colour', secondary);
  }
}
