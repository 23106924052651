<div fxLayout="column">
  <!-- ERROR BANNER -->
  <!-- <a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"
    ><b>Error:&nbsp;</b> {{ errMsg }}</a
  > -->

  <!-- PAGE BANNER -->
  <div
    class="banner accent-back"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="center start"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <a class="counter primary-fore">USERS</a>
    </div>
  </div>
  <div class="main-body" fxLayout="row">
    <!-- USER LIST -->
    <div
      class="footer-pad"
      fxFlex="75%"
      fxFlex.sm="70%"
      fxFlex.xs="100%"
      *ngIf="users | async as users; else processAsync"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="subheader"
      >
        <h3>COSTELLO USER LIST</h3>
        <button
          mat-mini-fab
          fxHide.gt-xs="true"
          color="primary"
          aria-label="Add user"
          (click)="addSmallScreen()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
      <span fxLayoutAlign="center center" *ngIf="users.data.length === 0"
        >There are no users!</span
      >
      <app-users-table
        *ngIf="users.data.length > 0"
        [admin]="admin"
        [user_id]="user_id"
      ></app-users-table>
    </div>
    <!-- NEW USER FORM -->
    <div
      class="new-form"
      fxFlex="25%"
      fxFlex.sm="30%"
      fxHide.lt-sm="true"
      fxLayout="column"
      fxLayoutAlign="space-between space-between"
    >
      <h3 fxLayout="row" fxLayoutAlign="start" class="subheader">
        NEW COSTELLO USER
      </h3>
      <mat-divider></mat-divider>
      <div [formGroup]="form" autocomplete="off" fxLayout="column" class="form">
        <mat-form-field class="form-control">
          <input
            matInput
            type="text"
            formControlName="first_name"
            placeholder="First Name"
            maxlength="100"
            required
          />
          <mat-hint>
            <span
              *ngIf="
                form.controls.first_name.errors?.required &&
                form.controls.first_name.touched
              "
              class="warn-fore"
              >Please enter the user's first name</span
            >
            <span
              class="warn-fore"
              [hidden]="!form.controls.first_name.errors?.pattern"
              >This field contains unsupported characters!</span
            >
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control">
          <input
            matInput
            type="text"
            formControlName="last_name"
            placeholder="Last Name"
            maxlength="100"
          />
          <mat-hint>
            <span
              class="warn-fore"
              [hidden]="!form.controls.last_name.errors?.pattern"
              >This field contains unsupported characters!</span
            >
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="form-control">
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
            maxlength="100"
            email
            required
          />
          <mat-hint>
            <span
              *ngIf="
                form.controls.email.errors?.required &&
                form.controls.email.touched
              "
              class="warn-fore"
              >Please enter the user's email address</span
            >
            <span
              class="warn-fore"
              [hidden]="!form.controls.email.errors?.email"
              >Please enter a valid email address</span
            >
          </mat-hint>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="end" class="footer-pad add-button">
        <button
          mat-fab
          color="primary"
          aria-label="Add user"
          [disabled]="form.invalid"
          (click)="
            addUser(
              form.value.first_name,
              form.value.last_name,
              form.value.email
            )
          "
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #processAsync>
  <ng-template #loading>
    <div fxFlex="75%" fxFlex.sm="70%" fxFlex.xs="100%">
      <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>
  </ng-template>
</ng-template>
