import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-participant-exit',
  templateUrl: './participant-exit.component.html',
  styleUrls: ['./participant-exit.component.scss']
})
export class ParticipantExitComponent implements OnInit, OnDestroy {

  project_id = this.route.parent.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_num = this.route.snapshot.paramMap.get('question_num');

  ngDestroy$ = new Subject();

  constructor(
    public route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe(params => {
      this.round_id = params.round_id;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  amend(): void {
    this.router.navigate(['/panel/' + this.project_id + '/round/' + this.round_id + '/background_questions']);
  }

}
