import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://ae7bd9695a0a4b55af27b2f83cbe3e41@o448216.ingest.sentry.io/5661662',
  release: 'delphi@1.1.0',
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     tracingOrigins: ["localhost", "https://yourserver.io/api"],
  //     routingInstrumentation: Sentry.routingInstrumentation,
  //   }),
  // ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
