import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { baseURL } from '../_shared/baseurl';
import { ProcessHttpmsgService } from './process-httpmsg.service';

interface AuthResponse {
  status: string;
  data: {
    id: number;
    first_name: string;
    last_name: string;
    admin_account: boolean;
    client_account: boolean;
    participant_account: boolean;
    email: string;
    created_on: Date;
    updated_on: Date;
    token: string;
  };
}

interface JWTResponse {
  status: string;
  success: string;
  user: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  authToken: string = undefined;
  isAuthenticated: Boolean = false;
  tokenKey = 'JWT';
  admin: Subject<boolean> = new Subject<boolean>();
  clientAccount: Subject<boolean> = new Subject<boolean>();
  participantAccount: Subject<boolean> = new Subject<boolean>();
  username: Subject<string> = new Subject<string>();
  userId: Subject<number> = new Subject<number>();
  user: Subject<any> = new Subject<any>();
  private userLoggedIn = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  getToken(): string {
    return this.authToken;
  }

  checkJWTtoken() {
    this.http
      .get<JWTResponse>(baseURL + '/auth/checkJWT')
      .pipe(first())
      .subscribe(
        (res) => {
          this.setUserLoggedIn(true);
          this.sendUser(res.user);
        },
        (err) => {
          console.log('JWT Token invalid: ', err);
          this.destroyUserCredentials();
        }
      );
  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.clearUser();
    this.isAuthenticated = false;
    localStorage.removeItem(this.tokenKey);
  }

  clearUser() {
    this.user.next(undefined);
  }

  loadUserCredentials() {
    const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
    if (credentials && credentials.username !== undefined) {
      this.useCredentials(credentials);
      if (this.authToken) {
        this.refreshJWTtoken()
          .pipe(first())
          .subscribe(() => console.log('Refreshed'));
      }
    }
  }

  refreshJWTtoken(): Observable<any> {
    return this.http.get<any>(baseURL + '/auth/refreshJWT').pipe(
      map((res) => {
        this.storeUserCredentials({
          username: res.data.email,
          token: res.token,
          user: res.data,
        });
        this.setUserLoggedIn(true);
        return { success: true };
      }),
      catchError((error) => this.processHTTPMsgService.handleError(error))
    );
  }

  getUser(): Observable<any> {
    this.loadUserCredentials();
    return this.user.asObservable();
  }

  logIn(user: any): Observable<any> {
    return this.http
      .post<AuthResponse>(baseURL + '/auth/signin', {
        email: user.email.toLowerCase(),
        password: user.password,
      })
      .pipe(
        map((res) => {
          this.storeUserCredentials({
            username: user.email.toLowerCase(),
            token: res.data.token,
            user: res.data,
          });
          this.setUserLoggedIn(true);
          return {
            success: true,
            email: user.email.toLowerCase(),
            id: res.data.id,
            admin_account: res.data.admin_account,
            client_account: res.data.client_account,
            participant_account: res.data.participant_account,
          };
        }),
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  requestReset(email): Observable<any> {
    return this.http
      .get(baseURL + '/auth/request_reset/' + email)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  resetPassword(password, token): Observable<any> {
    const content = {
      password: password,
    };
    return this.http
      .post(baseURL + '/auth/reset_password/' + token, content)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  storeUserCredentials(credentials: any) {
    localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
    this.useCredentials(credentials);
  }

  useCredentials(credentials: any) {
    this.isAuthenticated = true;
    this.sendUser(credentials.user);
    this.authToken = credentials.token;
  }

  sendUser(user: any) {
    this.user.next(user);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUsers(
    client_account = '%',
    exclusion_list = '{""}',
    participant_account = '%'
  ): Observable<any> {
    let params = new HttpParams()
      .set('client_account', client_account)
      .set('participant_account', participant_account)
      .set('exclusion_list', exclusion_list);

    return this.http
      .get(baseURL + '/users', { params })
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  deleteExternalUser(project_id, user_id): Observable<any> {
    return this.http
      .delete(
        baseURL + '/projects/' + project_id + '/external_users/' + user_id
      )
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  getActiveProject(): Observable<any> {
    return this.http
      .get(baseURL + '/users/activeProject')
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  deleteAccount(): Observable<any> {
    return this.http
      .delete(baseURL + '/users')
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  updatePassword(old_password, new_password): Observable<any> {
    const content = {
      old_password: old_password,
      new_password: new_password,
    };
    return this.http
      .put(baseURL + '/auth/update_password', content)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  createCostelloUser(first_name, last_name, email): Observable<any> {
    const content = {
      first_name: first_name,
      last_name: last_name,
      email: email,
    };
    return this.http
      .post(baseURL + '/costello_users', content)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  updateUser(user_id, first_name, last_name, email, title?): Observable<any> {
    const content = {
      first_name: first_name,
      last_name: last_name,
      email: email,
    };
    if (title) {
      content['title'] = title;
    }
    return this.http
      .patch(baseURL + '/users/' + user_id, content)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  deleteUser(user_id): Observable<any> {
    return this.http
      .delete(baseURL + '/users/' + user_id)
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }

  importParticipants(participants, project_id): Observable<any> {
    return this.http
      .post(baseURL + `/projects/${project_id}/import_participants`, {
        participants,
      })
      .pipe(
        catchError((error) => this.processHTTPMsgService.handleError(error))
      );
  }
}
