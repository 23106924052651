<div class="figure" *ngIf="chartData | async as chartData else processAsync">
    <canvas *ngIf="chartData.data.active_participants" baseChart 
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType">
  </canvas>
  <a *ngIf="!chartData.data.active_participants" fxLayoutAlign="center">
    No participants have been added</a>
</div>

<ng-template #processAsync>
  <ng-template #loading>
      <div>
          <mat-spinner color="accent" diameter="50"></mat-spinner>
      </div>
  </ng-template>
</ng-template>