import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-text-summary',
  templateUrl: './question-text-summary.component.html',
  styleUrls: ['./question-text-summary.component.scss']
})
export class QuestionTextSummaryComponent implements OnInit {

  @Input() textData: any;
  @Input() questionType: string;

  constructor() { }

  displayedColumns: string[] = ['item'];

  ngOnInit(): void {
    
  }

}
