import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { QuestionTypes } from '../../../_shared/questionTypes';
import { RoundAddQuestionSheetComponent } from '../../../components/round-add-question-sheet/round-add-question-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable, of, Subject } from 'rxjs';
import { UserService } from '../../../_services/user.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../_services/process-httpmsg.service';
import { ProfilingQuestionService } from '../../../_services/profiling-question.service';
import { Download, DownloadService } from '../../../_services/download.service';
import { ProfilingQuestionUploadComponent } from './components/profiling-question-upload/profiling-question-upload.component';
import { ProfilingOptionUploadComponent } from './components/profiling-option-upload/profiling-option-upload.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../_services/project.service';

@Component({
  selector: 'app-profiling-setup',
  templateUrl: './profiling-setup.component.html',
  styleUrls: ['./profiling-setup.component.scss'],
})
export class ProfilingSetupComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');

  form: UntypedFormGroup;
  questionTypes = [
    'Free Text',
    'Multiple Choice',
    'Numeric',
    'Ranking',
    'Single Choice',
  ];

  download$: Observable<Download>;
  project: Observable<any>;
  questions: Observable<any[]>;

  ngDestroy$ = new Subject();

  errMsg: String;
  loadingError$ = new Subject<boolean>();

  constructor(
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private location: Location,
    private downloadService: DownloadService,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private profilingQuestionService: ProfilingQuestionService,
    private route: ActivatedRoute,
    public router: Router,
    private userService: UserService
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[^=^+^-^@^\t^\r^/^\\^^^;^`^#][^/^\\^@^^^;^`^#]*$'
          ),
        ],
      ],
      questionType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.userService.isAuthenticated) {
      this.userService
        .getUser()
        .pipe(first())
        .subscribe(
          (user) => {
            if (user) {
              if (user.client_account) {
                this.router.navigate(['/main/project/' + this.project_id]);
              } else if (user.participant_account) {
                this.router.navigate(['/login']);
              } else {
                this.project = this.projectService
                  .getProject(this.project_id)
                  .pipe(
                    catchError((error) => {
                      this.errMsg =
                        this.processHTTPMsgService.extractH1Error(error);
                      console.error('Error loading project:', error);
                      this.loadingError$.next(true);
                      return of();
                    })
                  );
              }
            }
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
          }
        );

      this.questions = this.profilingQuestionService
        .getProfilingQuestions(this.project_id)
        .pipe(
          catchError((error) => {
            this.errMsg = error;
            console.error('Error loading questions:', error);
            this.loadingError$.next(true);
            return of();
          })
        );
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  goBack(): void {
    this.router.navigate(['/main/project/' + this.project_id]);
  }

  importQuestions() {
    const dialogRef = this.dialog.open(ProfilingQuestionUploadComponent, {
      data: { project_id: this.project_id },
      height: '50%',
      width: '60%',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }

  importQuestionOptions() {
    const dialogRef = this.dialog.open(ProfilingOptionUploadComponent, {
      data: { project_id: this.project_id },
      height: '50%',
      width: '60%',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          window.location.reload();
        }
      });
  }

  exportQuestions() {
    this.download$ = this.downloadService.downloadProfilingQuestions(
      this.project_id
    );
  }

  exportQuestionOptions() {
    this.download$ = this.downloadService.downloadProfilingOptions(
      this.project_id
    );
  }

  exportResponses() {
    this.download$ = this.downloadService.downloadProfilingResponses(
      this.project_id
    );
  }

  addQuestion(question, questionType) {
    this.profilingQuestionService
      .addProfilingQuestion(this.project_id, question, questionType)
      .pipe(first())
      .subscribe(
        (newQuestion) => {
          this.router.navigate([
            '/main/project/' +
              this.project_id +
              '/profiling/question/' +
              newQuestion.data.question_number +
              '/edit',
          ]);
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }

  addSmallScreen() {
    const Ref = this._bottomSheet.open(RoundAddQuestionSheetComponent, {
      data: {
        use_freetext: true,
        use_likert: false,
        use_mcq: true,
        use_numeric: true,
        use_ranking: true,
        use_scq: true,
      },
    });
    Ref.afterDismissed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.addQuestion(result.name, result.questionType);
        }
      });
  }
}
