import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from '@angular/core';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseService } from '../../../../../_services/response.service';
import { Observable, BehaviorSubject, Subscription, Subject, of } from 'rxjs';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../../_services/user.service';
import { ProjectService } from '../../../../../_services/project.service';

@Component({
  selector: 'app-question-response-table',
  templateUrl: './question-response-table.component.html',
  styleUrls: ['./question-response-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class QuestionResponseTableComponent implements OnInit, OnDestroy {
  is_client: boolean;

  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  question_number = this.route.snapshot.paramMap.get('question_number');

  dataSource: TableDataSource;
  // TODO: alternative table layout for free text questions - to allow categorisation and to replace the note field with just response
  displayedColumns = [
    'participant',
    'response',
    'note',
    'analysis_inclusion',
    'warning',
    'save',
  ];
  tableData = [];
  errMsg: String;
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'participant';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageSub: Subscription;
  pageIndex = 0;
  pageSize = 10;
  inclusion = new UntypedFormArray([]);
  expandedElement: string | null;

  project: Observable<any>;

  ngDestroy$ = new Subject();

  loadingError$ = new Subject<boolean>();

  constructor(
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private responseService: ResponseService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(first())
      .subscribe(
        (user) => {
          if (user) {
            if (user.participant_account) {
              this.router.navigate(['/login']);
            } else {
              this.is_client = user.client_account;
            }
          }
        },
        (error) => {
          this.errMsg = error;
        }
      );

    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.responseService,
      this.processHTTPMsgService
    );
    this.dataSource
      .loadResponses(this.project_id, this.round_id, this.question_number)
      .then(
        (res) => {
          this.tableData = res;

          //Reset form array to remove existing controls
          while (this.inclusion.length !== 0) {
            this.inclusion.removeAt(0);
          }

          res.forEach((response) => {
            this.inclusion.push(
              new UntypedFormControl(response.analysis_inclusion)
            );
          });

          this.showTable = true;
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );

    this.project = this.projectService.getProject(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading project:', error);
        this.loadingError$.next(true);
        return of();
      })
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (screenWidth <= 959) {
      this.displayedColumns = ['participant', 'response', 'warning'];
    } else {
      this.displayedColumns = [
        'participant',
        'response',
        'note',
        'analysis_inclusion',
        'warning',
        'save',
      ];
    }
  }

  expand_contract(row) {
    if (this.expandedElement === row) {
      this.expandedElement = null;
    } else {
      this.expandedElement = row;
    }
  }

  saveChanges() {
    const response_ids = this.tableData.map((row) => {
      return row.id;
    });

    this.responseService
      .responseInclusion(
        this.project_id,
        this.tableData[0].question_id,
        this.tableData[0].question_type,
        this.tableData[0].calculate_consensus,
        this.inclusion.value,
        response_ids
      )
      .pipe(first())
      .subscribe(
        () => {
          window.location.reload();
        },
        (error) => {
          this.errMsg = error;
          console.error(error);
        }
      );
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private responseService: ResponseService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadResponses(project_id, round_id, question_number): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.responseService
        .getQuestionResponses(project_id, round_id, question_number)
        .pipe(first())
        .subscribe(
          (responses) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(responses.data);
            resolve(responses.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
