<!-- ERROR BANNER -->
<a *ngIf="errMsg" class="warn-fore" fxLayout="row" fxLayoutAlign="center"><b>Error:&nbsp;</b> {{errMsg}}</a>
<div *ngIf="showTable" [class.full-container]="!is_client" [class.table-container]="is_client">

<mat-table mat-table [dataSource]="tableData" *ngIf="showTable" matSort matSortActive={{sortField}} matSortDirection={{direction}}>

    <ng-container matColumnDef="question_number">
        <mat-header-cell *matHeaderCellDef>No.</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.question_number}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Question</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
          <a>{{item.question}}</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="num_responses">
        <mat-header-cell *matHeaderCellDef>Responses</mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <!-- TODO: Replace with something else as this doesn't work when they are repeated over multiple rounds... -->
          <a>{{item.responses}}/N</a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action_view">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <button fxHide.lt-sm="true" mat-button color="primary" (click)="viewResponses(item, n)">Responses</button>
            <button fxHide.gt-xs="true" mat-icon-button color="primary" (click)="viewResponses(item, n)">
                <mat-icon>bar_chart</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action_edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <button *ngIf="started_rounds === '0'"
                fxHide.lt-sm="true" mat-button color="primary" (click)="editQuestion(item, n)">Edit</button>
            <button *ngIf="started_rounds === '0'"
            fxHide.gt-xs="true" mat-icon-button color="primary" (click)="editQuestion(item, n)">
                <mat-icon>edit</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action_delete">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item; let n = index;">
            <button *ngIf="started_rounds === '0'"
                mat-icon-button color="warn" (click)="deleteQuestion(item, n)">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" (click)="onRowClicked(row, i)"></mat-row>
</mat-table>
</div>
<!-- <mat-paginator 
  [length]="numRecords"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10]" 
  showFirstLastButtons
></mat-paginator> -->

<div class='loading' fxLayout="row" fxLayoutAlign="center center" *ngIf="dataSource.loading$ | async">
    <mat-spinner color="accent" diameter="50"></mat-spinner>
</div>