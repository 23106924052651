import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ProjectService } from '../../../../../_services/project.service';

@Component({
  selector: 'app-footer-participant',
  templateUrl: './footer-participant.component.html',
  styleUrls: ['./footer-participant.component.scss'],
})
export class FooterParticipantComponent implements OnInit {
  year: Number;
  project_id = this.route.snapshot.paramMap.get('project_id');

  copyApprovalDetails: Observable<any>;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  constructor(
    public route: ActivatedRoute,
    private processHTTPMsgService: ProcessHttpmsgService,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.copyApprovalDetails = this.projectService
      .getCopyApprovalDetails(this.project_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading user: ', error);
          this.loadingError$.next(true);
          return of();
        })
      );
  }
}
