import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { QuestionService } from '../../../../../_services/question.service';
import {
  UntypedFormArray,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-branching-logic',
  templateUrl: './branching-logic.component.html',
  styleUrls: ['./branching-logic.component.scss'],
})
export class BranchingLogicModalComponent implements OnInit {
  roundQuestions: Observable<any>;
  savedBranches: Observable<any>;
  errMsg: string;
  loadingError$ = new Subject<boolean>();
  branches = new UntypedFormArray([]);
  choices = [{ label: 'End Round', id: -1 }];

  constructor(
    private questionService: QuestionService,
    public dialogRef: MatDialogRef<BranchingLogicModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // Load existing branching logic
    this.savedBranches = this.questionService
      .getBranchingLogic(
        this.data.project_id,
        this.data.round_id,
        this.data.question_id
      )
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading branches:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.savedBranches.pipe(first()).subscribe(
      (branching_logic) => {
        const default_branch = branching_logic.data.find((branch) => {
          return branch.is_default;
        });
        // Loop through option labels and add branches if found, else use default
        this.data.options.forEach((element) => {
          var old_branch = branching_logic.data.find((branch) => {
            return branch.condition_value === element.label;
          });
          if (old_branch) {
            if (!old_branch.follow_question && old_branch.is_end) {
              this.branches.push(
                new UntypedFormControl(-1, Validators.required)
              );
            } else {
              this.branches.push(
                new UntypedFormControl(
                  old_branch.follow_question,
                  Validators.required
                )
              );
            }
          } else {
            if (!default_branch.follow_question && default_branch.is_end) {
              this.branches.push(
                new UntypedFormControl(-1, Validators.required)
              );
            } else {
              this.branches.push(
                new UntypedFormControl(
                  default_branch.follow_question,
                  Validators.required
                )
              );
            }
          }
        });
      },
      (error) => {
        this.errMsg = error;
      }
    );

    this.roundQuestions = this.questionService
      .getQuestions(
        this.data.project_id,
        this.data.round_id,
        '{"' + this.data.question_id + '"}'
      )
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading questions:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.roundQuestions.pipe(first()).subscribe(
      (questions) => {
        questions.data.forEach((question) => {
          this.choices.push({
            label: 'Qu ' + question.question_number + ': ' + question.question,
            id: question.id,
          });
        });
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const updates = [];
    // Loop through options/form values
    // Work out if form value was changed (handling avoidance of updating/creating default branches)
    // Parse update of end vs following_question
    for (let i = 0; i < this.branches.value.length; i++) {
      if (this.branches.controls[i].dirty) {
        if (this.branches.value[i] === -1) {
          updates.push({
            condition_value: this.data.options[i].label,
            follow_question: null,
            is_end: true,
            is_default: false,
            start_question: this.data.question_id,
            condition_question: this.data.question_id,
          });
        } else {
          updates.push({
            condition_value: this.data.options[i].label,
            follow_question: this.branches.value[i],
            is_end: false,
            is_default: false,
            start_question: this.data.question_id,
            condition_question: this.data.question_id,
          });
        }
      }
    }
    this.dialogRef.close(updates);
  }
}

@Component({
  selector: 'app-branching-logic-smallscreen',
  templateUrl: './branching-logic.component.html',
  styleUrls: ['./branching-logic.component.scss'],
})
export class BranchingLogicSheetComponent implements OnInit {
  roundQuestions: Observable<any>;
  savedBranches: Observable<any>;
  errMsg: string;
  loadingError$ = new Subject<boolean>();
  branches = new UntypedFormArray([]);
  choices = [{ label: 'End Round', id: -1 }];

  constructor(
    private questionService: QuestionService,
    private _bottomSheetRef: MatBottomSheetRef<BranchingLogicSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // Load existing branching logic
    this.savedBranches = this.questionService
      .getBranchingLogic(
        this.data.project_id,
        this.data.round_id,
        this.data.question_id
      )
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading branches:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.savedBranches.pipe(first()).subscribe(
      (branching_logic) => {
        const default_branch = branching_logic.data.find((branch) => {
          return branch.is_default;
        });
        // Loop through option labels and add branches if found, else use default
        this.data.options.forEach((element) => {
          var old_branch = branching_logic.data.find((branch) => {
            return branch.condition_value === element.label;
          });
          if (old_branch) {
            if (!old_branch.follow_question && old_branch.is_end) {
              this.branches.push(
                new UntypedFormControl(-1, Validators.required)
              );
            } else {
              this.branches.push(
                new UntypedFormControl(
                  old_branch.follow_question,
                  Validators.required
                )
              );
            }
          } else {
            if (!default_branch.follow_question && default_branch.is_end) {
              this.branches.push(
                new UntypedFormControl(-1, Validators.required)
              );
            } else {
              this.branches.push(
                new UntypedFormControl(
                  default_branch.follow_question,
                  Validators.required
                )
              );
            }
          }
        });
      },
      (error) => {
        this.errMsg = error;
      }
    );

    this.roundQuestions = this.questionService
      .getQuestions(
        this.data.project_id,
        this.data.round_id,
        '{"' + this.data.question_id + '"}'
      )
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading questions:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.roundQuestions.pipe(first()).subscribe(
      (questions) => {
        questions.data.forEach((question) => {
          this.choices.push({
            label: 'Qu ' + question.question_number + ': ' + question.question,
            id: question.id,
          });
        });
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

  save() {
    const updates = [];
    // Loop through options/form values
    // Work out if form value was changed (handling avoidance of updating/creating default branches)
    // Parse update of end vs following_question
    for (let i = 0; i < this.branches.value.length; i++) {
      if (this.branches.controls[i].dirty) {
        if (this.branches.value[i] === -1) {
          updates.push({
            condition_value: this.data.options[i].label,
            follow_question: null,
            is_end: true,
            is_default: false,
            start_question: this.data.question_id,
            condition_question: this.data.question_id,
          });
        } else {
          updates.push({
            condition_value: this.data.options[i].label,
            follow_question: this.branches.value[i],
            is_end: false,
            is_default: false,
            start_question: this.data.question_id,
            condition_question: this.data.question_id,
          });
        }
      }
    }

    this._bottomSheetRef.dismiss(updates);
  }
}
