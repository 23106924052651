import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ProcessHttpmsgService } from '../../../../../_services/process-httpmsg.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { RoundService } from '../../../../../_services/round.service';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProfilingResponseService } from '../../../../../_services/profiling-response.service';
import { ProjectService } from '../../../../../_services/project.service';

@Component({
  selector: 'app-profiling-response-table',
  templateUrl: './profiling-response-table.component.html',
  styleUrls: ['./profiling-response-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ProfilingResponseTableComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = '1'; // update to use project_id and is_profiling to retrieve
  question_number = this.route.snapshot.paramMap.get('question_number');

  dataSource: TableDataSource;
  // TODO: alternative table layout for free text questions - to allow categorisation and to replace the note field with just response
  displayedColumns = ['round', 'participant', 'response', 'note'];
  tableData = [];
  errMsg: String;
  loadingError$ = new Subject<boolean>();
  showTable: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  direction: 'asc';
  sortField: 'participant';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  numRecords = 1;
  pageIndex = 0;
  pageSize = 10;
  expandedElement: string | null;

  form: UntypedFormGroup;
  project: Observable<any>;
  rounds: Observable<any>;
  dummyRounds = ['1', '2', '3'];
  participants: Observable<any>;
  dummyParticipants = ['1', '2', '3', '4', '5'];
  ngDestroy$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private processHTTPMsgService: ProcessHttpmsgService,
    private route: ActivatedRoute,
    private profilingResponseService: ProfilingResponseService,
    private projectService: ProjectService,
    private roundService: RoundService
  ) {
    this.form = fb.group({
      round: [, Validators.required],
      participant: [, Validators.required],
    });
  }

  ngOnInit(): void {
    this.adjustColumns(window.innerWidth);

    this.dataSource = new TableDataSource(
      this.profilingResponseService,
      this.processHTTPMsgService
    );
    this.dataSource
      .loadResponses(this.project_id, this.question_number, '{"%"}', '{"%"}')
      .then(
        (res) => {
          this.tableData = res;
          this.showTable = true;
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
    this.project = this.projectService.getProject(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading project:', error);
        this.loadingError$.next(true);
        return of();
      })
    );
    this.rounds = this.roundService.getRounds(this.project_id).pipe(
      catchError((error) => {
        this.errMsg = this.processHTTPMsgService.extractH1Error(error);
        console.error('Error loading rounds:', error);
        this.loadingError$.next(true);
        return of();
      })
    );
    this.rounds.pipe(first()).subscribe(
      (result) => {
        const rounds = result.data.map((round) => {
          return round.id;
        });
        this.form.controls.round.setValue(rounds);
      },
      (error) => {
        this.errMsg = error;
      }
    );
    this.participants = this.projectService
      .getProjectParticipants(this.project_id)
      .pipe(
        catchError((error) => {
          this.errMsg = this.processHTTPMsgService.extractH1Error(error);
          console.error('Error loading participants:', error);
          this.loadingError$.next(true);
          return of();
        })
      );
    this.participants.pipe(first()).subscribe(
      (result) => {
        const participants = result.data.map((participant) => {
          return participant.participant;
        });
        this.form.controls.participant.setValue(participants);
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // Adjust displayed columns based on screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustColumns(window.innerWidth);
  }

  adjustColumns(screenWidth) {
    if (screenWidth <= 959) {
      this.displayedColumns = ['round', 'participant', 'response', 'action'];
    } else {
      this.displayedColumns = ['round', 'participant', 'response', 'note'];
    }
  }

  expand_contract(row) {
    if (this.expandedElement === row) {
      this.expandedElement = null;
    } else {
      this.expandedElement = row;
    }
  }

  applyFilter(): void {
    this.showTable = false;
    const participantFilter = JSON.stringify(
      this.form.value.participant.map((item) => item.toString())
    )
      .replace('[', '{')
      .replace(']', '}');
    const roundFilter = JSON.stringify(
      this.form.value.round.map((item) => item.toString())
    )
      .replace('[', '{')
      .replace(']', '}');

    this.dataSource
      .loadResponses(
        this.project_id,
        this.question_number,
        participantFilter,
        roundFilter
      )
      .then(
        (res) => {
          this.tableData = res;
          this.showTable = true;
        },
        (error) => {
          console.error(error);
          this.errMsg = error;
        }
      );
  }
}

export class TableDataSource implements DataSource<any> {
  private rowsSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  errMsg: string;
  tableData: any;

  constructor(
    private profilingResponseService: ProfilingResponseService,
    private processHTTPMsgService: ProcessHttpmsgService
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.rowsSubject.complete();
    this.loadingSubject.complete();
  }

  read(): Observable<any> {
    return this.rowsSubject.asObservable();
  }

  loadResponses(
    project_id,
    question_number,
    participantFilter,
    roundFilter
  ): any {
    this.loadingSubject.next(true);
    var promise = new Promise((resolve, reject) => {
      this.profilingResponseService
        .getQuestionResponses(
          project_id,
          question_number,
          participantFilter,
          roundFilter
        )
        .pipe(first())
        .subscribe(
          (responses) => {
            this.loadingSubject.next(false);
            this.rowsSubject.next(responses.data);
            resolve(responses.data);
          },
          (error) => {
            console.error(error);
            this.errMsg = error;
            reject();
          }
        );
    });
    return promise;
  }
}
