import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ProjectService } from '../../../../../_services/project.service';

@Component({
  selector: 'app-project-results-figure',
  templateUrl: './project-results-figure.component.html',
  styleUrls: ['./project-results-figure.component.scss'],
})
export class ProjectResultsFigureComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  chartData: Observable<any>;
  ngDestroy$ = new Subject();

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            //Function to 'wrap' axis labels rather than have them go diagonally
            callback: function (label: any) {
              if (/\s/.test(label) && label.length > 10) {
                return label.split(' ');
              } else {
                return label;
              }
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Number of questions',
          },
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      ],
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    //Counts of questions with 'Consensus' status
    {
      data: [],
      label: 'Consensus',
      stack: 'a',
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderColor: [],
      hoverBorderColor: [],
    },
    //Counts of questions with 'No consensus' status
    {
      data: [],
      label: 'No Consensus',
      stack: 'a',
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderColor: [],
      hoverBorderColor: [],
    },
    //Counts of questions with 'N/A' status
    {
      data: [],
      label: 'Consensus Not Measured',
      stack: 'a',
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderColor: [],
      hoverBorderColor: [],
    },
    //Counts of questions with 'Awaiting' status
    {
      data: [],
      label: 'Awaiting',
      stack: 'a',
      backgroundColor: [],
      hoverBackgroundColor: [],
      borderColor: [],
      hoverBorderColor: [],
    },
  ];

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.chartData = this.projectService
      .getProjectResults(this.project_id)
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading project:', error);
          this.loadingError$.next(true);
          return of();
        })
      );

    this.chartData.pipe(first()).subscribe(
      (data) => {
        //Set chart data
        this.barChartLabels = data.data.round_labels;
        this.barChartData[0].data = data.data.consensus;
        this.barChartData[1].data = data.data.no_consensus;
        this.barChartData[2].data = data.data.unmeasured;
        this.barChartData[3].data = data.data.awaiting;

        //Set bar colours
        var consensusBGC = new Array(data.data.round_labels.length);
        consensusBGC.fill('rgba(136, 14, 79, 0.5)');
        this.barChartData[0].backgroundColor = consensusBGC;
        var consensusFGC = new Array(data.data.round_labels.length);
        consensusFGC.fill('rgba(136, 14, 79, 1)');
        this.barChartData[0].hoverBackgroundColor = consensusFGC;
        this.barChartData[0].borderColor = consensusFGC;
        this.barChartData[0].hoverBorderColor = consensusFGC;

        var no_consensusBGC = new Array(data.data.round_labels.length);
        no_consensusBGC.fill('rgba(247,146,72, 0.5)');
        this.barChartData[1].backgroundColor = no_consensusBGC;
        var no_consensusFGC = new Array(data.data.round_labels.length);
        no_consensusFGC.fill('rgba(247,146,72, 1)');
        this.barChartData[1].hoverBackgroundColor = no_consensusFGC;
        this.barChartData[1].borderColor = no_consensusFGC;
        this.barChartData[1].hoverBorderColor = no_consensusFGC;

        var unmeasuredBGC = new Array(data.data.round_labels.length);
        unmeasuredBGC.fill('rgba(92,107,192, 0.5)');
        this.barChartData[2].backgroundColor = unmeasuredBGC;
        var unmeasuredFGC = new Array(data.data.round_labels.length);
        unmeasuredFGC.fill('rgba(92,107,192, 1)');
        this.barChartData[2].hoverBackgroundColor = unmeasuredFGC;
        this.barChartData[2].borderColor = unmeasuredFGC;
        this.barChartData[2].hoverBorderColor = unmeasuredFGC;

        var awaitedBGC = new Array(data.data.round_labels.length);
        awaitedBGC.fill('rgba(224, 224, 224, 0.5)');
        this.barChartData[3].backgroundColor = awaitedBGC;
        var awaitedFGC = new Array(data.data.round_labels.length);
        awaitedFGC.fill('rgba(224, 224, 224, 1)');
        this.barChartData[3].hoverBackgroundColor = awaitedFGC;
        this.barChartData[3].borderColor = awaitedFGC;
        this.barChartData[3].hoverBorderColor = awaitedFGC;
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
