<mat-toolbar class="header">
    <mat-toolbar-row>
        <button mat-icon-button matTooltip="Go Back" (click)="goBack()">
            <mat-icon aria-label="Go Back">navigate_before</mat-icon>
        </button>
        <a fxShow.xs="false">COSTELLO MEDICAL DELPHI APP</a>
        <a fxShow.gt-xs="false">DELPHI</a>
        
        <!--sign in status and button-->
        <span class="flex-spacer"></span>
        <a *ngIf="username" fxShow.xs="false">{{username}}</a>
        <a *ngIf="username" fxShow.xs="false" mat-button (click)="logOut()">Logout</a>
        <button mat-icon-button fxShow.gt-xs="false" (click)="logOut()" matTooltip="Log out of the app">
            <mat-icon aria-label="Log out">exit_to_app</mat-icon>
        </button>
    </mat-toolbar-row>
    <!-- <mat-toolbar-row>
        <a class="figure-header">Account Management</a>
    </mat-toolbar-row> -->
</mat-toolbar>

<div fxLayout="row" fxLayout.lt-md="column" class="main-content">
    <!-- Left Panel -->
    <div fxFlex="50%" fxFlex.lt-md="100%">
        <h3 class="figure-header">CHANGE PASSWORD</h3>
        <mat-divider></mat-divider>
        <form [formGroup]="form" autocomplete="off" class="figure-header" fxLayout="column" fxLayoutAlign="start start">
            <mat-form-field class="form-control">
                <input matInput placeholder="Enter Your Current Password" type="password" id="password" formControlName="password" required>
                <mat-hint>
                  <span *ngIf="form.controls.password.errors && !form.controls.password.pristine">Please enter your current password</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="form-control">
                <input matInput placeholder="Enter New Password" type="password" id="newPassword" formControlName="newPassword" required>
                <mat-hint>
                  <span *ngIf="form.controls.newPassword.errors && !form.controls.newPassword.pristine">Please enter a new password</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="form-control">
                <input matInput placeholder="Confirm Password" type="password" id="repeatPassword" formControlName="repeatPassword" required>
                <mat-hint>
                  <span *ngIf="form.controls.newPassword.errors && !form.controls.newPassword.pristine">Please re-enter the new password</span>
                </mat-hint>
            </mat-form-field>
            <button mat-stroked-button class="button" [disabled]="form.invalid" (click)="updatePassword()">
                Update Password
            </button>
        </form>
        <a *ngIf="errMsg" class="warn-fore error-message" fxLayout="row" fxLayoutAlign="center">{{errMsg}}</a>
    </div>

    <!-- Right Panel -->
    <div fxFlex="50%" fxFlex.lt-md="100%">
        <h3 class="figure-header">DELETE ACCOUNT</h3>
        <mat-divider></mat-divider>
        <div class="figure-header">
            <p *ngIf="account_type==='Client'">
                Please note this action is irreversible. Deleting your account will remove all details associated with your account.
            </p>
            <p *ngIf="account_type==='Participant'">
                Please note this action is irreversible. Deleting your account will remove all details associated with your account, including any responses you have provided.
            </p>
            <p *ngIf="account_type==='Costello'">
                Please note this action is irreversible. <b>Any projects where you are the listed contact will also be deleted.</b>
            </p>
            <button mat-flat-button color="warn" (click)="deleteAccount()">
                Permanently Delete Account
            </button>
        </div>
        
    </div>
</div>