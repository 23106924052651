import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { RoundService } from '../../../../../_services/round.service';

@Component({
  selector: 'app-round-progress-figure',
  templateUrl: './round-progress-figure.component.html',
  styleUrls: ['./round-progress-figure.component.scss'],
})
export class RoundProgressFigureComponent implements OnInit, OnDestroy {
  project_id = this.route.snapshot.paramMap.get('project_id');
  round_id = this.route.snapshot.paramMap.get('round_id');
  chartData: Observable<any>;
  ngDestroy$ = new Subject();

  showChart = false;

  errMsg: string;
  loadingError$ = new Subject<boolean>();

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Number of questions',
          },
          ticks: {
            min: 0,
            stepSize: 1,
          },
        },
      ],
    },
  };
  public barChartLabels: Label[] = [
    'Awaiting',
    'Consensus Not Measured',
    'No Consensus',
    'Consensus',
  ];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    {
      data: [0, 0, 0, 0],
      label: 'Number of questions',
      backgroundColor: [
        'rgba(224, 224, 224, 0.5)',
        'rgba(92,107,192, 0.5)',
        'rgba(247,146,72, 0.5)',
        'rgba(136, 14, 79, 0.5)',
      ],
      hoverBackgroundColor: [
        'rgba(224, 224, 224, 1)',
        'rgba(92,107,192, 1)',
        'rgba(247,146,72, 1)',
        'rgba(136, 14, 79, 1)',
      ],
      borderColor: [
        'rgba(224, 224, 224, 1)',
        'rgba(92,107,192, 1)',
        'rgba(247,146,72, 1)',
        'rgba(136, 14, 79, 1)',
      ],
      hoverBorderColor: [
        'rgba(224, 224, 224, 1)',
        'rgba(92,107,192, 1)',
        'rgba(247,146,72, 1)',
        'rgba(136, 14, 79, 1)',
      ],
    },
  ];

  constructor(
    private roundService: RoundService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.chartData = this.roundService
      .getRoundProgress(this.project_id, this.round_id)
      .pipe(
        catchError((error) => {
          this.errMsg = error;
          console.error('Error loading project:', error);
          this.loadingError$.next(true);
          return of();
        })
      );

    this.chartData.pipe(first()).subscribe(
      (data) => {
        this.barChartData[0].data[0] = data.data.awaiting;
        this.barChartData[0].data[1] = data.data.unmeasured;
        this.barChartData[0].data[2] = data.data.no_consensus;
        this.barChartData[0].data[3] = data.data.consensus;
        this.showChart = true;
      },
      (error) => {
        this.errMsg = error;
      }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
