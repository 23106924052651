import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class ProcessHttpmsgService {

  constructor() { }

  public handleError(error: HttpErrorResponse | any) {
    let errMsg: string;

    if (error.error) {
      errMsg = error.error.error;
    } else {
      errMsg = `${error.status} - ${error.statusText || ''} ${error.error}`;
    }

    return throwError(errMsg);
  }

  public extractH1Error(error: string) {
    let message: string;

    if (error.indexOf('<h1>') > -1) {
      message = error.match(/<h1>(.+)<\/h1>/)[1];
      if (error.indexOf('duplicate key') > -1) {
        message = 'Cannot save item with duplicate value'
      }
    } else {
      message = error;
    }
    return message;
  }

}
